import { Sheet, Stack } from "@mui/joy";
import PageTitleBox from "../../components/PageTitleBox";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFound from "../NotFound/NotFound";
import DuplicateSKUForm from "../../components/Inventory/Excel/DuplicateSKUForm";
import { ItemService } from "../../services/item.service";
import UploadStatusBox from "../../components/Inventory/Excel/UploadStatusBox";
import useContainer from "./useContainer";
import { ICatalogDraftResponse } from "../../types/draft";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

const CatalogueDuplicateSKU = () => {
  const { t } = useTranslation();

  const { catalogId, draftId } = useParams();

  const [draft, setDraft] = useState<ICatalogDraftResponse>();

  const { isLoading, setIsLoading } = useContainer();

  const cachedInvalidItems: ICatalogDraftResponse["draft"] = JSON.parse(
    localStorage.getItem("catalog-import-invalid-items") || "{}"
  );

  /**
   * takes draftId as param, will assign to draft state
   * @param draftId
   * @returns IInventoryDraftResponse
   */
  const getInventoryDraft = async (draftId: string) => {
    setIsLoading(true);

    try {
      const draftResponse = await ItemService.getInventoryDraft(draftId);
      console.log("DRAFT RESPONSE", draftResponse);

      setDraft({
        ...draftResponse,
        draft: cachedInvalidItems,
      });
      setIsLoading(false);
    } catch (error: any) {
      console.error("Error fetching draft:", error);
      alert(error.message);
      setIsLoading(false);
    }
  };

  /**
   * run when url param draftId updated
   */
  useEffect(() => {
    if (draftId) {
      getInventoryDraft(draftId);
    }
  }, [draftId]);

  if (!draftId || !cachedInvalidItems) {
    return <NotFound />;
  }

  return !!draft ? (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <Stack
        direction={{ xs: "column", lg: "row" }}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          gap: { xs: "24px", lg: 0 },
        }}
      >
        <PageTitleBox
          title={t("catalog.import.duplicate.title")}
          subtitle={t(
            `catalog.import.duplicate.${catalogId ? "subtitle2" : "subtitle"}`
          )}
        />

        <UploadStatusBox
          currency={draft.draft.invalidItems[0].currency}
          totalItems={draft.draft.totalItems}
        />
      </Stack>

      <DuplicateSKUForm
        draft={draft.draft}
        confirm={catalogId ? true : false}
      />

      {/* modal */}
      <LoadingModal isLoading={isLoading} />
    </Sheet>
  ) : (
    <NotFound />
  );
};

export default CatalogueDuplicateSKU;
