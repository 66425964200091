import React, { useEffect, useState } from "react";
import { ItemType } from "../../types/item";
import { ItemService } from "../../services/item.service";
import useContainer from "./useContainer";
import {
  FormLabel,
  Box,
  Button,
  FormControl,
  Input,
  Sheet,
  Stack,
  Typography,
  Table,
  Checkbox,
} from "@mui/joy";
import PageTitleBox from "../../components/PageTitleBox";
import { useTranslation } from "react-i18next";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import {
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { theme } from "../../components/Theme";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { SuccessModal } from "../../components/SuccessModal";

type BatchItemEditType = {
  data: {
    inventory: {
      inventoryId: string;
      price: number;
      price2: number;
      price3: number;
      catalogs: {
        catalogInventoryId: string;
        price: number;
      }[];
    }[];
  };
  settings: {
    price: number;
    discount: number;
  };
};

type Path<T, Prefix extends string = ""> = T extends object
  ? {
      [K in keyof T]: T[K] extends Array<infer U>
        ?
            | `${Prefix}${K & string}.${number}`
            | Path<U, `${Prefix}${K & string}.${number}.`>
        : Path<T[K], `${Prefix}${K & string}.`> | `${Prefix}${K & string}`;
    }[keyof T]
  : Prefix extends `${infer P}.`
  ? P
  : Prefix;

type BatchItemEditTypePath = Path<BatchItemEditType>;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatAdapter(props, ref) {
    const { register, setValue } = useFormContext();
    const { onChange, ...other } = props;
    const fieldName = props.name as BatchItemEditTypePath;
    const watchedValue = useWatch({ name: fieldName });

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        defaultValue={watchedValue}
        {...register(fieldName, {
          valueAsNumber: true,
          validate: (value) =>
            value === undefined ||
            value === null ||
            !isNaN(value as number) ||
            "Invalid Number",
        })}
        onValueChange={(values) => {
          setValue(fieldName, parseFloat(values.value));
        }}
        thousandSeparator=","
        valueIsNumericString
      />
    );
  }
);

const BatchEditInventory = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState<ItemType[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const {
    isLoading,
    setIsLoading,
    searchTerm,
    setSearchTerm,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
  } = useContainer();

  const methods = useForm<BatchItemEditType>();
  const { handleSubmit, setValue, getValues, reset } = methods;
  const navigate = useNavigate();

  const fetchInventoryItemsById = (itemIds: string[]) => {
    setIsLoading(true);
    ItemService.getInventoryItemsByIds({ ids: itemIds })
      .then((res) => {
        setItems(res);

        const defaultData = res.map((i) => {
          const catalogData = i.catalogs?.map((cat) => {
            return {
              catalogInventoryId: cat.id ?? undefined,
              price: cat.price ?? undefined,
            };
          });

          return {
            inventoryId: i.id,
            price: i.price,
            catalogs: catalogData,
          };
        });
        reset({
          data: { inventory: defaultData },
        });
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const itemIds = localStorage.getItem("batchEditItems");
    const addedItem = localStorage.getItem("addedInventoryItems") || "[]";
    if (JSON.parse(addedItem).length) {
      setSelectedItems([...JSON.parse(addedItem)]);
    }
    localStorage.removeItem("addedInventoryItems");
    if (itemIds) {
      const parsedItemIds = JSON.parse(itemIds);
      if (parsedItemIds.length > 0) {
        fetchInventoryItemsById(parsedItemIds);
      }
    }
  }, []);

  const onSubmit = (data: BatchItemEditType) => {
    setIsLoading(true);

    const updateAll = selectedItems.length === 0;
    const payload = data.data.inventory.filter((i) => {
      if (updateAll || selectedItems.includes(i.inventoryId)) {
        return {
          inventoryId: i.inventoryId,
          price: Number(i.price),
          price2: Number(i.price2),
          price3: Number(i.price3),
        };
      }
    });

    ItemService.patchInventoryItemPrice({ updatePriceDto: payload })
      .then((res) => {
        if (res.length > 0) {
          setAlertMessage(
            t("catalog.inventory.batchEdit.successMessage", {
              items: res.length,
            })
          );
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        handleCancel();
      });
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    }
  };

  const handleSelectAll = () => {
    const ids = items.map((i) => i.id);
    setSelectedItems(ids);
  };

  const handleCancel = () => {
    localStorage.removeItem("batchEditItems");
    if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      window.location.href = "/";
    }
  };

  const handleUpdateAll = () => {
    const currentFormData = getValues("data.inventory");
    const price = getValues("settings.price");
    const discount = !isNaN(Number(getValues("settings.discount")))
      ? getValues("settings.discount")
      : 0;
    const updateAll = selectedItems.length === 0;

    const updatedItems = items.map((item) => {
      if (updateAll || selectedItems.includes(item.id)) {
        item.price =
          price > 0
            ? price - price * (discount / 100)
            : item.price - item.price * (discount / 100);
        item.price2 =
          item.price2 != null && item.conversion2 != null
            ? price * item.conversion2
            : null;
        item.price3 =
          item.price3 != null && item.conversion3 != null
            ? price * item.conversion3
            : null;
        return item;
      }
      return item;
    });
    setItems(updatedItems);

    const updatedFormData = currentFormData.map((i) => {
      if (updateAll || selectedItems.includes(i.inventoryId)) {
        const newPrice =
          price > 0
            ? price - price * (discount / 100)
            : i.price - i.price * (discount / 100);

        return {
          ...i,
          price: newPrice,
          catalogs: i.catalogs?.map((c) => {
            return {
              ...c,
              price:
                price > 0
                  ? newPrice
                  : c.price > 0
                  ? c.price - c.price * (discount / 100)
                  : newPrice,
            };
          }),
        };
      }

      return i;
    });

    setValue("data.inventory", updatedFormData);
  };

  const handleRemoveItem = (id: string) => {
    const updatedItems = items.filter((i) => i.id !== id);

    setItems(updatedItems);

    const currentFormData = getValues("data.inventory");
    const updatedFormData = currentFormData.filter((i) => i.inventoryId !== id);

    setValue("data.inventory", updatedFormData);

    const ids = updatedItems.map((i) => i.id);
    localStorage.setItem("batchEditItems", JSON.stringify(ids));
  };

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        padding: { xs: "1rem 0", lg: "2rem" },
      }}
    >
      {items && (
        <Box
          sx={{
            width: { xs: "92.4vw", lg: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* page header */}
          <PageTitleBox
            title={t("catalog.inventory.batchEdit.pageTitle")}
            subtitle={t("catalog.inventory.batchEdit.pageSubtitle")}
          />

          {/* searchbar */}
          <Stack
            direction={{ xs: "column", lg: "row" }}
            sx={{
              alignItems: { xs: "start", lg: "center" },
              justifyContent: { xs: "start", lg: "space-between" },
              gap: { xs: 1, lg: 0 },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <Input
                // variant="linkz-input-search"
                size="sm"
                name="searchFormData.search"
                placeholder={t("catalog.inventory.searchPlaceholder")}
                startDecorator={<MagnifyingGlassIcon width={24} height={24} />}
                fullWidth
                sx={{
                  flexGrow: 1,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </Box>

            <Button
              variant="linkz-outlined-primary"
              onClick={() => {
                navigate("/client/inventory/mass-update-pricing/add-items");
              }}
              startDecorator={<PlusIcon width={18} height={18} />}
            >
              {t("catalog.inventory.batchEdit.addItemBtn")}
            </Button>
          </Stack>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(event) => {
                // event.key === "Enter" && event.preventDefault();
                if (event.key === "Enter") {
                  if (!(event.target instanceof HTMLTextAreaElement)) {
                    event.preventDefault();
                  }
                }
              }}
            >
              <Stack direction={"column"}>
                {/* update all settings fields */}

                {/* items table */}
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    maxHeight: { lg: "27.5rem" },
                    overflow: "auto",
                    borderWidth: "1px",
                    position: "relative",
                  }}
                >
                  <Table
                    borderAxis="x"
                    sx={{
                      "& th": {
                        textAlign: "center",
                      },
                      minWidth: "100%",
                      "& tr > *:first-child": {
                        position: "sticky",
                        left: 0,
                        zIndex: 10,
                        boxShadow: "1px 0 var(--TableCell-borderColor)",
                        bgcolor: "background.surface",
                      },
                      "& tr > *:last-child": {
                        position: "sticky",
                        right: 0,
                        bgcolor: "var(--TableCell-headBackground)",
                      },
                    }}
                  >
                    <thead>
                      <tr key={"header"}>
                        <th key={"checkbox"} className="w-12">
                          <Checkbox
                            size="sm"
                            onChange={(event) => {
                              if (event.target.checked) {
                                handleSelectAll();
                              } else {
                                setSelectedItems([]);
                              }
                            }}
                          />
                        </th>
                        <th key={"index"} className="w-12">
                          {t("catalog.items.no")}
                        </th>
                        <th key={"picture"} className="w-24">
                          {t("catalog.items.picture")}
                        </th>
                        <th key={"sku"} className="w-24">
                          {t("catalog.items.sku")}
                        </th>
                        <th key={"productName"} className="w-40">
                          {t("catalog.items.productName")}
                        </th>
                        <th key={"price"} className="w-44">
                          {t("catalog.items.price")}
                        </th>
                        <th key={"baseUoM"} className="w-24">
                          {t("catalog.items.baseUoM")}
                        </th>
                        <th key={"priceUoM2"} className="w-44">
                          {t("catalog.items.priceUoM2")}
                        </th>
                        <th key={"uom2"} className="w-24">
                          {t("catalog.items.uom2")}
                        </th>
                        <th key={"priceUoM3"} className="w-44">
                          {t("catalog.items.priceUoM3")}
                        </th>
                        <th key={"uom3"} className="w-24">
                          {t("catalog.items.uom3")}
                        </th>
                        <th key={"category"} className="w-24">
                          {t("catalog.items.category")}
                        </th>
                        <th key={"action"} className="w-20 border-px border-l">
                          {t("commons.removeBtn")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((i, index) => {
                        return (
                          <CollapsibleRow
                            key={i.id}
                            i={i}
                            index={index}
                            selectedItems={selectedItems}
                            handleChecked={handleChecked}
                            searchTerm={searchTerm}
                            handleRemove={handleRemoveItem}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                </Box>

                <Stack
                  direction={"row"}
                  sx={{
                    display: { xs: "none", lg: "flex" },
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0.5rem 1rem",
                    backgroundColor:
                      theme.colorSchemes.light.palette.others.neutral_light,
                    borderWidth: "1px",
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Stack direction={"column"}>
                      <Typography fontWeight={700} fontSize={14}>
                        {t("catalog.inventory.batchEdit.editSelected")}
                      </Typography>
                      <Typography fontWeight={700} fontSize={14}>
                        {selectedItems.length}{" "}
                        <span className="font-medium">
                          {t("catalog.inventory.batchEdit.itemsSelected")}
                        </span>
                      </Typography>
                    </Stack>

                    <FormControl sx={{ height: "100%" }}>
                      <FormLabel>
                        {t("catalog.inventory.batchEdit.newPricePerUnit")}
                      </FormLabel>
                      <Input
                        className="w-96"
                        size="sm"
                        placeholder={"0,00"}
                        disabled={selectedItems.length <= 0}
                        name="settings.price"
                        slotProps={{
                          input: {
                            component: NumericFormatAdapter,
                          },
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Button
                    variant={
                      selectedItems.length <= 0
                        ? "outlined-gray"
                        : "linkz-filled-primary"
                    }
                    onClick={handleUpdateAll}
                    disabled={selectedItems.length <= 0}
                    sx={{
                      marginTop: "auto",
                    }}
                  >
                    {t("catalog.inventory.batchEdit.updatePriceBtn")}
                  </Button>
                </Stack>

                <Stack
                  direction={"column"}
                  sx={{
                    display: { xs: "flex", lg: "none" },
                    gap: "16px",
                    paddingY: "1rem",
                    backgroundColor:
                      theme.colorSchemes.light.palette.others.neutral_light,
                  }}
                >
                  <Stack direction={"column"}>
                    <Typography fontWeight={700} fontSize={14}>
                      {t("catalog.inventory.batchEdit.editSelected")}
                    </Typography>
                    <Typography fontWeight={700} fontSize={14}>
                      {selectedItems.length}{" "}
                      <span className="font-medium">
                        {t("catalog.inventory.batchEdit.itemsSelected")}
                      </span>
                    </Typography>
                  </Stack>

                  <FormControl sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
                    <FormLabel>
                      {t("catalog.inventory.batchEdit.newPricePerUnit")}
                    </FormLabel>
                    <Input
                      size="sm"
                      placeholder={"0,00"}
                      // defaultValue={getValues("settings.price") ?? 0}
                      disabled={selectedItems.length <= 0}
                      name="settings.price"
                      slotProps={{
                        input: {
                          component: NumericFormatAdapter,
                        },
                      }}
                    />
                  </FormControl>

                  <Button
                    variant={
                      selectedItems.length <= 0
                        ? "outlined-gray"
                        : "linkz-filled-primary"
                    }
                    onClick={handleUpdateAll}
                    disabled={selectedItems.length <= 0}
                    sx={{
                      marginTop: "auto",
                    }}
                  >
                    {t("catalog.inventory.batchEdit.updatePriceBtn")}
                  </Button>
                </Stack>

                <Stack
                  direction={"row"}
                  mt={{ xs: "1.5rem", lg: "0rem" }}
                  sx={{
                    width: "100%",
                    gap: { xs: 0, lg: "0.75rem" },
                    justifyContent: { xs: "space-between", lg: "right" },
                    alignItems: "center",
                    padding: { xs: 0, lg: "0.75rem" },
                  }}
                >
                  <Button
                    type="button"
                    variant="filter-outlined"
                    sx={{
                      minWidth: { xs: "49%", lg: "8rem" },
                      textWrap: "nowrap",
                    }}
                    onClick={handleCancel}
                  >
                    {t("commons.cancelBtn")}
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      minWidth: { xs: "49%", lg: "8rem" },
                      textWrap: "nowrap",
                    }}
                  >
                    {t("commons.saveChangesBtn")}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      )}

      {/* modal */}
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
      <LoadingModal isLoading={isLoading} />
    </Sheet>
  );
};

const CollapsibleRow = ({
  i,
  index,
  selectedItems,
  handleChecked,
  searchTerm = "",
  handleRemove,
}: {
  i: ItemType;
  index: number;
  selectedItems: string[];
  handleChecked: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  searchTerm: string;
  handleRemove: (id: string) => void;
}) => {
  const isSelected = selectedItems.includes(i.id);

  const { control, register, setValue } = useFormContext();

  const inventoryPriceValue = useWatch({
    control,
    name: `data.inventory.${index}.price`,
  });

  const matchesSearch =
    searchTerm.length > 0
      ? i.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
        i.productName.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

  return (
    <React.Fragment>
      <tr
        className={`${isSelected ? "bg-primary-50 " : ""} ${
          matchesSearch ? "" : "hidden"
        }`}
      >
        <td
          className="text-center"
          style={{
            backgroundColor: isSelected ? "#F1F8F4" : "",
          }}
        >
          <Checkbox
            size="sm"
            checked={isSelected}
            onChange={(e) => handleChecked(e, i.id)}
          />
        </td>
        <td className="text-center">{index + 1}</td>
        <td className="text-center">
          <div className="relative w-full flex justify-center py-3 cursor-pointer">
            {i.thumbnail ? (
              <img
                src={i.thumbnail}
                crossOrigin="anonymous"
                width={30}
                height={30}
                className="object-contain"
              />
            ) : (
              <img
                src="/inventory-img-placeholder.png"
                width={30}
                height={30}
                className="object-contain"
              />
            )}
          </div>
        </td>
        <td className="text-center break-all">{i.sku}</td>
        <td className="text-center break-all">{i.productName}</td>
        <td>
          <FormControl>
            <Controller
              control={control}
              name={`data.inventory.${index}.price`}
              defaultValue={inventoryPriceValue ?? i.price}
              render={({ field }) => {
                const { ref, onChange, ...rest } = field;

                return (
                  <NumericFormat
                    {...rest}
                    customInput={Input}
                    autoComplete="off"
                    size="sm"
                    startDecorator={
                      <Typography fontWeight={600} fontSize={14}>
                        {i.currency}
                      </Typography>
                    }
                    onValueChange={(target) => {
                      onChange(target.floatValue);

                      setValue(
                        `data.inventory.${index}.price2`,
                        i.conversion2
                          ? (target.floatValue || 0) * i.conversion2
                          : null
                      );

                      setValue(
                        `data.inventory.${index}.price3`,
                        i.conversion3
                          ? (target.floatValue || 0) * i.conversion3
                          : null
                      );
                    }}
                    allowLeadingZeros={false}
                    thousandSeparator=","
                    valueIsNumericString
                  />
                );
              }}
            />
            <input
              type="hidden"
              value={i.id}
              {...register(`data.inventory.${index}.inventoryId`)}
            />
          </FormControl>
        </td>
        <td className="text-center break-all">{i.uom1 ?? "-"}</td>
        <td className="text-center break-all">
          {i.price2 && i.conversion2 ? (
            <FormControl>
              <Controller
                control={control}
                name={`data.inventory.${index}.price2`}
                defaultValue={i.price2}
                render={({ field }) => {
                  const { ref, onChange, ...rest } = field;

                  return (
                    <NumericFormat
                      {...rest}
                      customInput={Input}
                      autoComplete="off"
                      size="sm"
                      startDecorator={
                        <Typography fontWeight={600} fontSize={14}>
                          {i.currency}
                        </Typography>
                      }
                      onValueChange={(target) => {
                        onChange(target.floatValue);
                      }}
                      allowLeadingZeros={false}
                      thousandSeparator=","
                      valueIsNumericString
                    />
                  );
                }}
              />
              <input
                type="hidden"
                value={i.id}
                {...register(`data.inventory.${index}.inventoryId`)}
              />
            </FormControl>
          ) : (
            "-"
          )}
        </td>
        <td className="text-center break-all">{i.uom2 ?? "-"}</td>
        <td className="text-center break-all">
          {i.price3 && i.conversion3 ? (
            <FormControl>
              <Controller
                control={control}
                name={`data.inventory.${index}.price3`}
                defaultValue={i.price3}
                render={({ field }) => {
                  const { ref, onChange, ...rest } = field;

                  return (
                    <NumericFormat
                      {...rest}
                      customInput={Input}
                      autoComplete="off"
                      size="sm"
                      startDecorator={
                        <Typography fontWeight={600} fontSize={14}>
                          {i.currency}
                        </Typography>
                      }
                      onValueChange={(target) => {
                        onChange(target.floatValue);
                      }}
                      allowLeadingZeros={false}
                      thousandSeparator=","
                      valueIsNumericString
                    />
                  );
                }}
              />
              <input
                type="hidden"
                value={i.id}
                {...register(`data.inventory.${index}.inventoryId`)}
              />
            </FormControl>
          ) : (
            "-"
          )}
        </td>
        <td className="text-center break-all">{i.uom3 ?? "-"}</td>
        <td className="text-center break-all">{i.inventoryType}</td>
        <td
          className="text-center break-all border-px border-l "
          style={{
            backgroundColor: isSelected ? "#F1F8F4" : "",
          }}
        >
          <Button
            variant="outlined-gray"
            className="p-1 min-w-fit min-h-fit border-[#B41F1C] rounded-full"
            onClick={() => {
              handleRemove(i.id);
            }}
          >
            <TrashIcon color="#B41F1C" width={18} height={18} />
          </Button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default BatchEditInventory;
