import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { OrderService } from "../../../services/order.service";
import { getActiveBusiness } from "../../../store/session";
import { currencies } from "../Dashboard";

const useContainer = () => {
  //#region local states
  const [xLabels, setXLabels] = useState([
    "1 jan - 7 jan",
    "8 jan -14 jan",
    "15 jan - 21 jan",
    "22 jan - 28 jan",
    "29 jan - 4 feb",
    "5 feb - 11 feb",
    "12 feb - 18 feb",
  ]);

  const [state, setState] = useState({
    sale: true,
    purchase: true,
    gmv: true,
    catalog: true,
  });

  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    type: "",
  });
  const business = getActiveBusiness();
  const defaultCurrency = currencies.find(
    (cur) => cur.id.toUpperCase() === business?.companyCountry
  );
  const [selected, setSelected] = useState(
    () => defaultCurrency ?? currencies[0]
  );
  //#endregion

  //#region ----- Http calls
  const analyticDataQuery = useQuery({
    queryKey: [
      "analytic-data",
      dateRange.startDate,
      dateRange.endDate,
      selected.id,
      business?.id,
    ],
    queryFn: () =>
      OrderService.analyticData(
        dateRange.startDate,
        dateRange.endDate,
        dateRange.type,
        selected.name
      ),
    enabled: !!dateRange.startDate && !!dateRange.endDate,
  });

  const analyticStatisticsDataQuery = useQuery({
    queryKey: [
      "analyti-statisticsc-data",
      dateRange.startDate,
      dateRange.endDate,
      selected.id,
      business?.id,
    ],
    queryFn: () =>
      OrderService.analyticStatisticsData(
        dateRange.startDate,
        dateRange.endDate,
        dateRange.type,
        selected.name
      ),
    enabled: !!dateRange.startDate && !!dateRange.endDate,
  });

  //#region ----- handler functions
  const calculateDateRange = (value: string) => {
    const endDate = new Date();
    let startDate;
    switch (value) {
      case "this_week":
        startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - endDate.getDay());
        startDate.setHours(0, 0, 0, 0);
        break;

      case "this_month":
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        break;

      case "this_year":
        startDate = new Date(endDate.getFullYear(), 0, 1);
        break;

      default:
        console.error("Invalid value for date range calculation", value);
        return;
    }
    setDateRange({
      type: value,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  };
  const handleSelectChange = (value: any) => {
    calculateDateRange(value.name);
    generateXLabels(value.name);
  };

  const handleChange = (key: string, value: boolean) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  //#region lifecycle methods
  const analyticData = analyticDataQuery?.data
    ? {
        ...analyticDataQuery?.data,
        saleData: analyticDataQuery.data.gmvData.map(
          (gmv: number, index: number) =>
            gmv - analyticDataQuery.data.catalogData[index]
        ),
      }
    : {
        gmvData: [],
        purchaseData: [],
        saleData: [],
        catalogData: [],
      };

  const generateXLabels = (option: string) => {
    const currentDate = new Date();
    const xLabels: string[] = [];
    switch (option) {
      case "this_week":
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        for (
          let date = new Date(startOfWeek);
          date <= endOfWeek;
          date.setDate(date.getDate() + 1)
        ) {
          const label = `${date.getDate()} ${date.toLocaleString("default", {
            month: "short",
          })}`;
          xLabels.push(label);
        }
        break;
      case "this_month":
        const startOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const endOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ); // Last day of the month

        let currentWeekStart = new Date(startOfMonth);

        while (currentWeekStart <= endOfMonth) {
          const currentWeekEnd = new Date(currentWeekStart);
          currentWeekEnd.setDate(currentWeekStart.getDate() + 6);

          // Ensure the week doesn't extend beyond the end of the month
          if (currentWeekEnd > endOfMonth) {
            currentWeekEnd.setDate(endOfMonth.getDate());
          }

          const label = `${currentWeekStart.getDate()} ${currentWeekStart.toLocaleString(
            "default",
            { month: "short" }
          )} - ${currentWeekEnd.getDate()} ${currentWeekEnd.toLocaleString(
            "default",
            {
              month: "short",
            }
          )}`;
          xLabels.push(label);

          // Move to the next week
          currentWeekStart.setDate(currentWeekStart.getDate() + 7);
        }
        break;
      case "this_year":
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const endOfYear = new Date(currentDate.getFullYear(), 11, 31); // December 31
        for (
          let month = new Date(startOfYear);
          month <= endOfYear;
          month.setMonth(month.getMonth() + 1)
        ) {
          const label = `${month.toLocaleString("default", {
            month: "long",
          })}`.substring(0, 3);
          xLabels.push(label);
        }
        break;
      default:
        console.error("Invalid option for generating X labels", option);
        return;
    }
    setXLabels(xLabels);
  };

  // Use useMemo to memoize the calculation of totals

  useEffect(() => {
    if (dateRange.startDate === "" && dateRange.endDate === "") {
      calculateDateRange("this_week");
      generateXLabels("this_week");
    }
  }, []);
  //#endregion
  return {
    isLoading: analyticStatisticsDataQuery.isPending,
    data: analyticData,
    analyticData: analyticStatisticsDataQuery.data,
    handleSelectChange,
    handleChange,
    calculateDateRange,
    dateRange,
    state,
    xLabels,
    selected,
    setSelected,
  };
};

export default useContainer;
