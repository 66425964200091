import { TrashIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Modal,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { theme } from "../../Theme";
import { useEffect, useState } from "react";
import TablePagination from "../../../pages/Inventory/components/TablePagination";
import { PaginationType } from "../../../types/item";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import UploadStatusBox from "./UploadStatusBox";
import { InventoryItemDraftType } from "../../../types/draft";
import { ItemService } from "../../../services/item.service";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SuccessModal } from "../../SuccessModal";
import { BrowserRouterConstants } from "../../../utils/constants";
import { CatalogService } from "../../../services/catalog.service";
import { getBaseSku } from "../../../utils/excel";

type Props = {
  draft: {
    invalidItems: InventoryItemDraftType[];
    items?: InventoryItemDraftType[];
    totalItems: number;
  };
  confirm?: boolean;
};

type FormValues = {
  items: InventoryItemDraftType[];
};

const DuplicateSKUForm = ({ draft, confirm = true }: Props) => {
  const { t } = useTranslation();

  const { catalogId, draftId } = useParams();

  const [searchParams] = useSearchParams();
  const importMethod = searchParams.get("method");

  const navigate = useNavigate();

  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 20,
    max: Math.ceil(draft.invalidItems.length / 20),
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: { items: draft.invalidItems },
    mode: "onChange",
  });

  const watchedItems = useWatch({
    control,
    name: "items",
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "items",
  });

  /**
   * will trigger checkDuplicates whenever item is updated
   * or page changed
   * @param watchedItems @param paginatedData
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkDuplicates();
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [watchedItems]);

  // pagination indexes for slicing fields
  const startIndex = (pagination.current - 1) * pagination.limit;
  const endIndex = startIndex + pagination.limit;

  /**
   * validate and submit data. submit method will be
   * decided based on inventory / catalog flow
   * @param data
   */
  const onSubmit = async (data: FormValues) => {
    console.log("DATA", data);

    const inventoryItemDraft = data.items.map((item) => item);

    if (!draftId) {
      setShowConfirm(false);
      return;
    }

    try {
      let draftUpdateResponse;
      let navigatePath: string | undefined;

      // call to different service based on import method
      switch (importMethod) {
        case "create-catalog":
          draftUpdateResponse = await CatalogService.putCatalogDraft(
            draftId,
            inventoryItemDraft
          );

          if (hasInvalidItems(draftUpdateResponse)) {
            handleInvalidItems(draftUpdateResponse);
            return;
          }

          const cachedCatalogData = JSON.parse(
            localStorage.getItem("new-catalog") || "{}"
          );

          if (!cachedCatalogData) {
            window.alert("Cache does not exist");
            return;
          }

          localStorage.setItem(
            "new-catalog",
            JSON.stringify({ ...cachedCatalogData, draftId })
          );

          navigatePath = `/client/catalogues/create?type=${cachedCatalogData.catalogType}`;
          break;

        case "import-catalog":
          if (!catalogId) return;

          draftUpdateResponse = await CatalogService.putCatalogDraft(
            draftId,
            inventoryItemDraft
          );

          if (hasInvalidItems(draftUpdateResponse)) {
            handleInvalidItems(draftUpdateResponse);
            return;
          }

          const submitDraftResponse = await CatalogService.submitCatalogDraft(
            draftId,
            { catalogId }
          );

          if (submitDraftResponse) {
            setAlertMessage(
              t("catalog.import.success.updatedCatalog", {
                totalItems: draftUpdateResponse.totalItems,
              })
            );

            setShowAlert(true);
            navigatePath = `/client/catalogues/${catalogId}/items`;
          } else {
            throw new Error("Error submitting draft");
          }
          break;

        default:
          draftUpdateResponse = await ItemService.putInventoryDraft(
            draftId,
            inventoryItemDraft
          );

          if (hasInvalidItems(draftUpdateResponse)) {
            handleInvalidItems(draftUpdateResponse);
            return;
          }

          setAlertMessage(
            t("catalog.inventory.import.success.addInventory", {
              totalItems: draftUpdateResponse.totalItems,
            })
          );

          setShowAlert(true);
          navigatePath = `/client/${BrowserRouterConstants.Inventory}`;
          break;
      }

      if (navigatePath) {
        setTimeout(() => navigate(navigatePath), 3000);
      }
    } catch (error: any) {
      console.error("Error updating draft:", error);
      alert(error.message);
    } finally {
      setShowConfirm(false);
    }
  };

  /**
   * check if has invalid items in response
   * @param response
   * @returns
   */
  const hasInvalidItems = (response: any) => {
    return response.invalidItems && response.invalidItems.length > 0;
  };

  /**
   * handle invalid items if any
   * @param response
   */
  const handleInvalidItems = (response: any) => {
    localStorage.setItem(
      "catalog-import-invalid-items",
      JSON.stringify({
        invalidItems: response.invalidItems,
        totalItems: response.totalItems,
      })
    );

    window.alert(t("catalog.inventory.import.duplicate.error.moreDuplicates"));
    window.location.reload();
  };

  /**
   * will take action, and will update @var current page in pagination state
   * @param action string (pagination actions)
   */
  const handlePaging = (action: string | number) => {
    let currentPage = pagination.current;

    if (typeof action === "string") {
      switch (action) {
        case "first":
          currentPage = 1;
          break;

        case "previous":
          currentPage = pagination.current - 1;
          break;

        case "next":
          currentPage = pagination.current + 1;
          break;

        case "last":
          currentPage = pagination.max;
          break;
      }
    } else {
      currentPage = action;
    }

    setPagination({
      ...pagination,
      current: currentPage,
    });
  };

  /**
   * will validate duplicated SKUs
   * @returns boolean
   */
  const checkDuplicates = () => {
    const items = getValues("items");
    // console.log("CHECK VALUE FIELDS", items);

    // clear previous errors
    items.forEach((_, index) => {
      clearErrors(`items.${index}.sku`);
    });

    // find duplicate sku
    const skuMap = new Map();
    const duplicates: any[] = [];

    items.forEach((item, index) => {
      const sku = getBaseSku(item.sku);
      if (!sku) return;

      if (skuMap.has(sku)) {
        duplicates.push(index);
        if (!duplicates.includes(skuMap.get(sku))) {
          duplicates.push(skuMap.get(sku));
        }
      } else {
        skuMap.set(sku, index);
      }
    });

    // ser errors for all rows with duplicate sku
    duplicates.forEach((index) => {
      setError(`items.${index}.sku`, {
        type: "duplicate",
        message: t("catalog.inventory.import.duplicate.error.duplicateSKU"),
      });
    });

    return duplicates.length === 0;
  };

  /**
   * will filter and count the amount of sku errors from formState: errors
   * @returns number
   */
  const getSKUErrors = () => {
    if (!errors.items) {
      return 0;
    }

    const skuErrorCount = Array.isArray(errors.items)
      ? errors.items.filter((item) => item?.sku.type === "duplicate").length
      : 0;

    return skuErrorCount;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction={"column"}
        sx={{
          gap: { xs: "24px", lg: "32px" },
          pb: { xs: "148px", lg: "246px" },
        }}
      >
        <Box
          sx={{
            borderWidth: "1px",
            borderColor: theme.palette.neutral[200],
            borderRadius: "8px",
            p: "24px",
            overflow: "auto",
          }}
        >
          <Table
            borderAxis="x"
            sx={{
              "& th": { textAlign: "center", py: "20px", px: "10px" },
              "& td": { textAlign: "center", py: "20px", px: "8px" },
              "& tr > *:last-child": {
                position: "sticky",
                right: 0,
                bgcolor: "var(--TableCell-headBackground)",
              },
              "& thead th:nth-child(1)": { width: "45px" },
              "& thead th:nth-child(2)": { width: "250px" },
              "& thead th:nth-child(3)": { width: "200px" },
              "& thead th:nth-child(4)": { width: "387px" },
              "& thead th:nth-child(5)": { width: "110px" },
              minWidth: "100%",
              borderWidth: "1px",
              borderColor: theme.palette.neutral[300],
            }}
          >
            <thead>
              <tr key={"header"}>
                <th key={"index"}>{t("catalog.items.no")}</th>
                <th key={"sku"}>
                  <Typography level="text-sm-bold">
                    {t("catalog.items.sku")}
                  </Typography>
                </th>
                <th key={"productName"}>
                  <Typography level="text-sm-bold">
                    {t("catalog.items.productName")}
                  </Typography>
                </th>
                <th key={"productName2"}>
                  <Typography level="text-sm-bold">
                    {t("catalog.items.productName")}
                  </Typography>
                </th>
                <th key={"inventoryType"}>
                  <Typography level="text-sm-bold">
                    {t("catalog.items.category")}
                  </Typography>
                </th>
                <th
                  key={"action"}
                  className="border-l"
                  style={{ width: "80px", right: "-24px" }}
                >
                  <Typography level="text-sm-bold">
                    {t("commons.removeBtn")}
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {fields.slice(startIndex, endIndex).map((field, index) => {
                // actual index of the item within draft.error.duplicate
                const offsetIndex = startIndex + index;
                return (
                  <tr key={field.id}>
                    <td className="text-center">{offsetIndex + 1}</td>
                    <td className="text-center">
                      <FormControl
                        error={!!errors.items?.[offsetIndex]?.sku}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Controller
                          name={`items.${offsetIndex}.sku`}
                          control={control}
                          // defaultValue={paginatedData?.[index].sku}
                          rules={{
                            required: true,
                            // validate: (value) => {
                            //   const duplicates = watchedItems.filter(
                            //     (item) => item.sku === value
                            //   );
                            //   console.log("DUPLICATES IN INPUT", duplicates);
                            //   return duplicates.length > 1
                            //     ? t(
                            //         "catalog.inventory.import.duplicate.error.duplicateSKU"
                            //       )
                            //     : true;
                            // },
                          }}
                          render={({ field }) => <Input fullWidth {...field} />}
                        />

                        {errors.items?.[offsetIndex]?.sku && (
                          <FormHelperText>
                            {errors.items?.[offsetIndex]?.sku?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </td>
                    <td className="text-center">
                      {draft.invalidItems[offsetIndex].productName}
                    </td>
                    <td className="text-center">
                      <FormControl
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Input
                          fullWidth
                          {...register(`items.${offsetIndex}.productName`, {
                            required: true,
                          })}
                        />
                      </FormControl>
                    </td>
                    <td className="text-center break-all">
                      {draft.invalidItems[offsetIndex].inventoryType}
                    </td>
                    <td
                      className="text-center break-all border-l"
                      style={{ right: "-24px" }}
                    >
                      <IconButton>
                        <TrashIcon
                          onClick={() => {
                            remove(offsetIndex);
                          }}
                          width={24}
                          height={24}
                          color={theme.palette.others.cart_danger_button}
                        />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>

        <TablePagination
          handlePaging={handlePaging}
          setPagination={setPagination}
          pagination={pagination}
          withLimit
        />

        <Stack
          direction={"row"}
          sx={{
            position: "fixed",
            width: "100%",
            maxWidth: "inherit",
            bottom: 0,
            left: 0,
            bgcolor: theme.palette.others.cart_toolbar_bg,
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              justifyContent: { md: "space-between" },
              alignItems: "center",
              width: "100%",
              ml: { lg: "240px" },
              py: "24px",
              px: "32px",
              gap: { xs: "8px", md: "32px" },
            }}
          >
            <Divider
              sx={{
                width: "100dvw",
                position: "absolute",
                display: { xs: "none", lg: "block" },
                left: "240px",
                top: 0,
                backgroundColor: theme.palette.others.disabled,
              }}
            />
            <Typography
              level="body-sm"
              sx={{
                textAlign: "right",
                color: errors.items
                  ? theme.palette.others.text_danger
                  : theme.palette.neutral[600],
              }}
            >
              {getSKUErrors() > 0
                ? `${getSKUErrors()} 
            ${t("commons.error.form.duplicated", {
              field: "SKU",
            })}`
                : t("catalog.inventory.import.duplicate.success.resolvedSKU")}
            </Typography>

            <Button
              id="duplicatesku-submit"
              sx={{
                my: "auto",
                minWidth: { xs: "100%", lg: "150px" },
              }}
              disabled={!isValid}
              type={confirm ? "button" : "submit"}
              onClick={() => {
                setShowConfirm(confirm);
              }}
            >
              {t("commons.confirmChangeBtn")}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {/* modal */}
      <ConfirmSubmitModal
        show={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={handleSubmit(onSubmit)}
        draft={draft}
      />
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
    </form>
  );
};

const ConfirmSubmitModal = ({
  show,
  onClose,
  onConfirm,
  draft,
}: {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  draft: {
    invalidItems: InventoryItemDraftType[];
    items?: InventoryItemDraftType[];
    totalItems: number;
  };
}) => {
  const { t } = useTranslation();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    boxShadow: 24,
    width: { xs: "94%", lg: "454px" },
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
    p: "40px",
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <Modal
      id="confirmchanges-modal"
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          direction={"column"}
          sx={{
            gap: "24px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ExclamationTriangleIcon
            width={69}
            height={62.5}
            color={theme.palette.warning[400]}
          />
          <Typography
            textAlign={"center"}
            level="text-xl2-bold"
            id="modal-modal-title"
            textColor={theme.palette.common.black}
          >
            {t("catalog.inventory.import.duplicate.confirm.title")}
          </Typography>
          <Typography
            textAlign={"center"}
            level="text-md-regular"
            id="modal-modal-subtitle"
            textColor={theme.palette.text.secondary}
          >
            {t("catalog.inventory.import.duplicate.confirm.subtitle")}
          </Typography>

          <UploadStatusBox
            currency={draft.invalidItems[0].currency}
            totalItems={draft.totalItems}
          />

          <Stack
            direction={{ xs: "column-reverse", lg: "row" }}
            gap={{ xs: "8px", lg: "24px" }}
            width={"100%"}
          >
            <Button
              variant="linkz-outlined-primary"
              size="sm"
              onClick={onClose}
            >
              {t("catalog.inventory.import.duplicate.confirm.cancelBtn")}
            </Button>

            <Button size="sm" onClick={onConfirm}>
              {t("catalog.inventory.import.duplicate.confirm.confirmBtn")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DuplicateSKUForm;
