import { useQuery } from "@tanstack/react-query";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import "dayjs/locale/id";
import "flag-icons/css/flag-icons.css";
import { useEffect, useState } from "react";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { OrderService } from "../../services/order.service";
import { TrackerService } from "../../services/tracker.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { PayableRecieveableCard } from "./PayableRecieveableCard";
import CreditLimitCard from "./components/CreditLimitCard";
import DahsboardChart from "./components/DahsboardChart";

ChartJS.register(ArcElement, Tooltip, Legend);

export const currencies = [
  {
    id: "sg",
    name: "SGD",
  },
  {
    id: "my",
    name: "MYR",
  },
  {
    id: "id",
    name: "IDR",
  },
  { id: "ph", name: "PHP" },
  { id: "th", name: "THB" },
  { id: "vn", name: "VND" },
  { id: "us", name: "USD" },
  { id: "hk", name: "HKD" },
  { id: "jp", name: "JPY" },
  { id: "mm", name: "MMK" },
  { id: "au", name: "AUD" },
];

export default function Dashboard() {
  const { session } = useAuthStore();
  useEffect(() => {
    setTimeout(() => {
      TrackerService.track(
        TRACKER_CONSTANTS.LOGIN_SUCCESS.events.HomeDashboardVisited,
        {
          ...session,
          ...session?.activeBusiness,
        }
      );
    }, 2500);
  }, []);

  const business = getActiveBusiness();
  console.log({ business });
  const defaultCurrency = currencies.find(
    (cur) => cur.id.toUpperCase() === business?.companyCountry
  );
  const [payableCurrency, setPayableCurrency] = useState(() => defaultCurrency);
  const [recieveableCurrency, setRecieveableCurrency] = useState(
    () => defaultCurrency
  );
  const payableQuery = useQuery({
    queryKey: ["payable", payableCurrency?.id, business?.id],
    queryFn: () => OrderService.payable(payableCurrency?.name!),
  });
  const recieveableQuery = useQuery({
    queryKey: ["recieveable", recieveableCurrency?.id, business?.id],
    queryFn: () => OrderService.recieveable(recieveableCurrency?.name!),
  });
  // const creditData = useQuery({
  //   queryKey: ["business-credits", session?.activeBusiness.id],
  //   queryFn: () => BusinessService.getBusinessCredit(),
  // });
  const haveSOPermission = BusinessSessionHelper.RoleResourcePermissions(
    "sales",
    "READ"
  );

  const havePOPermission = BusinessSessionHelper.RoleResourcePermissions(
    "purchase",
    "READ"
  );

  const isPending = payableQuery.isPending || recieveableQuery.isPending;

  if (isPending) {
    return <LoadingModal isLoading={isPending} />;
  }
  const payable = payableQuery.data?.data;
  const recieveable = recieveableQuery.data?.data;

  return (
    <div className="flex flex-col gap-6 py-6 min-h-screen">
      {/* Credit Card */}
      {/* <div className="w-full lg:max-w-[570px] flex flex-col bg-white rounded-2xl overflow-hidden shadow-lg ">
        <div className="flex flex-col gap-2 p-4">
          <div className="flex gap-1 items-center">
            <img src="/credit.svg" alt="" />
            <p className="text-[#101828] text-base ">
              {t("dashboard.credits")}
            </p>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col flex-1 gap-2">
              <p className="text-2xl font-bold text-[#171A1C]">
                {creditData.data?.credits}
              </p>
              <p className="text-xs font-bold text-[#475467]">
                {t("commons.dateUpdated")}{" "}
                {dayjs(creditData.data?.data?.updatedAt)
                  .locale(i18next.resolvedLanguage ?? "en")
                  .format("ddd D MMM hh:mm A")}
              </p>
            </div>
            <Link
              to={BrowserRouterConstants.Credit}
              className="bg-[#499873] px-4 py-[10px] rounded-lg text-white text-sm font-semibold"
            >
              {t("dashboard.topUpBtn")}
            </Link>
          </div>
        </div>
        <Link
          to={BrowserRouterConstants.CreditTransactionTab}
          className="flex items-center justify-between text-[#499873] py-2 px-4 bg-[#F9FAFB] cursor-pointer"
        >
          <p className="py-[10px] text-sm font-semibold">
            {t("dashboard.creditHistoryBtn")}
          </p>
          <ChevronRightIcon className="w-6 h-6" />
        </Link>
      </div> */}
      {BusinessSessionHelper.RoleResourcePermissions("credit", "READ") && (
        <CreditLimitCard />
      )}
      {/* recievable / payable */}
      <div className="flex flex-col gap-6 items-stretch lg:flex-row">
        {haveSOPermission && (
          <div className="w-full lg:w-1/2">
            <PayableRecieveableCard
              currency={recieveableCurrency!}
              handleCurrencyChange={(val) => setRecieveableCurrency(val)}
              list={recieveable.topSellers ?? []}
              totalAmount={recieveable.totalAmount}
            />
          </div>
        )}
        {havePOPermission && (
          <div className="w-full lg:w-1/2">
            <PayableRecieveableCard
              currency={payableCurrency!}
              handleCurrencyChange={(val) => setPayableCurrency(val)}
              totalAmount={payable.totalAmount ?? 0}
              list={payable.topBuyers ?? []}
              isPayable={true}
            />
          </div>
        )}
      </div>

      {/* Chart */}
      <DahsboardChart />
    </div>
  );
}
