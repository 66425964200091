import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import FlagById from "../FlagById";
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { orderStatuses } from "../../types/order";
import DropdownButton from "../buttons/DropdownButton";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { DefaultCurrencies, WhiteListedCountries } from "../../utils/constants";

export const InputSearch = ({ paramQ, handleOnSearch, placeholder }: any) => {
  return (
    <FormControl sx={{ width: "100%", maxWidth: "480px" }}>
      {/* <FormLabel>Search for order</FormLabel> */}
      <Input
        value={paramQ || ""}
        variant="linkz-input-search"
        name="searchFormData.search"
        placeholder={placeholder}
        startDecorator={<MagnifyingGlassIcon width={20} height={20} />}
        onChange={handleOnSearch}
        size="sm"
      />
    </FormControl>
  );
};

export const OrderTypeFilter = ({
  selectedOrderTypeFilter,
  handleOrderTypeFilterChange,
  t,
}: any) => {
  return (
    <Select
      value={selectedOrderTypeFilter}
      size="sm"
      placeholder={t("order.filters.type.all")}
      onChange={handleOrderTypeFilterChange}
    >
      <Option value="">{t("order.filters.type.all")}</Option>
      <Option value="sales">{t("order.filters.type.sales")}</Option>
      <Option value="purchase">{t("order.filters.type.purchase")}</Option>
    </Select>
  );
};

export const OrderCreateButton = ({ label, options }: any) => {
  return (
    <DropdownButton
      endDecorator={<ExpandMoreOutlined />}
      id="btn-create-order"
      label={label}
      options={options}
    />
  );
};

export const OrderStatusFilter = ({
  selectedOrderStatusFilter,
  handleOrderStatusFilterChange,
  t,
}: any) => {
  return (
    <Select
      value={selectedOrderStatusFilter}
      size="sm"
      placeholder={t("order.filters.status.all")}
      onChange={handleOrderStatusFilterChange}
    >
      <Option value="">{t("order.filters.status.all")}</Option>
      {orderStatuses?.map((status, index) => (
        <Option key={index} value={status.value}>
          {status.label}
        </Option>
      ))}
    </Select>
  );
};

export const CurrencyFilter = ({
  selectedCurrencyFilter,
  handleOnChangeCurrencyFilter,
  t,
}: any) => {
  return (
    <Select
      size="sm"
      placeholder={t("order.filters.currency")}
      value={selectedCurrencyFilter}
      onChange={(_, newValue) => {
        handleOnChangeCurrencyFilter(newValue); // Currency's id
      }}
      renderValue={(option) => (
        <Typography
          startDecorator={
            <FlagById
              id={
                DefaultCurrencies.find(
                  (currency) => currency.id === option?.value
                )?.country ?? ""
              }
            />
          }
        >
          {option?.label}
        </Typography>
      )}
      sx={{ backgroundColor: "white" }}
    >
      {DefaultCurrencies.filter((currency) =>
        WhiteListedCountries.includes(currency.country)
      ).map((currency) => (
        <Option
          key={currency.country}
          value={currency.id}
          label={currency.label}
        >
          <Typography startDecorator={<FlagById id={currency.country} />}>
            {currency.label}
          </Typography>
        </Option>
      ))}
    </Select>
  );
};

export const DateFilter = ({
  selectedOrderDateFilter,
  handleOrderDateFilterChange,
  t,
}: any) => {
  return (
    <Select
      value={selectedOrderDateFilter}
      size="sm"
      placeholder={t("order.filters.date.placeholder")}
      slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
      onChange={handleOrderDateFilterChange}
    >
      <Option value="">{t("order.filters.date.all")}</Option>
      <Option value="today">{t("order.filters.date.today")}</Option>
      <Option value="yesterday">{t("order.filters.date.yesterday")}</Option>
      <Option value="this_week">{t("order.filters.date.thisWeek")}</Option>
      <Option value="this_month">{t("order.filters.date.thisMonth")}</Option>
    </Select>
  );
};

export const CompanyFilter = ({
  externalBusinessList,
  selectedCompany,
  handleOnChangeCompanyFilter,
  t,
}: any) => {
  return (
    <Autocomplete
      name={"companyFilter"}
      size="sm"
      // variant={"linkz-autocomplete-rounded"}
      // startDecorator={<MagnifyingGlassIcon width={20} height={20} />}
      placeholder={t("order.filters.company.placeholder")}
      options={externalBusinessList || null}
      getOptionLabel={(option: any) => option.companyName || ""}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      value={selectedCompany || ""}
      renderOption={(props, option: any) => (
        <AutocompleteOption
          key={option?.id}
          {...props}
          sx={{
            py: 1,
            "&:hover": {
              backgroundColor: "var(--joy-palette-neutral-100)",
            },
          }}
        >
          <Typography>
            {option?.newBusinessInput
              ? option?.newBusinessOptionText
              : `${option?.companyName}`}
          </Typography>
        </AutocompleteOption>
      )}
      onChange={(_event, newValue) => {
        if (newValue) {
          handleOnChangeCompanyFilter(newValue.id);
        } else {
          handleOnChangeCompanyFilter("");
        }
      }}
    />
  );
};

export const DateSort = ({
  sortByDate,
  handleOnChangeSortByDate,
  t,
  isMobile,
}: // isMobile,
any) => {
  return (
    <Select
      size="sm"
      placeholder={"Sort By Date"}
      indicator={null}
      endDecorator={
        sortByDate === "DESC" ? (
          <BarsArrowDownIcon width={20} height={20} />
        ) : (
          <BarsArrowUpIcon width={20} height={20} />
        )
      }
      value={sortByDate}
      renderValue={(option) => !isMobile && option?.label} // no label in Mobile (icon only)
      onChange={(_, value) => {
        handleOnChangeSortByDate(value);
      }}
    >
      <Option value="DESC">
        {t("order.sort.date.DESC")}
        <BarsArrowDownIcon width={20} height={20} />
      </Option>
      <Option value="ASC">
        {t("order.sort.date.ASC")}
        <BarsArrowUpIcon width={20} height={20} />
      </Option>
    </Select>
  );
};
