import { Box, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../components/Theme";
import { useParams } from "react-router-dom";

type Props = {
  currentStep: number;
  validation: (step: number) => boolean;
  onClick: (step: number) => void;
};

const CreateCatalogueSteps = ({ currentStep, validation, onClick }: Props) => {
  const { t } = useTranslation();

  const { catalogId } = useParams();

  const steps = [
    {
      step: 1,
      title: t("catalog.createCatalog.steps.1"),
    },
    {
      step: 2,
      title: t("catalog.createCatalog.steps.2"),
    },
    {
      step: 3,
      title: t("catalog.createCatalog.steps.3"),
    },
  ];

  return (
    <Stack
      direction={"row"}
      sx={{
        p: "4px",
        width: "100%",
        borderWidth: "1px",
        borderColor: theme.palette.neutral[300],
        borderRadius: "8px",
      }}
    >
      {steps.map((step) => {
        const isValid = catalogId ? true : validation(step.step);

        return (
          <Stack
            id={`catalog-create-steps-${step.step}-button`}
            key={step.step}
            direction={"row"}
            sx={{
              width: {
                xs: currentStep === step.step ? "60%" : "20%",
                md: "33.3%",
              },
              py: "16px",
              px: { xs: "16px", md: "24px" },
              borderColor: theme.palette.neutral[300],
              borderRadius: "8px",
              bgcolor:
                currentStep === step.step
                  ? theme.palette.primary[50]
                  : "transparent",
              alignItems: "center",
              gap: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              onClick(step.step);
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderWidth: "2px",
                borderColor:
                  currentStep === step.step || isValid
                    ? theme.palette.primary[500]
                    : theme.palette.neutral[300],
                borderRadius: "999px",
                width: "40px",
                height: "40px",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor:
                  currentStep !== step.step && isValid
                    ? theme.palette.primary[500]
                    : "transparent",
              }}
            >
              <Typography
                level="text-md-medium"
                textColor={
                  currentStep === step.step
                    ? theme.palette.primary[500]
                    : isValid
                    ? theme.palette.common.white
                    : theme.palette.neutral[400]
                }
              >
                {step.step}
              </Typography>
            </Box>

            <Typography
              level={
                currentStep === step.step || isValid
                  ? "text-sm-bold"
                  : "text-sm-medium"
              }
              sx={{
                color:
                  currentStep === step.step
                    ? theme.palette.primary[500]
                    : isValid
                    ? theme.palette.common.black
                    : theme.palette.neutral[400],
                display: {
                  xs: currentStep === step.step ? "flex" : "none",
                  lg: "flex",
                },
              }}
            >
              {step.title}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default CreateCatalogueSteps;
