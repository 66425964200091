import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  Option,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/joy";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
  WhiteListedCountries,
} from "../../../utils/constants";
import { useEffect } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { animateSelectStyle } from "../../../styles/select";
import { countries } from "../../../components/Countries";
import { matchesFormat } from "../../../utils/misc";
import { useTranslation } from "react-i18next";
import FlagById from "../../../components/FlagById";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import useContainer from "./useContainer";
import { Fade } from "@mui/material";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";

interface AddressModalProps {
  show?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  selectedAddress?: IAddressResponse;
}

export default function AddressModal({
  show = false,
  onClose,
  onSuccess,
  selectedAddress,
}: AddressModalProps) {
  const {
    register,
    watch,
    setError,
    handleSubmit,
    setValue,
    errors,
    loading,
    showModal,
    setShowModal,
    handleEdit,
    onSubmit,
    handleClose,
    trigger,
    clearErrors,
  } = useContainer({ selectedAddress, onClose, onSuccess });

  useEffect(() => {
    setShowModal(show);
    if (selectedAddress) handleEdit(selectedAddress);
  }, [show]);

  const { t } = useTranslation();

  return (
    <Modal
      onClose={handleClose}
      open={showModal}
      sx={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
        display: "flex",
        alignItems: { xs: "flex-end", sm: "center" },
        justifyContent: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={showModal} timeout={500}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#fff",
            boxShadow: 24,
            borderRadius: { xs: "16px", sm: "32px" },
            outline: "none",
            width: "80%",
            maxWidth: { xs: "100vw", sm: "640px" },
            height: "auto",
            maxHeight: "90vh",
            overflowY: "auto",
            padding: { xs: "16px", sm: "32px" },
          }}
          className={"hide-scrollbar"}
          justifyContent={"center"}
        >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography level="text-xl-semibold" textColor={"text.primary"}>
              {t("profile.address.newAddress")}
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <Stack direction={"column"} spacing={{ xs: 1, sm: 2 }}>
              <FormControl error={!!errors.title}>
                <FormLabel>{t("profile.address.addressTitle")}*</FormLabel>
                <Input
                  placeholder={t("profile.address.typeAddressTitle")}
                  sx={{ backgroundColor: "white" }}
                  {...register("title")}
                />
                {errors.title?.message && (
                  <FormHelperText>{errors.title?.message}</FormHelperText>
                )}
                <FormHelperText>
                  {"You can give title like ‘Home’, ‘Office’ or ‘Warehouse’"}
                </FormHelperText>
              </FormControl>

              <FormControl error={!!errors.fullName}>
                <FormLabel>{t("profile.address.fullName")}*</FormLabel>
                <Input
                  placeholder={t("profile.address.yourFullName")}
                  {...register("fullName")}
                />
                {errors.fullName?.message && (
                  <FormHelperText>{errors.fullName?.message}</FormHelperText>
                )}
              </FormControl>

              <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
                <FormControl
                  sx={{ width: "100%" }}
                  error={!!errors.countryCode}
                >
                  <FormLabel>{t("profile.address.country")}</FormLabel>
                  <Select
                    placeholder={t("profile.address.country")}
                    indicator={<KeyboardArrowDown />}
                    onChange={(_, value) => {
                      if (value) setValue("countryCode", value);
                    }}
                    renderValue={(option) => (
                      <Typography
                        startDecorator={
                          <FlagById id={`${option?.value ?? ""}`} />
                        }
                      >
                        {option?.label}
                      </Typography>
                    )}
                    value={watch("countryCode")}
                    sx={{ ...animateSelectStyle, backgroundColor: "white" }}
                  >
                    {countries
                      .filter((country) =>
                        WhiteListedCountries.includes(country.code)
                      )
                      .map((country) => (
                        <Option
                          key={country.code}
                          value={country.code}
                          label={country.label}
                        >
                          <Typography
                            startDecorator={<FlagById id={country.code} />}
                          >
                            {country.label}
                          </Typography>
                        </Option>
                      ))}
                  </Select>
                  {errors.countryCode?.message && (
                    <FormHelperText>
                      {errors.countryCode?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  sx={{ width: "100%" }}
                  error={!!errors.phoneNumber}
                >
                  <FormLabel>{t("profile.address.phoneNumber")}*</FormLabel>
                  <PhoneInput
                    value={
                      (watch("phoneNumber") &&
                        `+${watch("phoneNumberCountryCode")}${watch(
                          "phoneNumber"
                        )}`) ||
                      ""
                    }
                    onlyCountries={PhNoWhiteListedCountries}
                    enableTerritories={false}
                    country={BusinessSessionHelper.GetBusinessCountryID().toLocaleLowerCase()}
                    masks={{
                      my: "..-....-....",
                      sg: "....-....",
                      th: "... ... ...",
                      id: "... ... ... ...",
                      mm: ". .... .... .....",
                      au: "... ... ...",
                    }}
                    isValid={(inputNumber, meta: any, countries) => {
                      if (inputNumber === "" || inputNumber === meta.dialCode) {
                        return true;
                      }

                      if (
                        inputNumber.toString().startsWith(`${meta.dialCode}0`)
                      ) {
                        if (!errors.phoneNumber?.message) {
                          setError("phoneNumber", {
                            message: t(
                              "commons.error.form.mobileInvalidStartZero"
                            ),
                          });
                        }
                        return false;
                      }

                      const result = (countries || []).some((country: any) => {
                        if (
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber)
                        ) {
                          const iso2: CountryCode = meta.iso2 as CountryCode;
                          const format = PhNoFormat[iso2];
                          if (!format) return false;
                          return matchesFormat(
                            inputNumber,
                            format,
                            country.countryCode
                          );
                        }
                        return false;
                      });

                      if (!result && !errors.phoneNumber?.message) {
                        setError("phoneNumber", {
                          message: t("commons.error.form.mobileInvalid"),
                        });
                      } else if (result && !!errors.phoneNumber?.message) {
                        if (errors.phoneNumber.type === "manual") {
                          return false;
                        }
                        clearErrors("phoneNumber");
                        trigger("phoneNumber");
                      }
                      return result;
                    }}
                    inputStyle={{
                      width: "100%",
                      fontSize: "16px",
                      minHeight: "2.8rem",
                      height: "auto",
                      borderRadius: "8px",
                      fontWeight: 500,
                    }}
                    buttonStyle={{
                      borderRadius: "8px 0 0 8px",
                    }}
                    onChange={(phone, meta: any) => {
                      setValue("phoneNumberCountryCode", meta.dialCode);
                      setValue(
                        "phoneNumber",
                        phone.replace(new RegExp(meta.dialCode), "")
                      );
                    }}
                  />
                  {errors.phoneNumber?.message && (
                    <FormHelperText>
                      {errors.phoneNumber?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <FormControl error={!!errors.address}>
                <FormLabel>{t("profile.address.address")}*</FormLabel>
                <Input
                  placeholder={t("profile.address.typeAddress")}
                  {...register("address")}
                />
                {errors.address?.message && (
                  <FormHelperText>{errors.address?.message}</FormHelperText>
                )}
              </FormControl>

              <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
                <FormControl sx={{ width: "100%" }} error={!!errors.province}>
                  <FormLabel>{t("profile.address.provinceState")}*</FormLabel>
                  <Input
                    placeholder={t("profile.address.typeProvinceState")}
                    {...register("province")}
                  />
                  {errors.province?.message && (
                    <FormHelperText>{errors.province?.message}</FormHelperText>
                  )}
                </FormControl>

                <FormControl sx={{ width: "100%" }} error={!!errors.postalCode}>
                  <FormLabel>{t("profile.address.postalCode")}</FormLabel>
                  <Input
                    placeholder={t("profile.address.typePostalCode")}
                    {...register("postalCode")}
                  />
                  {errors.postalCode?.message && (
                    <FormHelperText>
                      {errors.postalCode?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <FormControl
                className="flex flex-row justify-between items-center"
                error={!!errors.defaultAddress}
              >
                <FormLabel>{t("profile.address.default")}</FormLabel>
                <Switch
                  {...register("defaultAddress")}
                  defaultChecked={true}
                  checked={watch("defaultAddress")}
                  onChange={(e) => setValue("defaultAddress", e.target.checked)}
                />
              </FormControl>

              <Stack direction={"row"} spacing={2} mt={2}>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  size="sm"
                  variant="linkz-outlined-primary"
                  color="primary"
                  className="h-10 w-full"
                >
                  {t("profile.address.cancel")}
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="linkz-filled-primary"
                  color="primary"
                  className="h-10 w-full"
                >
                  {t("profile.address.confirm")}
                </Button>
              </Stack>
            </Stack>
          </form>

          <LoadingModal isLoading={loading} />
        </Box>
      </Fade>
    </Modal>
  );
}
