import { Sheet, Stack } from "@mui/joy";
import PageTitleBox from "../../components/PageTitleBox";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFound from "../NotFound/NotFound";
import DuplicateSKUForm from "../../components/Inventory/Excel/DuplicateSKUForm";
import { ItemService } from "../../services/item.service";
import UploadStatusBox from "../../components/Inventory/Excel/UploadStatusBox";
import useContainer from "./useContainer";
import { IInventoryDraftResponse } from "../../types/draft";

const InventoryDuplicateSKU = () => {
  const { t } = useTranslation();
  const { draftId } = useParams();

  const [draft, setDraft] = useState<IInventoryDraftResponse>();

  const { setIsLoading } = useContainer();

  /**
   * takes draftId as param, will assign to draft state
   * @param draftId
   * @returns IInventoryDraftResponse
   */
  const getInventoryDraft = async (draftId: string) => {
    try {
      const draftResponse = await ItemService.getInventoryDraft(draftId);
      console.log("DRAFT RESPONSE", draftResponse);

      setDraft(draftResponse);
    } catch (error: any) {
      console.error("Error fetching draft:", error);
      alert(error.message);
      setIsLoading(false);
    }
  };

  /**
   * run when url param draftId updated
   */
  useEffect(() => {
    if (draftId) {
      getInventoryDraft(draftId);
    }
  }, [draftId]);

  if (!draftId) {
    return <NotFound />;
  }

  //   const UploadStatusBox = () => {
  //     return (
  //       <Box
  //         sx={{
  //           borderWidth: "1px",
  //           borderColor: theme.palette.neutral[300],
  //           borderRadius: "8px",
  //           py: "16px",
  //           px: "24px",
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: "16px",
  //           minWidth: { xs: "100%", lg: "276px" },
  //           textWrap: "nowrap",
  //         }}
  //       >
  //         <Typography level="text-md-bold">
  //           {t("catalog.inventory.import.duplicate.uploadStatus.title")}
  //         </Typography>
  //         <Grid container gap={"4px"} columns={3}>
  //           <Grid xs={2}>
  //             <Typography
  //               level="text-xs-medium"
  //               sx={{
  //                 color: theme.palette.neutral[600],
  //                 width: "50%",
  //               }}
  //             >
  //               {t("catalog.inventory.import.duplicate.uploadStatus.currency")}
  //             </Typography>
  //           </Grid>

  //           <Grid>
  //             <Typography
  //               level="text-xs-semibold"
  //               sx={{
  //                 ml: "auto",
  //               }}
  //             >
  //               : IDR
  //             </Typography>
  //           </Grid>

  //           <Grid xs={2}>
  //             <Typography
  //               level="text-xs-medium"
  //               sx={{
  //                 color: theme.palette.neutral[600],
  //                 width: "50%",
  //               }}
  //             >
  //               {t(
  //                 "catalog.inventory.import.duplicate.uploadStatus.totalDraftItems"
  //               )}
  //             </Typography>
  //           </Grid>

  //           <Grid>
  //             <Typography level="text-xs-semibold">: 279</Typography>
  //           </Grid>
  //         </Grid>
  //       </Box>
  //     );
  //   };

  return !!draft ? (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <Stack
        direction={{ xs: "column", lg: "row" }}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          gap: { xs: "24px", lg: 0 },
        }}
      >
        <PageTitleBox
          title={t("catalog.inventory.import.duplicate.title")}
          subtitle={t("catalog.inventory.import.duplicate.subtitle")}
        />

        <UploadStatusBox
          currency={draft.draft.items[0].currency}
          totalItems={draft.draft.items.length}
        />
      </Stack>

      <DuplicateSKUForm
        draft={{ ...draft.draft, totalItems: draft.draft.items.length }}
      />
    </Sheet>
  ) : (
    <NotFound />
  );
};

export default InventoryDuplicateSKU;
