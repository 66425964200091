import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { theme } from "../../../../components/Theme";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  DELIVERY_FEES_OPTIONS,
  DELIVERY_FEES_OPTIONS_ENUM,
} from "../../../../types/order";
import { If, IfElse } from "../../../../components/Condition";
import { NumericFormat } from "react-number-format";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CustomCreateCatalogType } from "../../../../types/catalog";

type Props = {
  catalog: CustomCreateCatalogType;
};

const DeliveryInfoSection = ({ catalog }: Props) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  // watched fields
  const currency = watch("currency");
  const feeType = watch("deliveryFeeConfig.feeType");

  // delivery fee fieldArray
  const { fields, remove, append } = useFieldArray({
    control,
    name: "deliveryFeeConfig.feeValues",
  });

  return (
    <Box
      sx={{
        borderWidth: "1px",
        borderColor: theme.palette.neutral[300],
        borderRadius: "8px",
        py: "32px",
        px: "24px",
      }}
    >
      <Typography level="text-xl-bold" sx={{ color: "#101828" }}>
        {t("catalog.createCatalog.devliveryFeesTitle")}
      </Typography>

      <Controller
        name="deliveryFeeConfig.feeType"
        control={control}
        render={({ field: { onChange, onBlur, name, value, ref } }) => {
          return (
            <FormControl sx={{ flex: "1", mt: "32px" }} ref={ref}>
              <FormLabel>{t("catalog.createCatalog.ChooseFeeTypes")}</FormLabel>
              <Autocomplete
                name={name}
                freeSolo={true}
                placeholder={t("catalog.createCatalog.ChooseFeeTypes")}
                options={DELIVERY_FEES_OPTIONS}
                getOptionLabel={(option) =>
                  typeof option !== "string" ? option?.label : ""
                }
                onChange={(_event, newValue) => {
                  if (typeof newValue !== "string") {
                    onChange(newValue?.value);
                  }
                }}
                value={
                  DELIVERY_FEES_OPTIONS.find((pft) => pft.value === value) ||
                  null
                }
                onBlur={onBlur}
                sx={{ width: { xs: "100%", lg: "100%" } }}
                renderOption={(props, option) => (
                  <AutocompleteOption
                    {...props}
                    key={option.value}
                    sx={{
                      "&:hover": {
                        backgroundColor: "var(--joy-palette-neutral-100)",
                      },
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      flex={1}
                    >
                      <Stack>
                        {option.label}
                        <Typography>{option.description}</Typography>
                      </Stack>
                    </Stack>
                  </AutocompleteOption>
                )}
              />
            </FormControl>
          );
        }}
      />
      <FormHelperText>{(errors.feeType as any)?.message}</FormHelperText>

      <Divider sx={{ my: "16px" }} />

      <FormControl sx={{ flex: "1", margin: "16px auto" }}>
        {/* delivery fee type titles */}
        <Stack direction={"column"} gap={"4px"}>
          <Typography
            level={"text-md-semibold"}
            sx={{ color: theme.palette.common.black }}
          >
            {t(`catalog.createCatalog.deliveryFee.${feeType}`)}
          </Typography>

          <Typography
            level={"text-xs-medium"}
            sx={{ color: theme.palette.neutral[600] }}
          >
            {t(`catalog.createCatalog.deliveryFee.${feeType}Subtitle`)}
          </Typography>
        </Stack>

        {/* delivery fee values */}
        {fields.map((item, index) => (
          <Stack
            key={item.id}
            direction={{ xs: "column", md: "row" }}
            spacing={3}
            sx={{
              alignItems: "flex-end",
              py: "12px",
              // borderWidth: { xs: "1px", md: 0 },
              // borderRadius: "12px",
              //   my: { xs: 0, md: "8px" },
            }}
          >
            {feeType !== DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT && (
              <Controller
                name={`deliveryFeeConfig.feeValues.${index}.upToCount`}
                defaultValue={
                  catalog?.deliveryFeeConfig?.feeValues[index].upToCount
                }
                control={control}
                render={({ field: { name, onChange, ref, value } }) => (
                  <FormControl
                    sx={{
                      width: { xs: "100%", md: "45%" },
                    }}
                    ref={ref}
                  >
                    <FormLabel>
                      {t("commons.startFrom")}{" "}
                      <span className="text-danger-400">*</span>
                    </FormLabel>

                    <IfElse
                      condition={
                        feeType !== DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE
                      }
                      ifBlock={
                        <Input
                          name={name}
                          type="number"
                          placeholder={t("commons.startFrom")}
                          endDecorator={
                            feeType !==
                            DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE ? (
                              <span className="text-black bold">
                                {t("commons.Items")}
                              </span>
                            ) : null
                          }
                          startDecorator={
                            feeType ===
                            DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE ? (
                              <span className="text-black bold">
                                {currency}
                              </span>
                            ) : null
                          }
                          aria-label="catalogName"
                          className="min-h-[3.5rem]"
                          value={value}
                          onChange={(v) => {
                            onChange(Number(v.target.value));
                          }}
                        />
                      }
                      elseBlock={
                        <NumericFormat
                          name={name}
                          customInput={Input}
                          startDecorator={<>{currency}</>}
                          placeholder={t("commons.Fee")}
                          value={value}
                          onValueChange={(v) => {
                            onChange(Number(v.value));
                          }}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currency === "IDR" ? "." : ","}
                          decimalSeparator={currency === "IDR" ? "," : "."}
                        />
                      }
                    />
                  </FormControl>
                )}
              />
            )}

            <Controller
              name={`deliveryFeeConfig.feeValues.${index}.value`}
              defaultValue={catalog?.deliveryFeeConfig?.feeValues[index].value}
              control={control}
              render={({ field: { name, onChange, ref, value } }) => (
                <FormControl className="w-[100%]" ref={ref}>
                  <FormLabel>
                    {t("commons.Fee")}{" "}
                    <span className="text-danger-400">*</span>
                  </FormLabel>

                  <NumericFormat
                    name={name}
                    customInput={Input}
                    startDecorator={<>{currency}</>}
                    placeholder={t("commons.Fee")}
                    // disabled={isReadOnly || isAdjustmentOrder}
                    value={value}
                    onValueChange={(v) => {
                      onChange(Number(v.value));
                      // handleCalculatePrice();
                    }}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    // valueIsNumericString={true}
                    thousandSeparator={currency === "IDR" ? "." : ","}
                    decimalSeparator={currency === "IDR" ? "," : "."}
                  />
                </FormControl>
              )}
            />

            {feeType !== DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT && (
              <IconButton>
                <TrashIcon
                  onClick={() => {
                    remove(index);
                  }}
                  width={24}
                  height={24}
                  color={theme.palette.others.cart_danger_button}
                />
              </IconButton>
            )}
          </Stack>
        ))}
      </FormControl>

      <If condition={feeType !== DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT}>
        <Button
          id="catalog-create-deliveryInfo-addFee-button"
          variant="plain"
          startDecorator={<PlusIcon width={20} height={20} />}
          onClick={() => {
            append({ upToCount: 0, value: 0 });
          }}
        >
          {t("commons.AddFee")}
        </Button>
      </If>
    </Box>
  );
};

export default DeliveryInfoSection;
