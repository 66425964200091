import { z } from "zod";
import { RoleType } from "../../types/role-type";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { EmployeeService } from "../../services/employee.service";
import { CreateEmployee } from "../../types/employee";
import toast from "react-hot-toast";
import showErrorToast from "../../components/Error";
import { useLocation, useNavigate } from "react-router-dom";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { BusinessService } from "../../services/business.service";

const employeeScheme = z.object({
  fullName: z.string().min(1, "Required name"),
  email: z.string().email("Required email address").or(z.literal("")),
  phoneNumber: z.string().min(1, "Required phone number").or(z.literal("")),
  phoneNumberCountryCode: z.string().min(1, "Required country code").optional(),
  role: z.enum([RoleType.BusinessAdmin, RoleType.BusinessUser]),
  resourcePermissions: z.array(
    z.object({
      resource: z.string().min(1, "Required resource"),
      permissions: z.array(z.string()),
    })
  ),
});

export default function useEmployeeContainer({ isEdit = false }) {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [businessResources, setBusinessResources] = useState([]);

  const { state } = useLocation();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<z.infer<typeof employeeScheme>>({
    resolver: zodResolver(employeeScheme),
  });

  const [profileImage, setProfileImage] = useState<File>();

  const mutation = useMutation({
    mutationFn: EmployeeService.create,
    onSuccess: () => {
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        navigate("/client/employees", { replace: true });
      }, 3000);
    },
    onError: (err) => {
      setLoading(false);
      toast(showErrorToast(err), { duration: 4000 });
    },
  });

  const updateMutation = useMutation({
    mutationFn: EmployeeService.update,
    onSuccess: () => {
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        navigate("/client/employees", { replace: true });
      }, 3000);
    },
    onError: (err) => {
      setLoading(false);
      toast(showErrorToast(err), { duration: 4000 });
    },
  });

  const onSubmit = (data: z.infer<typeof employeeScheme>) => {
    // let payload: CreateEmployee = {}
    let payload = {} as CreateEmployee;

    if (data.email) {
      payload = {
        fullName: data.fullName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        phoneNumberCountryCode: data.phoneNumberCountryCode,
        role: data.role,
        resourcePermissions: data.resourcePermissions,
      };
    } else {
      payload = {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        phoneNumberCountryCode: data.phoneNumberCountryCode,
        role: data.role,
        resourcePermissions: data.resourcePermissions,
      };
    }

    if (!data.email && !data.phoneNumber) {
      setError("email", {
        message: "Please fill in email or phone number",
        type: "manual",
      });

      setError("phoneNumber", {
        message: "Please fill in email or phone number",
        type: "manual",
      });
    }

    const activeBusinessId = BusinessSessionHelper.GetBusinessId();
    if (activeBusinessId) {
      if (isEdit) {
        updateMutation.mutate({
          payload,
          businessId: activeBusinessId,
          employeeId: state?.employee?.id,
        });
      } else {
        mutation.mutate({ payload, businessId: activeBusinessId });
      }
    }
  };

  const getBusinessResources = () => {
    BusinessService.getBusinessResources().then((data) => {
      setBusinessResources(data);
    });
  };

  const setResource = (
    resource: string,
    set: boolean,
    viewOnly: boolean = true
  ) => {
    var current = getValues("resourcePermissions");
    current = current?.filter((item) => item.resource) ?? [];
    if (!set) {
      current = current?.filter((item) => item.resource !== resource);
    } else {
      var permissions = ["READ"];
      if (!viewOnly) {
        permissions = ["CREATE", "READ", "UPDATE", "DELETE"];
      }
      current = [...current, { resource, permissions: permissions }];
    }
    console.log(current);
    setValue("resourcePermissions", current);
  };

  const setResourcePermissions = (resource: string, viewOnly: boolean) => {
    var current = getValues("resourcePermissions");
    if (!current || !current?.map((item) => item.resource).includes(resource)) {
      setResource(resource, true, viewOnly);
    } else {
      var newResource = current.find((item) => item.resource === resource);
      current = current.filter(
        (item) => item.resource !== resource && item.resource
      );
      var permissions = ["READ"];
      if (!viewOnly) {
        permissions = ["CREATE", "READ", "UPDATE", "DELETE"];
      }
      newResource!.permissions = permissions;
      setValue("resourcePermissions", [...current, newResource!]);
    }
  };

  useEffect(() => {
    if (state?.employee && isEdit) {
      const employee = state?.employee;
      console.log(employee);
      setValue("fullName", employee.fullName);
      setValue("email", employee.email);
      setValue("phoneNumber", employee.phoneNumber);
      setValue("phoneNumberCountryCode", employee.phoneNumberCountryCode);
      setValue("role", employee.role);
      setValue("resourcePermissions", employee.resourcePermissions);
    }
  }, [state?.employee]);

  useEffect(() => {
    getBusinessResources();
  }, []);

  return {
    errors,
    profileImage,
    success,
    loading,
    businessResources,
    setResource,
    setResourcePermissions,
    getValues,
    register,
    watch,
    handleSubmit,
    setProfileImage,
    onSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
  };
}
