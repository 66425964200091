import { Box, Modal, Stack, Typography } from "@mui/joy";
import Lottie from "lottie-react";
import CatalogLoader from "../../../public/animations/catalog-loader.json";
import { theme } from "../Theme";
import { useTranslation } from "react-i18next";

interface Props {
  isLoading?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "380px",
  height: "372px",
  p: "40px",
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const LottieCatalogLoader = ({ isLoading = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isLoading}
      style={{
        backgroundColor: "rgba(50, 56, 62,.6)",
        backdropFilter: "blur(0px)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"} className="hide-scrollbar">
        <Stack direction={"column"} gap={"24px"}>
          <Box
            sx={{
              width: "300px",
              height: "200px",
            }}
          >
            <Lottie animationData={CatalogLoader} loop={true} autoplay={true} />
          </Box>

          <Stack
            direction={"column"}
            sx={{
              gap: "12px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              level="text-xl2-bold"
              textColor={theme.palette.common.black}
            >
              {t("catalog.createCatalog.loader.title")}
            </Typography>
            <Typography
              level="text-md-regular"
              textColor={theme.palette.text.secondary}
            >
              {t("catalog.createCatalog.loader.subtitle")}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LottieCatalogLoader;
