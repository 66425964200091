import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
  FirstPageOutlined,
  LastPageOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Input,
  Modal,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { PaginationType } from "../../../types/item";
import { If } from "../../../components/Condition";
import { t } from "i18next";
import { theme } from "../../../components/Theme";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type Props = {
  handlePaging: (action: string | number) => void;
  pagination: PaginationType;
  setPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
  withLimit?: boolean;
  withSelected?: boolean;
  selected?: number;
  total?: number;
  justifyContent?: string;
};

const TablePagination = ({
  handlePaging,
  pagination,
  setPagination,
  withLimit = false,
  withSelected = false,
  selected = 0,
  total = 0,
  justifyContent = "space-between",
}: Props) => {
  const [showJumpToPage, setShowJumpToPage] = useState(false);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      width={"100%"}
      justifyContent={justifyContent}
      alignItems={"center"}
      gap={"1.5rem"}
    >
      <If condition={withLimit}>
        <Typography
          fontSize={14}
          fontWeight={400}
          textColor={"#101828"}
          display={"flex"}
          alignItems={"center"}
          gap={"0.5rem"}
        >
          {t("commons.pagination.show")}
          <Select
            value={pagination.limit}
            size="sm"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            onChange={(_event, value) => {
              /**
               * will update parent's pagination limit to value
               * and update pagination max with new pagination limit
               * and go back to first page
               * @param value
               */
              if (value) {
                const newPagination = pagination;
                newPagination.limit = value;
                newPagination.max = Math.ceil(pagination.max / value);

                setPagination(newPagination);
                handlePaging("first");
              }
            }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
          {t("commons.pagination.itemsPerPage")}
        </Typography>
      </If>
      <If condition={withSelected}>
        <Typography fontSize={14}>
          <span className="hidden lg:block">
            {t("catalog.cartSelectedDescWithoutTotal", {
              selected: selected,
              total: total,
            })}
          </span>
        </Typography>
      </If>

      {/* screen large page selection */}
      <Stack
        direction={"row"}
        sx={{
          display: { xs: "none", lg: "flex" },
          justifyContent: "center",
          alignItems: "center",
          gap: 0,
        }}
      >
        <IconButton
          key={"first"}
          disabled={pagination.current === 1}
          type="button"
          onClick={() => {
            handlePaging("first");
          }}
        >
          <FirstPageOutlined />
        </IconButton>
        <IconButton
          key={"previous"}
          disabled={pagination.current === 1}
          type="button"
          onClick={() => {
            handlePaging("previous");
          }}
        >
          <ChevronLeftOutlined />
        </IconButton>
        {/* {Array.from({ length: pagination.max }, (_, i) => (
          <Button
            key={i}
            variant="plain"
            type="button"
            className={`min-w-fit py-1 px-4 ${
              pagination.current === i + 1
                ? "text-primary-500 bg-primary-100"
                : "text-gray-600"
            }`}
            onClick={() => {
              handlePaging(i + 1);
            }}
          >
            {i + 1}
          </Button>
        ))} */}
        {Array.from({ length: pagination.max }, (_, i) => {
          const page = i + 1;
          const isCurrent = pagination.current === page;
          const showEllipsisBefore =
            page === pagination.max - 1 &&
            pagination.current < pagination.max - 3;
          const showEllipsisAfter = page === 2 && pagination.current > 4;

          // Always show the first page, last page, and pages around the current page
          if (
            page === 1 || // Always show the first page
            page === pagination.max || // Always show the last page
            (page >= pagination.current - 1 &&
              page <= pagination.current + 1) || // Show current page and neighbors
            showEllipsisBefore || // Show ellipsis before the last page
            showEllipsisAfter // Show ellipsis after the first page
          ) {
            return (
              <Button
                key={i}
                variant="plain"
                type="button"
                className={`min-w-fit py-1 px-4 ${
                  isCurrent
                    ? "text-primary-500 bg-primary-100"
                    : "text-gray-600"
                }`}
                onClick={() => {
                  handlePaging(page);
                }}
              >
                {page}
              </Button>
            );
          }

          // Show ellipsis instead of extra pages
          if (
            page === pagination.current - 2 ||
            page === pagination.current + 2
          ) {
            return (
              <Button
                variant="plain-gray"
                key={`ellipsis-${i}`}
                onClick={() => {
                  setShowJumpToPage(true);
                }}
                sx={{
                  width: "32px",
                  minWidth: "32px",
                  p: 0,
                }}
              >
                ...
              </Button>
            );
          }

          // Hide other pages
          return null;
        })}
        <IconButton
          key={"next"}
          disabled={pagination.current === pagination.max}
          type="button"
          onClick={() => {
            handlePaging("next");
          }}
        >
          <ChevronRightOutlined />
        </IconButton>
        <IconButton
          key={"last"}
          disabled={pagination.current === pagination.max}
          type="button"
          onClick={() => {
            handlePaging("last");
          }}
        >
          <LastPageOutlined />
        </IconButton>
      </Stack>

      {/* screen small page selection */}
      <Stack
        direction={"row"}
        sx={{
          display: { xs: "flex", lg: "none" },
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          alignContent: "center",
        }}
      >
        <Button
          key={"previous"}
          disabled={pagination.current === 1}
          type="button"
          onClick={() => {
            handlePaging("previous");
          }}
          sx={{
            p: "10px",
            minWidth: 0,
          }}
        >
          <ArrowLeftIcon width={12} height={12} />
        </Button>
        <Typography
          level="text-sm-medium"
          textColor={theme.palette.neutral[600]}
          onClick={() => {
            setShowJumpToPage(true);
          }}
        >{`${t("commons.pagination.page")} ${pagination.current} ${t(
          "commons.pagination.of"
        )} ${pagination.max}`}</Typography>
        <Button
          key={"next"}
          disabled={pagination.current === pagination.max}
          type="button"
          onClick={() => {
            handlePaging("next");
          }}
          sx={{
            p: "10px",
            minWidth: 0,
          }}
        >
          <ArrowRightIcon width={12} height={12} />
        </Button>
      </Stack>

      {/* modal */}
      <JumpToPageModal
        show={showJumpToPage}
        onClick={(pageToJumpTo) => {
          handlePaging(pageToJumpTo);
          setShowJumpToPage(false);
        }}
        onClose={() => {
          setShowJumpToPage(false);
        }}
      />
    </Stack>
  );
};

/**
 * JumpToPageModal
 *
 * A modal that allows users to jump to a specific page number.
 *
 * @returns A modal component that displays a page number input and two buttons
 */
const JumpToPageModal = ({
  show = false,
  onClick,
  onClose,
}: {
  show?: boolean;
  onClick: (pageToJumpTo: number) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const [pageToJumpTo, setPageToJumpTo] = useState(1);

  const handleClick = () => {
    onClick(pageToJumpTo);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#fff",
          boxShadow: 24,
          width: { xs: "85%", lg: "20%" },
          maxWidth: 600,
          p: 4,
          borderRadius: "32px",
          borderColor: "transparent",
          outline: "none",
        }}
        justifyContent={"center"}
      >
        <Typography
          mt={3}
          textAlign={"center"}
          fontWeight={700}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#171A1C",
          }}
        >
          {t("commons.pagination.jumpToTitle")}
        </Typography>

        {/* input number  */}
        <Stack
          direction={"row"}
          justifyContent={"center"}
          mt={2}
          width={"100%"}
        >
          <Input
            type="number"
            size="lg"
            sx={{
              width: "100%",
            }}
            onChange={(e) => setPageToJumpTo(Number(e.target.value))}
          />
        </Stack>

        {/* buttons */}
        <Stack
          direction={{ xs: "column-reverse", lg: "row" }}
          justifyContent={"space-between"}
          mt={3}
          sx={{ width: "100%" }}
          spacing={2}
        >
          <Button
            type="button"
            onClick={handleClick}
            className="w-full rounded-md"
          >
            {t("commons.pagination.jumpBtn")}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TablePagination;
