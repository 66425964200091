import { Box, Grid, Typography } from "@mui/joy";
import { theme } from "../../Theme";
import { useTranslation } from "react-i18next";

type Props = {
  currency: string;
  totalItems: number;
};

const UploadStatusBox = ({ currency, totalItems }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderWidth: "1px",
        borderColor: theme.palette.neutral[300],
        borderRadius: "8px",
        py: "16px",
        px: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        minWidth: { xs: "100%", lg: "276px" },
        textWrap: "nowrap",
      }}
    >
      <Typography level="text-md-bold">
        {t("catalog.inventory.import.duplicate.uploadStatus.title")}
      </Typography>
      <Grid container gap={"4px"} columns={3}>
        <Grid xs={2}>
          <Typography
            level="text-xs-medium"
            sx={{
              color: theme.palette.neutral[600],
              width: "50%",
            }}
          >
            {t("catalog.inventory.import.duplicate.uploadStatus.currency")}
          </Typography>
        </Grid>

        <Grid>
          <Typography
            level="text-xs-semibold"
            sx={{
              ml: "auto",
            }}
          >
            : {currency}
          </Typography>
        </Grid>

        <Grid xs={2}>
          <Typography
            level="text-xs-medium"
            sx={{
              color: theme.palette.neutral[600],
              width: "50%",
            }}
          >
            {t(
              "catalog.inventory.import.duplicate.uploadStatus.totalDraftItems"
            )}
          </Typography>
        </Grid>

        <Grid>
          <Typography level="text-xs-semibold">
            : {totalItems} {t("commons.pagination.items")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadStatusBox;
