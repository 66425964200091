import {
  Box,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import Divider from "@mui/joy/Divider";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import Gauth from "../../components/Gauth";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events.ts";
import Shared from "../../layouts/Shared";
import { TrackerService } from "../../services/tracker.service";
import BusinessSelectionModal from "./BusinessSelectionModal/BusinessSelectionModal";
import useContainer from "./useContainer";
import PhoneSigninForm from "./components/PhoneSignin/PhoneSigninForm.tsx";
import EmailSigninForm from "./components/EmailSignin/EmailSigninForm.tsx";

export default function Signin() {
  const { t } = useTranslation();
  const [urlSearchParams] = useSearchParams();
  const callbackUrl = urlSearchParams.get("callbackURL");

  const {
    googleSignInHandler,
    isLoading,
    openBusinessSelectionModal,
    closeBusinessModalHandler,
  } = useContainer();

  useEffect(() => {
    TrackerService.track(
      TRACKER_CONSTANTS.LOGIN_SUCCESS.events.LoginPageVisited
    );
  }, []);

  return (
    <>
      <Shared
        minWidth={"329px"}
        width={{
          md: "464px",
        }}
        showNavArrow={false}
        sxProps={{ backgroundColor: "transparent" }}
      >
        <Helmet
          link={[
            { href: "https://linkzasia.com/auth/login", rel: "canonical" },
          ]}
          meta={[{ content: "Log In", name: "description" }]}
          title="Log In - Linkz"
        />
        <Stack
          component="section"
          data-testid="login"
          id="login"
          flex={1}
          // padding={2}
          // mt={{ lg: 5, sm: 0 }}
        >
          <Stack
          // sx={{
          //   flex: 1,
          //   "@media (min-width: 500px)": {
          //     flex: "unset",
          //   },
          // }}
          >
            <Tabs aria-label="signup-method" defaultValue={0}>
              <TabList
                sx={{
                  [`&& .${tabClasses.root}`]: {
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                    [`&.${tabClasses.selected}`]: {
                      "&::after": {
                        bgcolor: "primary.500",
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3,
                        height: 2,
                      },
                      color: "primary.plainColor",
                    },
                    bgcolor: "transparent",
                    flex: "initial",
                    paddingY: 2,
                  },
                  justifyContent: "space-evenly",
                  paddingTop: 1,
                }}
              >
                <Tab
                  sx={{
                    width: "50%",
                    "&.Mui-selected": {
                      fontWeight: 700,
                      "& .tab-text": {
                        color: "primary.500",
                      },
                    },
                  }}
                >
                  <Typography
                    fontSize={16}
                    className="tab-text"
                    sx={{ fontWeight: "400px" }}
                  >
                    {t("landing.signup.methods.email")}
                  </Typography>
                </Tab>
                <Tab
                  sx={{
                    width: "50%",
                    "&.Mui-selected": {
                      fontWeight: 700,
                      "& .tab-text": {
                        color: "primary.500",
                      },
                    },
                  }}
                >
                  <Typography
                    fontSize={16}
                    className="tab-text"
                    sx={{ fontWeight: "400px" }}
                  >
                    {t("landing.signup.methods.phone")}
                  </Typography>
                </Tab>
              </TabList>

              <Box
                component="section"
                data-testid="register"
                id="register"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  py: "1rem",
                }}
              >
                <TabPanel
                  value={0}
                  sx={{
                    p: 0,
                  }}
                >
                  <EmailSigninForm />
                  <Divider sx={{ my: 2 }}> {t("landing.or")}</Divider>
                  <Box
                    onClick={googleSignInHandler}
                    width={"300px"}
                    marginX={"auto"}
                  >
                    <Gauth />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={1}
                  sx={{
                    p: 0,
                  }}
                >
                  <PhoneSigninForm />
                </TabPanel>
              </Box>
            </Tabs>
          </Stack>

          <Stack
            columnGap={4}
            mt={7}
            justifyContent={"center"}
            direction={"row"}
          >
            <Typography>{t("landing.newToLinkz")}</Typography>
            <Link
              component={RouterLink}
              to={
                `/signup` + (callbackUrl ? `?callbackURL=${callbackUrl}` : ``)
              }
            >
              <Typography fontWeight={600} textColor="primary.500">
                {t("landing.createAccountLabel")}
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Shared>
      <LoadingModal isLoading={isLoading} />
      <BusinessSelectionModal
        close={closeBusinessModalHandler}
        isOpen={openBusinessSelectionModal}
      />
    </>
  );
}
