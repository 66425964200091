import { Box, Typography } from "@mui/joy";
import { If } from "./Condition";
import { theme } from "./Theme";

type Props = {
  title: string;
  subtitle?: string;
  showOnMobile?: boolean;
};

const PageTitleBox = ({ title, subtitle, showOnMobile = true }: Props) => {
  return (
    <Box
      sx={{
        display: showOnMobile ? "block" : { xs: "none", md: "block" },
      }}
    >
      <Typography
        level="text-xl2-bold"
        sx={{ color: theme.palette.common.black }}
      >
        {title}
      </Typography>
      <If condition={subtitle ? true : false}>
        <Typography
          level="text-md-medium"
          sx={{ color: theme.palette.neutral[600] }}
        >
          {subtitle}
        </Typography>
      </If>
    </Box>
  );
};

export default PageTitleBox;
