/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";

import FormControl from "@mui/joy/FormControl";
import IconButton from "@mui/joy/IconButton";

import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Fragment, useEffect, useState } from "react";

import { Stack, Tab, tabClasses, TabList, Tabs } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { Pagination } from "../../components/Pagination";

import { OrderService } from "../../services/order.service";
import { useAuthStore } from "../../store/session";
import { IOrderList, orderReportTypes } from "../../types/order";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import toast from "react-hot-toast";
import OrderReportTable from "../../components/Orders/OrderReportTable";
import OrderDetailsPdf, { OrderDetailsProps } from "./pdf/OrderDetailsPDF";
import { getInvoiceNumber } from "../../utils/order";
import generatePDF from "react-to-pdf";
import { BusinessService } from "../../services/business.service";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import OrderReportCards from "../../components/Orders/OrderReportCards";
import { orderStatuses } from "../../types/order";
import {
  CompanyFilter,
  CurrencyFilter,
  DateFilter,
  DateSort,
  InputSearch,
  OrderStatusFilter,
  OrderTypeFilter,
} from "../../components/Orders/OrderFilters";

const DownloadPdfButton = ({ onClickDownloadPdf }: any) => (
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      onClickDownloadPdf();
    }}
  >
    <ArrowDownTrayIcon width={20} height={20} />
  </IconButton>
);

export default function ReportOrders() {
  const { t } = useTranslation();
  // const location = useLocation();
  const [loading, setLoading] = useState<any>(true);
  const { session } = useAuthStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md")); // Mobile is started from md
  const [orderList, setOrderList] = useState<IOrderList[] | any>([]);
  const [externalBusinessList, setExternalBusinessList] = useState<any>([]);

  const [pagination, setPagination] = useState<any>({
    currentLast: 0,
    max: 0,
    limit: 20,
    offset: 0,
  });

  const paramStatus = searchParams.get("status");
  const paramDate = searchParams.get("date");
  const paramType = searchParams.get("type");
  const paramQ = searchParams.get("q");
  const paramReportType = searchParams.get("reportType");
  const paramCompanyId = searchParams.get("companyId");
  const paramCurrency = searchParams.get("currency");
  const paramSortByDate = searchParams.get("sortByDate") || "DESC";

  const selectedCompany = externalBusinessList?.find(
    (business: any) => business.id === paramCompanyId
  );

  console.log("Selected Company >>>", selectedCompany);

  useEffect(() => {
    getAndSetExternalBusinessList();
  }, []);

  useEffect(() => {
    if (!paramQ) {
      // If no input search, no need to use Debounce timer
      setLoading(true);
      getAndSetOrderList();
      return () => {};
    }

    // Debounce timer
    const timer = setTimeout(() => {
      setLoading(true);
      getAndSetOrderList();
    }, 700);
    return () => {
      clearTimeout(timer);
    };
  }, [searchParams, pagination.limit, pagination.offset]);

  // useEffect(() => {
  //   console.log("orderList Effect ???", session?.activeBusiness);
  //   if (session?.activeBusiness && !paramQ) {
  //     getAndSetOrderList();
  //   }
  // }, [session?.activeBusiness, pagination.offset, pagination.limit]);

  async function getAndSetExternalBusinessList() {
    const externalBusinessList = await BusinessService.getExternalBusinessList({
      businessId: session?.activeBusiness.id,
    });
    if (externalBusinessList) {
      console.log("External Business List", externalBusinessList);
      setExternalBusinessList(externalBusinessList);
    }
  }

  async function fetchOrderPdfContent(orderId: string) {
    try {
      const result = await OrderService.getOrderPDFContent(orderId);
      result.invoiceNumber = getInvoiceNumber(result, result.revisions);
      console.log("PDF Content ::", result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async function getAndSetOrderList() {
    console.log(
      "Get Order List Params :::",
      paramQ,
      paramType,
      paramDate,
      paramStatus
    );
    try {
      const responseData = await OrderService.getOrderReportByReportType({
        businessId: session?.activeBusiness.id,
        reportType: paramReportType,
        searchTerm: paramQ,
        companyId: paramCompanyId,
        hasPagination: true,
        limit: pagination.limit,
        offset: pagination.offset,
      });
      console.log("Order List Response :::", responseData);
      const initialOrderList = responseData.items.map((item: any) => {
        const orderType =
          item.orderType ||
          (item.seller && item.seller.id === session?.activeBusiness?.id)
            ? "sales"
            : item.buyer && item.buyer.id === session?.activeBusiness?.id
            ? "purchase"
            : "draft";
        return {
          ...item,
          orderType,
        };
      });
      console.log("Initial Order List :::", initialOrderList);

      setOrderList(initialOrderList);
      setPagination(responseData.pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  }

  // if (Object.keys(pdfContent).length > 0) {
  //   console.log("PDF Content >>>", !!pdfContent)
  //   toPDF({});
  // }

  // const { toPDF, targetRef } = usePDF();
  const [pdfContent, setPdfContent] = useState<OrderDetailsProps>(
    {} as OrderDetailsProps
  );

  const targetElement = () => document.getElementById("order-details-pdf");

  const handleDownloadPdf = async (orderId: string) => {
    setLoading(true);
    const result = await fetchOrderPdfContent(orderId);
    if (!result) {
      console.log("No PDF Content");
      return;
    }
    setPdfContent(result);
  };

  useEffect(() => {
    console.log("PDF Content >>>", pdfContent);
    if (pdfContent && Object.keys(pdfContent).length > 0) {
      setTimeout(() => {
        generatePDF(targetElement, {
          filename: `order-${
            pdfContent?.invoiceNumber || "00000000-0000000"
          }.pdf`,
        });
        setLoading(false);
      }, 700);
    }
  }, [pdfContent]);

  const handleOnSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchTerm(e.target.value);
    const newParams = new URLSearchParams(searchParams);
    if (e.target.value) {
      newParams.set("q", e.target.value);
    } else {
      newParams.delete("q");
    }
    setSearchParams(newParams);
  };

  const handleOrderDateFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("date", newValue);
    } else {
      newParams.delete("date");
    }
    setSearchParams(newParams);
  };

  const filterByDate = (orderDate: any) => {
    const now = dayjs();
    switch (paramDate) {
      case "today":
        return dayjs(orderDate).isSame(now, "day");
      case "yesterday":
        return dayjs(orderDate).isSame(now.subtract(1, "day"), "day");
      case "this_week":
        return dayjs(orderDate).isSame(now, "week");
      case "this_month":
        return dayjs(orderDate).isSame(now, "month");
      default:
        return true;
    }
  };

  const handleOrderStatusFilterChange = (
    _event: React.SyntheticEvent | null,
    // newValue: { value: string; label: string; backendStatuses: [] } | null
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("status", newValue);
    } else {
      newParams.delete("status");
    }
    setSearchParams(newParams);
  };

  const handleOrderTypeFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("type", newValue);
    } else {
      newParams.delete("type");
    }
    setSearchParams(newParams);
  };

  const handleOnChangeCompanyFilter = (companyId: any) => {
    console.log("handleOnChangeCompanyFilter >>>", companyId);
    const newParams = new URLSearchParams(searchParams);
    if (companyId) {
      newParams.set("companyId", companyId);
    } else {
      newParams.delete("companyId");
    }
    setSearchParams(newParams);
  };

  const handleOnChangeCurrencyFilter = (currencyId?: string | null) => {
    console.log("handleOnChangeCurrencyFilter >>>", currencyId);
    const newParams = new URLSearchParams(searchParams);
    if (currencyId) {
      newParams.set("currency", currencyId);
    } else {
      newParams.delete("currency");
    }
    setSearchParams(newParams);
  };

  const handleOnChangeSortByDate = (newValue: any) => {
    console.log("handleOnChangeSortByDate >>>", newValue);
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("sortByDate", newValue);
    } else {
      newParams.delete("sortByDate");
    }
    setSearchParams(newParams);
  };

  const handleChangeReportType = (newValue: any) => {
    setPagination({ ...pagination, offset: 0 }); // pagination should start from 1st page whenever report type is changed
    const newParams = new URLSearchParams(searchParams);
    newParams.set("reportType", newValue);
    setSearchParams(newParams);
  };

  const paramStatusFilterBackendStatuses = orderStatuses.find(
    (status) => status.value === paramStatus
  )?.backendStatuses;

  const filteredOrderList = orderList
    ?.filter((order: any) => {
      console.log("Order to be filtered :::", {
        paramStatus,
        paramStatusFilterBackendStatuses,
        orderStatus: order.status,
        paramCurrency: paramCurrency,
        orderCurrency: order.currency,
        paramSortByDate: paramSortByDate,
      });
      return (
        (paramType ? paramType === order.orderType : true) &&
        (paramStatus
          ? paramStatusFilterBackendStatuses &&
            paramStatusFilterBackendStatuses.includes(order.status)
          : true) &&
        (paramCurrency ? paramCurrency === order.currency : true) &&
        filterByDate(order.updatedAt)
      );
    })
    .sort((a: any, b: any) => {
      const dateA = new Date(a.updatedAt) as any;
      const dateB = new Date(b.updatedAt) as any;

      if (paramSortByDate === "ASC") {
        return dateA - dateB;
      } else if (paramSortByDate === "DESC") {
        return dateB - dateA;
      }
      return 0;
    });

  const tableHeader = () => {
    if (paramReportType === "TOTAL_PAYABLE") {
      return ["OrderNo.", "Company", "Payable", "Status"];
    } else if (paramReportType === "TOTAL_RECEIVABLE") {
      return ["OrderNo.", "Company", "Receivable", "Status"];
    } else if (paramReportType === "PAYMENT_HISTORY") {
      return [
        { title: "Type", width: "80px" },
        "OrderNo.",
        "Company",
        "Payment Type",
        "Total Amount",
        "Paid",
        "Settlement",
        "Payment Method",
      ];
    }
  };

  const handlePaging = (action: string | number) => {
    console.log("Paging Action :::", action);
    if (typeof action === "string") {
      /** when pagination button is string */
      switch (action) {
        case "first":
          setPagination({ ...pagination, offset: 0 });
          break;

        case "previous":
          setPagination({
            ...pagination,
            offset: pagination.offset - pagination.limit,
          });
          break;

        case "next":
          setPagination({
            ...pagination,
            offset: pagination.offset + pagination.limit,
          });
          break;

        case "last":
          setPagination({
            ...pagination,
            offset:
              (Math.floor(pagination.max / pagination.limit) - 1) *
                pagination.limit +
              pagination.limit,
          });
          break;
      }
    } else {
      /** when pagination button is number */
      console.log({ pagination });
      setPagination({
        ...pagination,
        offset: (action - 1) * pagination.limit,
      });
    }
  };

  const DesktopFilterSection = () => {
    return (
      <Fragment>
        {/* First Row */}
        <Stack flexDirection={"row"} width={"100%"}>
          <Stack flexGrow={1}>
            <InputSearch
              {...{
                paramQ,
                handleOnSearch,
                placeholder: t("order.searchPlaceholder"),
              }}
            />
          </Stack>
        </Stack>

        {/* Second Row */}
        <Stack
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Stack>
            <DateFilter
              selectedOrderDateFilter={paramDate}
              handleOrderDateFilterChange={handleOrderDateFilterChange}
              t={t}
            />
          </Stack>
          <Stack direction={"row"} gap={3}>
            <CompanyFilter
              externalBusinessList={externalBusinessList}
              selectedCompany={selectedCompany}
              handleOnChangeCompanyFilter={handleOnChangeCompanyFilter}
              t={t}
            />

            {paramReportType === orderReportTypes.PAYMENT_HISTORY && (
              <OrderTypeFilter
                selectedOrderTypeFilter={paramType}
                handleOrderTypeFilterChange={handleOrderTypeFilterChange}
                t={t}
              />
            )}

            {[
              orderReportTypes.TOTAL_PAYABLE,
              orderReportTypes.TOTAL_RECEIVABLE,
            ].includes(paramReportType as string) && (
              <OrderStatusFilter
                selectedOrderStatusFilter={paramStatus}
                handleOrderStatusFilterChange={handleOrderStatusFilterChange}
                t={t}
              />
            )}

            <CurrencyFilter
              selectedCurrencyFilter={paramCurrency}
              handleOnChangeCurrencyFilter={handleOnChangeCurrencyFilter}
              t={t}
            />

            {/** Sort By Date */}
            <DateSort
              sortByDate={paramSortByDate}
              handleOnChangeSortByDate={handleOnChangeSortByDate}
              t={t}
              isMobile={isMobile}
            />
          </Stack>
        </Stack>
      </Fragment>
    );
  };

  const MobileFilterSection = () => {
    return (
      <Fragment>
        <Stack rowGap={"16px"}>
          <Stack flexGrow={1}>
            <InputSearch {...{ paramQ, handleOnSearch, t }} />
          </Stack>

          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            gap={"8px"}
            rowGap={"16px"}
            sx={{
              [`& > *`]: { flexGrow: 1, flexBasis: "calc(50% - 4px)" },
            }}
          >
            <CompanyFilter
              externalBusinessList={externalBusinessList}
              selectedCompany={selectedCompany}
              handleOnChangeCompanyFilter={handleOnChangeCompanyFilter}
              t={t}
            />

            <CurrencyFilter
              selectedCurrencyFilter={paramCurrency}
              handleOnChangeCurrencyFilter={handleOnChangeCurrencyFilter}
              t={t}
            />

            <Stack flexBasis={"auto"}>
              <DateFilter
                selectedOrderDateFilter={paramDate}
                handleOrderDateFilterChange={handleOrderDateFilterChange}
                t={t}
              />
            </Stack>

            {paramReportType === orderReportTypes.PAYMENT_HISTORY ? (
              <Stack flexBasis={"auto"}>
                <OrderTypeFilter
                  selectedOrderTypeFilter={paramType}
                  handleOrderTypeFilterChange={handleOrderTypeFilterChange}
                  t={t}
                />
              </Stack>
            ) : (
              <Stack flexBasis={"auto"}>
                <OrderStatusFilter
                  selectedOrderStatusFilter={paramStatus}
                  handleOrderStatusFilterChange={handleOrderStatusFilterChange}
                  t={t}
                />
              </Stack>
            )}

            {/** Sort By Date */}
            <Stack flexGrow={0} flexBasis={"auto"}>
              <DateSort
                sortByDate={paramSortByDate}
                handleOnChangeSortByDate={handleOnChangeSortByDate}
                t={t}
                isMobile={isMobile}
              />
            </Stack>
          </Stack>
        </Stack>
      </Fragment>
    );
  };

  /* Render Page UI */
  return (
    <Fragment>
      <Tabs
        aria-label="transactions"
        sx={{
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
          backgroundColor: "transparent",
        }}
        onChange={(_event, newValue) => {
          console.log("Report Type >>>", newValue);
          handleChangeReportType(newValue);
        }}
        value={paramReportType}
      >
        <TabList
          sx={{
            overflowX: "scroll",
            [`&& .${tabClasses.root}`]: {
              "&:hover": {
                bgcolor: "transparent",
              },
              [`&.${tabClasses.selected}`]: {
                "&::after": {
                  bgcolor: "primary.500",
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  height: 2,
                },
                color: "primary.plainColor",
              },
              bgcolor: "transparent",
              flex: isMobile ? "1 0 auto" : "0 0 auto",
              paddingX: isMobile ? 1 : 2,
              paddingY: 2,
              whiteSpace: "nowrap",
            },
            gap: isMobile ? 1 : 4,
            justifyContent: "start",
          }}
        >
          <Tab
            value={"TOTAL_RECEIVABLE"}
            sx={{ fontWeight: "600" }}
            disabled={false}
          >
            {t("order.reportType.TOTAL_RECEIVABLE")}
          </Tab>
          <Tab
            value={"TOTAL_PAYABLE"}
            sx={{ fontWeight: "600" }}
            disabled={false}
          >
            {t("order.reportType.TOTAL_PAYABLE")}
          </Tab>
          <Tab
            value={"PAYMENT_HISTORY"}
            sx={{ fontWeight: "600" }}
            disabled={false}
          >
            {t("order.reportType.PAYMENT_HISTORY")}
          </Tab>
        </TabList>
      </Tabs>

      {/** Search Filters - Mobile  */}
      {isMobile && (
        <Sheet
          className="SearchAndFilters-mobile"
          sx={{
            display: { xs: "flex", lg: "none" },
            my: 2,
            gap: 3,
            flexDirection: "column",
          }}
        >
          <Stack flexWrap={"wrap"}>{MobileFilterSection()}</Stack>
        </Sheet>
      )}

      {/** Search Filters - Tablet and Above  */}
      {!isMobile && (
        <Stack
          className="SearchAndFilters-tabletUp"
          display={{ xs: "none", lg: "flex" }}
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          sx={{
            borderRadius: "sm",
            py: 2,
            gap: 2,
          }}
        >
          {DesktopFilterSection()}
        </Stack>
      )}

      {filteredOrderList && filteredOrderList?.length > 0 ? (
        <>
          {isMobile ? (
            <OrderReportCards
              orderList={filteredOrderList}
              reportType={paramReportType}
              handleDownloadPdf={handleDownloadPdf}
              DownloadPdfButton={DownloadPdfButton}
            />
          ) : (
            <OrderReportTable
              orderList={filteredOrderList}
              tableHeader={tableHeader()}
              reportType={paramReportType}
              handleDownloadPdf={handleDownloadPdf}
              DownloadPdfButton={DownloadPdfButton}
            />
          )}

          <Stack
            direction={"row"}
            width={"100%"}
            flexWrap={isMobile ? "wrap" : "nowrap"}
            justifyContent={isMobile ? "center" : "space-between"}
            alignContent={"center"}
            gap={3}
            my={"32px"}
          >
            <FormControl
              size="sm"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography>Show</Typography>
              <Select
                size="sm"
                placeholder={"Limit"}
                value={pagination.limit}
                onChange={(_, value) => {
                  // const currentPage = Math.ceil(pagination.currentLast / pagination.limit);
                  setPagination({
                    ...pagination,
                    limit: value,
                    // offset: (currentPage - 1) * value,
                  });
                }}
              >
                {[10, 20, 50, 100].map((limit, index) => (
                  <Option key={index} value={limit}>
                    {limit}
                  </Option>
                ))}
              </Select>
              <Typography>invoices per page</Typography>
            </FormControl>
            <Pagination
              sx={{
                display: "flex",
                [`& .Mui-selected`]: {
                  backgroundColor: "var(--joy-palette-primary-500)",
                  color: "white",
                },
              }}
              count={Math.ceil(pagination.max / pagination.limit)}
              page={Math.ceil(pagination.currentLast / pagination.limit)}
              // defaultPage={pagination.currentLast}
              onChange={(_, value) => {
                console.log("pagination value", value, pagination.currentLast);
                handlePaging(value);
              }}
              showFirstButton
              showLastButton
            />
          </Stack>
        </>
      ) : (
        <Box textAlign={"center"} mt={4}>
          <Typography level={"h3"} mb={2}>
            {t("order.table.noOrders")}
          </Typography>
          <Typography> {t("order.table.noOrdersPrompt")}</Typography>
        </Box>
      )}

      {/** Modals */}
      <LoadingModal isLoading={loading} />

      {/* Pdf Content for Order invoice download */}
      <div className="h-0 overflow-hidden w-[800px]">
        {/* <div className="w-[800px]"> */}
        {pdfContent && Object.keys(pdfContent).length > 0 && (
          // <div ref={targetRef}>
          <div id="order-details-pdf">
            <OrderDetailsPdf {...(pdfContent as OrderDetailsProps)} />
          </div>
        )}
      </div>
    </Fragment>
  );
}
