import { ModalDialog, Modal, Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { BtnFilledPrimary, BtnOutlinedPrimary } from "../stateless/buttons";
import { useTranslation } from "react-i18next";

export const OfflinePaymentModal = (props: {
  open: boolean;
  setOpenModal: any;
  paymentAttempts?: number;
  onFinish?: () => void;
  onChangePaymentMethod?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpenModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ModalDialog
        layout="center"
        maxWidth={"lg"}
        sx={{
          width: "100%",
          maxWidth: "600px",
          borderRadius: "md",
          p: 2,
          overflow: "scroll",
        }}
      >
        <Typography
          textColor={"primary.solidActiveBg"}
          textAlign={"center"}
          level="h4"
          fontWeight={600}
        >
          Offline Payment
        </Typography>
        <Typography
          mt={4}
          mb={2}
          textColor={"text.primary"}
          fontWeight={"bold"}
          fontSize={"lg"}
        >
          {t("offlinePaymentModal.label")}
        </Typography>
        <Typography textColor={"text.primary"} fontSize={"sm"}>
         {t("offlinePaymentModal.description")}
        </Typography>
        {
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <BtnFilledPrimary
              label="Finish Payment"
              color="primary"
              sx={{ width: "100%", borderRadius: "10px" }}
              onClick={() => {
                props.setOpenModal(false);
                props.onFinish?.();
              }}
            />
            <Typography fontSize={"sm"}>
              {t("paymentInfoModal.changePaymentAttemptsDesc")}.{" "}
              <Typography textColor={"gray"}>
                ({Math.max(3 - (props.paymentAttempts ?? 3), 0)} {t("paymentInfoModal.changePaymentAttemptsLeftDesc")})
              </Typography>
            </Typography>
            {Math.max(3 - (props.paymentAttempts ?? 3), 0) > 0 && (
              <BtnOutlinedPrimary
                label={t("paymentInfoModal.changePaymentMethodBtn")}
                color="primary"
                sx={{ width: "100%", borderRadius: "10px" }}
                onClick={() => {
                  props.setOpenModal(false);
                  props.onChangePaymentMethod?.();
                }}
              />
            )}
          </Box>
        }
      </ModalDialog>
    </Modal>
  );
};
