import { Button, List, ListItem, Stack, Typography } from "@mui/joy";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import DownloadTemplateButton from "./DownloadTemplateButton";
import { theme } from "../../Theme";
import { SnackBarInfo } from "../../snackbar";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import FailModal from "../../FailModal";

type Props = {
  onDrop: (acceptedFiles: any) => void;
};

const InventoryUploadDropzone = ({ onDrop }: Props) => {
  const { t } = useTranslation();
  const [showFailModal, setShowFailModal] = useState(false);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
  });

  useEffect(() => {
    setShowFailModal(fileRejections.length > 0);
  }, [fileRejections]);

  return (
    <Stack
      direction={"column"}
      sx={{
        borderWidth: "1px",
        borderColor: theme.palette.neutral[300],
        width: { lg: "479px" },
        minHeight: "320px",
        height: { lg: "550px" },
        borderRadius: "8px",
        gap: "20px",
        alignItems: "center",
        cursor: "pointer",
        py: { xs: "30px", lg: "72px" },
        px: "80px",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        transitionDuration: "300ms",
        "&:hover": {
          borderColor: theme.palette.primary[500],
        },
      }}
      {...getRootProps()}
    >
      <Typography
        level="text-lg-bold"
        sx={{ color: theme.palette.common.black }}
      >
        {t("catalog.inventory.empty.option")} 1
      </Typography>
      <img
        src="/bulk.png"
        width={100}
        height={100}
        className="object-contain"
      />
      <Stack
        direction={"column"}
        sx={{
          gap: "4px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography
          level="text-lg-semibold"
          sx={{ color: theme.palette.common.black }}
        >
          {t("catalog.inventory.empty.option1")}
        </Typography>
        <Typography level="text-sm-regular">
          {t("catalog.inventory.empty.option1Prompt")}
        </Typography>
      </Stack>
      <List
        sx={{
          width: "15.62rem",
          gap: "8px",
        }}
      >
        <ListItem sx={{ display: "flex", gap: "8px", padding: 0 }}>
          <Typography fontSize={14}>1.</Typography>
          <DownloadTemplateButton />
        </ListItem>
        <ListItem sx={{ display: "flex", gap: "8px", padding: 0 }}>
          <Typography fontSize={14}>2.</Typography>
          <Button
            id="inventory-import-button"
            {...getRootProps({ className: "dropzone" })}
            startDecorator={<PlusIcon width={20} height={20} />}
            fullWidth
            className="whitespace-nowrap"
          >
            {t("catalog.reuploadBtn")}
            <input {...getInputProps()} />
          </Button>
        </ListItem>
      </List>

      {/* modal */}
      <FailModal
        open={showFailModal}
        title="Failed Uploading File"
        content={
          <ul>
            {fileRejections.map(({ file, errors }) => (
              <li key={file.name}>
                {file.name}
                <ul>
                  {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        }
        onClose={() => {
          setShowFailModal(false);
        }}
      />
      <SnackBarInfo message={t("catalog.inventory.uploadRemarks")} />
    </Stack>
  );
};

export default InventoryUploadDropzone;
