import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { OrderService } from "../../../services/order.service";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../Error";
import { OrderStatusEnum } from "../../../types/order";

export const useMultiPaymentContainer = ({
  orderId,
  orderStatus,
  currency,
  grandTotal,
  multiPaymentsData,
  isReadOnly,
  isSeller,
}: {
  orderId: string;
  orderStatus: OrderStatusEnum;
  currency: string;
  grandTotal: number;
  multiPaymentsData?: any;
  isReadOnly?: boolean;
  isSeller?: boolean;
}) => {
  const paymentHistorySchema = z.object({
    time: z.number(),
    amount: z.number().gt(0, { message: "Amount can't be 0" }),
    percentage: z.number().gt(0, { message: "Percentage can't be 0%" }),
    status: z.enum(["PENDING", "SUCCESS", "FAILED"]), // assuming these are the possible statuses
  });

  const paymentSchema = z.object({
    paymentTimes: z.number().default(2),
    paymentTypes: z.string().default("AMOUNT"), // assuming these are the possible payment types
    paymentHistory: z.array(paymentHistorySchema),
  });

  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<z.infer<typeof paymentSchema>>({
    resolver: zodResolver(paymentSchema),
    mode: "onSubmit",
    defaultValues: {
      paymentTimes: 2,
      paymentTypes: "AMOUNT",
      paymentHistory: [
        { time: 1, amount: 0, percentage: 0, status: "PENDING" },
        { time: 2, amount: 0, percentage: 0, status: "PENDING" },
      ],
    },
  });

  const [onRequestFullPayment, setOnRequestFullPayment] = useState(false);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState("");
  const [onConfirmChangePaymentType, setOnConfirmChangePaymentType] =
    useState(false);

  const testRef = useRef(null);

  const handleChangePaymentPlan = () => {
    setOnConfirmChangePaymentType(false);
    setValue("paymentTypes", selectedPaymentPlan);
    const currentPaymentTimes = getValues("paymentTimes");
    setValue("paymentTimes", 0);
    setValue(
      "paymentHistory",
      Array.from({ length: currentPaymentTimes }, (_, index) => ({
        time: index + 1,
        amount: 0,
        percentage: 0,
        status: "PENDING",
      }))
    );
    setValue("paymentTimes", currentPaymentTimes);
    clearErrors("paymentHistory");
  };

  const handleCancelChangePaymentPlan = () => {
    setOnConfirmChangePaymentType(false);
    setSelectedPaymentPlan(getValues("paymentTypes"));
  };

  useEffect(() => {
    console.log("Test Ref in Hook ::", testRef.current);
    const typedPaymentsTotal =
      getValues("paymentTypes") === "AMOUNT"
        ? getValues("paymentHistory")?.reduce((acc: any, paymentHist: any) => {
            return acc + paymentHist.amount;
          }, 0)
        : getValues("paymentHistory")?.reduce((acc: any, paymentHist: any) => {
            return acc + paymentHist.percentage;
          }, 0);
    if (typedPaymentsTotal > 0) {
      getValues("paymentTypes") !== selectedPaymentPlan &&
        setOnConfirmChangePaymentType(true);
    } else {
      handleChangePaymentPlan();
    }
  }, [selectedPaymentPlan]);

  useEffect(() => {
    if (multiPaymentsData) {
      setValue("paymentHistory", multiPaymentsData.paymentHistory);
      setValue("paymentTimes", multiPaymentsData.paymentTimes);
      setValue("paymentTypes", multiPaymentsData.paymentTypes);
    }
  }, [multiPaymentsData]);

  const getNextPayment = () => {
    const paymentHistory = getValues("paymentHistory");
    const nextPayment = paymentHistory
      ?.sort((a, b) => a.time - b.time)
      ?.filter((payment) => payment.status === "PENDING")[0];
    return nextPayment;
  };

  const allowRequestFullPayment = () => {
    const paymentHistory = getValues("paymentHistory");
    const allowed = paymentHistory
      ?.map((payment) => payment.status)
      .includes("SUCCESS");
    return allowed;
  };

  const getPaidPaymentAmount = () => {
    const paymentHistory = getValues("paymentHistory");
    const paidAmount = paymentHistory?.reduce((total, payment: any) => {
      if (payment.status === "SUCCESS") {
        return total + payment.amount;
      }
      return total;
    }, 0);
    return paidAmount;
  };

  const cancelRequestFullPayment = () => {
    OrderService.cancelRequestFullPayment(orderId)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(showErrorToast(err), ErrorToastConfig);
      });
  };

  const isFullPaymentRequested = () => {
    return multiPaymentsData?.isFullPaymentRequested;
  };

  const getFullPaymentAmount = () => {
    console.log(
      "FULLAMOUNT",
      multiPaymentsData?.fullPaymentRequestData?.payableAmount
    );
    return multiPaymentsData?.fullPaymentRequestData?.payableAmount;
  };

  const getLatestPaidPayment = () => {
    const paymentHistory = getValues("paymentHistory");
    const lates = paymentHistory
      ?.filter((v) => v.status == "SUCCESS")
      ?.sort((a, b) => b.time - a.time);
    if (lates.length > 0) return lates[0];
    return null;
  };

  return {
    orderId,
    orderStatus,
    grandTotal,
    control,
    trigger,
    setValue,
    getValues,
    watch,
    errors,
    currency,
    isReadOnly,
    getNextPayment,
    selectedPaymentPlan,
    setSelectedPaymentPlan,
    onConfirmChangePaymentType,
    handleChangePaymentPlan,
    handleCancelChangePaymentPlan,
    allowRequestFullPayment,
    onRequestFullPayment,
    setOnRequestFullPayment,
    getPaidPaymentAmount,
    isSeller,
    cancelRequestFullPayment,
    isFullPaymentRequested,
    getFullPaymentAmount,
    getLatestPaidPayment,
    testRef
  };
};
