import { Card, Modal, Stack } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "react-i18next";
import { BtnFilledPrimarySm, BtnOutlinedPrimarySm } from "./stateless/buttons";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

interface IProps {
  title: string;
  content: string;
  open: boolean;
  btnConfirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmationModal(props: IProps) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    boxShadow: 24,
    px: "24px",
    py: "32px",
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
    maxWidth: 460,
    width: "80%",
  };

  const { t } = useTranslation();

  return (
    <Modal
      open={props.open}
      style={{ backgroundColor: "#32383E99", backdropFilter: "unset" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <ExclamationCircleIcon
            width={72}
            height={72}
            color="var(--joy-palette-primary-500)"
          />
          <Typography
            level="text-xl2-bold"
            id="modal-modal-title"
            mt={"8px"}
            textAlign={"center"}
          >
            {props.title}
          </Typography>
          <Typography
            level="text-md-regular"
            id="modal-modal-description"
            mt={"8px"}
            textAlign={"center"}
          >
            {props.content}
          </Typography>
          <br />
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={2}
            sx={{ width: "100%", ["& > *"]: { flexGrow: 1 } }}
          >
            <BtnOutlinedPrimarySm
              onClick={props.onCancel}
              label={t("commons.cancelBtn")}
            />
            <BtnFilledPrimarySm
              onClick={props.onConfirm}
              variant="solid"
              color="primary"
              id="button-confirm"
              label={
                !!props.btnConfirmText
                  ? props.btnConfirmText
                  : t("commons.removeBtn")
              }
            />
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}
