import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import TablePagination from "../../../Inventory/components/TablePagination";
import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/24/outline";
import { theme } from "../../../../components/Theme";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ItemType, PaginationType } from "../../../../types/item";
import { ItemService } from "../../../../services/item.service";
import { CatalogService } from "../../../../services/catalog.service";
import { If } from "../../../../components/Condition";
import { NumericFormat } from "react-number-format";
import { useSearchParams } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import debounce from "lodash.debounce";
import { InventoryItemDraftType } from "../../../../types/draft";

type Props = {
  catalog: any;
  inventoryItems: ItemType[];
  setInventoryItems: Dispatch<SetStateAction<ItemType[]>>;
};

type Item = {
  id: string;
  price?: number;
};

const PreviewTable = ({
  catalog,
  inventoryItems,
  setInventoryItems,
}: Props) => {
  const draftId = catalog.draftId;

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCategory = searchParams.get("category");
  const isInventoryExist = searchParams.get("isInventoryExist");
  const query = searchParams.get("query");

  const { control, setValue } = useFormContext();

  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 20,
    max: Math.ceil(catalog.items?.length / 20),
  });
  const [categories, setCategories] = useState<string[]>([]);
  const [cachedPriceData, setCachedPriceData] = useState<any[]>(
    JSON.parse(localStorage.getItem("updated-items-price") ?? "[]")
  );
  const [draftItems, setDraftItems] = useState<InventoryItemDraftType[]>([]);

  const watchedItems = useWatch({
    control,
    name: "items",
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "items",
  });

  // pagination indexes for slicing fields: if there's draftId,
  // startIndex always 0, if not depends on current page
  const startIndex = draftId ? 0 : (pagination.current - 1) * pagination.limit;
  const paginatedFields = fields.slice(
    startIndex,
    startIndex + pagination.limit
  );

  /**
   * fetch inventory items based on item ids in the catalog
   */
  const getAndSetInventoryItems = async () => {
    console.log("PAGINATED FIELDS", paginatedFields);
    const inventoryItemsResponse = await ItemService.getInventoryItemsByIds({
      ids: paginatedFields.map((field: any) => field.itemId),
    });

    setInventoryItems(inventoryItemsResponse);
  };

  /**
   * get draft items and set as inventory items
   */
  async function getAndSetDraftItems({
    draftId,
    limit,
    offset,
    inventoryType,
    isExistInventory,
    searchTerm,
  }: {
    draftId: string;
    limit: number;
    offset: number;
    inventoryType?: string;
    isExistInventory?: string;
    searchTerm?: string;
  }) {
    console.log("PAGINATED FIELDS", paginatedFields);
    try {
      const draftResponse = await CatalogService.getCatalogDraftItems({
        draftId,
        limit,
        offset,
        inventoryType,
        isExistInventory,
        searchTerm,
      });
      console.log("DRAFT RESPONE 3", draftResponse);

      const items = draftResponse.data.map((item) => item);
      console.log("MAPPED ITEMS", items);
      setValue(
        "items",
        items.map((item) => {
          // get price from cachedPriceData, if not exist get from inventoryItems
          const price =
            cachedPriceData.find((data) => data.id === item.id)?.price ??
            item.price;
          return { value: item.id, price: price };
        })
      );
      setInventoryItems(
        items.map((item) => {
          if (!!item.inventory?.sku)
            return {
              ...item,
              ...item.inventory,
              price: item.price,
              id: item.id,
            };
          else return item;
        })
      );
      setDraftItems(draftResponse.data);
      setPagination(draftResponse.pagination);

      // get inventoryTypes (category) of each items in draft
      if (categories.length === 0) {
        const draftInventoryTypeResponse =
          await CatalogService.getCatalogDraftInventoryTypes({ draftId });
        setCategories(draftInventoryTypeResponse);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * debounce input for 300ms before fetching
   */
  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") searchParams.delete("query");
      else searchParams.set("query", value);

      setSearchParams(searchParams);
    }, 300),
    []
  );

  /**
   * fetch every pagination change if there is no draftId
   * (to prevent this triggering when in excel method)
   */
  useEffect(() => {
    if (paginatedFields.length > 0 && !draftId) getAndSetInventoryItems();
  }, [pagination]);

  /**
   * trigger handleUpdatePrice every time an item field
   * is updated
   * @param watchedItems
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleUpdatePrice();
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [watchedItems]);

  /**
   * fetch and set draft items when filters change
   * @param selectedCategory
   * @param isInventoryExist,
   * @param query searchTerm
   */
  useEffect(() => {
    if (!draftId) return;
    const offset =
      pagination.current === 1 ? 0 : pagination.current * pagination.limit;

    getAndSetDraftItems({
      draftId,
      limit: pagination.limit,
      offset,
      inventoryType: selectedCategory ?? "",
      isExistInventory: isInventoryExist ?? "",
      searchTerm: query ?? "",
    });
  }, [selectedCategory, isInventoryExist, query]);

  /**
   * will take action, and will update @var current page in pagination state
   * @param action string (pagination actions)
   */
  const handlePaging = (action: string | number) => {
    let currentPage = pagination.current;

    if (typeof action === "string") {
      switch (action) {
        case "first":
          currentPage = 1;
          break;

        case "previous":
          currentPage = pagination.current - 1;
          break;

        case "next":
          currentPage = pagination.current + 1;
          break;

        case "last":
          currentPage = pagination.max;
          break;
      }
    } else {
      currentPage = action;
    }

    // fetch draft items if draftId exist
    if (draftId) {
      const offset =
        currentPage === 1 ? 0 : (currentPage - 1) * pagination.limit;

      getAndSetDraftItems({
        draftId,
        limit: pagination.limit,
        offset,
        inventoryType: selectedCategory ?? "",
        isExistInventory: isInventoryExist ?? "",
        searchTerm: query ?? "",
      });
    }

    setPagination({
      ...pagination,
      current: currentPage,
    });
  };

  /**
   * will get changed price, update state and localStorage
   */
  const handleUpdatePrice = () => {
    let updatedItems: ItemType[] = cachedPriceData;

    const changedItems = watchedItems
      .map((watchedItem: Item) => {
        const existingItem = inventoryItems.find(
          (inventoryItem) => inventoryItem.id === watchedItem.id
        );

        if (existingItem && existingItem.price !== watchedItem.price) {
          return {
            ...existingItem,
            price: watchedItem.price,
          };
        }

        return null;
      })
      .filter((item: any) => item !== null);

    if (updatedItems.length === 0) {
      updatedItems = changedItems;
    } else {
      changedItems.forEach((changedItem: ItemType) => {
        const index = updatedItems.findIndex(
          (updatedItem) => updatedItem.id === changedItem.id
        );

        if (index !== -1) {
          updatedItems[index] = changedItem;
        } else {
          updatedItems.push(changedItem);
        }
      });
    }

    setCachedPriceData(updatedItems);
    localStorage.setItem("updated-items-price", JSON.stringify(updatedItems));
  };

  return (
    <>
      {/* toolbar */}
      <If condition={catalog.importMethod === "excel"}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          sx={{
            width: "100%",
            gap: "16px",
          }}
        >
          {/* category filter */}
          <FormControl
            size="sm"
            sx={{
              width: { xs: "100%", lg: "33%" },
            }}
          >
            <FormLabel>
              <Typography level="text-sm-regular">
                {t("catalog.items.category")}
              </Typography>
            </FormLabel>
            <Select
              id="catalog-create-previewtable-category-filter"
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  const newParams = new URLSearchParams(searchParams);

                  if (newValue === "") newParams.delete("category");
                  else newParams.set("category", encodeURIComponent(newValue));

                  setSearchParams(newParams);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={""}
              sx={{
                paddingY: "10px",
                paddingX: "16px",
                fontWeight: 600,
                gap: "8px",
                borderRadius: "8px",
                color: theme.colorSchemes.light.palette.others.neutral_dark,
                borderColor:
                  theme.colorSchemes.light.palette.others.neutral_dark,
              }}
              indicator={<KeyboardArrowDown width={18} height={18} />}
            >
              <Option value={""}>{t("catalog.inventory.allFilterBtn")}</Option>
              {categories.map((cat) => (
                <Option key={cat} value={cat}>
                  {cat}
                </Option>
              ))}
            </Select>
          </FormControl>

          {/* is inventory exist filter */}
          <FormControl
            size="sm"
            sx={{
              width: { xs: "100%", lg: "33%" },
            }}
          >
            <FormLabel>
              <Typography level="text-sm-regular">
                {t("commons.pagination.filter")}
              </Typography>
            </FormLabel>
            <Select
              id="catalog-create-previewtable-inventory-filter"
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  const newParams = new URLSearchParams(searchParams);

                  if (newValue === "") newParams.delete("isInventoryExist");
                  else
                    newParams.set(
                      "isInventoryExist",
                      encodeURIComponent(newValue)
                    );

                  setSearchParams(newParams);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={""}
              sx={{
                paddingY: "10px",
                paddingX: "16px",
                fontWeight: 600,
                gap: "8px",
                borderRadius: "8px",
                color: theme.colorSchemes.light.palette.others.neutral_dark,
                borderColor:
                  theme.colorSchemes.light.palette.others.neutral_dark,
                minWidth: { xs: "100%", lg: "30%" },
              }}
              indicator={<KeyboardArrowDown width={18} height={18} />}
            >
              <Option value={""}>
                {t("catalog.createCatalog.import.filters.inventoryFilter.all")}
              </Option>
              <Option value={"false"}>
                {t("catalog.createCatalog.import.filters.inventoryFilter.new")}
              </Option>
              <Option value={"true"}>
                {t(
                  "catalog.createCatalog.import.filters.inventoryFilter.exist"
                )}
              </Option>
            </Select>
          </FormControl>

          {/* search bar */}
          <FormControl
            size="sm"
            sx={{
              width: { xs: "100%", lg: "33%" },
            }}
          >
            <FormLabel>
              <Typography level="text-sm-regular">
                {t("catalog.items.productName")}
              </Typography>
            </FormLabel>
            <Input
              id="catalog-create-previewtable-query-filter"
              defaultValue={query ?? ""}
              size="sm"
              name="searchFormData.search"
              placeholder={t("catalog.inventory.searchPlaceholder")}
              startDecorator={<MagnifyingGlassIcon width={24} height={24} />}
              fullWidth
              sx={{
                flexGrow: 1,
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value;
                debouncedSetSearchTerm(newValue);
              }}
            />
          </FormControl>
        </Stack>
      </If>

      <Table
        borderAxis="x"
        sx={{
          "& th": { textAlign: "center", py: "20px", px: "10px" },
          "& td": { textAlign: "center", py: "20px", px: "8px" },
          "& thead th:nth-child(1)": { width: "68px" },
          "& thead th:nth-child(2)": { width: "80px" },
          "& thead th:nth-child(3)": {
            width: { xs: "105px", md: "200px" },
          },
          "& thead th:nth-child(4)": {
            display: { xs: "none", md: "table-cell" },
            width: "200px",
          },
          "& thead th:nth-child(5)": {
            display: { xs: "none", md: "table-cell" },
            width: "200px",
          },
          "& thead th:nth-child(6)": {
            display: { xs: "none", md: "table-cell" },
            width: "200px",
          },
          "& thead th:nth-child(7)": {
            width: "80px",
            borderLeft: {
              xs: 0,
              md: `1px solid ${theme.palette.neutral[300]}`,
            },
          },
          "& tbody td:nth-child(4)": {
            display: { xs: "none", md: "table-cell" },
          },
          "& tbody td:nth-child(5)": {
            display: { xs: "none", md: "table-cell" },
          },
          "& tbody td:nth-child(6)": {
            display: { xs: "none", md: "table-cell" },
          },
          "& tbody td:nth-child(7)": {
            borderLeft: {
              xs: 0,
              md: `1px solid ${theme.palette.neutral[300]}`,
            },
          },
          minWidth: "100%",
          borderWidth: "1px",
          borderColor: theme.palette.neutral[300],
        }}
      >
        <thead>
          <tr key={"header"}>
            <th key={"index"}>{t("catalog.items.no")}</th>
            <th key={"picture"}>
              <Typography level="text-sm-bold">
                {t("catalog.items.picture")}
              </Typography>
            </th>
            <th key={"sku"}>
              <Typography level="text-sm-bold">
                {t("catalog.items.sku")}
              </Typography>
            </th>
            <th key={"productName"}>
              <Typography level="text-sm-bold">
                {t("catalog.items.productName")}
              </Typography>
            </th>
            <If condition={catalog.importMethod === "inventory"}>
              <th key={"currency"}>
                <Typography level="text-sm-bold">
                  {t("catalog.items.currency")}
                </Typography>
              </th>
            </If>
            <If condition={catalog.importMethod === "excel"}>
              <th key={"price"}>
                <Typography level="text-sm-bold">
                  {t("catalog.items.price")}
                </Typography>
              </th>
            </If>
            <If condition={catalog.importMethod === "excel"}>
              <th key={"uom"}>
                <Typography level="text-sm-bold">
                  {t("catalog.items.uom")}
                </Typography>
              </th>
            </If>
            <If condition={catalog.importMethod === "inventory"}>
              <th key={"inventoryType"}>
                <Typography level="text-sm-bold">
                  {t("catalog.items.category")}
                </Typography>
              </th>
            </If>
            <th key={"action"} style={{ width: "80px" }}>
              <Typography level="text-sm-bold">
                {t("commons.removeBtn")}
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedFields.map((field, index) => {
            // actual index of the item within catalog.items
            const offsetIndex = startIndex + index;

            const currency = inventoryItems[index]?.currency;

            return (
              <tr key={field.id}>
                <td className="text-center">{offsetIndex + 1}.</td>
                <td className="text-center">
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      py: "12px",
                    }}
                  >
                    {!!inventoryItems[index]?.thumbnail ? (
                      <img
                        src={inventoryItems[index]?.thumbnail as string}
                        crossOrigin="anonymous"
                        width={30}
                        height={30}
                        className="object-contain"
                      />
                    ) : (
                      <img
                        src="/inventory-img-placeholder.png"
                        width={30}
                        height={30}
                        className="object-contain"
                      />
                    )}
                  </Box>
                </td>
                <td className="text-center">
                  {inventoryItems[index]?.sku}{" "}
                  {/* only show chips for draft items */}
                  {draftItems.length > 0 &&
                    draftItems[index]?.inventory?.id === null && (
                      <Chip
                        sx={{
                          bgcolor: theme.palette.primary[500],
                          p: "4px",
                          minHeight: "16px",
                          maxHeight: "16px",
                          minWidth: "35px",
                          color: theme.palette.common.white,
                        }}
                      >
                        <Typography level="text-xs-medium" fontSize={12}>
                          New
                        </Typography>
                      </Chip>
                    )}
                </td>
                <td className="text-center">
                  {inventoryItems[index]?.productName}
                </td>
                <If condition={catalog.importMethod === "inventory"}>
                  <td className="text-center">{currency}</td>
                </If>
                <If condition={catalog.importMethod === "excel"}>
                  <td className="text-center">
                    <Controller
                      name={`items.${offsetIndex}.price`}
                      defaultValue={inventoryItems[index]?.price}
                      control={control}
                      render={({ field: { name, onChange, ref, value } }) => (
                        <FormControl className="w-[100%]" ref={ref}>
                          <NumericFormat
                            name={name}
                            customInput={Input}
                            startDecorator={<>{currency}</>}
                            placeholder={t("commons.price")}
                            value={value}
                            onValueChange={(v) => {
                              onChange(Number(v.value));
                            }}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currency === "IDR" ? "." : ","}
                            decimalSeparator={currency === "IDR" ? "," : "."}
                          />
                        </FormControl>
                      )}
                    />
                  </td>
                </If>
                <If condition={catalog.importMethod === "excel"}>
                  <td className="text-center">{inventoryItems[index]?.uom1}</td>
                </If>
                <If condition={catalog.importMethod === "inventory"}>
                  <td className="text-center">
                    {inventoryItems[index]?.inventoryType}
                  </td>
                </If>
                <td className="text-center break-all border-l">
                  <IconButton>
                    <TrashIcon
                      onClick={() => {
                        remove(offsetIndex);
                      }}
                      width={24}
                      height={24}
                      color={theme.palette.others.cart_danger_button}
                    />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <TablePagination
        justifyContent={
          catalog.importMethod === "excel" ? "space-between" : "flex-end"
        }
        handlePaging={handlePaging}
        pagination={pagination}
        setPagination={setPagination}
        withLimit={catalog.importMethod === "excel"}
      />
    </>
  );
};

export default PreviewTable;
