import { HeadObjectCommand } from "@aws-sdk/client-s3";
import FormData from "form-data";
import { privateAxios } from "../lib/api";
import { s3Client } from "../lib/spaces";
import { resizeFile } from "../utils/file";
export class UploadService {
  static async checkIfImageExists(imageName: string, folder?: string) {
    const key = `${folder ? folder + "/" : ""}${imageName}`;
    const command = new HeadObjectCommand({
      Bucket: import.meta.env.VITE_SPACES_BUCKET_NAME,
      Key: key,
    });

    try {
      await s3Client.send(command);
      const doSpaceHost =
        import.meta.env.VITE_SPACES_HOST ||
        "https://linkz-staging-bucket.sgp1.digitaloceanspaces.com";
      return `${doSpaceHost}/${key}`;
    } catch (error: any) {
      if (error.name === "NotFound") {
        console.log(`File does not exist: ${key}`);
        return false;
      }
      console.error(`Error checking file existence: ${error}`);
      throw error;
    }
  }

  static async urlToFile(url: string, fileName: string) {
    const urlWithTimestamp = `${url}${
      url.includes("?") ? "&" : "?"
    }_t=${Date.now()}`;
    const response = await fetch(urlWithTimestamp);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  }

  static async upload(
    file: File,
    name: string,
    folder?: string,
    compresed = true
  ) {
    const compresedFile = compresed ? await resizeFile(file) : file;

    if (compresedFile) {
      // Create a form data instance
      const formData = new FormData();
      // Append the file to the form data
      formData.append("file", file);

      // If folder is provided, append it to the form data
      formData.append("folder", folder ? folder : "");
      formData.append("name", name);

      return privateAxios.post(
        `${import.meta.env.VITE_API_URL}/objects/upload`,
        formData
        // {
        //   headers: {
        //     ...formData.,
        //   },
        // }
      );
    } else {
      throw new Error("Failed to compress file");
    }
  }
}
