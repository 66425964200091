import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { ItemService } from "../../services/item.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CreateItemType, ItemType } from "../../types/item";
import useContainer from "./useContainer";
import NotFound from "../NotFound/NotFound";
import TablePagination from "./components/TablePagination";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { validateDuplicateSku } from "../../utils/excel";
import { theme } from "../../components/Theme";
import UploadImageBtn from "../../components/Inventory/Items/UploadImageBtn";
import { IfElse } from "../../components/Condition";
import PageTitleBox from "../../components/PageTitleBox";

export default function AddCatalogueItems() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");
  const isCreate = searchParams.get("isCreate");

  const [flexiColumns, setFlexiColumns] = useState<string[]>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const cachedCatalogData = JSON.parse(
    localStorage.getItem(isCreate ? "new-catalog" : "edit-catalog") ?? "{}"
  );
  const [selectedItems, setSelectedItems] = useState<string[]>(
    cachedCatalogData.items.map((item: any) => item.itemId)
  );
  console.log("SELECTED ITEMS", cachedCatalogData);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [_showDuplicateModal, setShowDuplicateModal] = useState<{
    show: boolean;
    useDatabaseSku: boolean;
    onClick: (data: ItemType[]) => void;
  }>({ show: false, useDatabaseSku: true, onClick: () => {} });
  const [_payload, setPayload] = useState<string[]>([]);
  const [_selectedQuantityItem, setSelectedQuantityItem] = useState<
    { show: boolean; item: ItemType } | undefined
  >();
  const [_databaseSku, setDatabaseSku] = useState<string[]>([]);
  const [_duplicateSKU, setDuplicateSKU] = useState<{
    processedData: CreateItemType[];
    duplicates: string[];
  }>();
  const {
    handlePaging,
    handleUpdateSku,
    handleItemFlexiCols,
    fetchInventoryItems,
    setIsLoading,
    setShowAlert,
    setAlertMessage,
    pagination,
    setPagination,
    inventoryItems,
    setInventoryItems,
    searchTerm,
    setSearchTerm,
    setShowToast,
  } = useContainer();

  const { session } = useAuthStore.getState();

  const initiateInventoryItems = useCallback((businessId: string) => {
    ItemService.getInventoryItems({
      businessId: businessId,
      paging: true,
      limit: 50,
      offset: 0,
      sortBy: "updatedAt",
    })
      .then((itemRes) => {
        setShowAlert(false);
        setInventoryItems(itemRes.data);
        if (itemRes.pagination) {
          setPagination(itemRes.pagination);
        }
        return ItemService.getInventoryItemField({
          fields: ["flexiColumns", "inventoryType"],
          businessId,
        });
      })
      .then((filterRes) => {
        const { allFlexiColumns } = handleItemFlexiCols(filterRes);
        setFlexiColumns(allFlexiColumns);

        const cats: string[] = [];
        for (const element of filterRes) {
          cats.push(element.inventoryType);
        }

        const uniqueValues = cats.filter(
          (item, index) => cats.indexOf(item) === index
        );
        setCategories(uniqueValues);

        return ItemService.getInventoryItemField({
          fields: ["id", "sku", "productName"],
          businessId,
          isDistinct: false,
        });
      })
      .then((skuRes) => {
        const allSku = skuRes.map((item: ItemType) => item.sku);
        setDatabaseSku(allSku);
        const validatedDuplicates = validateDuplicateSku(
          skuRes as CreateItemType[]
        );

        if (validatedDuplicates.length > 0 && verifySKUReminder()) {
          setDuplicateSKU({
            processedData: skuRes as CreateItemType[],
            duplicates: validatedDuplicates,
          });
          setShowDuplicateModal({
            show: true,
            useDatabaseSku: false,
            onClick: (data: ItemType[]) => {
              setIsLoading(true);
              handleUpdateSku(businessId, data);
            },
          });
          localStorage.setItem(
            "duplicateSKUReminder",
            new Date().getTime().toString()
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      initiateInventoryItems(businessId);
      if (query && !searchTerm) {
        fetchInventoryItems({
          businessId,
          paging: true,
          limit: pagination.limit,
          offset: 0,
          sortBy: "updatedAt",
          sortDirection: sortDirection,
          searchTerm: query,
          inventoryType: selectedFilter,
        });
        const newPagination = pagination;
        newPagination.limit = 10;
        setPagination(newPagination);
        handlePaging("first");
      }
    }
  }, [getActiveBusiness()?.id]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      if (query && !searchTerm) {
        return;
      }
      fetchInventoryItems({
        businessId,
        paging: true,
        limit: pagination.limit,
        offset: 0,
        sortBy: "updatedAt",
        sortDirection: sortDirection,
        searchTerm: searchTerm,
        inventoryType: selectedFilter,
      });
      const newPagination = pagination;
      newPagination.limit = 10;
      setPagination(newPagination);
      handlePaging("first");
    }
  }, [searchTerm, selectedFilter, sortDirection]);

  useEffect(() => {
    const category = searchParams.get("category");
    const _sortDirection = searchParams.get("sortDirection");
    if (category) {
      setSelectedFilter(category);
    }
    if (_sortDirection) {
      setSortDirection(_sortDirection as any);
    }
    if (query) {
      setSearchTerm(query);
    }
    return () => {};
  }, []);

  const verifySKUReminder = () => {
    const reminder = localStorage.getItem("duplicateSKUReminder");

    if (reminder) {
      const currentTime = new Date().getTime();
      const hoursDifference = (currentTime - +reminder) / (1000 * 60 * 60);

      return hoursDifference > 24;
    }

    return true;
  };

  const handleSelectAll = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      ItemService.getInventoryItemField({
        fields: ["id"],
        businessId,
        inventoryType: selectedFilter,
        searchTerm,
      })
        .then((res) => {
          const ids: string[] = res.map((i: { id: string }) => i.id);
          setSelectedItems(ids);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, inventoryItems[index].id]);
    } else {
      setSelectedItems(
        selectedItems.filter((i) => i !== inventoryItems[index].id)
      );
    }
  };

  // const handledSearch = () => {
  //   const businessId = getActiveBusiness()?.id;

  //   if (businessId) {
  //     if (query && !searchTerm) {
  //       return;
  //     }
  //     fetchInventoryItems({
  //       businessId,
  //       paging: true,
  //       limit: pagination.limit,
  //       offset: 0,
  //       sortBy: "updatedAt",
  //       sortDirection: sortDirection,
  //       searchTerm: searchTerm,
  //       inventoryType: selectedFilter,
  //     });
  //   }
  // };

  const handleAddItems = () => {
    const items: string[] = inventoryItems
      .filter((item) => selectedItems.includes(item.id))
      .map((item) => {
        return item.id;
      });

    setPayload(items);

    const newCache = {
      ...cachedCatalogData,
      items,
    };
    const catalogId = cachedCatalogData.id;
    localStorage.setItem(
      isCreate ? "new-catalog" : "edit-catalog",
      JSON.stringify(newCache)
    );
    navigate(
      isCreate == "true"
        ? `/client/catalogues/create?type=${cachedCatalogData.catalogType}`
        : `/client/catalogues/${catalogId}/edit`
    );
  };

  if (!session) {
    return <NotFound />;
  }

  const { tableData } = handleItemFlexiCols(inventoryItems, flexiColumns);
  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        overflow: "hidden",
        py: "32px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <PageTitleBox
        title={t("catalog.inventory.createCatalog.addItemsTitle")}
        subtitle={t("catalog.inventory.createCatalog.addItemsSubtitle")}
      />

      <Stack
        direction={"column"}
        sx={{
          borderWidth: { xs: 0, md: "1px" },
          borderRadius: "8px",
          p: { xs: "16px", md: "24px" },
          gap: { xs: "16px", md: "24px" },
        }}
      >
        {/* toolbar */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: "10px", md: "10px" }}
          justifyContent={"space-between"}
          width={{ xs: "90vw", md: "100%" }}
          margin="10px 0px"
        >
          <FormControl sx={{ width: { xs: "90%", lg: "50%" } }}>
            <FormLabel>{t("catalog.items.inventoryType")}</FormLabel>
            <Select
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  const newParams = new URLSearchParams(searchParams);

                  if (newValue === "") {
                    newParams.delete("category", encodeURIComponent(newValue));
                  } else {
                    newParams.set("category", encodeURIComponent(newValue));
                  }

                  // setSearchParams(newParams);
                  setSelectedFilter(newValue);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={""}
              sx={{
                // minWidth: "100%",
                paddingY: "10px",
                paddingX: "16px",
                fontWeight: 600,
                gap: "8px",
                color: theme.colorSchemes.light.palette.others.neutral_dark,
                borderColor:
                  theme.colorSchemes.light.palette.others.neutral_dark,
              }}
              indicator={<ChevronDownIcon width={18} height={18} />}
            >
              <Option value={""}>{t("catalog.inventory.allFilterBtn")}</Option>
              {categories.map((cat) => (
                <Option key={cat} value={cat}>
                  {cat}
                </Option>
              ))}
            </Select>
          </FormControl>

          {/* searchbar */}
          <FormControl sx={{ width: { xs: "90%", lg: "50%" } }}>
            <FormLabel>{t("catalog.items.productName")}</FormLabel>
            <Input
              defaultValue={searchTerm}
              // variant="linkz-input-search"
              size="sm"
              name="searchFormData.search"
              placeholder={t("catalog.inventory.searchPlaceholder")}
              fullWidth
              sx={{
                width: "100%",
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newParams = new URLSearchParams(searchParams);
                const query = event.target.value;
                if (query) {
                  newParams.set("q", query);
                } else {
                  newParams.delete("q");
                }
                // setSearchParams(newParams);
                setSearchTerm(event.target.value);
              }}
            />
          </FormControl>
        </Stack>

        {/* items table */}
        <Box
          sx={{
            width: { xs: "90vw", lg: "100%" },
            height: "30%",
            maxHeight: { lg: "27.5rem" },
            overflow: "auto",
            borderWidth: "2px",
            position: "relative",
            margin: "10px 0px",
          }}
        >
          <Table
            borderAxis="x"
            sx={{ "& th": { textAlign: "center" }, minWidth: "100%" }}
          >
            <thead>
              <tr key={"header"}>
                <th key={"checkbox"} className="w-12">
                  <Checkbox
                    size="sm"
                    onChange={(event) => {
                      if (event.target.checked) {
                        handleSelectAll();
                      } else {
                        setSelectedItems([]);
                      }
                    }}
                  />
                </th>
                {/* <th key={"index"} className="w-12 ">
                  {t("catalog.items.no")}
                </th> */}
                <th key={"picture"} className="w-24">
                  {t("catalog.items.picture")}
                </th>
                <th key={"sku"} className="w-36">
                  {t("catalog.items.sku")}
                </th>
                <th key={"productName"} className="w-48">
                  {t("catalog.items.productName")}
                </th>
                <th key={"quantity"} className="w-24">
                  {t("catalog.items.quantity")}
                </th>
                <th key={"currency"} className="w-24">
                  {t("catalog.items.currency")}
                </th>
                <th key={"price"} className="w-36">
                  {t("catalog.items.price")}
                </th>
                <th key={"unit_of_measure"} className="w-24">
                  {t("catalog.items.uom")}
                </th>
                <th key={"inventoryType"} className="w-36">
                  {t("catalog.items.category")}
                </th>
                {flexiColumns?.map((col) => (
                  <th key={col} className="w-28">
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((d, index) => {
                const isSelected = selectedItems.includes(
                  inventoryItems[index].id
                );
                return (
                  <tr
                    key={inventoryItems[index].id}
                    className={`${isSelected ? "bg-primary-50 " : ""}`}
                  >
                    <td className="text-center">
                      <Checkbox
                        size="sm"
                        checked={isSelected}
                        onChange={(e) => handleChecked(e, index)}
                      />
                    </td>
                    <td className="text-center">
                      <UploadImageBtn
                        item={inventoryItems[index]}
                        setIsLoading={setIsLoading}
                        setShowToast={setShowToast}
                        setAlertMessage={setAlertMessage}
                      />
                    </td>
                    {d.map((i, idx) => (
                      <td
                        key={
                          inventoryItems[index].id +
                          i.toString() +
                          "_col" +
                          index +
                          Math.random() * 695622
                        }
                        className="text-center break-all"
                      >
                        <IfElse
                          condition={idx === 2}
                          ifBlock={
                            <IfElse
                              condition={
                                Number(i) <=
                                (inventoryItems[index].lowStockQuantity || 0)
                              }
                              ifBlock={
                                <button
                                  onClick={() => {
                                    setSelectedQuantityItem({
                                      show: true,
                                      item: inventoryItems[index],
                                    });
                                  }}
                                  className="bg-[#F7D5AC] w-fit h-fit mx-auto rounded-[4px] p-1"
                                >
                                  {i}
                                </button>
                              }
                              elseBlock={<>{i}</>}
                            />
                          }
                          elseBlock={<>{i}</>}
                        />
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>

        {/* pagination */}
        <TablePagination
          handlePaging={handlePaging}
          pagination={pagination}
          setPagination={setPagination}
          withLimit={true}
        />
      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            width: "100%",
            paddingY: "1.25rem",
            paddingX: "1.25rem",
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            transition: "all 0.1s ease-in-out",
            overflow: "hidden",
          }}
        >
          <Typography level="text-sm-bold">
            {selectedItems.length}{" "}
            <span className="font-medium">
              {t("catalog.inventory.batchEdit.itemsSelected")}
            </span>
          </Typography>
        </Box>

        {/* buttons */}
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          sx={{
            justifyContent: { xs: "center", md: "right" },
            gap: { xs: "16px", md: "12px" },
            width: "100%",
          }}
        >
          <Button
            type="button"
            variant={"linkz-outlined-primary"}
            className="ml-auto rounded-md"
            sx={{
              minWidth: { xs: "100%", md: "80px" },
            }}
            onClick={() => navigate(-1)}
          >
            {t("commons.cancelBtn")}
          </Button>
          <Button
            disabled={!(selectedItems.length > 0)}
            className="min-w-[9.2rem] whitespace-nowrap rounded-md"
            onClick={handleAddItems}
          >
            {t("catalog.inventory.createCatalog.addItemsBtn")}
          </Button>
        </Stack>
      </Stack>
    </Sheet>
  );
}
