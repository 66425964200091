import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import {
  ArrowBack,
  CheckCircle,
  KeyboardArrowDown,
  ViewSidebarOutlined,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Link,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useQueryClient } from "@tanstack/react-query";
import "flag-icons/css/flag-icons.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LogoImg from "../assets/linkz-logo-small.png";
import { BusinessSessionHelper } from "../helpers/business-session.helper";
import { BusinessService } from "../services/business.service";
import { animateSelectStyle } from "../styles/select";
import { IBusinessResponse } from "../types/business";
import FlagById from "./FlagById";
import HamburgerIcon from "./Icons/Hamburger";
import LanguageSelector from "./LanguageSelector";
import { theme } from "./Theme";
import { openSidebar } from "./utils";
interface Props {
  paths: {
    name: string;
    path: string;
  }[];
}

const CompanySwitcher = ({
  activeBusiness,
  setActiveBusiness,
  client,
  availableBusinesses,
  t,
}: any) => {
  return (
    <Select
      className={`border border-[#98A2B3] flex-1`}
      key={activeBusiness?.id}
      value={activeBusiness}
      onChange={async (_, value) => {
        if (!value) return;
        await BusinessSessionHelper.SetActiveBusiness(
          value as IBusinessResponse
        );
        setActiveBusiness(value as IBusinessResponse);
        client.invalidateQueries({
          queryKey: ["business-credits", "kyc-payment-gateway-status"],
        });
        window.location.reload();
      }}
      renderValue={(option) => (
        <Typography
          level="text-sm-medium"
          fontWeight={600}
          mr={"auto"}
          startDecorator={
            <span
              className={`fi fi-${activeBusiness?.companyCountry.toLocaleLowerCase()} shadow-md`}
            ></span>
          }
        >
          {option?.label}
        </Typography>
      )}
      variant="plain"
      size="sm"
      indicator={<KeyboardArrowDown />}
      sx={{ ...animateSelectStyle, backgroundColor: "white" }}
    >
      {(availableBusinesses as any[])
        ?.sort(
          (a, b) =>
            (a.id === activeBusiness?.id ? 0 : 1) -
            (b.id === activeBusiness?.id ? 0 : 1)
        )
        .map((business) => (
          <Option
            key={business.id}
            value={business}
            label={business.companyName}
          >
            <Stack
              sx={{ width: "100%" }}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={5}
            >
              <div>
                <Typography
                  sx={{ flex: 1 }}
                  startDecorator={<FlagById id={business.companyCountry} />}
                >
                  {business.companyName}
                </Typography>
                {business.id === activeBusiness?.id && (
                  <Typography fontSize={12} textColor="neutral.500">
                    {t("profile.business.currentLoggedInCompanyLabel")}
                  </Typography>
                )}
              </div>
              {business.id === activeBusiness?.id && (
                <CheckCircle color="primary" />
              )}
            </Stack>
          </Option>
        ))}
    </Select>
  );
};

const BreadcrumbHeader = ({ paths }: Props) => {
  const { t } = useTranslation();
  const [availableBusinesses, setAvailableBusinesses] = useState<
    IBusinessResponse[]
  >([]);
  const client = useQueryClient();
  const [activeBusiness, setActiveBusiness] = useState<IBusinessResponse>();
  const getAvailableBusinesses = () => {
    BusinessService.getBusiness({ paging: false }).then((data) => {
      setAvailableBusinesses(data);
      const activeBusiness = data.find(
        (b) => b.id === BusinessSessionHelper.GetBusinessId()
      );
      if (activeBusiness) setActiveBusiness(activeBusiness);
    });
  };
  useEffect(() => {
    getAvailableBusinesses();
  }, []);
  const { pathname } = useLocation();
  const isDashboardRoute = ["/client", "/client/"].includes(pathname);
  const navigate = useNavigate();
  // const lastBreadcumb = paths[paths.length - 1];
  // console.log("Paths ::", { paths }, isDashboardRoute);
  return (
    <Stack direction={"column"} gap={{ xs: "4px", lg: "32px" }}>
      <Stack
        direction={"row"}
        // justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "100%",
        }}
      >
        <Typography sx={{ display: { xs: "none", lg: "block" } }}>
          <ViewSidebarOutlined />
        </Typography>

        {/* <div className="min-[900px]:flex items-center hidden"> */}
        <Stack
          sx={{ display: { xs: "none", lg: "flex" } }}
          direction={"row"}
          alignItems={"center"}
        >
          <Breadcrumbs
            aria-label="breadcrumbs"
            sx={{
              marginLeft: "16px",
            }}
          >
            {paths.map((p, index) => {
              if (index === paths.length - 1) {
                return (
                  <Typography
                    key={p.name}
                    sx={{
                      marginLeft: "16px",
                    }}
                    className="text-[#499873]"
                  >
                    {t(`breadcrumb.${p.name}`)}
                  </Typography>
                );
              }

              return (
                <Link
                  key={p.name}
                  color="neutral"
                  href={p.path}
                  sx={{
                    marginX: "16px",
                  }}
                >
                  {t(`breadcrumb.${p.name}`)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Stack>
        {!isDashboardRoute ? (
          <Typography
            level="text-md-medium"
            sx={{
              textTransform: "capitalize",
              display: { xs: "block", lg: "none" },
            }}
          >
            <Button
              variant="plain-gray"
              sx={{
                fontWeight: 700,
                color: theme.palette.common.black,
                fontSize: 16,
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBack className="mr-2" />
              {t(`breadcrumb.${paths[paths.length - 1]?.name}`)}
            </Button>
          </Typography>
        ) : (
          <Stack sx={{ display: { xs: "flex", lg: "none" } }}>
            <img src={LogoImg} alt="linkz-logo" width={68} height={"auto"} />
          </Stack>
        )}

        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
            ml: "auto",
          }}
          // className="flex items-center justify-between gap-4 ml-auto"
        >
          {/* Desktop View - Company Switcher */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"8px"}
            sx={{ display: { xs: "none", lg: "flex" } }}
          >
            <CompanySwitcher
              {...{
                activeBusiness,
                setActiveBusiness,
                client,
                availableBusinesses,
                t,
              }}
            />
            <ShieldCheckIcon className="w-6 h-6 text-[#499873]" />
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flex={1}
            gap={"4px"}
          >
            {/* <Typography className="xl:hidden">
              {t(`breadcrumb.${lastBreadcumb?.name}`)}
            </Typography> */}
            <LanguageSelector />
            <Box
              sx={{ display: { xs: "block", lg: "none" }, cursor: "pointer" }}
              onClick={() => openSidebar()}
            >
              <HamburgerIcon />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      {/* Mobile View - Company Switcher */}
      {isDashboardRoute && (
        <Stack
          direction={"row"}
          gap={"8px"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", lg: "none" } }}
        >
          <CompanySwitcher
            {...{
              isDashboardRoute,
              activeBusiness,
              setActiveBusiness,
              client,
              availableBusinesses,
              t,
            }}
          />
          <ShieldCheckIcon className={`w-6 h-6 text-[#499873]`} />
        </Stack>
      )}

      {/* {!(
        window.location.href.endsWith("/profile") ||
        window.location.href.endsWith("/profile/kyc")
      ) &&
        !BusinessSessionHelper.IsValidatedKYCPaymentGateway() && ( */}
      {/* <KycSnackBar /> */}
      {/* )} */}

      <Divider />
      {/* <hr
        className={`w-full border-b border-solid border-[${theme.colorSchemes.light.palette.others.disabled}]`}
      /> */}
    </Stack>
  );
};

export default BreadcrumbHeader;
