import {
  IconButton,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from "@mui/joy";
import {
  OrderStatusEnum,
  orderStatuses as defaultOrderStatuses,
  PaymentFlowTypeEnum,
} from "../../types/order";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useFormContext } from "react-hook-form";
import { useMediaQuery } from "@mui/material";
import {
  CloseFullscreenRounded,
  OpenInFullRounded,
  ViewSidebarOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import dayjs from "dayjs";

export default function OrderStatusStepper({
  currentOrderStatus,
  singlePaymentInfo,
  multiPaymentContainer,
  orderStatusTimestamps,
  isRightSidebarHidden,
  setIsRightSidebarHidden,
}: any) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  const [isOpenedInFull, setIsOpenedInFull] = useState(false);

  const {
    getValues: getMPCValues,
    getNextPayment,
    isFullPaymentRequested,
    getLatestPaidPayment,
  } = multiPaymentContainer;

  const { getValues: getFormValues } = useFormContext();

  const { t } = useTranslation();

  const nextPayment = getNextPayment();

  const afterAwaitingResponseStatuses = [
    OrderStatusEnum.CONFIRMED,
    OrderStatusEnum.PROCESSING,
    OrderStatusEnum.PENDING_FINAL_PAYMENT,
    OrderStatusEnum.COMPLETE,
    OrderStatusEnum.CANCELLED,
  ];

  console.log(
    "multiPaymentContainer from OrderStatusStepper ??",
    "current Order Status",
    {
      currentOrderStatus,
      orderStatusTimestamps,
      check: afterAwaitingResponseStatuses.includes(currentOrderStatus),
    },
    getMPCValues(),
    getNextPayment()
  );

  const isPendingPaymentStatusHidden = (status: any) => {
    return (
      status.value === OrderStatusEnum.PENDING_PAYMENT &&
      (!getFormValues("downPayment") ||
        getFormValues("downPayment") >= getFormValues("grandTotalAmount"))
    );
  };

  const isPendingFinalPaymentStatusHidden = (status: any) => {
    return (
      status.value === OrderStatusEnum.PENDING_FINAL_PAYMENT &&
      getFormValues("paymentFlowType") ===
        PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
    );
  };

  const isAwaitingResponseStatusHidden = (status: any) => {
    return (
      status.value === OrderStatusEnum.AWAITING_RESPONSE &&
      /**
       * If currentOrderStatus is already one of the statuses after awaiting response,
       * then hide this status
       *  */
      afterAwaitingResponseStatuses.includes(currentOrderStatus)
    );
  };

  const isConfirmedStatusHidden = (status: any) => {
    return (
      status.value === OrderStatusEnum.CONFIRMED &&
      /**
       * If confirmedAt timestamp is not available yet, or
       * if currentOrderStatus is not yet one of the statuses after awaiting response,
       * then hide this status
       *  */
      !afterAwaitingResponseStatuses.includes(currentOrderStatus)
    );
  };

  const isCancelledStatusHidden = (status: any) => {
    return (
      status.value === OrderStatusEnum.CANCELLED &&
      currentOrderStatus !== OrderStatusEnum.CANCELLED
    );
  };

  const isProcessStatusHidden = (status: any) => {
    return (
      status.value == OrderStatusEnum.PROCESSING &&
      [
        PaymentFlowTypeEnum.RECURRING_PAYMENT,
        PaymentFlowTypeEnum.MULTIPLE_PAYMENTS,
      ].includes(getFormValues("paymentFlowType")) &&
      getFormValues("paymentFlowType") !==
        PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
    );
  };

  const isCompleteStatusHidden = (status: any) => {
    return (
      status.value === OrderStatusEnum.COMPLETE &&
      currentOrderStatus === OrderStatusEnum.CANCELLED
    );
  };

  let orderStatusesToLoop = defaultOrderStatuses;

  if (
    getFormValues("paymentFlowType") === PaymentFlowTypeEnum.MULTIPLE_PAYMENTS
  ) {
    let mpcValues = getMPCValues("paymentHistory");
    if (isFullPaymentRequested()) {
      const latestPaidPayment = getLatestPaidPayment();
      mpcValues = mpcValues?.filter(
        (v: any) => v.time <= latestPaidPayment?.time + 1
      );
    }
    const paymentStatuses = mpcValues?.map((payment: any) => ({
      value: "PENDING_FINAL_PAYMENT_" + payment?.time,
      label: `${t("commons.payment")} ` + payment?.time,
      description: "",
      backendStatuses: [OrderStatusEnum.PENDING_FINAL_PAYMENT],
    }));
    const mainStatuses = defaultOrderStatuses.filter(
      (status) =>
        !["PENDING_FINAL_PAYMENT", "COMPLETE", "CANCELLED"].includes(
          status.value
        )
    );
    const finalStatuses = defaultOrderStatuses.filter((status) =>
      ["COMPLETE", "CANCELLED"].includes(status.value)
    );
    orderStatusesToLoop = [
      ...mainStatuses,
      ...(paymentStatuses?.length ? paymentStatuses : []),
      ...finalStatuses,
    ];
  }

  console.log("orderStatusesToLoop", orderStatusesToLoop);

  // get the index number of the currentOrderStatus (Backend status)
  const currentOrderStatusIndex = orderStatusesToLoop.findIndex((s) => {
    if (
      currentOrderStatus === OrderStatusEnum.PENDING_FINAL_PAYMENT &&
      getFormValues("paymentFlowType") === PaymentFlowTypeEnum.MULTIPLE_PAYMENTS
    ) {
      if (!isFullPaymentRequested()) {
        return s.value === "PENDING_FINAL_PAYMENT_" + nextPayment?.time;
      }
      const latestPaidPayment = getLatestPaidPayment();
      if (latestPaidPayment) {
        return (
          s.value === "PENDING_FINAL_PAYMENT_" + (latestPaidPayment?.time + 1)
        );
      }
    } else {
      return s.backendStatuses.includes(currentOrderStatus);
    }
  });

  const getStepVariant = (index: number) => {
    if (index < currentOrderStatusIndex) {
      return "linkz-step-finished";
    } else if (index === currentOrderStatusIndex) {
      return "linkz-step-ongoing";
    } else {
      return "linkz-step-unfinished";
    }
  };

  const getStepInfo = (
    status: any,
    index: number
  ): { label: string; description: string; date?: string } => {
    /** Label/Description preparation for status steps */
    switch (true) {
      case status.value === OrderStatusEnum.DRAFT:
        let defaultDraftInfo = {
          label: status.label,
          description: t(`order.stepper.statusDesc.default`),
        };
        if (index < currentOrderStatusIndex) {
          const submittedAt = orderStatusTimestamps.submittedAt
            ? dayjs(orderStatusTimestamps.submittedAt).format(
                "DD MMM YYYY hh:mm A"
              )
            : "";
          return {
            ...defaultDraftInfo,
            description: t("order.stepper.statusDesc.submitted"),
            date: submittedAt,
          };
        }
        return defaultDraftInfo;

      case status.value === OrderStatusEnum.AWAITING_RESPONSE:
        let defaultARInfo = {
          label: status.label,
          description: t("order.stepper.statusDesc.waiting"),
        };
        if (index < currentOrderStatusIndex) {
          /** This condition is not necessary now
           * because status 'CONFIRMED' is already separated from AWAITING_RESPONSE */
        }
        return defaultARInfo;

      case status.value === OrderStatusEnum.CONFIRMED:
        let defaultConfirmedInfo = {
          label: status.label,
          description: "",
        };
        let confirmedDescription = t("order.stepper.statusDesc.buyerConfirmed");
        let confirmedAt = orderStatusTimestamps?.confirmedAt
          ? dayjs(orderStatusTimestamps.confirmedAt).format(
              "DD MMM YYYY hh:mm A"
            )
          : "";
        if (
          getFormValues("paymentFlowType") ===
            PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY &&
          singlePaymentInfo
        ) {
          const paymentMethod = singlePaymentInfo?.paymentMethod
            ? `${t("commons.by")} ` +
              singlePaymentInfo?.paymentMethod
                .replace(/[-_]/g, " ")
                .replace(/\bva\b/g, "virtual account")
            : "";
          confirmedDescription =
            t(`order.stepper.statusDesc.upfrontPayment`) + paymentMethod;
          confirmedAt = singlePaymentInfo?.updatedAt
            ? dayjs(singlePaymentInfo?.updatedAt).format("DD MMM YYYY hh:mm A")
            : "";
        } else if (
          getFormValues("paymentFlowType") ===
            PaymentFlowTypeEnum.SPLIT_PAYMENT &&
          singlePaymentInfo
        ) {
          const paymentMethod = singlePaymentInfo?.paymentMethod
            ? `${t("commons.by")} ` +
              singlePaymentInfo?.paymentMethod
                .replace(/[-_]/g, " ")
                .replace(/\bva\b/g, "virtual account")
            : "";
          confirmedDescription =
            t(`order.stepper.statusDesc.initialPayment`) + paymentMethod;
          confirmedAt = singlePaymentInfo?.updatedAt
            ? dayjs(singlePaymentInfo?.updatedAt).format("DD MMM YYYY hh:mm A")
            : "";
        }
        return {
          ...defaultConfirmedInfo,
          description: confirmedDescription,
          date: confirmedAt,
        };

      case status.value === OrderStatusEnum.PROCESSING:
        let defaultProcessingInfo = {
          label: status.label,
          description: "",
        };
        if (index === currentOrderStatusIndex) {
          return {
            ...defaultProcessingInfo,
            description: t(`order.stepper.statusDesc.waitingProcessing`),
          };
        } else if (index < currentOrderStatusIndex) {
          const processedAt = orderStatusTimestamps.processedAt
            ? dayjs(orderStatusTimestamps.processedAt).format(
                "DD MMM YYYY hh:mm A"
              )
            : "";
          return {
            label: t(`order.stepper.label.processed`),
            description: t(`order.stepper.statusDesc.processed`),
            date: processedAt,
          };
        }
        return defaultProcessingInfo;

      /** Label/Description preparation for Multiple Payment status steps
       * It'll cover the case where the status is "PENDING_FINAL_PAYMENT" only
       */
      case status.value.startsWith("PENDING_FINAL_PAYMENT"):
        let defaultPFPInfo = {
          label: status.label,
          description: "",
        };
        if (index === currentOrderStatusIndex) {
          return {
            ...defaultPFPInfo,
            description: t(`order.stepper.statusDesc.waitingPayment`),
          };
        } else if (index < currentOrderStatusIndex) {
          let paidTime = "";
          let paymentMethod = "";
          let paidLabel = status?.label?.replace(/(pending)/gi, "");

          if (
            getFormValues("paymentFlowType") ===
            PaymentFlowTypeEnum.MULTIPLE_PAYMENTS
          ) {
            const paymentHistory = getMPCValues("paymentHistory")?.find(
              (p: any) =>
                p.time === parseInt(status.value.split("_").slice(-1)[0])
            );
            console.log(
              "paymentHistory in Loop",
              typeof status.value.split("_").slice(-1)[0],
              paymentHistory
            );
            paidTime = paymentHistory?.paidTime
              ? dayjs(paymentHistory.paidTime).format("DD MMM YYYY hh:mm A")
              : "";
            paymentMethod = paymentHistory?.paymentMethod
              ? `${t("commons.by")} ` +
                paymentHistory.paymentMethod.replace(/[-_]/g, " ")
              : "";
          } else {
            paymentMethod = singlePaymentInfo?.paymentMethod
              ? `${t("commons.by")} ` +
                singlePaymentInfo?.paymentMethod
                  .replace(/[-_]/g, " ")
                  .replace(/\bva\b/g, "virtual account")
              : "";
            paidTime = singlePaymentInfo?.updatedAt
              ? dayjs(singlePaymentInfo?.updatedAt).format(
                  "DD MMM YYYY hh:mm A"
                )
              : "";
          }
          return {
            label: paidLabel + ` ${t("commons.paid")}`,
            description:
              paidLabel +
              ` ${t("order.stepper.statusDesc.hasBeenMade")} ` +
              paymentMethod,
            date: paidTime,
          };
        }
        return defaultPFPInfo;

      case status.value === OrderStatusEnum.COMPLETE:
        let defaultCompleteInfo = {
          label: status.label,
          description: "",
        };
        if (index === currentOrderStatusIndex) {
          const completedAt = orderStatusTimestamps.completedAt
            ? dayjs(orderStatusTimestamps.completedAt).format(
                "DD MMM YYYY hh:mm A"
              )
            : "";
          return {
            label: t(`order.stepper.label.completed`),
            description: t("order.stepper.statusDesc.orderFinalized"),
            date: completedAt,
          };
        }
        return defaultCompleteInfo;

      default:
        return {
          label: status.label,
          description: "",
        };
    }
  };

  return (
    <Stack
      gap={"16px"}
      my={isMobile ? 2 : 0}
      position={"relative"}
      sx={
        isMobile || isRightSidebarHidden
          ? {
              padding: "24px",
              border: "1px solid var(--joy-palette-divider)",
              borderRadius: "8px",
            }
          : {}
      }
    >
      {isMobile && (
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 9,
            background: "var(--joy-palette-common-white)",
            // color: "var(--joy-palette-neutral-600)",
            border: "1px solid var(--joy-palette-divider)",
            borderRadius: "50%",
          }}
          onClick={() => {
            setIsOpenedInFull(!isOpenedInFull);
          }}
        >
          {isOpenedInFull ? (
            <CloseFullscreenRounded width={20} height={20} strokeWidth={2} />
          ) : (
            <OpenInFullRounded width={20} height={20} strokeWidth={2} />
          )}
        </IconButton>
      )}

      {isRightSidebarHidden && (
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 9,
            background: "var(--joy-palette-common-white)",
            // color: "var(--joy-palette-neutral-600)",
            border: "1px solid var(--joy-palette-divider)",
            borderRadius: "50%",
          }}
          onClick={() => {
            setIsRightSidebarHidden(!isRightSidebarHidden);
          }}
        >
          {<ViewSidebarOutlined width={20} height={20} strokeWidth={2} />}
        </IconButton>
      )}

      {!((isMobile && !isOpenedInFull) || isRightSidebarHidden) && (
        <Typography
          level={"text-xl-semibold"}
          display={{ xs: "none", md: "block" }}
        >
          {t("order.createOrder.summary.orderStatus")}
        </Typography>
      )}

      <Stepper orientation="vertical">
        {/* Order Status Steps Loop */}
        {orderStatusesToLoop.map((status, index) => {
          let stepElement;
          const stepVariant = getStepVariant(index);
          const stepInfo = getStepInfo(status, index);
          if (
            isPendingPaymentStatusHidden(status) ||
            isPendingFinalPaymentStatusHidden(status) ||
            isAwaitingResponseStatusHidden(status) ||
            isConfirmedStatusHidden(status) ||
            isCancelledStatusHidden(status) ||
            isProcessStatusHidden(status) ||
            isCompleteStatusHidden(status)
          ) {
            stepElement = (
              <>
                {/**
                 * Skip the Statuses:
                 * - Pending Payment (Conditional)
                 * - Pending Final Payment (Conditional)
                 * - Cancelled (Not necessary to show it in normal Complete flow)
                 *  */}
              </>
            );
          } else {
            stepElement = (
              <Step
                key={index}
                sx={{
                  minHeight: "46px",
                  marginBottom: "4px",
                  // display: {
                  //   xs:
                  //     stepVariant === "linkz-step-ongoing" || isOpenedInFull
                  //       ? "grid"
                  //       : "none !important",
                  //   md: "grid !important",
                  // },
                  display:
                    stepVariant !== "linkz-step-ongoing" &&
                    ((isMobile && !isOpenedInFull) || isRightSidebarHidden)
                      ? "none !important"
                      : "grid",
                }}
                indicator={
                  <StepIndicator variant={stepVariant}>
                    <CheckIcon strokeWidth={"3.5px"} />
                  </StepIndicator>
                }
              >
                <Typography
                  level={"text-md-semibold"}
                  textColor={
                    stepVariant === "linkz-step-ongoing"
                      ? "initial"
                      : "neutral.400"
                  }
                >
                  {stepInfo.label}
                </Typography>

                <Stack rowGap={1}>
                  {!!stepInfo.description && (
                    <Typography
                      level={"text-sm-regular"}
                      textColor={
                        stepVariant === "linkz-step-ongoing"
                          ? "initial"
                          : "neutral.500"
                      }
                    >
                      {stepInfo.description}
                    </Typography>
                  )}

                  {!!stepInfo.date && (
                    <Typography
                      level={"text-sm-regular"}
                      textColor={
                        stepVariant === "linkz-step-ongoing"
                          ? "initial"
                          : "neutral.500"
                      }
                    >
                      {stepInfo.date}
                    </Typography>
                  )}
                </Stack>
              </Step>
            );
          }
          return stepElement;
        })}
      </Stepper>
    </Stack>
  );
}
