import {
  Box,
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  Sheet,
  Stack,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { theme } from "../../components/Theme";
import { useTranslation } from "react-i18next";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import {
  FileDownloadOutlined,
  WbIncandescentOutlined,
} from "@mui/icons-material";
import FailModal from "../../components/FailModal";
import { SuccessModal } from "../../components/SuccessModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { useEffect, useState } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import useContainer from "./useContainer";
import { CatalogType } from "../../types/catalog";
import NotFound from "../NotFound/NotFound";
import { IfElse } from "../../components/Condition";
import dayjs from "dayjs";
import { CatalogService } from "../../services/catalog.service";
import { UploadService } from "../../services/upload.service";
import { useQuery } from "@tanstack/react-query";

const ExportImportCatalogue = () => {
  const { t } = useTranslation();

  const { catalogId } = useParams();

  const [selectedTab, setSelectedTab] = useState("export");
  const [catalog, setCatalog] = useState<CatalogType>();

  const { fetchOneCatalog } = useContainer();

  /**
   * fetch catalog based on catalogId, assign to catalog state
   * and reset the form with new formatted values
   */
  useEffect(() => {
    if (catalogId) {
      (async () => {
        const catalogResponse = await fetchOneCatalog(catalogId);

        if (catalogResponse) {
          setCatalog(catalogResponse);
        }
      })();
    }
  }, [catalogId]);

  /**
   * change active tab value state
   * @param _event
   * @param value
   */
  const handleTabChange = (
    _event: React.SyntheticEvent | null,
    value: string | number | null
  ) => {
    if (typeof value === "string") {
      setSelectedTab(value);
    }
  };

  const imgExistanceCheckQuery = useQuery({
    queryKey: ["business-profile-image", catalog?.business?.id],
    queryFn: () => UploadService.checkIfImageExists(catalog?.business?.id!),
    enabled: !!catalog?.business?.id,
    retry: false,
    staleTime: 0,
  });

  if (!catalogId) {
    return <NotFound />;
  }

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        py: "32px",
      }}
    >
      {catalog && (
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            gap: "32px",
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              justifyContent: { md: "space-between" },
            }}
          >
            <PageTitleBox
              title={t("catalog.import.title")}
              subtitle={t("catalog.import.subtitle")}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "16px",
                p: "16px",
                backgroundColor: theme.palette.others.list_item_border,
                borderBottomWidth: "1px",
                borderColor: theme.palette.neutral[300],
                borderRadius: "8px",
                maxWidth: { xs: "100%", md: "35%" },
              }}
            >
              <img
                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                src={
                  imgExistanceCheckQuery.data || "/default-company-image.png"
                }
                alt=""
              />

              <Typography level="text-xl2-bold" className={"truncate-2-lines"}>
                {catalog.catalogName}
              </Typography>
            </Box>
          </Stack>

          <Tabs
            aria-label="Basic tabs"
            value={selectedTab}
            onChange={handleTabChange}
            className="w-[90vw] md:w-[75vw]"
            sx={{
              backgroundColor: "transparent",
              marginTop: 4,
            }}
          >
            <TabList
              sx={{
                [`&& .${tabClasses.root}`]: {
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                  [`&.${tabClasses.selected}`]: {
                    "&::after": {
                      bgcolor: "primary.500",
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3,
                      height: 2,
                    },
                    color: "primary.plainColor",
                  },
                  bgcolor: "transparent",
                  flex: "initial",
                  paddingY: 2,
                },
              }}
            >
              <Tab
                id="catalog-exportImport-export-tab"
                value={"export"}
                key={"export"}
                sx={{
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                  minWidth: { xs: "50%", lg: 150 },
                  flex: "none",
                  scrollSnapAlign: "start",
                  flexDirection: "column",
                }}
              >
                {t("catalog.import.tabs.exportTitle")}
              </Tab>
              <Tab
                id="catalog-exportImport-import-tab"
                value={"import"}
                key={"import"}
                sx={{
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                  minWidth: { xs: "50%", lg: 150 },
                  flex: "none",
                  scrollSnapAlign: "start",
                  flexDirection: "column",
                }}
              >
                {t("catalog.import.tabs.importTitle")}
              </Tab>
            </TabList>

            {/* default export tab */}
            <TabPanel value={"export"} key={"export"} sx={{ py: "32px" }}>
              <ExportPanel catalog={catalog} />
            </TabPanel>

            {/* default import tab */}
            <TabPanel value={"import"} key={"import"} sx={{ py: "32px" }}>
              <ImportPanel />
            </TabPanel>
          </Tabs>
        </Stack>
      )}
    </Sheet>
  );
};

const ExportPanel = ({ catalog }: { catalog: CatalogType }) => {
  const { t } = useTranslation();

  const handleExcelDownload = async (_event: any) => {
    await CatalogService.exportCatalogItems(catalog.id);
  };

  return (
    <Stack direction={"column"} gap={"16px"}>
      <Typography level="text-sm-medium">
        {t("catalog.import.tabs.export.exportPrompt")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", lg: "flex-start" },
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            borderWidth: "1px",
            borderColor: theme.palette.neutral[300],
            borderRadius: "8px",
            py: "16px",
            px: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            minWidth: "300px",
            width: { xs: "100%", lg: "40%" },
          }}
        >
          {/* catalog name */}
          <Stack
            direction={"row"}
            sx={{
              gap: "16px",
              alignItems: "center",
            }}
          >
            <IfElse
              condition={dayjs().isAfter(dayjs(catalog.periodEndDate))}
              ifBlock={
                <Chip
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: theme.palette.neutral[200],
                    borderWidth: "1px",
                    borderColor: theme.palette.neutral[600],
                    py: "4px",
                    px: "8px",
                    height: "24px",
                  }}
                >
                  <Typography
                    level="text-xs-semibold"
                    textColor={theme.palette.neutral[600]}
                  >
                    Expired
                  </Typography>
                </Chip>
              }
              elseBlock={
                <Chip
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: theme.palette.others.active_chip_bg,
                    borderWidth: "1px",
                    borderColor: theme.palette.primary[500],
                    py: "4px",
                    px: "8px",
                    height: "24px",
                  }}
                >
                  <Typography
                    level="text-xs-semibold"
                    textColor={theme.palette.primary[500]}
                  >
                    Active
                  </Typography>
                </Chip>
              }
            />
            <Typography
              level="text-lg-semibold"
              textColor={theme.palette.common.black}
              className={"truncate-2-lines"}
            >
              {catalog.catalogName}
            </Typography>
          </Stack>

          {/* catalog details */}
          <Grid container columns={5}>
            {/* currency */}
            <Grid xs={3}>
              <Typography level="text-xs-medium">
                {t("catalog.items.currency")}
              </Typography>
            </Grid>
            <Grid
              xs={2}
              sx={{
                display: "flex",
                gap: "8px",
              }}
            >
              <Typography level="text-xs-medium">:</Typography>
              <Typography level="text-xs-semibold">
                {catalog.currency}
              </Typography>
            </Grid>

            {/* total items */}
            <Grid xs={3}>
              <Typography level="text-xs-medium">
                {t("catalog.totalItems")}
              </Typography>
            </Grid>
            <Grid
              xs={2}
              sx={{
                display: "flex",
                gap: "8px",
              }}
            >
              <Typography level="text-xs-medium">:</Typography>
              <Typography level="text-xs-semibold">
                {catalog.itemsCount ?? 0}
              </Typography>
            </Grid>
          </Grid>

          {/* download button */}
          <Button
            id="catalog-exportImport-export-button"
            variant="linkz-outlined-primary"
            startDecorator={<FileDownloadOutlined />}
            onClick={handleExcelDownload}
            sx={{
              width: "100%",
            }}
          >
            {t("catalog.import.tabs.export.exportCatalogBtn")}
          </Button>
        </Box>
      </Box>

      <Stack
        direction={"row"}
        sx={{
          gap: "8px",
          alignItems: "center",
        }}
      >
        <WbIncandescentOutlined color="primary" className="rotate-180" />
        <Typography level="text-xs-regular">
          {t("catalog.import.tabs.export.exportTip")}
        </Typography>
      </Stack>
    </Stack>
  );
};

const ImportPanel = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const {
    onDrop,
    alertModal,
    excelErrors,
    setExcelErrors,
    showFailModal,
    setShowFailModal,
  } = useContainer();

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
  });

  /**
   * will show fail modal based on excel upload error
   * @param fileRejections
   */
  useEffect(() => {
    if (fileRejections.length > 0) {
      const formattedErrors = fileRejections.map((reject) => {
        return {
          name: reject.file.name,
          errors: reject.errors,
        };
      });

      setIsLoading(false);
      setExcelErrors(formattedErrors);
      setShowFailModal(true);
    }
  }, [fileRejections]);

  /**
   * will show fail modal based on backend validation results
   * @param excelErrors
   */
  useEffect(() => {
    if (excelErrors.length > 0) {
      setIsLoading(false);
      setShowFailModal(true);
    }
  }, [excelErrors]);

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      gap={{ xs: "24px", lg: "32px" }}
    >
      <Box
        sx={{
          width: { xs: "100%", lg: "65%" },
          minHeight: { xs: "0", lg: "244px" },
          bgcolor: theme.palette.neutral[50],
          p: "32px",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: "32px",
          borderWidth: "1px",
          borderColor: theme.palette.neutral[300],
          borderRadius: "8px",
        }}
        {...getRootProps()}
      >
        <img
          src="/bulk.png"
          width={100}
          height={100}
          className="object-contain"
        />

        <Stack
          direction={"column"}
          sx={{
            gap: "16px",
            alignItems: { xs: "center", lg: "baseline" },
          }}
        >
          <Stack direction={"column"} gap={"4px"}>
            <Typography level="text-md-bold">
              {t("catalog.import.tabs.import.importPrompt")}
            </Typography>

            <Typography
              level="text-xs-medium"
              textColor={theme.palette.neutral[600]}
            >
              {t("catalog.import.tabs.import.importSubtitle")}
            </Typography>
          </Stack>
          <Button
            id="catalog-exportImport-import-button"
            {...getRootProps({ className: "dropzone" })}
            variant="linkz-outlined-primary"
            startDecorator={<CloudArrowUpIcon width={20} height={20} />}
            sx={{
              width: "136px",
            }}
          >
            {t("commons.selectFileBtn")}
            <input {...getInputProps()} />
          </Button>
        </Stack>
      </Box>

      {/* tips */}
      <Box
        sx={{
          width: { xs: "100%", lg: "35%" },
          minHeight: { xs: "0", lg: "244px" },
          p: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
          borderWidth: "1px",
          borderColor: theme.palette.neutral[300],
          borderRadius: "8px",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            gap: "8px",
            alignItems: "center",
            justifyContent: "left",
            textAlign: "left",
            width: "100%",
          }}
        >
          <WbIncandescentOutlined color="primary" className="rotate-180" />
          <Typography
            level="text-md-bold"
            textColor={theme.palette.neutral[600]}
          >
            {t("catalog.import.tabs.import.tip.title")}
          </Typography>
        </Stack>

        <Typography
          level="text-xs-medium"
          textColor={theme.palette.neutral[600]}
        >
          {t("catalog.import.tabs.import.tip.paragraph")}
        </Typography>

        <List
          marker="disc"
          size="sm"
          sx={{
            px: "24px",
          }}
        >
          {Array.from({ length: 4 }).map((_point, index) => (
            <ListItem sx={{ p: 0 }}>
              <Typography
                level="text-xs-medium"
                textColor={theme.palette.neutral[600]}
              >
                {t(`catalog.import.tabs.import.tip.${index + 1}`)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* modal */}
      <FailModal
        open={showFailModal}
        title="Failed Uploading File"
        content={
          <Box
            sx={{
              width: "100%",
              p: "16px",
              textAlign: "left",
              height: "250px",
              overflow: "auto",
            }}
            className="scrollbar-container"
          >
            <List marker="disc">
              {excelErrors.map(({ name, errors }) => (
                <ListItem key={name}>
                  {t("catalog.import.error.errorOnRow", {
                    row: name,
                  })}
                  :
                  <List marker="circle">
                    {errors.map((e: any) => (
                      <ListItem key={e.code}>{e.message}</ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Box>
        }
        onClose={() => {
          setShowFailModal(false);
        }}
      />
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertModal.message}
        open={alertModal.show}
      />
      <LoadingModal isLoading={isLoading} />
    </Stack>
  );
};

export default ExportImportCatalogue;
