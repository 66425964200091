import { privateAxios } from "../lib/api";
import {
  CreateCatalogType,
  ICatalogInviteCreatePayload,
} from "../types/catalog";
import { IDraftItemsResponse, InventoryItemDraftType } from "../types/draft";
import { IInventoryImportResponse } from "../types/item";
import { ApiConstants } from "../utils/constants";

interface RemoveCatalogProps {
  catalogId: string;
  businessId: string;
}
export class CatalogService {
  static async validateCatalogName(catalogName: string) {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/validate/${catalogName}`
    );

    return response.data as Boolean;
  }

  static async createCatalogue({ data }: { data: CreateCatalogType }) {
    const response = await privateAxios.post(
      `${ApiConstants.catalogURL}`,
      data
    );

    return response.data;
  }

  static async updateCatalogue({
    data,
    catalogId,
  }: {
    data: CreateCatalogType;
    catalogId: string;
  }) {
    const response = await privateAxios.patch(
      `${ApiConstants.catalogURL}/${catalogId}`,
      data
    );

    return response.data;
  }

  static removeCatalogAccess(payload: RemoveCatalogProps[]) {
    return privateAxios.post(
      `${ApiConstants.catalogURL}/remove-catalog/`,
      payload
    );
  }

  static sendEmailInvitation({
    catalogId,
    accountIds,
  }: {
    catalogId: string;
    accountIds: string[];
  }) {
    return privateAxios.patch(
      `${ApiConstants.catalogURL}/${catalogId}/send-email`,
      { accountIds }
    );
  }

  static sendEmailInvitationToUnregisterUser({
    payload,
  }: {
    catalogId: string;
    payload: ICatalogInviteCreatePayload[];
  }) {
    return privateAxios.patch(
      `${ApiConstants.catalogURL}/invite/send-email/new-account`,
      payload
    );
  }

  /**
   * download an excel file with catalog items data
   * @param catalogId
   * @returns excel download
   */
  static async exportCatalogItems(catalogId: string) {
    try {
      const response = await privateAxios.get(
        `${ApiConstants.catalogURL}/${catalogId}/export`,
        {
          responseType: "blob",
        }
      );

      if (!response.data) {
        throw new Error("No data received");
      }

      const blobUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type:
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      const contentDisposition = response.headers["content-disposition"];
      const serverFilename = contentDisposition
        ? contentDisposition.split("filename=")[1]?.replace(/["']/g, "")
        : null;
      link.setAttribute(
        "download",
        serverFilename || "Linkz-inventory-template"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);

      return true;
    } catch (error) {
      console.error("Download failed:", error);
      return false;
    }
  }

  /**
   * upload an excel file to backend server
   * @param acceptedFile
   * @returns IInventoryImportDraftResponse
   */
  static async importCatalogItems(
    acceptedFile: File
  ): Promise<{ data: IInventoryImportResponse }> {
    const formData = new FormData();
    formData.append("file", acceptedFile);

    const response = await privateAxios.post(
      `${ApiConstants.catalogURL}/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  }

  /**
   *
   * @param draftId
   * @returns IDraftItemsResponse
   */
  static async getCatalogDraftItems({
    draftId,
    limit,
    offset,
    inventoryType,
    isExistInventory,
    searchTerm,
  }: {
    draftId: string;
    limit: number;
    offset: number;
    inventoryType?: string;
    isExistInventory?: string;
    searchTerm?: string;
  }) {
    const response = await privateAxios.get(
      `${ApiConstants.draftURL}/${draftId}/items`,
      {
        params: {
          paging: true,
          limit,
          offset,
          inventoryType,
          isExistInventory,
          searchTerm,
        },
      }
    );

    return response.data as IDraftItemsResponse;
  }

  /**
   * will fetch inventoryType for assigned draft
   * @param draftId
   * @returns any
   */
  static async getCatalogDraftInventoryTypes({ draftId }: { draftId: string }) {
    const response = await privateAxios.get(
      `${ApiConstants.draftURL}/${draftId}/items/inventory-type`
    );

    return response.data as any;
  }

  /**
   *
   * @param draftId
   * @returns excel download
   */
  static async exportDraftItems({ draftId }: { draftId: string }) {
    try {
      const response = await privateAxios.get(
        `${ApiConstants.draftURL}/${draftId}/items/export`,
        {
          responseType: "blob",
        }
      );

      if (!response.data) {
        throw new Error("No data received");
      }

      const blobUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type:
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      const contentDisposition = response.headers["content-disposition"];
      const serverFilename = contentDisposition
        ? contentDisposition.split("filename=")[1]?.replace(/["']/g, "")
        : null;
      link.setAttribute(
        "download",
        serverFilename || "Linkz-inventory-template"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);

      return true;
    } catch (error) {
      console.error("Download failed:", error);
      return false;
    }
  }

  /**
   * upload an excel file to backend server
   * @param acceptedFile
   * @param draftId
   * @returns IInventoryImportDraftResponse
   */
  static async importDraftItems(
    draftId: string,
    acceptedFile: File
  ): Promise<{ data: IInventoryImportResponse }> {
    const formData = new FormData();
    formData.append("file", acceptedFile);

    const response = await privateAxios.post(
      `${ApiConstants.draftURL}/${draftId}/items/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  }

  /**
   *
   * @param draftId
   * @param payload
   * @returns IInventoryImportResponse
   */
  static async putCatalogDraft(
    draftId: string,
    payload: InventoryItemDraftType[]
  ) {
    const response = await privateAxios.put(
      `${ApiConstants.draftURL}/${draftId}/items`,
      payload
    );

    return response.data as IInventoryImportResponse;
  }

  /**
   * will submit draft on create catalog form submission
   * @param draftId
   * @param payload catalogId
   * @returns
   */
  static async submitCatalogDraft(
    draftId: string,
    payload: { catalogId: string }
  ) {
    const response = await privateAxios.post(
      `${ApiConstants.draftURL}/${draftId}/submit`,
      payload
    );

    return response.data as any;
  }
}
