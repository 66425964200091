import {
  Select,
  Option,
  Box,
  Typography,
  Input,
  FormHelperText,
} from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Chip } from "@mui/joy";
import { formatCurrencyByCode } from "../../../utils/formatPrice";
import { ConfirmationModal } from "../../ConfirmationModal";
import RequestFullPaymentModal from "./RequestFullPaymentModal";
import { ThreeColumnGridRow } from "../../stateless/layouts";
import { Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";

export default function MultiplePaymentPlan({ container }: { container: any }) {
  const { t } = useTranslation();
  const {
    orderId,
    grandTotal,
    control: mpcControl,
    setValue: setMPCValue,
    getValues: getMPCValues,
    watch: mpcWatch,
    errors: mpcErrors,
    currency,
    isReadOnly,
    // testRef,
    selectedPaymentPlan,
    setSelectedPaymentPlan,
    onConfirmChangePaymentType,
    handleChangePaymentPlan,
    handleCancelChangePaymentPlan,
    onRequestFullPayment,
    setOnRequestFullPayment,
    getPaidPaymentAmount,
    isFullPaymentRequested,
    getFullPaymentAmount,
  } = container;

  const paymentStatusLabel = (status: string | any) => {
    switch (status) {
      case "PENDING":
        return "Pending";
      case "SUCCESS":
        return "Paid";
      case "FAILED":
        return "Failed";
      case "CANCELED":
        return "Canceled";
      default:
        return "Pending";
    }
  };

  const paymentStatusColor = (status: string | any) => {
    switch (status) {
      case "PENDING":
        return "warning";
      case "SUCCESS":
        return "primary";
      case "FAILED":
        return "danger";
      case "CANCELED":
        return "danger";
      default:
        return "warning";
    }
  };

  const currentAmount = formatCurrencyByCode(
    (getMPCValues("paymentHistory") ?? [])?.reduce(
      (acc: number, curr: any) => acc + curr.amount,
      0
    ),
    currency,
    currency
  );
  const totalAmount = formatCurrencyByCode(grandTotal, currency, currency);
  const requestedAmount = formatCurrencyByCode(
    getFullPaymentAmount(),
    currency,
    currency
  );

  if (!selectedPaymentPlan) {
    setSelectedPaymentPlan(getMPCValues("paymentTypes"));
  }

  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "var(--joy-palette-primary-50)",
        borderRadius: "xl",
        boxShadow: "var(--joy-shadow-sm)",
      }}
    >
      <Typography level="text-lg-semibold">
        {t("order.multiPayment.paymentPlan")}
        <Typography color="danger">*</Typography>
      </Typography>

      <Stack spacing={2} my={4}>
        <ThreeColumnGridRow
          label={t("order.multiPayment.paymentValueType.label")}
          inputElement={
            <Select
              disabled={!!isReadOnly}
              value={selectedPaymentPlan || "AMOUNT"}
              defaultValue={"AMOUNT"}
              onChange={(_, value) => {
                setSelectedPaymentPlan(value);
              }}
              indicator={<KeyboardArrowDown />}
              sx={{ maxWidth: 260 }}
              id="payment-plan-select"
            >
              <Option id="payment-opt-amount" value="AMOUNT">
                Amount
              </Option>
              <Option id="payment-opt-percentage" value="PERCENTAGE">
                Percentage
              </Option>
            </Select>
          }
          outputValue={""}
        />
        {/* <ThreeColumnGridRow
          label={"Test"}
          inputElement={
            <Select
              disabled={!!isReadOnly}
              value={selectedPaymentPlan || "AMOUNT"}
              defaultValue={"AMOUNT"}
              onChange={(e, value) => {
                testRef.current = selectedPaymentPlan;
                console.log("Test Ref in Component ::", value, testRef.current);
                setSelectedPaymentPlan(value);
              }}
              indicator={<KeyboardArrowDown />}
              sx={{ maxWidth: 260 }}
            >
              <Option value="AMOUNT">
                {t("order.multiPayment.paymentValueType.options.amount")}
              </Option>
              <Option value="PERCENTAGE">
                {t("order.multiPayment.paymentValueType.options.percentage")}
              </Option>
            </Select>
          }
          outputValue={""}
        /> */}

        {/* Payment Times Loop */}
        {(getMPCValues("paymentHistory") ?? []).map((payment: any) => (
          <ThreeColumnGridRow
            key={payment.time}
            label={`${t("commons.payment")} ${payment.time}`}
            inputElement={
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                {(getMPCValues("paymentTypes") || selectedPaymentPlan) ===
                "PERCENTAGE" ? (
                  <Controller
                    name={`paymentHistory.${payment.time - 1}.percentage`}
                    control={mpcControl}
                    render={({ field: { onChange, name, value } }: any) => (
                      <Stack>
                        <NumericFormat
                          name={name}
                          value={value}
                          customInput={Input}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={
                            getMPCValues("currency") === "IDR" ? "." : ","
                          }
                          decimalSeparator={
                            getMPCValues("currency") === "IDR" ? "," : "."
                          }
                          sx={{ maxWidth: 260 }}
                          placeholder={""}
                          endDecorator={"%"}
                          {...{
                            isAllowed: (values) => {
                              const { floatValue } = values;
                              return (
                                floatValue === undefined || floatValue <= 100
                              ); // Limit input to 100%
                            },
                          }}
                          onValueChange={(v) => {
                            const value = v.value;
                            onChange(Number(value) || 0);
                            if (`${parseFloat(value)}` !== "NaN") {
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.percentage`,
                                parseFloat(value)
                              );
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.amount`,
                                (parseFloat(value) / 100) * grandTotal
                              );
                            }
                          }}
                          disabled={isReadOnly}
                        />
                        {mpcErrors?.paymentHistory?.[payment.time - 1]
                          ?.percentage?.message && (
                          <FormHelperText
                            color="danger"
                            style={{ marginBottom: "2px" }}
                          >
                            {
                              mpcErrors?.paymentHistory?.[payment.time - 1]
                                ?.percentage?.message
                            }
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                ) : (
                  <Controller
                    name={`paymentHistory.${payment.time - 1}.amount`}
                    control={mpcControl}
                    render={({ field: { onChange, name, value } }: any) => (
                      <Stack>
                        <NumericFormat
                          name={name}
                          value={value}
                          customInput={Input}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={
                            getMPCValues("currency") === "IDR" ? "." : ","
                          }
                          decimalSeparator={
                            getMPCValues("currency") === "IDR" ? "," : "."
                          }
                          sx={{ maxWidth: 260 }}
                          placeholder={""}
                          startDecorator={
                            (getMPCValues("paymentTypes") ??
                              selectedPaymentPlan) === "AMOUNT" && currency
                          }
                          onValueChange={(v) => {
                            const value = v.value;
                            onChange(Number(value) || 0);
                            if (`${parseFloat(value)}` !== "NaN") {
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.amount`,
                                parseFloat(value)
                              );
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.percentage`,
                                Math.round(
                                  (parseFloat(value) / grandTotal) * 100
                                )
                              );
                            }
                          }}
                          disabled={isReadOnly}
                        />
                        {mpcErrors?.paymentHistory?.[payment.time - 1]?.amount
                          ?.message && (
                          <FormHelperText
                            color="danger"
                            style={{ marginBottom: "2px" }}
                          >
                            {
                              mpcErrors?.paymentHistory?.[payment.time - 1]
                                ?.amount?.message
                            }
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                )}

                {isReadOnly && (
                  <Stack position={"relative"} justifyContent={"center"}>
                    <Chip
                      variant="outlined"
                      size="sm"
                      color={
                        paymentStatusColor(
                          getMPCValues(
                            `paymentHistory.${payment.time - 1}.status`
                          )
                        ) as any
                      }
                      sx={{
                        position: "absolute",
                        left: "100%",
                        marginLeft: "4px",
                      }}
                    >
                      {
                        paymentStatusLabel(
                          getMPCValues(
                            `paymentHistory.${payment.time - 1}.status`
                          )
                        ) as any
                      }
                    </Chip>
                  </Stack>
                )}
              </Stack>
            }
            outputValue={
              <Typography
                sx={{
                  textDecoration: getMPCValues(
                      `paymentHistory.${payment.time - 1}.status`
                    ) === "CANCELED"
                      ? "line-through"
                      : "",
                }}
              >
                {formatCurrencyByCode(
                  getMPCValues(`paymentHistory.${payment.time - 1}.amount`),
                  currency,
                  currency
                )}
              </Typography>
            }
          />
        ))}
      </Stack>

      {/* Payment History Errors - displaying errors all at once */}
      {/* {mpcErrors.paymentHistory &&
        Object.values(mpcErrors.paymentHistory).map((payHistError: any) => {
          console.log("payHistError", payHistError);
          return (
            <FormHelperText color="danger" style={{ margin: "8px 0" }}>
              {(getMPCValues("paymentTypes") ?? selectedPaymentPlan) ===
                "AMOUNT" && `payHistError?.amount?.time ${payHistError?.amount?.message}`}
              {(getMPCValues("paymentTypes") ?? selectedPaymentPlan) ===
                "PERCENTAGE" && payHistError?.percentage?.message}
            </FormHelperText>
          );
        })} */}

      {(mpcWatch("paymentHistory") as any[])?.reduce(
        (acc, curr) => acc + curr.amount,
        0
      ) !== grandTotal && (
        <Typography color="danger" fontSize={"sm"}>
          {t("order.multiPayment.errors.totalPayment", {
            currentAmount,
            totalAmount,
          })}
          {/* Total Payment{" "}
          {formatCurrencyByCode(
            (getMPCValues("paymentHistory") ?? [])?.reduce(
              (acc: number, curr: any) => acc + curr.amount,
              0
            ),
            currency,
            currency
          )}{" "}
          must be equal to{" "}
          {formatCurrencyByCode(grandTotal, currency, currency)} */}
        </Typography>
      )}

      {isFullPaymentRequested() && (
        <Typography
          mb={"1rem"}
          color="warning"
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          {t("order.multiPayment.errors.fullPaymentRequested", {
            requestedAmount,
          })}
          {/* Full payment requested by seller with amount{" "}
          {formatCurrencyByCode(getFullPaymentAmount(), currency, currency)} */}
        </Typography>
      )}
      <Box>
        {/* {orderStatus !== OrderStatusEnum.COMPLETE &&
          allowRequestFullPayment() &&
          isSeller &&
          !isFullPaymentRequested() && (
            <Button onClick={() => setOnRequestFullPayment(true)}>
              Request Full Payment
            </Button>
          )} 
        {orderStatus !== OrderStatusEnum.COMPLETE &&
          isFullPaymentRequested() &&
          isSeller && (
            <Button
              sx={{ bgcolor: "white" }}
              variant="outlined"
              onClick={() => cancelRequestFullPayment()}
            >
              Cancel Full Payment
            </Button>
          )} */}
      </Box>

      {!isReadOnly && (
        <ConfirmationModal
          open={onConfirmChangePaymentType}
          title="Change Payment Plan"
          content="Changing Payment plan will erase all filled-in information. Are you sure you want to continue?"
          onCancel={() => handleCancelChangePaymentPlan()}
          onConfirm={() => {
            handleChangePaymentPlan();
          }}
          btnConfirmText="Proceed"
        />
      )}
      <RequestFullPaymentModal
        orderId={orderId}
        open={onRequestFullPayment}
        grandTotal={grandTotal}
        paidAmount={getPaidPaymentAmount()}
        currency={currency}
        onClose={() => setOnRequestFullPayment(false)}
      />
    </Box>
  );
}
