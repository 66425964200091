import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, Link as RouterLink } from "react-router-dom";
import WelcomeIllustratorSVG from "../components/Icons/WelcomeIllustratorSVG";
import Shared from "../layouts/Shared";
import { useAuthStore } from "../store/session";
import { BrowserRouterConstants } from "../utils/constants";

export default function Welcome() {
  const { t } = useTranslation();
  const { session } = useAuthStore();
  if (session?.accessToken) {
    return <Navigate to={"/client"} />;
  }
  return (
    <Shared minWidth={"329px"} width={{ md: "480px" }}>
      <Helmet
        link={[{ href: "https://linkzasia.com/auth", rel: "canonical" }]}
        meta={[{ content: "Onboarding", name: "description" }]}
        title="Onboarding - Linkz"
      />

      <Stack
        spacing={4}
        justifyContent={"center"}
        alignItems={"center"}
        flex={1}
        py={"1rem"}
      >
        <WelcomeIllustratorSVG />
        <Typography
          component="h3"
          fontWeight="lg"
          level="h3"
          sx={
            {
              // color: "common.white",
            }
          }
          textAlign="center"
        >
          {t("landing.tagLine")}
        </Typography>
      </Stack>
      <Stack
        paddingX={2}
        spacing={3}
        width={"100%"}
        maxWidth={{ xs: "100%", lg: "352px" }}
        mx={"auto"}
      >
        <Button
          id="btn-signup"
          aria-labelledby="navigate-to-login"
          component={RouterLink}
          size="lg"
          to={BrowserRouterConstants.Signup}
        >
          {t("landing.createAccountBtn")}
        </Button>
        <Button
          id="btn-signin"
          aria-labelledby="navigate-to-login"
          component={RouterLink}
          size="lg"
          to={BrowserRouterConstants.Signin}
          variant="soft"
        >
          {t("landing.loginBtn")}
        </Button>
      </Stack>
    </Shared>
  );
}
