import { Button, Chip, Stack, Typography } from "@mui/joy";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../../../components/Error";
import { GreenClock, RedClock } from "../../../../components/Icons/Clock";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";
import PaymentInfoModal from "../../../../components/payments/PaymentInfoModal";
import {
  PaymentService,
  PaymentType,
} from "../../../../services/payment.service";
import { getActiveBusiness } from "../../../../store/session";
import { Operation } from "../../../../types/credits";
import { getCountryInfo } from "../../../../utils/country";
import { getMonthName } from "../../../../utils/formatDate";
import useContainer from "./useContainer";
import { useSearchParams } from "react-router-dom";

function getTransactionName(transactionType: string) {
  const map: { [key: string]: string } = {
    CatalogueOrder: "Catalogue Order",
    PurchaseOrder: "Purchase Order",
    SalesOrder: "Sales Order",
    CreditTopup: "Credit Topup",
  };
  return map[transactionType] || "Unknown";
}
const Transactions = () => {
  const { isPending, transactions } = useContainer();
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);
  const [searchParams] = useSearchParams();
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const currentBusiness = getActiveBusiness();

  const getPaymentInfo = (transactionId: any) => {
    PaymentService.enquiryPaymentStatus(
      transactionId,
      "",
      PaymentType.FINAL_PAYMENT
    )
      .then((response) => {
        setPaymentInfo(response.data.transactionInfo);
      })
      .catch((error) => {
        toast(showErrorToast(error), ErrorToastConfig);
      })
      .finally(() => {
        setShowPaymentInfoModal(true);
      });
  };

  useEffect(() => {
    const transactionId = searchParams.get("transaction_id");
    if (transactionId) {
      getPaymentInfo(transactionId);
    }
  }, []);

  if (isPending) {
    return <LoadingModal isLoading={isPending} />;
  }
  return (
    <div className="flex flex-col gap-8">
      {transactions.map((tx) => (
        <div className="flex flex-col gap-4 pt-4">
          <Typography fontWeight={700} fontSize={14}>
            {getMonthName(tx.month)} {tx.year}
          </Typography>
          {tx.transactions.map((item) => {
            const isWithdraw = item.operation === Operation.Deduct;
            return (
              <div className="p-4 border-b border-[#EAECF0] flex flex-col">
                <div className="flex justify-between items-center">
                  <div className="flex gap-1 items-start">
                    {isWithdraw ? <RedClock /> : <GreenClock />}
                    <div className="flex flex-col gap-1">
                      <Typography fontWeight={700} fontSize={14}>
                        {getTransactionName(item.transaction_type)}
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        textColor={"neutral.500"}
                      >
                        {dayjs(item.created_at).format("DD MMMM YYYY")}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Stack
                      spacing={2}
                      justifyContent={"end"}
                      alignItems={"end"}
                    >
                      <Typography
                        textColor={isWithdraw ? "danger.500" : "primary.500"}
                        fontWeight={700}
                        fontSize={14}
                      >
                        {isWithdraw ? "-" : "+"} {item.no_of_credits} Credit
                      </Typography>
                      {item.status !== "PROCESSED" && (
                        <Chip
                          color={
                            item.status === "Pending" ? "danger" : "primary"
                          }
                        >
                          {item.status}
                        </Chip>
                      )}
                      {item.status === "Pending" &&
                        getTransactionName(item.transaction_type) ===
                          "Credit Topup" && (
                          <Button
                            size="sm"
                            variant="plain"
                            // sx={{ px:  }}
                            onClick={() => getPaymentInfo(item.id)}
                          >
                            Check Payment Status
                          </Button>
                        )}
                    </Stack>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
      {paymentInfo && (
        <PaymentInfoModal
          open={showPaymentInfoModal}
          setOpenModal={setShowPaymentInfoModal}
          paymentInfo={paymentInfo}
          currency={
            getCountryInfo(currentBusiness?.countryCode ?? "ID", "LOCALE")
              ?.currency
          }
        />
      )}
    </div>
  );
};

export default Transactions;
