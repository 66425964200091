/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Fragment, useEffect, useState } from "react";

import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { Stack } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import OrderListCards from "../../components/Orders/OrderListCards";
import OrderListTable from "../../components/Orders/OrderListTable";
import { Pagination } from "../../components/Pagination";
import i18next from "../../i18n/config";
import { OrderService } from "../../services/order.service";
import { useAuthStore } from "../../store/session";
import { IOrderList, OrderStatusEnum } from "../../types/order";
import { BrowserRouterConstants } from "../../utils/constants";
import CancelOrderReasonModal from "../../components/Orders/CancelOrderReasonModal";
import {
  DateFilter,
  DateSort,
  InputSearch,
  OrderStatusFilter,
  OrderTypeFilter,
} from "../../components/Orders/OrderFilters";

const rows = [
  {
    id: "INV-1234",
    date: "Feb 3, 2023",
    status: "Refunded",
    orderType: "sales",
    customer: {
      initial: "O",
      name: "Olivia Ryhe",
      email: "olivia@email.com",
    },
  },
];

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// type Order = "asc" | "desc";

// function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (
//   // a: { [key in Key]: string | number | null },
//   // b: { [key in Key]: string | number | null }
//   a: { [key in Key]: any },
//   b: { [key in Key]: any }
// ) => number {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// // with exampleArray.slice().sort(exampleComparator)
// function stableSort<T>(
//   array: readonly T[],
//   comparator: (a: T, b: T) => number
// ) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const ThreeDotsRowMenu = ({
  orderId,
  orderStatus,
  handleOnClick,
}: {
  orderId: string;
  orderStatus: string;
  handleOnClick: any;
}) => {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <NavLink
          to={
            BrowserRouterConstants.ClientPrefix +
            BrowserRouterConstants.Orders +
            `/${orderId}`
          }
        >
          <MenuItem> {i18next.t("order.table.rowMenu.edit")}</MenuItem>
        </NavLink>
        <MenuItem onClick={() => handleOnClick("duplicate", orderId)}>
          {" "}
          {i18next.t("order.table.rowMenu.duplicate")}
        </MenuItem>

        {orderStatus === OrderStatusEnum.DRAFT ? (
          <>
            <Divider />

            <MenuItem
              color="danger"
              onClick={() => handleOnClick("delete", orderId)}
            >
              {i18next.t("order.table.rowMenu.delete")}
            </MenuItem>
          </>
        ) : orderStatus !== OrderStatusEnum.CANCELLED &&
          orderStatus !== OrderStatusEnum.COMPLETE ? (
          <>
            <Divider />
            <MenuItem
              color="primary"
              onClick={() => handleOnClick("cancel", orderId)}
            >
              Cancel
            </MenuItem>
          </>
        ) : (
          ""
        )}
      </Menu>
    </Dropdown>
  );
};

export default function ListOutstandingOrders() {
  const { t } = useTranslation();
  // const location = useLocation();
  const [selected, setSelected] = useState<readonly (string | number | null)[]>(
    []
  );
  const [loading, setLoading] = useState<any>(true);
  const [confirmationModal, setConfirmationModal] = useState<any>({
    open: false,
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [cancelOrderWithReasonModal, setCancelOrderWithReasonModal] =
    useState<any>({
      open: false,
      onConfirm: () => {},
      onCancel: () => {},
    });
  const { session } = useAuthStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  // const [searchTerm, setSearchTerm] = useState<string>();
  const [orderList, setOrderList] = useState<IOrderList[] | any>([]);
  // const [selectedOrderTypeFilter, setSelectedOrderTypeFilter] = useState<any>();
  // const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] =
  //   useState<any>();
  // const [selectedOrderDateFilter, setSelectedOrderDateFilter] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    currentLast: 0,
    max: 0,
    limit: 20,
    offset: 0,
  });

  const paramSellerBusinessId = searchParams.get("sellerBusinessId");
  const paramBuyerBusinessId = searchParams.get("buyerBusinessId");

  const paramStatus = searchParams.get("status");
  const paramDate = searchParams.get("date");
  const paramType = searchParams.get("type");
  const paramQ = searchParams.get("q");
  const paramSortByDate = searchParams.get("sortByDate") || "DESC";

  useEffect(() => {
    if (!paramQ) {
      // If no input search, no need to use Debounce timer
      setLoading(true);
      getAndSetOrderList();
      return () => {};
    }

    // Debounce timer
    const timer = setTimeout(() => {
      setLoading(true);
      getAndSetOrderList();
    }, 700);
    return () => {
      clearTimeout(timer);
    };
  }, [searchParams, pagination.offset, pagination.limit]);

  async function getAndSetOrderList() {
    console.log("getAndSetOrderList");
    setLoading(true);
    const responseData = await OrderService.getOutstandingOrderListPublic({
      sellerBusinessId: paramSellerBusinessId,
      buyerBusinessId: paramBuyerBusinessId,
      searchTerm: paramQ || "",
      hasPagination: true,
      limit: pagination.limit,
      offset: pagination.offset,
    });
    console.log("Order List Response :::", responseData);
    const initialOrderList = responseData.items.map((item: any) => {
      const orderType =
        item.seller && item.seller.id === session?.activeBusiness?.id
          ? "sales"
          : item.buyer && item.buyer.id === session?.activeBusiness?.id
          ? "purchase"
          : "draft";
      return {
        ...item,
        orderType,
      };
    });
    console.log("Initial Order List :::", initialOrderList);

    setOrderList(initialOrderList);
    setPagination(responseData.pagination);
    setLoading(false);
  }

  const handleOnClick = (action: any, orderId: any) => {
    switch (action) {
      case "duplicate":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to duplicate this order?",
          content: "",
          btnConfirmText: "Duplicate",
          onConfirm: () => duplicateOrder(orderId),
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      case "delete":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to delete this draft?",
          content: "This cannot be undone.",
          btnConfirmText: "Delete",
          onConfirm: () => deleteOrder(orderId),
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      case "cancel":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to cancel this order?",
          content: "This cannot be undone.",
          onConfirm: () => {
            setCancelOrderWithReasonModal({
              open: true,
              onConfirm: (reason: string) => {
                cancelOrder(orderId, reason);
              },
              onCancel: () => setCancelOrderWithReasonModal({ open: false }),
            });
          },
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      default:
        break;
    }
  };

  const duplicateOrder = async (orderId: string) => {
    setLoading(true);
    const duplicatedOrder = await OrderService.duplicateOrder(orderId);
    setLoading(false);
    setConfirmationModal({ open: false });
    if (duplicatedOrder)
      navigate(
        BrowserRouterConstants.ClientPrefix +
          BrowserRouterConstants.Orders +
          `/${duplicatedOrder.id}`
      );
  };

  const cancelOrder = async (orderId: string, reason?: string) => {
    console.log("To Cancel", orderId);
    setLoading(true);
    const deletedTimestamp = await OrderService.cancelOrder(orderId, {
      reason,
    });
    setLoading(false);
    setConfirmationModal({ open: false });
    if (deletedTimestamp) getAndSetOrderList();
  };

  const deleteOrder = async (orderId: string) => {
    setLoading(true);
    const isDeleted = await OrderService.deleteOrder(orderId);
    setLoading(false);
    setConfirmationModal({ open: false });
    if (isDeleted) getAndSetOrderList();
  };

  const handleOnSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchTerm(e.target.value);
    const newParams = new URLSearchParams(searchParams);
    if (e.target.value) {
      newParams.set("q", e.target.value);
    } else {
      newParams.delete("q");
    }
    setSearchParams(newParams);
  };

  const handleOrderDateFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("date", newValue);
    } else {
      newParams.delete("date");
    }
    setSearchParams(newParams);
  };

  const filterByDate = (orderDate: any) => {
    const now = dayjs();
    switch (paramDate) {
      case "today":
        return dayjs(orderDate).isSame(now, "day");
      case "yesterday":
        return dayjs(orderDate).isSame(now.subtract(1, "day"), "day");
      case "this_week":
        return dayjs(orderDate).isSame(now, "week");
      case "this_month":
        return dayjs(orderDate).isSame(now, "month");
      default:
        return true;
    }
  };

  const handleOrderStatusFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: { value: string; label: string; backendStatuses: [] } | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("status", newValue.value);
    } else {
      newParams.delete("status");
    }
    setSearchParams(newParams);
  };

  const handleOrderTypeFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("type", newValue);
    } else {
      newParams.delete("type");
    }
    setSearchParams(newParams);
  };

  const handleOnChangeSortByDate = (newValue: any) => {
    console.log("handleOnChangeSortByDate >>>", newValue);
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("sortByDate", newValue);
    } else {
      newParams.delete("sortByDate");
    }
    setSearchParams(newParams);
  };

  const filteredOrderList = orderList?.filter((order: any) => {
    console.log("Order to be filtered :::", paramStatus, order);
    return (
      (paramType ? paramType === order.orderType : true) &&
      (paramStatus ? paramStatus === order.status : true) &&
      filterByDate(order.updatedAt)
    );
  });

  const handlePaging = (action: string | number) => {
    console.log("Paging Action :::", action);
    if (typeof action === "string") {
      /** when pagination button is string */
      switch (action) {
        case "first":
          setPagination({ ...pagination, offset: 0 });
          break;

        case "previous":
          setPagination({
            ...pagination,
            offset: pagination.offset - pagination.limit,
          });
          break;

        case "next":
          setPagination({
            ...pagination,
            offset: pagination.offset + pagination.limit,
          });
          break;

        case "last":
          setPagination({
            ...pagination,
            offset:
              (Math.floor(pagination.max / pagination.limit) - 1) *
                pagination.limit +
              pagination.limit,
          });
          break;
      }
    } else {
      /** when pagination button is number */
      setPagination({
        ...pagination,
        offset: (action - 1) * pagination.limit,
      });
    }
  };

  const DesktopFilterSection = () => {
    return (
      <Fragment>
        {/* First Row */}
        <Stack flexDirection={"row"} width={"100%"}>
          <Stack flexGrow={1}>
            <InputSearch
              {...{
                paramQ,
                handleOnSearch,
                placeholder: t("order.searchPlaceholder"),
              }}
            />
          </Stack>
        </Stack>

        {/* Second Row */}
        <Stack
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Stack>
            <DateFilter
              selectedOrderDateFilter={paramDate}
              handleOrderDateFilterChange={handleOrderDateFilterChange}
              t={t}
            />
          </Stack>
          <Stack direction={"row"} gap={3}>
            <OrderTypeFilter
              selectedOrderTypeFilter={paramType}
              handleOrderTypeFilterChange={handleOrderTypeFilterChange}
              t={t}
            />

            <OrderStatusFilter
              selectedOrderStatusFilter={paramStatus}
              handleOrderStatusFilterChange={handleOrderStatusFilterChange}
              t={t}
            />

            {/** Sort By Date */}
            <DateSort
              sortByDate={paramSortByDate}
              handleOnChangeSortByDate={handleOnChangeSortByDate}
              t={t}
              isMobile={isMobile}
            />
          </Stack>
        </Stack>
      </Fragment>
    );
  };

  const MobileFilterSection = () => {
    return (
      <Fragment>
        <Stack rowGap={"16px"}>
          <Stack flexGrow={1}>
            <InputSearch {...{ paramQ, handleOnSearch, t }} />
          </Stack>

          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            gap={"8px"}
            rowGap={"16px"}
            sx={{
              [`& > *`]: { flexGrow: 1, flexBasis: "calc(50% - 4px)" },
            }}
          >
            <Stack flexBasis={"auto"}>
              <DateFilter
                selectedOrderDateFilter={paramDate}
                handleOrderDateFilterChange={handleOrderDateFilterChange}
                t={t}
              />
            </Stack>

            <Stack flexBasis={"auto"}>
              <OrderTypeFilter
                selectedOrderTypeFilter={paramType}
                handleOrderTypeFilterChange={handleOrderTypeFilterChange}
                t={t}
              />
            </Stack>
            <Stack flexBasis={"auto"}>
              <OrderStatusFilter
                selectedOrderStatusFilter={paramStatus}
                handleOrderStatusFilterChange={handleOrderStatusFilterChange}
                t={t}
              />
            </Stack>

            {/** Sort By Date */}
            <Stack flexGrow={0} flexBasis={"auto"}>
              <DateSort
                sortByDate={paramSortByDate}
                handleOnChangeSortByDate={handleOnChangeSortByDate}
                t={t}
                isMobile={isMobile}
              />
            </Stack>
          </Stack>
        </Stack>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {/** Search Filters - Mobile  */}
      {isMobile && (
        <Sheet
          className="SearchAndFilters-mobile"
          sx={{
            display: { xs: "flex", lg: "none" },
            my: 2,
            gap: 3,
            flexDirection: "column",
          }}
        >
          <Stack flexWrap={"wrap"}>{MobileFilterSection()}</Stack>
        </Sheet>
      )}

      {/** Search Filters - Tablet and Above  */}
      {!isMobile && (
        <Stack
          className="SearchAndFilters-tabletUp"
          display={{ xs: "none", lg: "flex" }}
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          sx={{
            borderRadius: "sm",
            py: 2,
            gap: 2,
          }}
        >
          {DesktopFilterSection()}
        </Stack>
      )}

      {filteredOrderList && filteredOrderList?.length > 0 ? (
        isMobile ? (
          <OrderListCards
            // isPublicView={false}
            orderList={filteredOrderList}
            ThreeDotsRowMenu={ThreeDotsRowMenu}
            handleOnClick={handleOnClick}
          />
        ) : (
          <>
            <OrderListTable
              isPublicView={false}
              orderList={filteredOrderList}
              selected={selected}
              setSelected={setSelected}
              handleOnClick={handleOnClick}
              rows={rows}
              ThreeDotsRowMenu={ThreeDotsRowMenu}
            />

            {/** Bottom Pagination */}

            <Stack
              direction={"row"}
              width={"100%"}
              justifyContent={"center"}
              alignContent={"center"}
              gap={0}
              mt={8}
            >
              <Pagination
                count={Math.ceil(pagination.max / pagination.limit)}
                page={Math.ceil(pagination.currentLast / pagination.limit)}
                // defaultPage={pagination.currentLast}
                onChange={(_, value) => {
                  console.log(
                    "pagination value",
                    value,
                    pagination.currentLast
                  );
                  handlePaging(value);
                }}
                showFirstButton
                showLastButton
              />
            </Stack>
          </>
        )
      ) : (
        <Box textAlign={"center"} mt={4}>
          <Typography level={"h3"} mb={2}>
            {t("order.table.noOrders")}
          </Typography>
          <Typography> {t("order.table.noOrdersPrompt")}</Typography>
        </Box>
      )}

      {/** Modals */}
      <ConfirmationModal
        title={confirmationModal.title}
        content={confirmationModal.content}
        open={confirmationModal.open}
        btnConfirmText={confirmationModal.btnConfirmText}
        onCancel={confirmationModal.onCancel}
        onConfirm={confirmationModal.onConfirm}
      />
      <CancelOrderReasonModal
        show={cancelOrderWithReasonModal.open}
        onConfirm={cancelOrderWithReasonModal.onConfirm}
        onClose={cancelOrderWithReasonModal.onCancel}
      />
      <LoadingModal isLoading={loading} />
    </Fragment>
  );
}
