import { Pagination as MaterialPagination, SxProps } from "@mui/material";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { UsePaginationProps } from "@mui/material/usePagination/usePagination";

const materialTheme = materialExtendTheme();

export const Pagination = ({
  sx,
  ...props
}: { sx?: SxProps } & UsePaginationProps) => (
  <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
    <MaterialPagination {...props} sx={sx} />
  </MaterialCssVarsProvider>
);
