import {
  Box,
  Button,
  List,
  ListItem,
  Sheet,
  Stack,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import PageTitleBox from "../../components/PageTitleBox";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  FileDownloadOutlined,
  WbIncandescentOutlined,
} from "@mui/icons-material";
import { ItemService } from "../../services/item.service";
import { theme } from "../../components/Theme";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";
import useContainer from "./useContainer";
import FailModal from "../../components/FailModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";

const ExportImportInventory = () => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState("export");

  const handleTabChange = (
    _event: React.SyntheticEvent | null,
    value: string | number | null
  ) => {
    if (typeof value === "string") {
      setSelectedTab(value);
    }
  };

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <Stack
        direction={"column"}
        sx={{
          width: "100%",
          gap: "32px",
        }}
      >
        <PageTitleBox
          title={t("catalog.inventory.import.title")}
          subtitle={t("catalog.inventory.import.subtitle")}
        />

        <Tabs
          aria-label="Basic tabs"
          value={selectedTab}
          onChange={handleTabChange}
          className="w-[90vw] md:w-[75vw]"
          sx={{
            backgroundColor: "transparent",
            marginTop: 4,
          }}
        >
          <TabList
            sx={{
              [`&& .${tabClasses.root}`]: {
                "&:hover": {
                  bgcolor: "transparent",
                },
                [`&.${tabClasses.selected}`]: {
                  "&::after": {
                    bgcolor: "primary.500",
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    height: 2,
                  },
                  color: "primary.plainColor",
                },
                bgcolor: "transparent",
                flex: "initial",
                paddingY: 2,
              },
            }}
          >
            <Tab
              id="inventory-export-tab"
              value={"export"}
              key={"export"}
              sx={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: { xs: "50%", lg: 150 },
                flex: "none",
                scrollSnapAlign: "start",
                flexDirection: "column",
              }}
            >
              {t("catalog.inventory.import.tabs.exportTitle")}
            </Tab>
            <Tab
              id="inventory-import-tab"
              value={"import"}
              key={"import"}
              sx={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: { xs: "50%", lg: 150 },
                flex: "none",
                scrollSnapAlign: "start",
                flexDirection: "column",
              }}
            >
              {t("catalog.inventory.import.tabs.importTitle")}
            </Tab>
          </TabList>

          {/* default export tab */}
          <TabPanel value={"export"} key={"export"} sx={{ py: "32px" }}>
            <ExportPanel />
          </TabPanel>

          {/* default import tab */}
          <TabPanel value={"import"} key={"import"} sx={{ py: "32px" }}>
            <ImportPanel />
          </TabPanel>
        </Tabs>
      </Stack>
    </Sheet>
  );
};

const ExportPanel = () => {
  const { t } = useTranslation();

  const handleExcelDownload = async (_event: any) => {
    await ItemService.exportInventoryItems();
  };

  return (
    <Stack direction={"column"} gap={"16px"}>
      <Typography level="text-sm-medium">
        {t("catalog.inventory.import.tabs.export.exportPrompt")}
      </Typography>

      <Button
        id="inventory-export-button"
        variant="linkz-outlined-primary"
        startDecorator={<FileDownloadOutlined />}
        onClick={handleExcelDownload}
        sx={{
          width: "250px",
        }}
      >
        {t("catalog.inventory.import.tabs.export.exportInventoryBtn")}
      </Button>

      <Stack
        direction={"row"}
        sx={{
          gap: "8px",
          alignItems: "center",
        }}
      >
        <WbIncandescentOutlined color="primary" className="rotate-180" />
        <Typography level="text-xs-regular">
          {t("catalog.inventory.import.tabs.export.exportTip")}
        </Typography>
      </Stack>
    </Stack>
  );
};

const ImportPanel = () => {
  const { t } = useTranslation();
  const { onDrop, setExcelErrors, excelErrors, showAlert, alertMessage } =
    useContainer();

  const [showFailModal, setShowFailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
  });

  /**
   * will show fail modal based on excel upload error
   * @param fileRejections
   */
  useEffect(() => {
    if (fileRejections.length > 0) {
      const formattedErrors = fileRejections.map((reject) => {
        return {
          name: reject.file.name,
          errors: reject.errors,
        };
      });

      setIsLoading(false);
      setExcelErrors(formattedErrors);
      setShowFailModal(true);
    }
  }, [fileRejections]);

  /**
   * will show fail modal based on backend validation results
   * @param excelErrors
   */
  useEffect(() => {
    if (excelErrors.length > 0) {
      setIsLoading(false);
      setShowFailModal(true);
    }
  }, [excelErrors]);

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      gap={{ xs: "24px", lg: "32px" }}
    >
      <Box
        sx={{
          width: { xs: "100%", lg: "65%" },
          minHeight: { xs: "0", lg: "244px" },
          bgcolor: theme.palette.neutral[50],
          p: "32px",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: "32px",
          borderWidth: "1px",
          borderColor: theme.palette.neutral[300],
          borderRadius: "8px",
        }}
        {...getRootProps()}
      >
        <img
          src="/bulk.png"
          width={100}
          height={100}
          className="object-contain"
        />

        <Stack
          direction={"column"}
          sx={{
            gap: "16px",
            alignItems: { xs: "center", lg: "baseline" },
          }}
        >
          <Stack direction={"column"} gap={"4px"}>
            <Typography level="text-md-bold">
              {t("catalog.inventory.import.tabs.import.importPrompt")}
            </Typography>

            <Typography
              level="text-xs-medium"
              textColor={theme.palette.neutral[600]}
            >
              {t("catalog.inventory.import.tabs.import.importSubtitle")}
            </Typography>
          </Stack>
          <Button
            id="inventory-import-button"
            {...getRootProps({ className: "dropzone" })}
            variant="linkz-outlined-primary"
            startDecorator={<CloudArrowUpIcon width={20} height={20} />}
            sx={{
              width: "136px",
            }}
          >
            {t("commons.selectFileBtn")}
            <input {...getInputProps()} />
          </Button>
        </Stack>
      </Box>

      {/* tips */}
      <Box
        sx={{
          width: { xs: "100%", lg: "35%" },
          minHeight: { xs: "0", lg: "244px" },
          p: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
          borderWidth: "1px",
          borderColor: theme.palette.neutral[300],
          borderRadius: "8px",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            gap: "8px",
            alignItems: "center",
            justifyContent: "left",
            textAlign: "left",
            width: "100%",
          }}
        >
          <WbIncandescentOutlined color="primary" className="rotate-180" />
          <Typography
            level="text-md-bold"
            textColor={theme.palette.neutral[600]}
          >
            {t("catalog.inventory.import.tabs.import.tip.title")}
          </Typography>
        </Stack>

        <Typography
          level="text-xs-medium"
          textColor={theme.palette.neutral[600]}
        >
          {t("catalog.inventory.import.tabs.import.tip.paragraph")}
        </Typography>

        <List
          marker="disc"
          size="sm"
          sx={{
            px: "24px",
          }}
        >
          {Array.from({ length: 4 }).map((_point, index) => (
            <ListItem sx={{ p: 0 }}>
              <Typography
                level="text-xs-medium"
                textColor={theme.palette.neutral[600]}
              >
                {t(`catalog.inventory.import.tabs.import.tip.${index + 1}`)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* modal */}
      <FailModal
        open={showFailModal}
        title="Failed Uploading File"
        content={
          <Box
            sx={{
              width: "100%",
              p: "16px",
              textAlign: "left",
              height: "250px",
              overflow: "auto",
            }}
            className="scrollbar-container"
          >
            <List marker="disc">
              {excelErrors.map(({ name, errors }) => (
                <ListItem key={name}>
                  {t("catalog.inventory.import.error.errorOnRow", {
                    row: name,
                  })}
                  :
                  <List marker="circle">
                    {errors.map((e: any) => (
                      <ListItem key={e.code}>{e.message}</ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Box>
        }
        onClose={() => {
          setShowFailModal(false);
        }}
      />
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
      <LoadingModal isLoading={isLoading} />
    </Stack>
  );
};

export default ExportImportInventory;
