import { Card, CardCover, Stack } from "@mui/joy";
import ImagePolicy from "./ImagePolicy";
import UploadButton from "./buttons/UploadButton";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";

export default function UploadImage({
  file,
  src,
  buttonTitle,
  renderImage,
  handleFile,
}: {
  file?: File | null;
  src?: string | null;
  buttonTitle?: string;
  renderImage?: (file: File | null | undefined) => void;
  handleFile?: (file: File | null) => void;
}) {
  const { t } = useTranslation();
  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      spacing={5}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack direction={"row"} gap={{ xs: 2, lg: 5 }} alignItems={"center"}>
        {renderImage?.(file) || (
          <Card
            sx={{
              width: 100,
              height: 100,
              borderRadius: 16,
            }}
          >
            {(file || src) && (
              <CardCover>
                {(file || src) && (
                  <Avatar
                    id="companyIcon"
                    src={file ? URL.createObjectURL(file!) : src ?? ""}
                  />
                )}
              </CardCover>
            )}
          </Card>
        )}
        {/* Image policy */}
        <ImagePolicy file={file ?? null} />
      </Stack>

      <UploadButton
        title={buttonTitle ?? t("commons.upload")}
        onChange={(file: File | undefined) => {
          if (file) handleFile?.(file);
        }}
      />
    </Stack>
  );
}
