import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/joy";
import { AddressItem } from "./AddressItem";
import { AddressService } from "../../../services/address.service";
import { useMutation } from "@tanstack/react-query";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import AddressModal from "./AddressModal";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../../components/Error";
import { useTranslation } from "react-i18next";

export function AddressList() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState<IAddressResponse[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<IAddressResponse>();

  async function getAddresses() {
    const res = await AddressService.getAddressByAccount();
    if (res) setAddresses(res);
  }

  const deleteMutation = useMutation({
    mutationFn: AddressService.deleteAddress,
    onSuccess: () => {
      getAddresses();
      setLoading(false);
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  useEffect(() => {
    getAddresses();
  }, []);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="flex justify-between items-center">
        <Typography level="title-lg" textColor={"#408565"}>
          {t("profile.address.myAddress")}
        </Typography>
        <Button
          startDecorator={<Add />}
          onClick={() => {
            setShowModal(true);
          }}
          size="sm"
          variant="linkz-filled-primary"
          color="primary"
        >
          {t("profile.address.addNewAddress")}
        </Button>
      </div>
      <div className="mt-8 flex flex-col space-y-4 overflow-y-auto">
        {addresses.length > 0 ? (
          <>
            {addresses.map((address) => (
              <AddressItem
                name={address.fullName}
                phone={`+${address?.phoneNumberCountryCode} ${address?.phoneNumber}`}
                address={address.address}
                title={address.title}
                isDefault={address.defaultAddress}
                onDelete={() => {
                  setLoading(true);
                  deleteMutation.mutate(address.id);
                }}
                onEdit={() => {
                  setSelectedAddress(address);
                  setShowModal(true);
                }}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
      {showModal && (
        <AddressModal
          show={showModal}
          selectedAddress={selectedAddress}
          onClose={() => {
            setShowModal(false);
          }}
          onSuccess={() => {
            getAddresses();
          }}
        />
      )}
      <LoadingModal isLoading={loading} />
    </React.Fragment>
  );
}
