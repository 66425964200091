import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import startsWith from "lodash.startswith";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";
import UploadImage from "../../components/UploadImage";
import { animateSelectStyle } from "../../styles/select";
import { ROLE_TYPES, RoleType, RoleTypeTranslate } from "../../types/role-type";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
} from "../../utils/constants";
import { matchesFormat } from "../../utils/misc";
import useEmployeeContainer from "./employeeContainer";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";

const inputStyle = { backgroundColor: "white" };

export default function EmployeeForm({ isEdit = false }) {
  const {
    errors,
    profileImage,
    success,
    loading,
    businessResources,
    setResource,
    setResourcePermissions,
    getValues,
    register,
    watch,
    handleSubmit,
    setProfileImage,
    onSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useEmployeeContainer({ isEdit });

  const { t } = useTranslation();
  console.log(errors);
  console.log(getValues("resourcePermissions"));

  const renderResourcePermissionsForm = (category: string) => {
    return (
      <div key={category}>
        {/* // TODO: figure out the categories and map the localization */}
        <Typography>
          {t("manage.employee.permission.title")} ({category})
        </Typography>
        {businessResources
          .filter((r: any) => r.category === category)
          .map((resource: any) => (
            <div
              style={{
                padding: "12px 0",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <Box
                key={resource.id}
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <Checkbox
                    onChange={(event) => {
                      setResource(resource.id, event.target.checked, false);
                    }}
                    label={resource.name}
                    checked={
                      watch("resourcePermissions")?.filter(
                        (r: any) => r.resource === resource.id
                      ).length > 0
                    }
                  />
                </FormControl>
                <FormControl>
                  <Select
                    indicator={<KeyboardArrowDown />}
                    sx={{ ...animateSelectStyle }}
                    placeholder="Permissions"
                    value={
                      watch("resourcePermissions")?.find(
                        (r: any) => r.resource === resource.id
                      )?.permissions.length
                    }
                    onChange={(_, value) => {
                      setResourcePermissions(resource.id, value == 1);
                    }}
                  >
                    {[
                      {
                        value: 1,
                        label: "Can View",
                      },
                      {
                        value: 4,
                        label: "Can Edit",
                      },
                    ].map((permission) => (
                      <Option
                        value={permission.value}
                        key={`${permission.label}-${resource.id}`}
                      >
                        {permission.label}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Divider />
            </div>
          ))}
      </div>
    );
  };
  return (
    <form
      id="employee-form"
      onSubmit={handleSubmit(onSubmit)}
      className="w-[90vw] lg:w-[100%]"
    >
      <Stack marginTop={5} direction={"column"} gap={5}>
        <UploadImage
          file={profileImage}
          handleFile={(file) => {
            if (file) setProfileImage(file);
          }}
          buttonTitle={t("manage.employee.uploadBtn")}
          renderImage={(file) => (
            <Avatar
              variant="outlined"
              color="primary"
              sx={{ width: 100, height: 100 }}
            >
              {file && <img src={URL.createObjectURL(file)} alt="profile" />}
            </Avatar>
          )}
        />
        <FormControl error={!!errors.fullName}>
          <FormLabel>
            <Typography textColor={"neutral.600"}>
              {t("manage.employee.fullNameLabel")}
            </Typography>
          </FormLabel>
          <Input
            placeholder={t("manage.employee.fullNameLabel")}
            sx={{ ...inputStyle }}
            {...register("fullName")}
          />
          {errors.fullName?.message && (
            <FormHelperText>{errors.fullName?.message}</FormHelperText>
          )}
        </FormControl>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", lg: "row" }}
          gap={2}
        >
          <FormControl sx={{ flex: 1 }} error={!!errors.email}>
            <FormLabel>
              <Typography textColor={"neutral.600"}>
                {t("manage.employee.emailLabel")}
              </Typography>
            </FormLabel>
            <Input
              disabled={isEdit}
              type="email"
              placeholder={t("manage.employee.emailLabel")}
              sx={{ ...inputStyle }}
              {...register("email")}
            />
            {errors.email?.message && (
              <FormHelperText>{errors.email?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flex: 1 }} error={!!errors.phoneNumber}>
            <FormLabel>
              <Typography textColor={"neutral.600"}>
                {t("manage.employee.phoneNumberLabel")}
              </Typography>
            </FormLabel>
            <PhoneInput
              onlyCountries={PhNoWhiteListedCountries}
              enableTerritories={false}
              masks={{
                my: "..-....-....",
                sg: "....-....",
                th: "... ... ...",
                id: "... ... ... ...",
                mm: ". .... .... .....",
                au: "... ... ...",
              }}
              country={BusinessSessionHelper.GetBusinessCountryID().toLocaleLowerCase()}
              isValid={(inputNumber, meta: any, countries) => {
                if (inputNumber === "" || inputNumber === meta.dialCode) {
                  return true;
                }

                if (inputNumber.toString().startsWith(`${meta.dialCode}0`)) {
                  if (!errors.phoneNumber?.message) {
                    setError("phoneNumber", {
                      message: t("commons.error.form.mobileInvalidStartZero"),
                    });
                  }
                  return false;
                }

                const result = (countries || []).some((country: any) => {
                  if (
                    startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber)
                  ) {
                    const iso2: CountryCode = meta.iso2 as CountryCode;
                    const format = PhNoFormat[iso2];
                    if (!format) return false;
                    return matchesFormat(
                      inputNumber,
                      format,
                      country.countryCode
                    );
                  }
                  return false;
                });

                if (!result && !errors.phoneNumber?.message) {
                  setError("phoneNumber", {
                    message: t("commons.error.form.mobileInvalid"),
                  });
                } else if (result && !!errors.phoneNumber?.message) {
                  if (errors.phoneNumber.type === "manual") {
                    return false;
                  }
                  clearErrors("phoneNumber");
                  trigger("phoneNumber");
                }
                return result;
              }}
              inputStyle={{
                height: "42px",
                width: "100%",
                fontSize: "16px",
              }}
              onChange={(phone, meta: any) => {
                setValue("phoneNumberCountryCode", meta.dialCode);
                setValue(
                  "phoneNumber",
                  phone.replace(new RegExp(meta.dialCode), "")
                );
              }}
            />
            {errors.phoneNumber?.message && (
              <FormHelperText>{errors.phoneNumber?.message}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <FormControl error={!!errors.role}>
          <FormLabel>
            <Typography textColor={"neutral.600"}>
              {t("manage.employee.roleLabel")}
            </Typography>
          </FormLabel>
          <Select
            indicator={<KeyboardArrowDown />}
            value={watch("role")}
            placeholder={t("manage.employee.roleLabel")}
            sx={{ ...animateSelectStyle }}
            onChange={(_, value) => {
              {
                setValue("role", value as RoleType);
                if ((value as RoleType) === RoleType.BusinessAdmin) {
                  businessResources.forEach((r: any) => {
                    setResource(r.id, true, false);
                  });
                } else {
                  businessResources
                    .filter(
                      (r: any) =>
                        !["employee", "role-permission"].includes(r.code)
                    )
                    .forEach((r: any) => {
                      setResource(r.id, true, false);
                    });
                }
              }
            }}
          >
            {ROLE_TYPES.map((role) => (
              <Option value={role} key={role}>
                {t(RoleTypeTranslate[role])}
              </Option>
            ))}
          </Select>
          {errors.role?.message && (
            <FormHelperText>{errors.role?.message}</FormHelperText>
          )}
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
            alignItems: "start",
            gap: { xs: 2, lg: 5 },
          }}
        >
          <Box flex={1}>
            {[...new Set(businessResources.map((r: any) => r.category))]
              .filter((_, i) => i % 2 == 0)
              .map((category) => renderResourcePermissionsForm(category))}
          </Box>
          <Box flex={1}>
            {[...new Set(businessResources.map((r: any) => r.category))]
              .filter((_, i) => i % 2 == 1)
              .map((category) => renderResourcePermissionsForm(category))}
          </Box>
        </Box>
        <Stack
          justifyContent={"center"}
          direction={"row"}
          alignItems={"stretch"}
        >
          <Button
            variant="solid"
            type="submit"
            sx={{
              height: 48,
              maxHeight: 48,
              bottom: 0,
              width: 300,
              maxWidth: 300,
            }}
          >
            {isEdit
              ? t("manage.employee.updateUserBtn")
              : t("manage.employee.addUserBtn")}
          </Button>
        </Stack>
      </Stack>
      <SuccessModal
        open={success}
        title={t("commons.successModalTitle")}
        content={`${
          isEdit
            ? t("manage.employee.successUpdateUserDesc")
            : t("manage.employee.successAddUserDesc")
        }.`}
      />
      <LoadingModal isLoading={loading} />
    </form>
  );
}
