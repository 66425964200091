import PageTitleBox from "../../components/PageTitleBox";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CreateCatalogueSteps from "./components/Form/CreateCatalogueSteps";
import { Sheet, Button, Divider, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import {
  DELIVERY_FEES_OPTIONS_ENUM,
  PaymentFlowTypeEnum,
} from "../../types/order";
import { useNavigate, useParams } from "react-router-dom";
import { theme } from "../../components/Theme";
import {
  CreateCatalogType,
  CustomCreateCatalogType,
  TaxIDType,
} from "../../types/catalog";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { If, IfElse } from "../../components/Condition";
import { TaxService } from "../../services/tax.service";
import { useAuthStore } from "../../store/session";
import { PlusIcon } from "@heroicons/react/24/outline";
import ChangeCurrencyModal from "../Inventory/components/ChangeCurrencyModal";
import PreviewTable from "./components/Form/PreviewTable";
import NotFound from "../NotFound/NotFound";
import useContainer from "./useContainer";
import CatalogInfoSection from "./components/Form/CatalogInfoSection";
import DeliveryInfoSection from "./components/Form/DeliveryInfoSection";
import { CatalogService } from "../../services/catalog.service";
import { ItemService } from "../../services/item.service";
import useFormContainer from "./useFormContainer";
import { ItemType } from "../../types/item";

const EditCatalogue = () => {
  const { t } = useTranslation();

  const { catalogId } = useParams();

  const navigate = useNavigate();

  const { session } = useAuthStore();

  const [catalog, setCatalog] = useState<CustomCreateCatalogType>(
    JSON.parse(localStorage.getItem("edit-catalog") || "{}")
  );
  const [currencyModal, setCurrencyModal] = useState({
    show: false,
    currency: catalog?.currency,
  });
  const [isStepValid, setIsStepValid] = useState(false);
  const [inventoryItems, setInventoryItems] = useState<ItemType[]>([]);

  const methods = useForm<CreateCatalogType>({
    defaultValues: {
      deliveryFeeConfig: {
        feeValues: (catalog &&
          catalog.deliveryFeeConfig &&
          catalog.deliveryFeeConfig.feeValues.map((v) => ({
            upToCount: v.upToCount,
            value: v.value,
          }))) || [{ upToCount: 0, value: 0 }],
        feeType:
          (catalog &&
            catalog.deliveryFeeConfig &&
            catalog.deliveryFeeConfig.feeType) ||
          DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT,
      },
      catalogName: catalog?.catalogName ?? "",
      // taxId:
      //   catalog.taxId && typeof catalog.taxId == "string"
      //     ? catalog.taxId
      //     : null,
      taxId: catalog.taxId ? catalog.taxId.id : null,
      periodStartDate: catalog?.periodStartDate || null,
      periodEndDate: catalog?.periodEndDate || null,
      paymentFlowType:
        catalog.catalogType == "PUBLIC"
          ? PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
          : catalog?.paymentFlowType,
      currency: catalog.currency,
      items:
        catalog.items &&
        catalog.items.map((item) => {
          console.log("ITEMS TYPE", typeof item);
          return typeof item === "object" ? item : { itemId: item };
        }),
    },
  });
  const { setValue, watch, handleSubmit, formState, reset } = methods;

  const { fetchOneCatalog } = useContainer();
  const {
    handleValidateStep,
    handleSaveData,
    handleGoToNext,
    handleGotoPrev,
    currentStep,
    setCurrentStep,
    nationalTaxes,
    setNationalTaxes,
  } = useFormContainer(methods, catalog, setCatalog);

  // watced values depending on currentStep
  const watchValidation =
    currentStep === 1
      ? watch([
          "catalogName",
          "paymentFlowType",
          "currency",
          "periodStartDate",
          "periodEndDate",
        ])
      : currentStep === 2
      ? watch("items")
      : watch("deliveryFeeConfig.feeType");

  /**
   * get national taxes based on business's country
   */
  async function getAndSetNationalTaxes() {
    try {
      const res = await TaxService.getTaxesOption(
        session?.activeBusiness.companyCountry as string
      );

      setNationalTaxes(res);
      setValue(
        "taxId",
        res.find((t: TaxIDType) =>
          catalog.taxId ? t.id === catalog.taxId.id : t.taxLabel === "NONE"
        )?.id || null
      );
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAndSetNationalTaxes();
  }, [session?.activeBusiness.id]);

  /**
   * fetch catalog based on catalogId, assign to catalog state
   * and reset the form with new formatted values
   */
  useEffect(() => {
    console.log("WHERE IS CATALOG?", { catalogId, catalog });
    const isCacheValid = catalog.id === catalogId;
    if (catalogId && !isCacheValid) {
      (async () => {
        const catalogResponse = await fetchOneCatalog(catalogId);

        if (catalogResponse) {
          // fomat CatalogType to CustomCreateCatalogType
          const formattedCatalog: CustomCreateCatalogType = {
            ...catalogResponse,
            step: 3,
            importMethod: "inventory" as "inventory" | "excel",
            items: catalogResponse.items.map((item) => item.inventoryItem),
          };

          console.log(
            "WHAT IS THE RESULT OF THIS",
            formattedCatalog.items.map((item) => {
              return { itemId: item };
            })
          );

          // reset the form with defaultValues
          reset({
            deliveryFeeConfig: {
              feeValues: (formattedCatalog &&
                formattedCatalog.deliveryFeeConfig &&
                formattedCatalog.deliveryFeeConfig.feeValues.map((v) => ({
                  upToCount: v.upToCount,
                  value: v.value,
                }))) || [{ upToCount: 0, value: 0 }],
              feeType:
                (formattedCatalog &&
                  formattedCatalog.deliveryFeeConfig &&
                  formattedCatalog.deliveryFeeConfig.feeType) ||
                DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT,
            },
            catalogName: formattedCatalog?.catalogName ?? "",
            // taxId:
            //   formattedCatalog.taxId &&
            //   typeof formattedCatalog.taxId == "string"
            //     ? formattedCatalog.taxId
            //     : null,
            taxId: formattedCatalog.taxId ? formattedCatalog.taxId.id : null,
            periodStartDate: formattedCatalog?.periodStartDate || null,
            periodEndDate: formattedCatalog?.periodEndDate || null,
            paymentFlowType:
              formattedCatalog.catalogType == "PUBLIC"
                ? PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
                : formattedCatalog?.paymentFlowType,
            currency: formattedCatalog.currency,
            items: formattedCatalog.items.map((item) => {
              return { itemId: item };
            }),
          });

          // save to localStorage
          localStorage.setItem(
            "edit-catalog",
            JSON.stringify(formattedCatalog)
          );

          setCatalog(formattedCatalog);
        }
      })();
    }
  }, [catalogId]);

  /**
   * remove localStorage edit-catalog item when unmount
   */
  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem("edit-catalog");
  //   };
  // }, []);

  /**
   * will get the first error field and setCurrentStep
   * to the corresponding @const stepFields
   */
  useEffect(() => {
    // fields need to be validated in each step
    const stepFields = [
      [
        "catalogName",
        "paymentFlowType",
        "currency",
        "periodStartDate",
        "periodEndDate",
      ],
      ["items"],
      ["feeValues"],
    ];

    console.log("formState.errors", formState.errors);
    const firstError = Object.keys(formState.errors)[0];
    if (!firstError) return;

    for (let i = 0; i < stepFields.length; i++) {
      if (stepFields[i].includes(firstError)) {
        setCurrentStep(i + 1);
        break;
      }
    }
  }, [formState]);

  /**
   * validate step every time watchedData changes
   */
  useEffect(() => {
    setIsStepValid(handleValidateStep(currentStep));
  }, [watchValidation]);

  /**
   * validate form data manually before creating catalog
   * @param data formValues
   */
  const onSubmit = async (data: CreateCatalogType) => {
    console.log("SUBMIT DATA", data);

    if (!catalogId) {
      return;
    }

    // validate each step
    for (let i = 0; i < 3; i++) {
      if (!handleValidateStep(i + 1)) {
        setCurrentStep(i + 1);
        return;
      }
    }

    const catalogPayload: CreateCatalogType = {
      catalogName: data.catalogName,
      catalogType: catalog.catalogType,
      paymentFlowType: data.paymentFlowType,
      currency: data.currency,
      deliveryFeeConfig: {
        feeType: data.deliveryFeeConfig.feeType,
        feeValues: data.deliveryFeeConfig.feeValues,
      },
      // feeType: data.deliveryFeeConfig.feeType,
      // feeValues: data.deliveryFeeConfig.feeValues,
      taxId: data.taxId,
      tags: { tags: [] },
      hidePrice: catalog.hidePrice,
      business: catalog?.business.id,
    };

    const itemsPayload = data.items?.map((item) => item.itemId);

    try {
      const catalogResponse = await CatalogService.updateCatalogue({
        data: catalogPayload,
        catalogId,
      });

      console.log("ITEMS PAYLOAD", itemsPayload);

      if (catalogResponse?.id) {
        await ItemService.updateCatalogItems({
          itemIds: itemsPayload ?? [],
          catalogId: catalogResponse.id,
        });

        localStorage.removeItem("edit-catalog");
        window.location.href = `/client/catalogues/${catalogResponse.id}/items`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!catalog) {
    return <NotFound />;
  }

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        py: "32px",
      }}
    >
      <PageTitleBox
        title={t("catalog.createCatalog.pageTitle")}
        showOnMobile={false}
      />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={"column"} gap={"32px"}>
            {/* steps */}
            <CreateCatalogueSteps
              currentStep={currentStep}
              validation={handleValidateStep}
              onClick={(step: number) => {
                if (step > catalog.step) {
                  return;
                }

                handleSaveData();
                setCurrentStep(step);
              }}
            />

            <Divider
              sx={{
                width: "100dvw",
                display: { xs: "block", lg: "none" },
                ml: -2,
                backgroundColor: theme.palette.neutral[200],
              }}
            />

            {/* catalog info */}
            <If condition={currentStep === 1}>
              {catalog && (
                <CatalogInfoSection
                  catalog={catalog}
                  nationalTaxes={nationalTaxes}
                />
              )}
            </If>

            {/* items preview */}
            <If condition={currentStep === 2}>
              <Stack
                direction={"column"}
                sx={{
                  borderWidth: { xs: 0, md: "1px" },
                  borderColor: theme.palette.neutral[300],
                  borderRadius: "8px",
                  py: "32px",
                  px: { xs: "8px", md: "24px" },
                  gap: "24px",
                }}
              >
                <Stack direction={"row"} justifyContent={"space-between"}>
                  {/* title and add item button */}
                  <Stack direction={"column"} gap={"4px"}>
                    <Typography
                      level="text-xl-bold"
                      sx={{ color: theme.palette.common.black }}
                    >
                      {t("catalog.createCatalog.steps.2")}
                    </Typography>

                    <If condition={catalog.items?.length > 0}>
                      <Typography
                        level="text-xs-medium"
                        sx={{ color: theme.palette.neutral[600] }}
                      >
                        {t("catalog.createCatalog.steps.subtitle2")}
                      </Typography>
                    </If>
                  </Stack>

                  <If condition={catalog.importMethod === "inventory"}>
                    <Button
                      id="catalog-edit-addItems-button"
                      variant="linkz-outlined-primary"
                      startDecorator={<PlusIcon width={20} height={20} />}
                      onClick={() => {
                        navigate(`/client/catalogues/add-items`);
                      }}
                      sx={{
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      {t("catalog.createCatalog.addItemToCatalogBtn")}
                    </Button>
                  </If>
                </Stack>

                <IfElse
                  condition={catalog.items?.length > 0}
                  ifBlock={
                    <PreviewTable
                      catalog={catalog}
                      inventoryItems={inventoryItems}
                      setInventoryItems={setInventoryItems}
                    />
                  }
                  elseBlock={<></>}
                />
              </Stack>
            </If>

            {/* delivery fee */}
            <If condition={currentStep === 3}>
              {catalog && <DeliveryInfoSection catalog={catalog} />}
            </If>

            {/* buttons */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                position: "relative",
                justifyContent: { md: "right" },
                alignItems: "center",
                width: "100%",
                py: { xs: "16px", md: "24px" },
                px: 0,
                gap: { xs: "8px", md: "16px" },
              }}
            >
              <Divider
                sx={{
                  width: "100dvw",
                  position: "absolute",
                  display: { xs: "none", lg: "block" },
                  top: 0,
                  left: "-272px",
                  backgroundColor: theme.palette.others.disabled,
                }}
              />

              {/* prev button, next button sm */}
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  justifyContent: { xs: "space-between", md: "left" },
                }}
              >
                <If condition={currentStep > 1}>
                  <Button
                    id="catalog-edit-prev-button"
                    variant="linkz-outlined-primary"
                    sx={{
                      my: "auto",
                      minWidth: {
                        xs: "48%",
                        lg: "100px",
                      },
                    }}
                    startDecorator={<ArrowLeftIcon width={18} height={18} />}
                    onClick={handleGotoPrev}
                  >
                    {t("commons.prevBtn")}
                  </Button>
                </If>

                <If condition={currentStep < 3}>
                  <Button
                    id="catalog-edit-next-button"
                    sx={{
                      display: { xs: "flex", md: "none" },
                      my: "auto",
                      minWidth: {
                        xs: currentStep > 1 ? "48%" : "100%",
                        lg: "100px",
                      },
                    }}
                    disabled={!isStepValid}
                    endDecorator={<ArrowRightIcon width={20} height={20} />}
                    onClick={handleGoToNext}
                  >
                    {t("commons.nextBtn")}
                  </Button>
                </If>

                <If condition={currentStep === 3}>
                  <Button
                    id="catalog-edit-submit-mobile"
                    sx={{
                      display: { xs: "flex", md: "none" },
                      my: "auto",
                      minWidth: "48%",
                    }}
                    disabled={!isStepValid}
                    type="submit"
                  >
                    {t("commons.saveChangesBtn")}
                  </Button>
                </If>
              </Stack>

              {/* next button lg, cancel button, submit button */}
              <Stack
                direction={"row"}
                sx={{
                  gap: "16px",
                  ml: { md: "auto" },
                  width: "100%",
                  justifyContent: { xs: "center", md: "right" },
                }}
              >
                <Button
                  id="catalog-edit-cancel-button"
                  variant="linkz-outlined-primary"
                  onClick={() => {
                    localStorage.removeItem("edit-catalog");
                    navigate(`/client/catalogues/${catalogId}/items`);
                  }}
                  sx={{
                    my: "auto",
                    minWidth: { xs: "100%", lg: "68px" },
                  }}
                >
                  {t("commons.cancelBtn")}
                </Button>

                <If condition={currentStep < 3}>
                  <Button
                    id="catalog-edit-next-button"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      my: "auto",
                      minWidth: "100px",
                    }}
                    disabled={!isStepValid}
                    endDecorator={<ArrowRightIcon width={20} height={20} />}
                    onClick={handleGoToNext}
                  >
                    {t("commons.nextBtn")}
                  </Button>
                </If>

                <If condition={currentStep === 3}>
                  <Button
                    id="catalog-edit-submit-desktop"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      my: "auto",
                      minWidth: "100px",
                    }}
                    disabled={!isStepValid}
                    type="submit"
                  >
                    {t("commons.saveChangesBtn")}
                  </Button>
                </If>
              </Stack>
            </Stack>
          </Stack>
        </form>

        {/* modal */}
        <ChangeCurrencyModal
          show={currencyModal.show}
          onClick={() => {
            // setValue("currency", currencyModal.currency);
            setCurrencyModal({
              show: false,
              currency: currencyModal.currency,
            });
          }}
          onClose={() => {
            setCurrencyModal({
              show: false,
              currency: currencyModal.currency,
            });
          }}
        />
      </FormProvider>
    </Sheet>
  );
};

export default EditCatalogue;
