import { Typography } from "@mui/joy";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Coin from "../../components/Icons/Coin";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import i18next from "../../i18n/config";
import { CreditsSection } from "./components/CreditsSection/CreditsSection";
import { TopupSuccessModal } from "./components/TopupSuccessModal";
import Transactions from "./components/Transactions/Transactions";
import useContainer from "./useContainer";
const activeBorderClass =
  " border-b-4 border-[#499873] font-bold text-[#499873]";
const inactiveBorderClass =
  "border-b border-[rgba(99, 107, 116, 0.3)] text-[#0B0D0E]";

const Credit = () => {
  const { t } = useTranslation();
  const { activeTab, data, isPending, showSuccessModal, loading } =
    useContainer();
  if (isPending) {
    return <LoadingModal isLoading={isPending || loading} />;
  }
  return (
    <>
      <div className="flex flex-col gap-y-8 pt-4">
        <div
          className={`flex flex-col p-4 border ${
            +data?.credits < 1 ? "border-[#C41C1C]" : "border-[#1f7a1f]"
          } rounded-lg gap-2`}
          style={{
            boxShadow: "box-shadow: 0px 2px 4px 0px rgba(21, 21, 21, 0.08)",
          }}
        >
          <div className="flex gap-4 justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <Coin />
              <Typography
                textColor={"text.primary"}
                fontWeight={400}
                fontSize={14}
              >
                {t("credits.title")}
              </Typography>
            </div>
            <Typography
              fontWeight={600}
              textColor={+data?.credits < 1 ? "danger.500" : "text.primary"}
              fontSize={20}
            >
              {data?.credits}
            </Typography>
          </div>
          <Typography fontWeight={400} fontSize={16}>
            {t("commons.dateUpdated")}{" "}
            {dayjs(data?.updatedAt)
              .locale(i18next.resolvedLanguage ?? "en")
              .format("ddd D MMM hh:mm A")}{" "}
          </Typography>
        </div>
        {/* Credits / History Section */}
        <div>
          <div className="flex items-center w-[90vw] md:w-full">
            <Link
              to="/client/credit?activeTab=credits"
              className={`text-center cursor-pointer w-[200px] py-6  ${
                activeTab === "credits"
                  ? activeBorderClass
                  : inactiveBorderClass
              }`}
            >
              {t("credits.tabs.credits")}
            </Link>
            <Link
              to="/client/credit?activeTab=history"
              className={`text-center cursor-pointer w-[200px] py-6 ${
                activeTab === "history"
                  ? activeBorderClass
                  : inactiveBorderClass
              }`}
            >
              {t("credits.tabs.history")}
            </Link>
          </div>
        </div>
        {/* Special Section */}
        {activeTab === "credits" ? <CreditsSection /> : <Transactions />}
      </div>
      <TopupSuccessModal open={showSuccessModal} />
    </>
  );
};

export default Credit;
