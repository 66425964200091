import { Button } from "@mui/joy";
// import XLSX from "xlsx-js-style";
import { useTranslation } from "react-i18next";
// import { cellStyle, secondaryCellStyle } from "../../../utils/excel";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ItemService } from "../../../services/item.service";
import React from "react";

type Props = {
  icon?: React.ReactNode;
  title?: string;
  id?: string;
};

const DownloadTemplateButton = ({ icon, title, id }: Props) => {
  const { t } = useTranslation();
  // const handleExcelDownload = (event: any) => {
  //   event.stopPropagation();
  //   const book = XLSX.utils.book_new();
  //   const cols = [
  //     {
  //       v: "SKU*",
  //       t: "s",
  //       s: cellStyle,
  //     },
  //     {
  //       v: "Product Name*",
  //       t: "s",
  //       s: cellStyle,
  //     },
  //     {
  //       v: "Category*",
  //       t: "s",
  //       s: cellStyle,
  //     },
  //     {
  //       v: "Currency*",
  //       t: "s",
  //       s: cellStyle,
  //     },
  //     {
  //       v: "Price*",
  //       t: "s",
  //       s: cellStyle,
  //     },
  //     {
  //       v: "Quantity*",
  //       t: "s",
  //       s: cellStyle,
  //     },
  //     {
  //       v: "Base UOM*",
  //       t: "s",
  //       s: cellStyle,
  //     },
  //     {
  //       v: "uom2",
  //       t: "s",
  //       s: secondaryCellStyle,
  //     },
  //     {
  //       v: "conversion2",
  //       t: "s",
  //       s: secondaryCellStyle,
  //     },
  //     {
  //       v: "uom3",
  //       t: "s",
  //       s: secondaryCellStyle,
  //     },
  //     {
  //       v: "conversion3",
  //       t: "s",
  //       s: secondaryCellStyle,
  //     },
  //   ];
  //   const sheet = XLSX.utils.aoa_to_sheet([cols]);
  //   sheet["!cols"] = [
  //     { width: 20 }, // sku
  //     { width: 32 }, // name
  //     { width: 12 }, // category
  //     { width: 10 }, // currency
  //     { width: 20 }, // price
  //     { width: 10 }, // quantity
  //     { width: 20 }, // base uom
  //     // { width: 20 }, // uom1
  //     // { width: 20 }, // conversion1
  //     { width: 20 }, // uom2
  //     { width: 20 }, // conversion2
  //     { width: 20 }, // uom3
  //     { width: 20 }, // conversion3
  //   ];
  //   XLSX.utils.book_append_sheet(book, sheet, "new_catalogue");
  //   XLSX.writeFile(book, "catalogue-template.xlsx");
  // };

  const handleExcelDownload = async (event: any) => {
    event.stopPropagation();
    await ItemService.exportInventoryItems();
  };

  return (
    <Button
      id={id ?? "inventory-export-button"}
      variant="linkz-outlined-primary"
      startDecorator={icon ?? <PlusIcon width={20} height={20} />}
      className="min-w-[14.12rem] whitespace-nowrap"
      onClick={handleExcelDownload}
    >
      {title ?? t("catalog.downloadTemplateBtn")}
    </Button>
  );
};

export default DownloadTemplateButton;
