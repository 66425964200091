import { CatalogType } from "../../types/catalog";
import { PaginationType } from "../../types/item";
import { useCallback, useState } from "react";
import { InventoryService } from "../../services/inventory.service";
import { getActiveBusiness } from "../../store/session";
import i18next from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CatalogService } from "../../services/catalog.service";

const useContainer = () => {
  //#region ----- local states
  const [catalogs, setCatalogs] = useState<CatalogType[]>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 10,
    max: 1,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [excelErrors, setExcelErrors] = useState<any[]>([]);
  const [showFailModal, setShowFailModal] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, message: "" });

  const { catalogId } = useParams();

  const navigate = useNavigate();

  //#region ----- misc
  const fetchAllCatalogs = useCallback(
    ({
      businessId,
      catalogType,
      paging = true,
      limit = 50,
      offset = 0,
      searchTerm,
      isReset = true,
      publicCatalogPeriodType = "ALL",
    }: {
      businessId: string;
      catalogType: "PUBLIC" | "PRIVATE";
      paging?: boolean;
      limit?: number;
      offset?: number;
      searchTerm?: string | null;
      isReset?: boolean;
      publicCatalogPeriodType?: string;
    }) => {
      InventoryService.getAllCatalogsInventory({
        businessId,
        catalogType,
        paging,
        limit,
        offset,
        searchTerm,
        publicCatalogPeriodType,
      })
        .then((res) => {
          if (isReset) {
            setCatalogs(res.data);
            setHasMore(true);
          } else {
            setCatalogs((prev) => [...prev, ...res.data]);
          }

          if (res.pagination) {
            setPagination(res.pagination);
          }
          if (res.data.length === 0) {
            setHasMore(false);
          }

          console.log(res);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [catalogs, hasMore, pagination]
  );

  const fetchOneCatalog = useCallback(async (catalogId: string) => {
    setIsLoading(true);

    try {
      const catalogResponse = await InventoryService.getOneCatalog({
        catalogId,
        withItems: true,
      });

      console.log("CATALOG RESPONSE", catalogResponse);
      setIsLoading(false);

      return catalogResponse;
    } catch (error: any) {
      console.error("Error finding catalog: ", error);
      window.alert(error.message);
      setIsLoading(false);
    }
  }, []);

  const removeCatalog = useCallback(
    (
      catalogId: string,
      catalogType: "PUBLIC" | "PRIVATE",
      status: string | null
    ) => {
      setIsLoading(true);
      InventoryService.removeCatalog({
        catalogId,
      })
        .then((res) => {
          setIsLoading(false);
          if (res) {
            setAlertMessage(i18next.t("catalog.catalogRemoved"));
            setShowAlert(true);
            fetchAllCatalogs({
              businessId: getActiveBusiness()?.id ?? "",
              limit: 10,
              offset: 0,
              catalogType: catalogType,
              isReset: true,
              publicCatalogPeriodType: status?.toUpperCase() ?? "ALL",
            });
          }
        })
        .catch((err) => console.error(err));
    },
    []
  );

  //#region ----- handler functions
  /**
   * upload excel file to backend API, filter out errors
   * @param acceptedFiles
   */
  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setIsLoading(true);

      try {
        const importResponse = await CatalogService.importCatalogItems(
          acceptedFiles[0]
        );
        console.log("IMPORT RESPONSE", importResponse);

        const responseInvalidItems = importResponse.data.invalidItems;
        if (responseInvalidItems && responseInvalidItems.length > 0) {
          // find items with error that's not Duplicate SKU error code
          const invalidItemErrors = responseInvalidItems.filter(
            (invalidItem) => {
              const nonDuplicateError = invalidItem.errors?.find(
                (error) => error.error !== "Duplicate SKU"
              );

              return !!nonDuplicateError;
            }
          );

          // put the errors in excelErrors state and show fail modal
          const itemErrors = invalidItemErrors.map((invalidItemError) => {
            const formattedErrors = invalidItemError.errors?.map((error) => {
              const fieldNames = error.fields.map((field) => {
                return error.error === "Duplicate SKU"
                  ? field
                  : i18next.t(`catalog.items.${field}`);
              });

              return {
                code: error.error,
                message: i18next.t("catalog.import.error.errorOnField", {
                  code: error.error,
                  field: fieldNames.join(", "),
                }),
              };
            });
            return {
              name: `row ${invalidItemError.row}`,
              errors: formattedErrors,
            };
          });
          setExcelErrors(itemErrors);
          setShowFailModal(true);

          // exit if there are other errors aside from Duplicate SKU
          if (itemErrors.length > 0) {
            setIsLoading(false);
            return;
          }

          // set invalid items to localStorage to access in CatalogueDuplicateSKU.tsx
          localStorage.setItem(
            "catalog-import-invalid-items",
            JSON.stringify({
              invalidItems: importResponse.data.invalidItems,
              totalItems: importResponse.data.totalItems,
            })
          );

          const catalogPath = catalogId ? `${catalogId}/` : "";
          navigate(
            `/client/catalogues/${catalogPath}export-import/duplicate/${
              importResponse.data.draftId
            }?method=${catalogId ? "import-catalog" : "create-catalog"}`,
            {}
          );
        } else {
          const totalItems =
            (importResponse.data?.added ?? 0) +
            (importResponse.data?.updated ?? 0);
          const message = i18next.t(
            importResponse.data?.updated ?? 0 > 0
              ? "catalog.import.success.updatedCatalog"
              : "catalog.import.success.addCatalog",
            {
              totalItems: totalItems,
            }
          );

          // only success modal when catalogId exist
          if (!!catalogId) {
            setAlertModal({
              show: true,
              message,
            });
          }

          setTimeout(async () => {
            // redirect to view catalogues item if there is catalogId
            if (!!catalogId && importResponse.data.draftId) {
              const submitResponse = await CatalogService.submitCatalogDraft(
                importResponse.data.draftId,
                {
                  catalogId: catalogId,
                }
              );

              if (submitResponse) {
                window.location.href = `/client/catalogues/${catalogId}/items`;
              }

              return;
            }

            const cachedCatalogData = JSON.parse(
              localStorage.getItem("new-catalog") || "{}"
            );

            if (!cachedCatalogData) {
              window.alert("Cache does not exist");
              return;
            }

            // set draftId to cached data to fetch the draft in PreviewTable.tsx
            const newCatalogData = {
              ...cachedCatalogData,
              draftId: importResponse.data.draftId,
            };
            localStorage.setItem("new-catalog", JSON.stringify(newCatalogData));

            setIsLoading(false);
            window.location.href = `/client/catalogues/create?type=${cachedCatalogData.catalogType}`;
          }, 3000);
        }
      } catch (error: any) {
        console.error("Error uploading file:", error);
        alert(error.message);
        setIsLoading(false);
      }
    }
  };

  //   const handlePaging = (action: string | number) => {
  //     const pagingConfig = {
  //       limit: 50,
  //       offset: 0,
  //     };

  //     if (typeof action === "string") {
  //       switch (action) {
  //         case "first":
  //           pagingConfig.offset = 0;
  //           break;

  //         case "previous":
  //           pagingConfig.offset = pagination.limit * (pagination.current - 2);
  //           break;

  //         case "next":
  //           pagingConfig.offset = pagination.limit * pagination.current;
  //           break;

  //         case "last":
  //           pagingConfig.offset =
  //             pagination.max * pagination.limit - pagination.limit;
  //           break;
  //       }
  //     } else {
  //       pagingConfig.offset = (action - 1) * pagination.limit;
  //     }

  //     if (catalog) {
  //       try {
  //         fetchAllCatalogs({
  //           catalogId: catalog?.id,
  //           paging: true,
  //           ...pagingConfig,
  //         });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };

  return {
    fetchAllCatalogs,
    fetchOneCatalog,
    removeCatalog,
    onDrop,
    pagination,
    setPagination,
    isLoading,
    setIsLoading,
    hasMore,
    setHasMore,
    catalogs,
    setCatalogs,
    confirmDelete,
    setConfirmDelete,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    excelErrors,
    setExcelErrors,
    showFailModal,
    setShowFailModal,
    alertModal,
    setAlertModal,
  };
};

export default useContainer;
