import { CartDataType, CartItemDataType, StoredCarts } from "../types/cart";
import { CatalogType } from "../types/catalog";
import { CatalogInventoryItemType, ItemType } from "../types/item";
import { TaxAmountTypeEnum, TaxTypeEnum } from "../types/order";

export const calculatePrice = (
  item: CatalogInventoryItemType,
  activeUOM: "uom1" | "uom2" | "uom3",
  isNormalPrice: boolean = false
) => {
  switch (activeUOM) {
    case "uom2":
      if (isNormalPrice) {
        return item.inventoryItem.price2 || item.inventoryItem.price;
      } else {
        return (
          item.price2 ?? (item.inventoryItem.price2 || item.inventoryItem.price)
        );
      }

    case "uom3":
      if (isNormalPrice) {
        return item.inventoryItem.price3 || item.inventoryItem.price;
      } else {
        return (
          item.price3 ?? (item.inventoryItem.price3 || item.inventoryItem.price)
        );
      }

    default:
      if (isNormalPrice) {
        return item.inventoryItem.price;
      } else {
        return item.price ?? item.inventoryItem.price;
      }
  }
};

export const getSubtotal = ({
  selected,
  items,
  cartItems,
  callback,
}: {
  selected: string[];
  items: CatalogInventoryItemType[];
  cartItems: CartDataType;
  callback?: (subtotal: number) => void;
}) => {
  let subtotal = 0;

  for (let i = 0; i < selected.length; i++) {
    const item = items.find((item) => item.inventoryItem.id === selected[i]);

    if (item && cartItems) {
      const qty = getQuantityFromCart(item.id, cartItems);
      const uom = getUOMFromCart(item.id, cartItems);

      subtotal += calculatePrice(item, uom) * qty;
    }
  }

  if (callback) {
    callback(subtotal);
  }
};

export const getMinimumQuantity = (item: CatalogInventoryItemType) => {
  return item.inventoryItem.minimumQuantity &&
    item.inventoryItem.minimumQuantity !== null
    ? item.inventoryItem.minimumQuantity
    : 0.1;
};

export const getConversionRate = (
  uom: "uom1" | "uom2" | "uom3",
  item: ItemType
) => {
  switch (uom) {
    case "uom2":
      return item.conversion2 || 1;

    case "uom3":
      return item.conversion3 || 1;

    default:
      return 1;
  }
};

export const getCurrencySymbol = (currency: string) => {
  const currencySymbol = [
    {
      name: "IDR",
      symbol: "Rp",
    },
    {
      name: "THB",
      symbol: "฿",
    },
    {
      name: "SGD",
      symbol: "S$",
    },
    {
      name: "MYR",
      symbol: "RM",
    }
  ];

  const symbol = currencySymbol.find((c) => c.name === currency);

  return symbol?.symbol ?? "Rp";
};

export const getQuantityFromCart = (id: string, cartItems: CartDataType) => {
  return cartItems?.items.find((item) => item.cid === id)?.quantity || 0;
};

export const getUOMFromCart = (id: string, cartItems: CartDataType) => {
  return cartItems?.items.find((item) => item.cid === id)?.uom || "uom1";
};

export const getCartItems = (
  catalogId: string,
  accountId: string,
  storageType: "local" | "session" = "local"
) => {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const isCartExist = storage.getItem("carts");
  if (isCartExist) {
    const storedCart: StoredCarts = JSON.parse(isCartExist);
    const selectCart = storedCart.data.find(
      (cart: CartDataType) =>
        cart.accountId === accountId && cart.catalogId === catalogId
    );

    return selectCart ?? null;
  }

  return null;
};

// export function updateCartData(
//   quantityChange: number,
//   override: boolean = false,
//   accountId: string,
//   catalogId: string,
//   inventoryItem: CatalogInventoryItemType,
//   directOrder: boolean = false,
//   activeUOM: "uom1" | "uom2" | "uom3",
//   callback?: (cartItems: CatalogCartData) => void
// ) {
//   const isCartExist = localStorage.getItem("cartItems");

//   if (isCartExist) {
//     // Retrieve cart data from localStorage
//     const storedCart: StoredCarts = JSON.parse(isCartExist);
//     const accountIndex = storedCart.data.findIndex(
//       (cart: AccountCartData) => cart.accountId === accountId
//     );

//     if (accountIndex !== -1) {
//       // Find the index of the catalog being modified
//       const catalogIndex = storedCart.data[accountIndex].catalogs.findIndex(
//         (c) => c.catalogId === catalogId
//       );

//       // Handle adding or removing items
//       if (catalogIndex !== -1) {
//         // Catalog exists
//         const itemIndex = storedCart.data[accountIndex].catalogs[
//           catalogIndex
//         ].items.findIndex(
//           (cartItem) => cartItem.inventoryItemId === inventoryItem.id
//         );

//         if (itemIndex !== -1) {
//           // Item already exists in the catalog
//           const newQuantity = override
//             ? quantityChange
//             : storedCart.data[accountIndex].catalogs[catalogIndex].items[
//                 itemIndex
//               ].quantity + quantityChange;

//           if (
//             newQuantity > 0
//             // newQuantity *
//             //   getConversionRate(activeUOM, inventoryItem.inventoryItem) >
//             // (inventoryItem.inventoryItem.minimumQuantity || 1)
//           ) {
//             storedCart.data[accountIndex].catalogs[catalogIndex].items[
//               itemIndex
//             ].quantity = newQuantity;

//             // setCount(newQuantity);
//           } else {
//             // Remove item if quantity becomes 0
//             storedCart.data[accountIndex].catalogs[catalogIndex].items.splice(
//               itemIndex,
//               1
//             );

//             // If the catalog becomes empty, remove it
//             if (
//               storedCart.data[accountIndex].catalogs[catalogIndex].items
//                 .length === 0
//             ) {
//               storedCart.data[accountIndex].catalogs.splice(catalogIndex, 1);

//               localStorage.setItem("cartItems", JSON.stringify(storedCart));
//               window.location.reload();
//             }
//           }
//         } else {
//           // Add new item to the catalog
//           storedCart.data[accountIndex].catalogs[catalogIndex].items.push({
//             inventoryItemId: inventoryItem.id,
//             quantity: quantityChange,
//             uom: activeUOM,
//             initialPrice: calculatePrice(inventoryItem, activeUOM),
//           });
//         }
//       } else {
//         // Catalog doesn't exist, create a new one with the item
//         storedCart.data[accountIndex].catalogs.push({
//           catalogId: catalogId || "",
//           directOrder: directOrder,
//           items: [
//             {
//               inventoryItemId: inventoryItem.id,
//               quantity: quantityChange,
//               uom: activeUOM,
//               initialPrice: calculatePrice(inventoryItem, activeUOM),
//             },
//           ],
//         });
//       }
//     } else {
//       // Account doesn't exist, create a new one with the item
//       storedCart.data.push({
//         accountId: accountId || "null",
//         catalogs: [
//           {
//             catalogId: catalogId || "",
//             directOrder: directOrder,
//             items: [
//               {
//                 inventoryItemId: inventoryItem.id,
//                 quantity: quantityChange,
//                 uom: activeUOM,
//                 initialPrice: calculatePrice(inventoryItem, activeUOM),
//               },
//             ],
//           },
//         ],
//       });
//     }

//     // Update localStorage with the modified cart data
//     localStorage.setItem("cartItems", JSON.stringify(storedCart));
//   } else {
//     // Cart doesn't exist, create a new one with the item
//     const newCart = {
//       data: [
//         {
//           accountId: accountId || "null",
//           catalogs: [
//             {
//               catalogId: catalogId,
//               directOrder: directOrder,
//               items: [
//                 {
//                   inventoryItemId: inventoryItem.id,
//                   quantity: quantityChange,
//                   uom: activeUOM,
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     };

//     // Insert localStorage with the modified cart data
//     localStorage.setItem("cartItems", JSON.stringify(newCart));
//     // setCartItems(newCart.data[0].catalogs[0]);
//   }

//   if (callback) {
//     const catalog = getCartItems(catalogId || "", accountId || "null");

//     if (catalog) {
//       callback(catalog);
//     }
//   }
// }

export function updateCartQty({
  catalogId,
  accountId = "wildcart",
  override = false,
  quantityChange,
  inventoryItem,
  direct = false,
  activeUOM,
  storageType = "local",
  deleteOnNull = true,
  callback,
}: {
  catalogId: string;
  accountId: string;
  override?: boolean;
  quantityChange: number;
  inventoryItem: CatalogInventoryItemType;
  direct?: boolean;
  activeUOM: "uom1" | "uom2" | "uom3";
  storageType: "local" | "session";
  deleteOnNull?: boolean;
  callback?: (cartItems: CartDataType) => void;
}) {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const isCartExist = storage.getItem("carts");

  if (isCartExist) {
    const storedCart: StoredCarts = JSON.parse(isCartExist);
    const selectCart = storedCart.data.find(
      (cart: CartDataType) =>
        cart.accountId === accountId && cart.catalogId === catalogId
    );

    if (selectCart) {
      const selectItem = selectCart.items.find(
        (item: CartItemDataType) => item.cid === inventoryItem.id
      );

      if (selectItem) {
        const newQuantity = override
          ? quantityChange
          : selectItem.quantity + quantityChange;

        if (
          newQuantity > 0
          // newQuantity *
          //   getConversionRate(activeUOM, inventoryItem.inventoryItem) >
          // (inventoryItem.inventoryItem.minimumQuantity || 1)
        ) {
          selectItem.quantity = newQuantity;

          // setCount(newQuantity);
        } else {
          // remove item if quantity becomes 0
          if (deleteOnNull) {
            selectCart.items = selectCart.items.filter(
              (i) => i.cid !== selectItem.cid
            );
          }

          // if the catalog becomes empty, remove it
          if (selectCart.items.length === 0) {
            deleteCart(accountId, catalogId, storageType);
          }
        }
      } else {
        // add new item to the catalog
        selectCart.items.push({
          cid: inventoryItem.id,
          quantity: quantityChange,
          uom: activeUOM,
          initPrice: calculatePrice(inventoryItem, activeUOM),
        });
      }
    } else {
      // selected cart does not exist, creating a new one
      storedCart.data.push({
        catalogId: catalogId,
        accountId: accountId,
        direct: direct,
        items: [
          {
            cid: inventoryItem.id,
            quantity: quantityChange,
            uom: activeUOM,
            initPrice: calculatePrice(inventoryItem, activeUOM),
          },
        ],
      });
    }
    // update storage with the modified cart data
    console.log("BANANA UPDATED CART", storedCart);
    storage.setItem("carts", JSON.stringify(storedCart));
  } else {
    createCart({
      payload: {
        catalogId: catalogId,
        accountId: accountId,
        direct: direct,
        items: [
          {
            cid: inventoryItem.id,
            quantity: quantityChange,
            uom: activeUOM,
            initPrice: calculatePrice(inventoryItem, activeUOM),
          },
        ],
      },
      storageType: storageType,
      callback,
    });
  }

  const newCartItems = getCartItems(catalogId, accountId, storageType);
  if (callback && newCartItems) {
    callback(newCartItems);
  }
}

export function updateCartUOM({
  catalogId,
  accountId = "wildcart",
  inventoryItem,
  uom,
  storageType = "local",
  callback,
}: {
  catalogId: string;
  accountId: string;
  inventoryItem: CatalogInventoryItemType;
  uom: "uom1" | "uom2" | "uom3";
  storageType: "local" | "session";
  callback?: (cartItems: CartDataType) => void;
}) {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const isCartExist = storage.getItem("carts");

  if (isCartExist) {
    const storedCart: StoredCarts = JSON.parse(isCartExist);
    const selectCart = storedCart.data.find(
      (cart: CartDataType) =>
        cart.accountId === accountId && cart.catalogId === catalogId
    );

    if (selectCart) {
      const selectItem = selectCart.items.find(
        (item: CartItemDataType) => item.cid === inventoryItem.id
      );

      if (selectItem) {
        selectItem.uom = uom;
        selectItem.initPrice = calculatePrice(inventoryItem, uom);
      }
    }

    // update localStorage with the modified cart data
    console.log(storedCart);
    storage.setItem("carts", JSON.stringify(storedCart));

    if (callback && selectCart) {
      callback(selectCart);
    }
  }
}

export function updateCartInitPrice({
  catalogId,
  accountId = "wildcart",
  inventoryItem,
  uom,
  storageType = "local",
  callback,
}: {
  catalogId: string;
  accountId: string;
  inventoryItem: CatalogInventoryItemType;
  uom: "uom1" | "uom2" | "uom3";
  storageType: "local" | "session";
  callback?: (cartItems: CartDataType) => void;
}) {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const isCartExist = storage.getItem("carts");

  if (isCartExist) {
    const storedCart: StoredCarts = JSON.parse(isCartExist);
    const selectCart = storedCart.data.find(
      (cart: CartDataType) =>
        cart.accountId === accountId && cart.catalogId === catalogId
    );

    if (selectCart) {
      const selectItem = selectCart.items.find(
        (item: CartItemDataType) => item.cid === inventoryItem.id
      );

      if (selectItem) {
        selectItem.initPrice = calculatePrice(inventoryItem, uom);
      }
    }

    // update localStorage with the modified cart data
    storage.setItem("carts", JSON.stringify(storedCart));

    if (callback && selectCart) {
      callback(selectCart);
    }
  }
}

export function updateCartInfo({
  catalogId,
  accountId,
  direct,
  storageType = "local",
}: {
  catalogId: string;
  accountId?: string | null;
  direct?: boolean | null;
  storageType: "local" | "session";
}) {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const isCartExist = storage.getItem("carts");

  if (isCartExist) {
    const storedCart: StoredCarts = JSON.parse(isCartExist);
    const selectCart = storedCart.data.find(
      (cart: CartDataType) =>
        cart.accountId === accountId && cart.catalogId === catalogId
    );

    if (selectCart) {
      selectCart.accountId = accountId ?? selectCart.accountId;
      selectCart.direct = direct ?? selectCart.direct;
    }

    storage.setItem("carts", JSON.stringify(storedCart));
  }
}

export function createCart({
  payload,
  storageType = "local",
  callback,
}: {
  payload: CartDataType;
  storageType: "local" | "session";
  callback?: (cartItems: CartDataType) => void;
}) {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const isCartExist = storage.getItem("carts");

  if (isCartExist) {
    const storedCart: StoredCarts = JSON.parse(isCartExist);
    storedCart.data.push(payload);

    // update storage with the modified cart data
    storage.setItem("carts", JSON.stringify(storedCart));
  } else {
    console.log("BANANA CREATED CART", { data: [payload] });
    storage.setItem("carts", JSON.stringify({ data: [payload] }));
  }

  if (callback) {
    callback(payload);
  }
}

export function verifyCartItems(
  cartItems: CartDataType,
  inventoryItems: CatalogInventoryItemType[]
) {
  const results = cartItems.items.map((item) => {
    const inventoryItem = inventoryItems.find(
      (inventoryItem) => inventoryItem.id === item.cid
    );

    if (inventoryItem) {
      return (
        item.quantity *
          getConversionRate(item.uom, inventoryItem.inventoryItem) >=
        (inventoryItem.inventoryItem.minimumQuantity ?? 1)
      );
    } else {
      return false;
    }
  });

  return !results.includes(false);
}

export const validateCartItems = (
  items: CatalogInventoryItemType[],
  cartItems: CartDataType,
  callback?: ({
    removedItems,
    updatedItems,
  }: {
    removedItems: CatalogInventoryItemType[];
    updatedItems: {
      cartItem: CartItemDataType;
      item: CatalogInventoryItemType;
    }[];
  }) => void
) => {
  const unremoved = items.filter((i) => i.deletedAt == null);
  const tempRemovedArray: CatalogInventoryItemType[] = [];
  const tempUpdatedArray: {
    cartItem: CartItemDataType;
    item: CatalogInventoryItemType;
  }[] = [];

  // remove items that are not in the cart
  const ids = unremoved.map((i) => i.id);
  cartItems.items.forEach((ci) => {
    const catalogInventoryItem = items.find((i) => i.id === ci.cid);
    if (!ids.includes(ci.cid) && catalogInventoryItem) {
      tempRemovedArray.push(catalogInventoryItem);
      updateCartQty({
        catalogId: cartItems.catalogId,
        accountId: cartItems.accountId,
        inventoryItem: catalogInventoryItem,
        override: true,
        quantityChange: 0,
        activeUOM: ci.uom,
        direct: cartItems.direct,
        storageType: cartItems.accountId === "wildcart" ? "session" : "local",
      });
    }
  });

  // update items that are in the cart
  unremoved.forEach((ci) => {
    const cartItem = cartItems.items.find((i) => i.cid === ci.id);
    if (cartItem && cartItem.initPrice !== calculatePrice(ci, cartItem.uom)) {
      tempUpdatedArray.push({
        cartItem: cartItem,
        item: ci,
      });
      updateCartInitPrice({
        catalogId: cartItems.catalogId,
        accountId: cartItems.accountId,
        inventoryItem: ci,
        uom: cartItem.uom,
        storageType: cartItems.accountId === "wildcart" ? "session" : "local",
      });
    }
  });

  if (callback) {
    callback({
      removedItems: tempRemovedArray,
      updatedItems: tempUpdatedArray,
    });
  }
};

export const validateOnKeyDown = (key: string) => {
  if (
    key !== "Backspace" &&
    key !== "Tab" &&
    key !== "ArrowLeft" &&
    key !== "ArrowRight" &&
    key !== "," &&
    key !== "." &&
    !/^[0-9]$/.test(key)
  ) {
    return true;
  }

  return false;
};

export const validateOnChange = (
  newValue: string,
  callback: (sanitizedValue: string) => void
) => {
  newValue = newValue.replace(",", ".");
  if (
    newValue.startsWith("0") &&
    !newValue.startsWith("0.") &&
    newValue.length > 1
  ) {
    newValue = newValue.replace(/^0+/, "");
  }

  if (!isNaN(Number(newValue)) || newValue === "") {
    if (newValue.includes(".")) {
      const [integerPart, decimalPart] = newValue.split(".");
      if (decimalPart.length > 2) {
        newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
      }
    }

    callback(newValue);
  }
};

export function deleteCart(
  catalogId: string,
  accountId: string,
  storageType: "local" | "session" = "local"
) {
  const storage = storageType === "session" ? sessionStorage : localStorage;
  const isCartExist = localStorage.getItem("carts");

  if (isCartExist) {
    const storedCart: StoredCarts = JSON.parse(isCartExist);
    const selectCart = storedCart.data.find(
      (cart: CartDataType) =>
        cart.accountId === accountId && cart.catalogId === catalogId
    );

    if (selectCart) {
      storedCart.data = storedCart.data.filter(
        (cart: CartDataType) =>
          cart.accountId !== accountId || cart.catalogId !== catalogId
      );
    }

    // update localStorage with the modified cart data
    storage.setItem("carts", JSON.stringify(storedCart));
  }

  // getCartItems(catalogId || "", accountId);
  // window.location.reload();
}

export const getDeliveryFee = (
  catalog: CatalogType,
  checkOutItems: CartDataType,
  subtotal: number,
  items?: CatalogInventoryItemType[]
) => {
  const deliveryType = catalog.deliveryFeeConfig.feeType;

  switch (deliveryType) {
    case "FIXED_AMOUNT":
      return catalog.deliveryFeeConfig.feeValues[0].value || 0;

    case "QUANTITY_BASE":
      let totalQty = 0;
      checkOutItems?.items.forEach((item) => {
        const inventoryItem = items?.find((i) => i.id === item.cid);
        totalQty +=
          item.quantity *
          (inventoryItem
            ? getConversionRate(item.uom, inventoryItem?.inventoryItem)
            : 1);
      });

      var feeValue = catalog.deliveryFeeConfig.feeValues.filter(
        (fee) => parseInt(fee.upToCount.toString()) <= totalQty
      );

      if (!feeValue || feeValue?.length === 0) {
        return 0;
      }

      var match = feeValue.reduce((prev, curr) => {
        if (curr.upToCount >= totalQty) return prev;
        return curr;
      });

      return match.value || 0;

    case "PRICE_BASE":
      var feeValue = catalog.deliveryFeeConfig.feeValues.filter(
        (fee) => parseInt(fee.upToCount.toString()) <= subtotal
      );

      if (!feeValue || feeValue?.length === 0) {
        return 0;
      }

      var match = feeValue.reduce((prev, curr) => {
        if (curr.upToCount >= subtotal) return prev;
        return curr;
      });

      return match.value || 0;

    // case "DISTANCE_BASE":
    //   return 0;

    default:
      return 0;
  }
};

export const getTaxAmount = (
  products: any,
  items: CatalogInventoryItemType[],
  catalog: CatalogType
) => {
  console.log("calculateOrderItemsTaxTotal >>>", products);
  if (!catalog.taxId) {
    return 0;
  }

  return products?.reduce((acc: any, product: any) => {
    const item = items.find((i) => i.id === product.cid);
    if (item && catalog?.taxId.taxAmount !== 0) {
      const totalPrice = product.quantity * calculatePrice(item, product.uom);
      if (catalog?.taxId?.taxType === TaxTypeEnum.PPH_GROSS_UP) {
        return acc + totalPrice * ((catalog?.taxId.taxAmount || 0) / 100);
      } else if (catalog?.taxId?.taxType === TaxTypeEnum.PPN_INCLUSIVE) {
        return (
          acc +
          (totalPrice * ((catalog.taxId.taxAmount || 0) / 100)) /
            (1 + (catalog.taxId.taxAmount || 0) / 100)
        );
      } else if (
        catalog?.taxId?.taxType === TaxTypeEnum.PPH_NPWP ||
        catalog?.taxId?.taxType === TaxTypeEnum.PPN_EXCLUSIVE ||
        catalog?.taxId?.taxType === TaxTypeEnum.GST
      ) {
        return acc + totalPrice * ((catalog?.taxId?.taxAmount || 0) / 100);
      } else if (catalog?.taxId?.taxType === TaxTypeEnum.MANUAL) {
        return catalog?.taxId?.taxAmountType === TaxAmountTypeEnum.FIXED
          ? acc + (catalog?.taxId?.taxAmount || 0)
          : acc + totalPrice * ((catalog?.taxId?.taxAmount || 0) / 100);
      }
    }
    return acc;
  }, 0);
};
