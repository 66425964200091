import { Box, Button, Snackbar, Stack, Typography } from "@mui/joy";
import { theme } from "../../../components/Theme";
// import TempImgP1 from "../../../assets/temp/pd1.png";
// import TempImgP2 from "../../../assets/temp/pd2.png";
// import TempImgP3 from "../../../assets/temp/pd3.png";
// import TempImgP4 from "../../../assets/temp/pd4.png";
import EmblaCarousel from "../../../components/carousel-thumbs/EmblaCarousel";
import { EmblaOptionsType } from "embla-carousel";
import { t } from "i18next";
import {
  MinusIcon,
  PlusIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import React, { SetStateAction, useState } from "react";
import { CatalogInventoryItemType } from "../../../types/item";
import { formatPrice } from "../../../utils/formatPrice";
import { calculatePrice, getConversionRate } from "../../../utils/cart";
import useContainer from "../useContainer";
import { CatalogType } from "../../../types/catalog";
import { useSearchParams } from "react-router-dom";

type Props = {
  catalog: CatalogType;
  setActiveUOM: React.Dispatch<SetStateAction<"uom1" | "uom2" | "uom3">>;
  activeUOM: "uom1" | "uom2" | "uom3";
  item: CatalogInventoryItemType;
};

function SupplierProductInformation({
  catalog,
  setActiveUOM,
  activeUOM,
  item,
}: Props) {
  //   const tempimgarr = [
  //     { src: TempImgP1 },
  //     { src: TempImgP2 },
  //     { src: TempImgP3 },
  //     { src: TempImgP4 },
  //   ];
  const OPTIONS: EmblaOptionsType = {};
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order");

  const [quantity, setQuantity] = useState(1);
  const { handleUpdateCart, handleUpdateOrder, showToast, setShowToast } =
    useContainer();

  return item ? (
    <div className="border-0 lg:border rounded-md p-0 lg:p-2">
      <Stack
        direction={{ sx: "column", lg: "row" }}
        spacing={3}
        alignItems={"center"}
        m={{ xs: 0, lg: 1 }}
      >
        <Stack
          sx={{
            width: { sx: "360px", lg: "360px" },
          }}
        >
          <EmblaCarousel
            slides={[{ src: item.inventoryItem.thumbnail }]}
            options={OPTIONS}
          />
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Typography
            fontSize={20}
            fontWeight={500}
            sx={{ color: `${theme.colorSchemes.light.palette.common.black}` }}
          >
            <span>{t("catalog.items.sku")}</span>
            <span>{item.inventoryItem.sku}</span>
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={700}
            sx={{ color: `${theme.colorSchemes.light.palette.common.black}` }}
          >
            {item.inventoryItem.productName}
          </Typography>
          <Stack direction={"row"} spacing={0.5} className="my-2">
            <Stack
              direction={"row"}
              spacing="10px"
              alignItems={"center"}
              sx={{
                color: "#475467",
                borderRadius: "4px",
                backgroundColor: "#F2F4F7",
                padding: "10px 15px",
                width: "100%",
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{
                  color: "#475467",
                }}
              >
                {"Price"}
              </Typography>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  sx={{
                    color: "#000",
                  }}
                >
                  {item.currency ?? item.inventoryItem.currency}{" "}
                  {formatPrice(
                    calculatePrice(item, activeUOM),
                    item.currency ?? item.inventoryItem.currency
                  )}
                </Typography>
                {/* &nbsp; */}
                {/* <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{
                    color: "#475467",
                  }}
                >
                  {formatPrice(
                    item.price ?? item.inventoryItem.price,
                    item.currency ?? item.inventoryItem.currency
                  )}
                </Typography> */}
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={0.5} className="my-6">
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              spacing="10px"
            >
              {item.inventoryItem.uom1 && (
                <button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveUOM("uom1");
                  }}
                  //   disabled={isDisabled}
                  className={`rounded-xl p-2 text-sm font-bold ${
                    activeUOM === "uom1"
                      ? "text-primary-900 bg-primary-100"
                      : "text-primary-400 border border-primary-400"
                  }`}
                >
                  {item.inventoryItem.uom1}
                </button>
              )}
              {item.inventoryItem.uom2 && (
                <button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveUOM("uom2");
                  }}
                  //   disabled={isDisabled}
                  className={`rounded-xl p-2 text-sm font-bold ${
                    activeUOM === "uom2"
                      ? "text-primary-900 bg-primary-100"
                      : "text-primary-400 border border-primary-400"
                  }`}
                >
                  {item.inventoryItem.uom2}
                </button>
              )}
              {item.inventoryItem.uom3 && (
                <button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveUOM("uom3");
                  }}
                  //   disabled={isDisabled}
                  className={`rounded-xl p-2 text-sm font-bold ${
                    activeUOM === "uom3"
                      ? "text-primary-900 bg-primary-100"
                      : "text-primary-400 border border-primary-400"
                  }`}
                >
                  {item.inventoryItem.uom3}
                </button>
              )}
              {/* <span className="text-primary-900 bg-primary-100 rounded-xl p-2 text-sm font-bold">
                {"Pieces"}
              </span>

              <span className="text-primary-400 border border-primary-400 rounded-xl p-2 text-sm font-bold">
                {"Pack"}
              </span>

              <span className="text-primary-400 border border-primary-400  rounded-xl p-2 text-sm font-bold">
                {"Box"}
              </span> */}
            </Stack>
            <div className="flex justify-start items-center gap-4">
              <span
                className={`text-[${theme.colorSchemes.light.palette.common.white}] text-sm w-[100px]`}
              >
                {"Variant Qty"}
              </span>
              <span
                className={`text-[${theme.colorSchemes.light.palette.common.black}] font-semibold`}
              >
                {activeUOM === "uom1"
                  ? `1 ${item.inventoryItem.uom1}`
                  : `${getConversionRate(activeUOM, item.inventoryItem)} ${
                      item.inventoryItem.uom1
                    }/${item.inventoryItem[activeUOM]}`}
              </span>
            </div>
            <div className="flex justify-start items-center gap-4">
              <span
                className={`text-[${theme.colorSchemes.light.palette.common.white}] text-sm w-[100px]`}
              >
                {"Estimated Delivery Fee"}
              </span>
              <span
                className={`text-[${theme.colorSchemes.light.palette.common.black}] font-semibold`}
              >
                {"10 Items"}
              </span>
            </div>
            <div className="lg:flex hidden justify-start items-center gap-4">
              <span
                className={`text-[${theme.colorSchemes.light.palette.common.white}] text-sm w-[100px]`}
              >
                {"Quantity"}
              </span>

              <Stack
                direction={"row"}
                className="border border-gray-400 rounded-lg overflow-hidden mt-4 mb-2"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <button
                  onClick={() =>
                    setQuantity(quantity - 1 > 0 ? quantity - 1 : 1)
                  }
                  className={`"rounded-none w-[40px] h-[34px] flex justify-center items-center border-r border-gray-700"
                                        }`}
                >
                  <MinusIcon className="w-6 h-6" />
                </button>
                <Typography
                  level="title-md"
                  className="w-[60px] flex justify-center items-center"
                >
                  {quantity}
                </Typography>
                <button
                  onClick={() => setQuantity(quantity + 1)}
                  className={`"px-2 w-[40px] h-[34px] flex justify-center items-center rounded-none  border-l border-gray-700" 
                                        }`}
                >
                  <PlusIcon className="w-6 h-6" />
                </button>
              </Stack>
            </div>
          </Stack>
          <Button
            fullWidth
            sx={{
              borderRadius: "0.5rem",
              display: { xs: "none", lg: "flex" },
            }}
            onClick={() => {
              if (orderId) {
                handleUpdateOrder(orderId, catalog, item, quantity, activeUOM);
              } else {
                handleUpdateCart(catalog.id, activeUOM, item, quantity);
              }

              setShowToast(true);

              setTimeout(() => {
                setShowToast(false);
              }, 3000);
            }}
          >
            <ShoppingCartIcon
              style={{
                width: 18,
                height: 18,
                marginRight: "0.5rem",
              }}
            />
            {t(`catalog.addToCartBtn`)}
          </Button>
          {/* <button
            onClick={() => {
              handleUpdateCart(catalog.id, activeUOM, item, quantity);
            }}
            className={`lg:flex hidden my-4 lg:my-0 py-3 px-4 rounded-lg  border capitalize items-center cursor-default 
                             text-white bg-primary-500 justify-center
                            `}
          >
            <ShoppingCartIcon
              style={{
                width: 18,
                height: 18,
                marginRight: "0.5rem",
              }}
            />
            {t(`catalog.addToCartBtn`)}
          </button> */}
        </Stack>
      </Stack>

      <Snackbar
        variant="solid"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showToast}
        key={"asjfkangdlknakjrsh"}
        sx={{
          width: "40%",
          maxWidth: 800,
        }}
      >
        <Box width={"100%"}>
          <Typography
            textAlign={"center"}
            textColor={"common.white"}
            fontSize={14}
          >
            {t(`catalog.${orderId ? "itemAddedToOrder" : "itemAddedToCart"}`)}
          </Typography>
        </Box>
      </Snackbar>
    </div>
  ) : (
    <>Item does not exist</>
  );
}

export default SupplierProductInformation;
