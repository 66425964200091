import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Option,
  Select,
  Textarea,
  Typography,
} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  // useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Toggler,
  TwentyEightDaysPicker,
  formatInventoryItemResponse,
  transformUomList,
} from "../../components/utils";
import { AccountService } from "../../services/account.service";
import { ItemService } from "../../services/item.service";
import { OrderService } from "../../services/order.service";

import {
  BanknotesIcon,
  ChevronDoubleRightIcon,
  NewspaperIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { getMessaging, onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
// import { usePDF } from "react-to-pdf";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import GrandTotal from "../../components/Orders/GrandTotal";
import ProductAndService from "../../components/Orders/ProductAndService";
import { SuccessModal } from "../../components/SuccessModal";
import PaymentInfoModal from "../../components/payments/PaymentInfoModal";
import { BusinessService } from "../../services/business.service";
import { PaymentService, PaymentType } from "../../services/payment.service";
import { TaxService } from "../../services/tax.service";
import { useAuthStore } from "../../store/session";
import {
  IOrderRevision,
  IOrderSavePayload,
  OrderStatusEnum,
  PaymentFlowTypeEnum,
  paymentFlowTypes,
  TaxAmountTypeEnum,
} from "../../types/order";
import { BrowserRouterConstants } from "../../utils/constants";
import { setDefaultCurrency } from "../../utils/misc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getInvoiceNumber } from "../../utils/order";
import { If } from "../../components/Condition";
import RelatedOrders from "../../components/Orders/RelatedOrders";
import OrderStatusStepper from "../../components/Orders/OrderStatusStepper";
import { useMediaQuery } from "@mui/material";
import OrderHeader from "../../components/Orders/OrderHeader";
import FirstPartyInfo from "../../components/Orders/FirstPartyInfo";
import SecondPartyInfo from "../../components/Orders/SecondPartyInfo";
import SectionToggleBar from "../../components/Orders/SectionToggleBar";
import { KeyboardArrowDown } from "@mui/icons-material";
import MultiplePaymentPlan from "../../components/Orders/MultiplePaymentPlan";
import OrderFooter from "../../components/Orders/OrderFooter";
import {
  BtnFilledPrimarySm,
  BtnOutlinedPrimarySm,
} from "../../components/stateless/buttons";
import { useMultiPaymentContainer } from "../../components/Orders/MultiplePaymentPlan/useContainer";
// import OrderDetails, { OrderDetailsProps } from "./pdf/OrderDetailsPDF";
// import { orderStatuses } from "../../types/order";

// const localeCurrencies = ["IDR", "MYR", "PHP", "THB", "VND"];
export const currencyList = [
  "IDR",
  "MYR",
  "PHP",
  "THB",
  "VND",
  "USD",
  "SGD",
  "EUR",
  "HKD",
  "JPY",
  "MMK",
  "AUD",
];
// currencyList.unshift(localeCurrencies[0]);

export const countryToCurrencyList: { [key: string]: string } = {
  ID: "IDR",
  MY: "MYR",
  PH: "PHP",
  TH: "THB",
  VN: "VND",
  US: "USD",
  SG: "SGD",
  HK: "HKD",
  JP: "JPY",
  MM: "MMK",
  AU: "AUD",
};

export default function PublicViewOrder() {
  const { t } = useTranslation();

  const [orderType, setOrderType] = useState<any>("sales");
  const [currentOrderStatus, setCurrentOrderStatus] = useState<any>("");
  const [invoiceNumber, setInvoiceNumber] = useState<any>("");
  const [isReadOnly, setIsReadOnly] = useState<any>(false);
  const initSuccessModal = {
    open: false,
    title: "Success",
    content: "Order Saved Successfully.",
  };
  const [successModal, setSuccessModal] = useState<any>(initSuccessModal);

  const [searchParam] = useSearchParams();
  /**
   * states and variables required for adjustment order:
   * @isAdjustmentOrder
   * @revisions
   * @revisionId
   * @currentRevision
   */
  // const [isAdjustmentOrder, setIsAdjustmentOrder] = useState(false);
  const [revisions, setRevisions] = useState<any[]>([]);
  const [mainOrderCreatedAt, setMainOrderCreatedAt] = useState("");
  const [currentRevision, setCurrentRevision] = useState<
    IOrderRevision | undefined
  >();
  const revisionId = searchParam.get("revision");

  const enquiryPaymentStatus = async (transactionId: string) => {
    setLoading(true);
    PaymentService.enquiryPaymentStatus(transactionId, "")
      .then((_) => {
        setLoading(false);
        setSuccessModal({
          ...successModal,
          open: true,
          content: t("order.createOrder.success.paymentSuccess"),
        });
        setTimeout(() => {
          window.location.href = window.location.href.split("?")[0];
          setSuccessModal(initSuccessModal);
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast(showErrorToast(error), ErrorToastConfig);
      });
  };

  // Listen to payment redirect url
  useEffect(() => {
    console.log("IFRAME", searchParam.get("transaction_id"));
    const transactionId = searchParam.get("transaction_id");
    if (transactionId && (transactionId?.length ?? 0) > 0) {
      enquiryPaymentStatus(searchParam.get("transaction_id") ?? "");
    }
  }, [searchParam]);

  onMessage(getMessaging(), (payload) => {
    console.log("FCM Message received. ", payload);
    if (`${payload.data?.topic}`.includes("payment-success")) {
      setSuccessModal({
        ...successModal,
        open: true,
        content: t("order.createOrder.success.paymentSuccess"),
      });
      setTimeout(() => {
        setSuccessModal(initSuccessModal);
      }, 2000);
    }
  });

  const [loading, setLoading] = useState<any>(false);
  // const [currencyList, setCurrencyList] = useState<any[]>();
  const [internalAccountList, setInternalAccountList] = useState<any[]>([]);
  const [selectedInternalAccount, setSelectedInternalAccount] = useState<any>(
    {}
  );
  const [externalBusinessList, setExternalBusinessList] = useState<any>([]);
  // const [selectedExternalBusiness, setSelectedExternalBusiness] = useState<any>(
  //   {}
  // );
  const [productSearchList, setProductSearchList] = useState<any[]>([]);

  const [nationalTaxes, setNationalTaxes] = useState<any[]>([]);

  const nationalTaxesWithOthers = nationalTaxes.concat([
    {
      taxLabel: "OTHERS",
      taxAmount: 0,
      taxType: "MANUAL",
    },
  ]);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [isRightSidebarHidden, setIsRightSidebarHidden] = useState(false);

  const { isScrollDown } = useOutletContext() as any;

  const [orderStatusTimestamps, setOrderStatusTimestamps] = useState<any>(null);

  const [sellerCountry, setSellerCountry] = useState<any>("");

  const [multiPaymentsData, setMultiPaymentsData] = useState<any>({});

  const prevFocusedError = useRef<any>("");
  // const navigate = useNavigate();
  const params = useParams();
  const orderId = params?.orderId;

  const { session } = useAuthStore();
  // const [pdfContent, setPdfContent] = useState<OrderDetailsProps>(
  //   {} as OrderDetailsProps
  // );
  const location = useLocation();

  const methods = useForm<IOrderSavePayload>({
    mode: "onChange",
    defaultValues: {
      // sellerIds: {
      //   businessId: "",
      //   accountId: "",
      // },
      // buyerIds: {
      //   businessId: "",
      //   accountId: "",
      // },
      sellerIds: null,
      buyerIds: null,
      orderItems: [],
      grandTotalAmount: 0,
      totalTax: 0,
      additionalDiscount: 0,
      deliveryFee: 0,
      downPayment: 0,
    },
  });

  const {
    control,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const selectedExternalBusiness = getValues("selectedExternalBusiness");
  const isUnregisterAccount =
    !selectedExternalBusiness?.role?.[0]?.account?.uid;
  console.log({ isUnregisterAccount });

  /** Initial fetch and set Data: Internal Account List */
  useEffect(() => {
    if (session?.activeBusiness) {
      getAndSetInternalAccountList();
      getAndSetExternalBusinessList();
    }
  }, [session?.activeBusiness]);

  /** Initial fetch and set Data: Order */
  useEffect(() => {
    if (internalAccountList && orderId) {
      // getAndSetInitialOrderData(urlOrderType, orderId);
      getAndSetInitialOrderData(orderId);
    }
  }, [internalAccountList, orderId]);

  async function getAndSetInternalAccountList() {
    const internalAccountList =
      await AccountService.getAccountListByCurrentBusinessInternal(
        session?.activeBusiness.id || null
      );
    if (internalAccountList) {
      console.log("Internal Account List", internalAccountList);
      setInternalAccountList(internalAccountList);
    }
  }

  async function getAndSetExternalBusinessList() {
    const externalBusinessList = await BusinessService.getExternalBusinessList({
      businessId: session?.activeBusiness.id,
    });
    if (externalBusinessList) {
      console.log("External Business List", externalBusinessList);
      setExternalBusinessList(externalBusinessList);
    }
  }

  function getAndSetProductSearchList(
    orderT: string,
    sellerId: string,
    buyerId: string | null
  ) {
    /** If sales, only sellerId is necessary to fetch seller's products
     * because the current user is seller */
    if (orderT === "sales" && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsSeller(sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));

      /** If purchase, buyerId and sellerId is necessary to fetch seller's products
       * because the current user is buyer and need to check seller's products first */
    } else if (orderT === "purchase" && buyerId && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsBuyer(buyerId, sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));
    }
  }

  async function getAndSetNationalTaxes(businessId: any) {
    try {
      const res = await TaxService.getBusinessTaxes(businessId || "");
      console.log("Response Taxes::", res);
      setNationalTaxes(res.map((res: any) => res.tax));
    } catch (error) {
      console.error(error);
    }
  }

  function changeOrderResponseToFormFormat(responseOrderData: any) {
    console.log("Change Order Response to Form Format ::", responseOrderData);
    let formOrderData = {} as IOrderSavePayload;
    formOrderData.createdById = responseOrderData.createdBy;
    formOrderData.createdByBusiness =
      responseOrderData.createdByBusiness || session?.activeBusiness.id;
    formOrderData.invoiceNumber = responseOrderData.invoiceNumber || null;
    if (responseOrderData.seller?.id === session?.activeBusiness.id) {
      /** Current user is seller */
      formOrderData.selectedExternalBusiness = responseOrderData.buyer || null;
      if (
        responseOrderData.additionalTax === null ||
        responseOrderData.additionalTax === undefined
      ) {
        /** If addtionalTax is not user-saved yet, set the default tax setting from seller */
        formOrderData.additionalTaxItem = {
          taxLabel: responseOrderData.seller?.defaultTax?.taxLabel || "NONE",
          taxAmount: responseOrderData.seller?.defaultTax?.taxAmount || 0,
          taxType: responseOrderData.seller?.defaultTax?.taxType || null,
          taxAmountType:
            responseOrderData.seller?.defaultTax?.taxAmountType ||
            TaxAmountTypeEnum.PERCENTAGE,
        };
      } else {
        /** If addtionalTax is already user-saved (even if it's 0), just set the user-saved value */

        formOrderData.additionalTaxItem = {
          taxLabel: responseOrderData.additionalTaxLabel || "NONE",
          taxAmount: responseOrderData.additionalTax || 0,
          taxType: responseOrderData.additionalTaxType || null,
          taxAmountType:
            responseOrderData.additionalTaxAmountType ||
            TaxAmountTypeEnum.PERCENTAGE,
        };

        // formOrderData.additionalTaxLabel =
        //   responseOrderData.additionalTaxLabel || "NONE";

        // formOrderData.additionalTax = responseOrderData.additionalTax || 0;

        // formOrderData.additionalTaxType =
        //   responseOrderData.additionalTaxType || null;
      }
    } else {
      /** Current user is buyer */

      formOrderData.selectedExternalBusiness = responseOrderData.seller || null;
      if (
        responseOrderData.additionalTax === null ||
        responseOrderData.additionalTax === undefined
      ) {
        /** Buyer's addtionalTax will be based on Seller. So, set the NONE tax setting initially */
        formOrderData.additionalTaxItem = {
          // taxLabel: responseOrderData.buyer?.defaultTax?.taxLabel || "NONE",
          // taxAmount: responseOrderData.buyer?.defaultTax?.taxAmount || 0,
          // taxType: responseOrderData.buyer?.defaultTax?.taxType || null,
          taxLabel: "NONE",
          taxAmount: 0,
          taxType: null,
          taxAmountType: TaxAmountTypeEnum.PERCENTAGE,
        };
      } else {
        /** If addtionalTax is already user-saved (even if it's 0), just set the user-saved value */

        formOrderData.additionalTaxItem = {
          taxLabel: responseOrderData.additionalTaxLabel || "NONE",
          taxAmount: responseOrderData.additionalTax || 0,
          taxType: responseOrderData.additionalTaxType || null,
          taxAmountType:
            responseOrderData.additionalTaxAmountType ||
            TaxAmountTypeEnum.PERCENTAGE,
        };
      }
    }

    formOrderData.sellerIds = responseOrderData.seller?.id
      ? {
          businessId: responseOrderData.seller.id,
          accountId: responseOrderData.sellerUser.id,
        }
      : null;

    formOrderData.buyerIds = responseOrderData.buyer?.id
      ? {
          businessId: responseOrderData.buyer?.id,
          accountId: responseOrderData.buyerUser?.id,
        }
      : null;

    formOrderData.paymentFlowType = responseOrderData.paymentFlowType;

    formOrderData.startMonth = responseOrderData.startMonth;
    formOrderData.endMonth = responseOrderData.endMonth;
    formOrderData.recurringDay = responseOrderData.recurringDay;
    formOrderData.recurringParentOrderId =
      responseOrderData.recurringParentOrderId || null;

    formOrderData.paymentTerm = responseOrderData.paymentTerm;
    formOrderData.currency =
      responseOrderData.currency ||
      responseOrderData.seller?.bankInfo?.[0]?.currency ||
      setDefaultCurrency(responseOrderData.seller?.companyCountry);
    formOrderData.deliveryDate = responseOrderData.deliveryDate
      ? dayjs(responseOrderData.deliveryDate).format("YYYY-MM-DD")
      : "";
    formOrderData.shippingMethod = responseOrderData.shippingMethod;
    formOrderData.vehicleNumber = responseOrderData.vehicleNumber;
    formOrderData.trackingNumber = responseOrderData.trackingNumber;
    formOrderData.driverName = responseOrderData.driverName;
    formOrderData.deliveryFee = responseOrderData.deliveryFee || 0;
    formOrderData.additionalDiscount =
      responseOrderData.additionalDiscount || 0;
    formOrderData.additionalDiscountType =
      responseOrderData.additionalDiscountType;
    formOrderData.grandTotalAmount = responseOrderData.grandTotalAmount;
    formOrderData.remarks = responseOrderData.remarks;

    formOrderData.downPayment = responseOrderData.downPayment || 0;
    formOrderData.isFromCatalog = responseOrderData.isFromCatalog;

    /** This is for the newly added Order Items from "Add New" button */
    formOrderData.defaultTaxItem = {
      taxLabel: responseOrderData.seller?.defaultTax?.taxLabel || "NONE",
      taxAmount: responseOrderData.seller?.defaultTax?.Amount || 0,
      taxType: responseOrderData.seller?.defaultTax?.taxType || null,
      taxAmountType:
        responseOrderData.seller?.defaultTax?.taxType ||
        TaxAmountTypeEnum.PERCENTAGE,
    };

    /** One OrderItem, One Product.. (only if there's orderItem data) */
    console.log("Response Order Items :::", responseOrderData.orderItems);
    formOrderData.orderItems = responseOrderData.orderItems?.map(
      (responseOrderItem: any) => {
        let formOrderItem = { ...responseOrderItem } as any;
        formOrderItem.orderItemId = responseOrderItem.id;
        formOrderItem.productId = responseOrderItem.inventoryItem.id;
        formOrderItem.createdById = responseOrderItem.inventoryItem.createdBy;
        formOrderItem.businessId = responseOrderItem.inventoryItem.businessId;
        formOrderItem.selectedItem =
          responseOrderItem.sku ?? responseOrderItem.inventoryItem.sku;
        formOrderItem.sku =
          responseOrderItem.sku ?? responseOrderItem.inventoryItem.sku;
        formOrderItem.productName =
          responseOrderItem.productName ??
          responseOrderItem.inventoryItem.productName;
        formOrderItem.productDescription =
          responseOrderItem.productDescription ??
          responseOrderItem.inventoryItem.productDescription;
        formOrderItem.inventoryType =
          responseOrderItem.inventoryItem.inventoryType;
        formOrderItem.uomList = transformUomList(
          responseOrderItem.inventoryItem
        );
        formOrderItem.uomItem =
          responseOrderItem.uomList?.find(
            (option: any) => option.uom === responseOrderItem.uom
          ) || responseOrderItem.uom;
        formOrderItem.uom = responseOrderItem.uom;

        if (
          responseOrderItem.tax === null ||
          responseOrderItem.tax === undefined
        ) {
          // orderItem.taxLabel = responseOrderData.seller?.defaultTax?.taxLabel;
          // orderItem.tax = responseOrderData.seller?.defaultTax?.taxAmount;
          // orderItem.taxType = responseOrderData.seller?.defaultTax?.taxType || null;

          formOrderItem.taxItem = {
            taxLabel: responseOrderData.seller?.defaultTax?.taxLabel || "NONE",
            taxAmount: responseOrderData.seller?.defaultTax?.taxAmount || 0,
            taxType: responseOrderData.seller?.defaultTax?.taxType || null,
            taxAmountType:
              responseOrderData.seller?.defaultTax?.taxAmountType ||
              TaxAmountTypeEnum.PERCENTAGE,
          };
        } else {
          formOrderItem.taxItem = {
            taxLabel: responseOrderItem.taxLabel || "NONE",
            taxAmount: responseOrderItem.tax || 0,
            taxType: responseOrderItem.taxType || null,
            taxAmountType:
              responseOrderItem.taxAmountType || TaxAmountTypeEnum.PERCENTAGE,
          };
        }

        formOrderItem.conversion = responseOrderItem.conversion;
        return formOrderItem;
      }
    );

    return formOrderData;
  }

  async function getAndSetInitialOrderData(orderId: string | undefined) {
    /** defined tempCurrentRevision to be used directly */
    let tempCurrentRevision = undefined;

    const responseOrderData = await OrderService.getOrderPublic(orderId);
    if (responseOrderData) {
      console.log(
        "Response Get Order :::",
        responseOrderData,
        session?.activeBusiness.id,
        internalAccountList
      );

      setOrderType(params?.orderType);

      console.log("====================================");
      console.log(
        "Response Data and location path :::",
        responseOrderData,
        location.pathname
      );
      console.log("====================================");

      if (
        responseOrderData.paymentFlowType ===
        PaymentFlowTypeEnum.MULTIPLE_PAYMENTS
      ) {
        setMultiPaymentsData(responseOrderData.multiPaymentsData);
      }

      setCurrentOrderStatus(responseOrderData.status || OrderStatusEnum.DRAFT);

      setIsReadOnly(true);
      /**
       * assigning values to states required by Adjustment Order
       */
      setInvoiceNumber(
        getInvoiceNumber(responseOrderData, responseOrderData.orderRevisions) ||
          null
      );
      setRevisions(responseOrderData.orderRevisions);
      setMainOrderCreatedAt(responseOrderData.createdAt);
      tempCurrentRevision = revisionId
        ? responseOrderData.orderRevisions.find(
            (r: IOrderRevision) => r.id === revisionId
          )
        : undefined;
      setCurrentRevision(tempCurrentRevision);

      setSellerCountry(responseOrderData.seller?.companyCountry || "");

      setOrderStatusTimestamps({
        submittedAt: responseOrderData.submittedAt,
        confirmedAt: responseOrderData.confirmedAt,
        processedAt: responseOrderData.processedAt,
        completedAt: responseOrderData.completedAt,
      });

      let initialOrderData: IOrderSavePayload =
        changeOrderResponseToFormFormat(responseOrderData);
      initialOrderData.createdById = responseOrderData.createdBy;

      initialOrderData.invoiceNumber = responseOrderData.invoiceNumber || null;

      if (params.orderType === "sales") {
        /** Current active business is seller */

        setOrderType("sales");
        setSelectedInternalAccount(responseOrderData.sellerUser);
        // handleOnChangeInternalAccount("sales", responseOrderData.sellerUser);
        // handleOnChangeExternalBusiness("sales", responseOrderData.buyer);
        // responseOrderData.buyer &&
        //   setSelectedExternalBusiness(responseOrderData.buyer);

        getAndSetNationalTaxes(responseOrderData.seller?.id);
        getAndSetProductSearchList("sales", responseOrderData.seller?.id, null);
      } else if (params.orderType === "purchase") {
        /** Current active business is buyer */

        setOrderType("purchase");
        setSelectedInternalAccount(responseOrderData.buyerUser);
        // responseOrderData.seller &&
        //   setSelectedExternalBusiness(responseOrderData.seller);
        // handleOnChangeExternalBusiness("purchase", responseOrderData.seller);
        getAndSetNationalTaxes(responseOrderData.seller?.id);
        getAndSetProductSearchList(
          "purchase",
          responseOrderData.seller?.id,
          responseOrderData.buyer?.id
        );
      }

      initialOrderData.sellerIds = responseOrderData.seller?.id
        ? {
            businessId: responseOrderData.seller.id,
            accountId: responseOrderData.sellerUser.id,
          }
        : null;

      initialOrderData.buyerIds = responseOrderData.buyer?.id
        ? {
            businessId: responseOrderData.buyer?.id,
            accountId: responseOrderData.buyerUser?.id,
          }
        : null;

      initialOrderData.paymentFlowType = responseOrderData.paymentFlowType;

      initialOrderData.startMonth = responseOrderData.startMonth;
      initialOrderData.endMonth = responseOrderData.endMonth;
      initialOrderData.recurringDay = responseOrderData.recurringDay;
      initialOrderData.recurringParentOrderId =
        responseOrderData.recurringParentOrderId || null;

      initialOrderData.paymentTerm = responseOrderData.paymentTerm;
      initialOrderData.currency =
        responseOrderData.currency ||
        countryToCurrencyList[responseOrderData.seller?.companyCountry];
      initialOrderData.deliveryDate = responseOrderData.deliveryDate
        ? dayjs(responseOrderData.deliveryDate).format("YYYY-MM-DD")
        : "";
      initialOrderData.shippingMethod = responseOrderData.shippingMethod;
      initialOrderData.vehicleNumber = responseOrderData.vehicleNumber;
      initialOrderData.trackingNumber = responseOrderData.trackingNumber;
      initialOrderData.driverName = responseOrderData.driverName;
      initialOrderData.deliveryFee = responseOrderData.deliveryFee || 0;
      initialOrderData.additionalDiscount =
        responseOrderData.additionalDiscount || 0;
      initialOrderData.additionalDiscountType =
        responseOrderData.additionalDiscountType;
      initialOrderData.grandTotalAmount = responseOrderData.grandTotalAmount;
      initialOrderData.remarks = responseOrderData.remarks;

      initialOrderData.additionalTaxItem = {
        taxLabel: responseOrderData.additionalTaxLabel,
        taxAmount: responseOrderData.additionalTax,
      };
      initialOrderData.additionalTaxLabel =
        responseOrderData.additionalTaxLabel;
      initialOrderData.additionalTax = responseOrderData.additionalTax || 0;

      initialOrderData.downPayment = responseOrderData.downPayment || 0;

      // TODO: Felix: Public View Order
      /** One OrderItem, One Product.. (only if there's orderItem data) */
      console.log("Response Order Items :::", responseOrderData.orderItems);
      initialOrderData.orderItems = responseOrderData.orderItems?.map(
        (orderItem: any) => {
          orderItem.orderItemId = orderItem.id;
          orderItem.productId = orderItem.inventoryItem.id;
          orderItem.createdById = orderItem.createdBy;
          orderItem.businessId = orderItem.inventoryItem.businessId;
          orderItem.sku = orderItem.sku || orderItem.inventoryItem.sku;
          orderItem.productName =
            orderItem.productName || orderItem.inventoryItem.productName;
          orderItem.productDescription =
            orderItem.productDescription ||
            orderItem.inventoryItem.productDescription;
          orderItem.inventoryType = orderItem.inventoryItem.inventoryType;
          orderItem.uomList = transformUomList(orderItem.inventoryItem);
          orderItem.uomItem = orderItem.uomList.find(
            (option: any) => option.uom === orderItem.uom
          );
          orderItem.uom = orderItem.uom;
          orderItem.taxLabel = orderItem.taxLabel;
          orderItem.tax = orderItem.tax;
          orderItem.taxItem = {
            taxLabel: orderItem.taxLabel,
            taxAmount: orderItem.tax,
          };
          orderItem.conversion = orderItem.conversion;
          return orderItem;
        }
      );

      console.log("initial Order Data (before reset)", initialOrderData);

      reset(initialOrderData);
    }
  }

  const multiPaymentContainer = useMultiPaymentContainer({
    orderId: orderId ?? "",
    orderStatus: currentOrderStatus,
    grandTotal: getValues("grandTotalAmount") ?? 0,
    multiPaymentsData: multiPaymentsData,
    currency: getValues("currency") ?? "IDR",
    isReadOnly: isReadOnly,
    isSeller: false,
  });

  const [paymentTransactionDetails, setPaymentTransactionDetails] =
    useState<any>(null);
  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);

  const getPaymentStatus = (onSuccess?: () => void) => {
    PaymentService.enquiryPaymentStatus(
      orderId ?? "",
      currentOrderStatus === OrderStatusEnum.PENDING_FINAL_PAYMENT
        ? PaymentType.FINAL_PAYMENT
        : PaymentType.INITIAL_PAYMENT
    ).then((response) => {
      setPaymentInfo(response.data.transactionInfo);
      setPaymentTransactionDetails(response.data);
      onSuccess?.();
    });
  };

  useEffect(() => {
    if (currentOrderStatus) getPaymentStatus();
  }, [currentOrderStatus]);

  const handleOnChangeInternalAccount = (orderT: any, selectedAcc: any) => {
    setSelectedInternalAccount(selectedAcc);
    if (orderT === "sales") {
      setValue(
        "sellerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );

      setValue(
        "currency",
        selectedAcc?.role?.[0]?.business?.bankInfo?.[0]?.currency ||
          setDefaultCurrency(selectedAcc?.role?.[0]?.business?.companyCountry)
      );
    } else if (orderT === "purchase") {
      setValue(
        "buyerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );
    }
  };

  // Function to focus on the first field with an error
  useEffect(() => {
    // const firstErrorField = Object.keys(errors)[0];
    const firstErrorField = Object.keys(errors).find(
      (key) => (errors as any)[key]
    );
    console.log(
      "Use Effect Error Current Field ::",
      firstErrorField,
      prevFocusedError.current
    );
    if (
      JSON.stringify(firstErrorField) !==
      JSON.stringify(prevFocusedError.current)
    ) {
      (
        document.querySelector(
          `input[name="${firstErrorField}"]`
        ) as HTMLInputElement | null
      )?.focus();

      prevFocusedError.current = firstErrorField;
    }
  }, [Object.keys(errors)]);

  const navigate = useNavigate();

  const FooterActionButtons = () => {
    return (
      <>
        <BtnOutlinedPrimarySm
          onClick={() => navigate(BrowserRouterConstants.Signin)}
          label={t("landing.loginBtn")}
        />
        <BtnFilledPrimarySm
          onClick={() => navigate(BrowserRouterConstants.Signup)}
          label={t("landing.createAccountBtn")}
          sx={{ order: { xs: -1, md: 1 } }}
        />
      </>
    );
  };

  // const { toPDF, targetRef } = usePDF({
  //   filename: `order-${pdfContent?.invoiceNumber || "00000000-0000000"}.pdf`,
  // });
  /** UI Presentation part */
  return (
    <FormProvider {...methods}>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <form>
        <Box
          sx={{
            borderRadius: "sm",
            paddingTop: "8px",
            display: "grid",
            gap: isRightSidebarHidden ? "0" : "24px",
            gridTemplateColumns: {
              xs: "1fr",
              md: isRightSidebarHidden ? "1fr 0" : "1fr 24%",
            },
            transition: "0.5s",
            maxWidth: "1200px",
            marginX: "auto",
          }}
        >
          {/** Main Column */}
          <Box
            sx={
              !(isMobile || isRightSidebarHidden)
                ? {
                    borderRight: "1px solid var(--joy-palette-divider)",
                    paddingRight: "24px",
                  }
                : {}
            }
          >
            {/* Order Header Sticky */}
            <Stack
              py={2}
              width={{ xs: "90vw", md: "100%" }}
              position={"sticky"}
              top={0}
              zIndex={99}
              bgcolor={"var(--joy-palette-common-white)"}
              className={`sticky-bg-white ${
                isScrollDown ? "scroll-down-shadow" : ""
              }`}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              columnGap={2}
            >
              <OrderHeader
                invoiceNumber={
                  currentRevision?.content.invoiceNumber || invoiceNumber
                }
                currentOrderStatus={
                  currentRevision?.content.status || currentOrderStatus
                }
                // toPDF={toPDF}
                {...{
                  orderType,
                  revisions,
                  isCreator: false,
                  // handleGeneratePdf,
                  // paymentLink,
                }}
              />

              {/* Desktop Mini - Order Status Stepper */}
              {!isMobile && isRightSidebarHidden && (
                <Stack flexBasis={"50%"} maxWidth={360}>
                  <OrderStatusStepper
                    {...{
                      currentOrderStatus,
                      singlePaymentInfo: paymentInfo,
                      multiPaymentContainer,
                      orderStatusTimestamps,
                      isRightSidebarHidden,
                      setIsRightSidebarHidden,
                    }}
                  />
                </Stack>
              )}

              {/* Sidebar Hiding Icon Button */}
              {!isRightSidebarHidden && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 20,
                    right: -40,
                    zIndex: 999,
                    background: "var(--joy-palette-common-white)",
                    // color: "var(--joy-palette-neutral-600)",
                    border: "1px solid var(--joy-palette-divider)",
                    borderRadius: "50%",
                    display: { xs: "none", md: "inline-flex" },
                  }}
                  onClick={() => {
                    setIsRightSidebarHidden(!isRightSidebarHidden);
                  }}
                >
                  <ChevronDoubleRightIcon
                    width={20}
                    height={20}
                    strokeWidth={2}
                  />
                </IconButton>
              )}
            </Stack>

            <Divider />

            {/** Mobile Order Status Stepper */}
            <Stack
              display={{ xs: "block", md: "none" }}
              spacing={3}
              my={4}
              width={{ xs: "90vw", md: "100%" }}
            >
              <OrderStatusStepper
                {...{
                  currentOrderStatus,
                  singlePaymentInfo: paymentInfo,
                  multiPaymentContainer,
                  orderStatusTimestamps,
                }}
              />
              {/* Order Summary */}
              {/* <OrderSummary
                orderType={orderType}
                invoiceNumber={
                  currentRevision?.content.invoiceNumber || invoiceNumber
                }
                currentOrderStatus={
                  currentRevision?.content.status || currentOrderStatus
                }
                // handleGeneratePdf={handleGeneratePdf}
                isReadOnly={isReadOnly}
                autoSaveLoading={autoSaveLoading}
                paymentLink={paymentLink}
                revisions={revisions}
                paymentInfo={paymentInfo}
                latestOrderUpdatedAt={latestOrderUpdatedAt}
              /> */}
            </Stack>

            <Stack spacing={4} my={4}>
              {/* First Party Information - Internal Account */}
              <FirstPartyInfo
                {...{
                  internalAccountList,
                  selectedInternalAccount,
                  isReadOnly,
                  handleOnChangeInternalAccount,
                  orderType,
                }}
              />
              <Divider />

              {/* Second Party Information - External Business */}
              <SecondPartyInfo
                {...{
                  externalBusinessList,
                  isReadOnly,
                  isCreator: false,
                  currentOrderStatus,
                  orderType,
                }}
              />
              <Divider />
            </Stack>

            {/** Type of Payment Flow */}
            <Stack spacing={4} my={4} width={{ xs: "90vw", md: "100%" }}>
              <Toggler
                defaultExpanded={true}
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <SectionToggleBar
                    {...{ open, setOpen, sxClasses }}
                    icon={<BanknotesIcon width={24} height={24} />}
                    title={t("order.createOrder.typeOfPaymentFlow")}
                    isRequired={true}
                  />
                )}
              >
                <Stack>
                  <Grid container spacing={4}>
                    {/* Payment Flow Type */}
                    <Grid xs={12} lg={6}>
                      <Controller
                        name="paymentFlowType"
                        control={control}
                        render={({
                          field: { onChange, onBlur, name, value, ref },
                        }) => {
                          return (
                            <FormControl ref={ref}>
                              <FormLabel>
                                {t("order.createOrder.form.paymentFlowType")}
                              </FormLabel>
                              <Autocomplete
                                name={name}
                                freeSolo={true}
                                placeholder={t(
                                  "order.createOrder.form.paymentFlowType"
                                )}
                                options={paymentFlowTypes?.filter(
                                  (pft) =>
                                    pft.value !==
                                    PaymentFlowTypeEnum.SPLIT_PAYMENT
                                )}
                                getOptionLabel={(option) =>
                                  typeof option !== "string"
                                    ? option?.label
                                    : ""
                                }
                                onChange={(_event, newValue) => {
                                  if (typeof newValue !== "string") {
                                    if (
                                      newValue &&
                                      newValue.value !==
                                        PaymentFlowTypeEnum.SPLIT_PAYMENT
                                    ) {
                                      setValue("downPayment", 0);
                                    }
                                    if (
                                      newValue &&
                                      newValue.value ===
                                        PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
                                    ) {
                                      setValue("paymentTerm", null);
                                    }
                                    if (
                                      newValue &&
                                      newValue.value !==
                                        PaymentFlowTypeEnum.RECURRING_PAYMENT
                                    ) {
                                      setValue("startMonth", null);
                                      setValue("endMonth", null);
                                      setValue("recurringDay", null);
                                    }

                                    onChange(
                                      newValue?.value as
                                        | keyof typeof PaymentFlowTypeEnum
                                        | null
                                    ); // set the Payment Flow Type's value only when the option changes
                                  }
                                }}
                                value={
                                  paymentFlowTypes.find(
                                    (pft) => pft.value === value
                                  ) || null
                                }
                                onBlur={onBlur}
                                disabled={isReadOnly}
                                renderOption={(props, option) => (
                                  <AutocompleteOption
                                    {...props}
                                    key={option.value}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor:
                                          "var(--joy-palette-neutral-100)",
                                      },
                                    }}
                                  >
                                    <Stack
                                      direction={"row"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                      flex={1}
                                    >
                                      <Stack>
                                        {option.label}
                                        <Typography>
                                          {option.description}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </AutocompleteOption>
                                )}
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>

                    {/* Payment Times */}
                    {getValues("paymentFlowType") ===
                      PaymentFlowTypeEnum.MULTIPLE_PAYMENTS && (
                      <Grid xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>
                            {t("order.multiPayment.paymentTimesLabel")}
                          </FormLabel>
                          <Select
                            disabled={!!isReadOnly}
                            value={multiPaymentContainer.watch("paymentTimes")}
                            onChange={(_, value) => {
                              multiPaymentContainer.setValue(
                                "paymentTimes",
                                value as number
                              );
                              multiPaymentContainer.setValue(
                                "paymentHistory",
                                Array.from({ length: value as number }).map(
                                  (_, num) => ({
                                    time: num + 1,
                                    amount: 0,
                                    percentage: 0,
                                    status: "PENDING",
                                  })
                                )
                              );
                            }}
                            indicator={<KeyboardArrowDown />}
                            sx={{
                              minWidth: 200,
                            }}
                          >
                            {Array.from({ length: 13 }).map(
                              (_, num) =>
                                num > 1 && (
                                  <Option key={num} value={num}>
                                    {num} {t("commons.payment")}
                                  </Option>
                                )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {/* Recurring Payment */}
                    {getValues("paymentFlowType") ===
                      PaymentFlowTypeEnum.RECURRING_PAYMENT && (
                      <Grid xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            justifyContent={"space-between"}
                            gap={2}
                            sx={{ width: "100%", ["& > *"]: { flexGrow: 1 } }}
                          >
                            <FormControl>
                              <FormLabel>
                                {t("order.createOrder.form.startMonth")}
                              </FormLabel>

                              <Controller
                                name="startMonth"
                                control={control}
                                render={({
                                  field: { name, value, ref, onChange },
                                }) => (
                                  <DesktopDatePicker
                                    views={["year", "month"]}
                                    slots={
                                      {
                                        // calendarHeader: CustomDatePickerHeader,
                                      }
                                    }
                                    // openTo=
                                    minDate={dayjs(new Date()).utc(true)}
                                    sx={{
                                      "--mui-shape-borderRadius": "8px",
                                      "--mui-palette-text-disabled":
                                        "var(--joy-palette-common-black)",
                                      color: "red",
                                      "& .MuiOutlinedInput-notchedOutline, & > .Mui-disabled .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor:
                                            "var(--joy-palette-neutral-400)",
                                        },
                                      "& > .Mui-disabled": {
                                        backgroundColor:
                                          "var(--joy-palette-neutral-200)",
                                      },
                                    }}
                                    slotProps={{
                                      popper: {
                                        sx: {
                                          "& .MuiDateCalendar-root": {
                                            height: "auto !important",
                                          },
                                        },
                                      },
                                      yearButton: {
                                        sx: {
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: 0,
                                          border:
                                            "1px solid var(--joy-palette-neutral-200)",
                                          "&:hover, &:focus, &.Mui-selected": {
                                            backgroundColor:
                                              "var(--joy-palette-primary-500) !important",
                                            color:
                                              "var(--joy-palette-common-white)",
                                          },
                                          "&.Mui-disabled": {
                                            background:
                                              "var(--joy-palette-neutral-200)",
                                            color:
                                              "var(--joy-palette-neutral-400)",
                                          },
                                        },
                                      },
                                      monthButton: {
                                        sx: {
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: 0,
                                          border:
                                            "1px solid var(--joy-palette-neutral-200)",
                                          "&:hover, &:focus, &.Mui-selected": {
                                            backgroundColor:
                                              "var(--joy-palette-primary-500) !important",
                                            color:
                                              "var(--joy-palette-common-white)",
                                          },
                                          "&.Mui-disabled": {
                                            background:
                                              "var(--joy-palette-neutral-200)",
                                            color:
                                              "var(--joy-palette-neutral-400)",
                                          },
                                        },
                                      },
                                    }}
                                    disabled={isReadOnly}
                                    name={name}
                                    value={dayjs(value).utc(true) || ""}
                                    onChange={onChange}
                                    inputRef={ref}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel>
                                {t("order.createOrder.form.endMonth")}
                              </FormLabel>
                              <Controller
                                name="endMonth"
                                control={control}
                                render={({
                                  field: { name, value, ref, onChange },
                                }) => (
                                  <DesktopDatePicker
                                    views={["year", "month"]}
                                    minDate={dayjs(
                                      getValues("startMonth") || new Date()
                                    ).utc(true)}
                                    sx={{
                                      "--mui-shape-borderRadius": "8px",
                                      "--mui-palette-text-disabled":
                                        "var(--joy-palette-common-black)",
                                      color: "red",
                                      "& .MuiOutlinedInput-notchedOutline, & > .Mui-disabled .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor:
                                            "var(--joy-palette-neutral-400)",
                                        },
                                      "& > .Mui-disabled": {
                                        backgroundColor:
                                          "var(--joy-palette-neutral-200)",
                                      },
                                    }}
                                    slotProps={{
                                      yearButton: {
                                        sx: {
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: 0,
                                          border:
                                            "1px solid var(--joy-palette-neutral-200)",
                                          "&:hover, &:focus, &.Mui-selected": {
                                            backgroundColor:
                                              "var(--joy-palette-primary-500) !important",
                                            color:
                                              "var(--joy-palette-common-white)",
                                          },
                                          "&.Mui-disabled": {
                                            background:
                                              "var(--joy-palette-neutral-100)",
                                            color:
                                              "var(--joy-palette-neutral-400)",
                                          },
                                        },
                                      },
                                      monthButton: {
                                        sx: {
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: 0,
                                          border:
                                            "1px solid var(--joy-palette-neutral-200)",
                                          "&:hover, &:focus, &.Mui-selected": {
                                            backgroundColor:
                                              "var(--joy-palette-primary-500) !important",
                                            color:
                                              "var(--joy-palette-common-white)",
                                          },
                                          "&.Mui-disabled": {
                                            background:
                                              "var(--joy-palette-neutral-100)",
                                            color:
                                              "var(--joy-palette-neutral-400)",
                                          },
                                        },
                                      },
                                    }}
                                    disabled={
                                      isReadOnly &&
                                      (orderType !== "sales" ||
                                        currentOrderStatus ===
                                          OrderStatusEnum.COMPLETE)
                                    }
                                    name={name}
                                    value={dayjs(value)}
                                    onChange={onChange}
                                    inputRef={ref}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel>
                                {t("order.createOrder.form.recurringDay")}
                              </FormLabel>
                              <Controller
                                name="recurringDay"
                                control={control}
                                render={({
                                  field: { name, value, onChange },
                                }) => (
                                  <TwentyEightDaysPicker
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder={"asd"}
                                    disabled={
                                      isReadOnly &&
                                      (orderType !== "sales" ||
                                        currentOrderStatus ===
                                          OrderStatusEnum.COMPLETE)
                                    }
                                  />
                                )}
                              />
                            </FormControl>
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                    )}

                    {getValues("paymentFlowType") !==
                      PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY && (
                      <Grid xs={12}>
                        <FormControl>
                          <FormLabel>
                            {t("order.createOrder.form.payment")}
                          </FormLabel>
                          <Input
                            placeholder={t("order.createOrder.form.payment")}
                            {...register("paymentTerm")}
                            disabled={isReadOnly}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </Toggler>
            </Stack>

            <Divider />

            {/* Section - Related Orders */}
            <If condition={revisions.length > 0}>
              <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
                <RelatedOrders
                  revisions={revisions}
                  orderType={orderType}
                  invoiceNumber={invoiceNumber}
                  currentOrderStatus={currentOrderStatus}
                  mainOrderCreatedAt={mainOrderCreatedAt}
                  currentRevision={currentRevision}
                />

                <Divider />
              </Stack>
            </If>

            <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
              {/** Section - Add Product & Service */}
              <ProductAndService
                nationalTaxes={nationalTaxesWithOthers}
                productSearchList={productSearchList}
                isReadOnly={isReadOnly}
                isBusinessAuthorized={false}
                orderType={orderType}
                sellerCountry={sellerCountry}
                currentRevision={currentRevision}
                // updateItemCallback={(items: any[]) => {
                //   validateLowStockQty(items);
                // }}
                revisions={revisions}
                orderStatus={currentOrderStatus}
              />

              <Divider />
            </Stack>

            {/* Section - Delivery Info */}
            <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
              <Toggler
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <SectionToggleBar
                    {...{ open, setOpen, sxClasses }}
                    icon={<TruckIcon width={24} height={24} />}
                    title={t("order.createOrder.deliveryForm.addDeliveryInfo")}
                    isRequired={false}
                  />
                )}
              >
                <Grid container spacing={2} mt={1}>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.driverName")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.driverName"
                        )}
                        defaultValue={""}
                        {...register("driverName")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.deliveryDate")}
                      </FormLabel>
                      <Input
                        type="date"
                        aria-label="Date"
                        {...register("deliveryDate")}
                        error={!!errors.deliveryDate}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.shippingMethod")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.shippingMethod"
                        )}
                        defaultValue={""}
                        {...register("shippingMethod")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.vehicleNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.vehicleNumber"
                        )}
                        defaultValue={""}
                        {...register("vehicleNumber")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.trackingNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.trackingNumber"
                        )}
                        defaultValue={""}
                        {...register("trackingNumber")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Toggler>
              <Divider />

              {/** Part - Remarks */}
              <Toggler
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <SectionToggleBar
                    {...{ open, setOpen, sxClasses }}
                    icon={<NewspaperIcon width={24} height={24} />}
                    title={t("order.createOrder.summary.remarksLabel")}
                    isRequired={false}
                  />
                )}
              >
                <Stack rowGap={4}>
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue={""}
                    rules={{
                      maxLength: {
                        value: 255,
                        message: t(
                          "order.createOrder.error.remarksMaxLength255"
                        ),
                      },
                    }}
                    render={({
                      field: { name, ref, onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <FormControl error={!!error} ref={ref}>
                        <FormLabel>
                          {t("order.createOrder.summary.remarksLabel")}
                        </FormLabel>
                        <Textarea
                          placeholder={t(
                            "order.createOrder.summary.remarksPlaceholder"
                          )}
                          minRows={4}
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          onBlur={onBlur}
                          value={value ?? ""}
                          disabled={isReadOnly || (revisionId && true)}
                          // slotProps={{
                          //   textarea: { maxLength: 255 },
                          // }}
                        />
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Stack>
              </Toggler>
              <Divider />

              {/** Part - Grand Total Amount */}
              <GrandTotal
                sellerCountry={sellerCountry}
                nationalTaxes={nationalTaxesWithOthers}
                isReadOnly={isReadOnly}
              />
              {getValues("paymentFlowType") ===
                PaymentFlowTypeEnum.MULTIPLE_PAYMENTS && (
                <MultiplePaymentPlan container={multiPaymentContainer} />
              )}
            </Stack>

            {/* Order Footer */}
            <Stack
              mt={4}
              position={"sticky"}
              bottom={0}
              zIndex={99}
              bgcolor={"white"}
              className="sticky-bg-white scroll-up-shadow"
            >
              <Divider />
              <OrderFooter
                {...{ footerActionButtons: <FooterActionButtons /> }}
              />
            </Stack>
          </Box>

          {/** Right Sidebar Column - Laptop & Desktop */}
          <Box
            alignSelf={"start"}
            position={"sticky"}
            top={24}
            display={isMobile || isRightSidebarHidden ? "none" : "grid"}
            justifyContent={"stretch"}
            alignContent={"start"}
            gap={3}
            sx={{
              opacity: isRightSidebarHidden ? "0" : "1",
            }}
          >
            <OrderStatusStepper
              {...{
                currentOrderStatus,
                singlePaymentInfo: paymentInfo,
                multiPaymentContainer,
                orderStatusTimestamps,
              }}
            />
            {/** Order Summary */}
            {/* <OrderSummary
              orderType={orderType}
              invoiceNumber={
                currentRevision?.content.invoiceNumber || invoiceNumber
              }
              currentOrderStatus={
                currentRevision?.content.status || currentOrderStatus
              }
              handleGeneratePdf={handleGeneratePdf}
              autoSaveLoading={autoSaveLoading}
              paymentLink={paymentLink}
              revisions={revisions}
              paymentInfo={paymentInfo}
              latestOrderUpdatedAt={latestOrderUpdatedAt}
            /> */}

            {/** Part - Sidebar Action Buttons - Laptop & Desktop */}
            {/* <Stack direction={"column"} alignItems={"center"} gap={2}>
              <OrderActionButtons />
            </Stack> */}
          </Box>
        </Box>

        {/** Modals */}
        {paymentTransactionDetails && paymentInfo && (
          <PaymentInfoModal
            open={showPaymentInfoModal}
            setOpenModal={setShowPaymentInfoModal}
            paymentInfo={paymentInfo}
            status={paymentTransactionDetails?.status}
            onFinish={() => window.location.reload()}
            currency={getValues("currency") ?? ""}
          />
        )}
        <SuccessModal
          open={successModal.open}
          title={successModal.title}
          content={successModal.content}
        />

        <LoadingModal isLoading={loading} />
      </form>
    </FormProvider>
  );
}
