import {
  Autocomplete,
  AutocompleteOption,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../components/Theme";
import { Controller, useFormContext } from "react-hook-form";
import { PaymentFlowTypeEnum, paymentFlowTypes } from "../../../../types/order";
import { KeyboardArrowDown } from "@mui/icons-material";
import { currencyList } from "../../../Order/EditOrders";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { useState } from "react";
import ChangeCurrencyModal from "../../../Inventory/components/ChangeCurrencyModal";
import { CustomCreateCatalogType, TaxIDType } from "../../../../types/catalog";

type Props = {
  catalog: CustomCreateCatalogType;
  nationalTaxes: TaxIDType[];
};

const CatalogInfoSection = ({ catalog, nationalTaxes }: Props) => {
  const { t } = useTranslation();

  const [currencyModal, setCurrencyModal] = useState({
    show: false,
    currency: catalog.currency,
  });
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  // watched fields
  const selectedTax = nationalTaxes?.find((tax) => tax.id === watch("taxId"));
  const oldCurrency = watch("currency");

  /**
   * get national taxes based on business's country
   */
  // async function getAndSetNationalTaxes() {
  //   try {
  //     const res = await TaxService.getTaxesOption(
  //       session?.activeBusiness.companyCountry as string
  //     );

  //     setNationalTaxes(res.map((res: any) => res));
  //     setValue(
  //       "taxId",
  //       nationalTaxes.find((t) => t.taxLabel === "NONE") || null
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // useEffect(() => {
  //   getAndSetNationalTaxes();
  // }, [session?.activeBusiness.id]);

  return (
    <Box
      sx={{
        borderWidth: "1px",
        borderColor: theme.palette.neutral[300],
        borderRadius: "8px",
        py: "32px",
        px: "24px",
      }}
    >
      <Typography level="text-xl-bold" sx={{ color: "#101828" }}>
        {t("catalog.createCatalog.catalogInformation")}
      </Typography>
      <Grid container spacing={2} mt={4} mb={2}>
        <Grid xs={12} lg={6}>
          <FormControl sx={{ flex: "1" }} error={!!errors.catalogName}>
            <FormLabel>
              {t("catalog.createCatalog.catalogueName")}{" "}
              <span className="text-danger-400">*</span>
            </FormLabel>
            <Input
              type="text"
              placeholder={t("catalog.createCatalog.catalogueName")}
              aria-label="catalogName"
              {...register("catalogName", {
                required: t("commons.error.form.required", {
                  label: t("catalog.createCatalog.catalogueName"),
                }),
                minLength: {
                  value: 3,
                  message: t("commons.error.form.minLength", {
                    label: t("catalog.createCatalog.catalogueName"),
                    min: 3,
                  }),
                },
              })}
              error={!!errors.catalogName}
              className="min-h-[40px]"
            />
            <FormHelperText>
              {(errors.catalogName as any)?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} lg={6}>
          <Controller
            name="paymentFlowType"
            control={control}
            render={({ field: { onChange, onBlur, name, value, ref } }) => {
              return (
                <FormControl sx={{ flex: "1" }} ref={ref}>
                  <FormLabel>
                    {t("order.createOrder.form.paymentFlowType")}
                  </FormLabel>
                  <Autocomplete
                    name={name}
                    freeSolo={true}
                    placeholder={t("order.createOrder.form.paymentFlowType")}
                    options={paymentFlowTypes.filter(
                      (pt) => pt.value !== PaymentFlowTypeEnum.SPLIT_PAYMENT
                    )}
                    disabled={catalog.catalogType == "PUBLIC"}
                    getOptionLabel={(option) =>
                      typeof option !== "string" ? option?.label : ""
                    }
                    onChange={(_event, newValue) => {
                      if (typeof newValue !== "string") {
                        onChange(
                          newValue?.value as
                            | keyof typeof PaymentFlowTypeEnum
                            | null
                        );
                      }
                    }}
                    value={
                      paymentFlowTypes.find((pft) => pft.value === value) ||
                      null
                    }
                    onBlur={onBlur}
                    sx={{ width: { xs: "100%", lg: "100%" } }}
                    renderOption={(props, option) => (
                      <AutocompleteOption
                        {...props}
                        key={option.value}
                        sx={{
                          "&:hover": {
                            backgroundColor: "var(--joy-palette-neutral-100)",
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          flex={1}
                        >
                          <Stack>
                            {option.label}
                            <Typography>{option.description}</Typography>
                          </Stack>
                        </Stack>
                      </AutocompleteOption>
                    )}
                  />
                </FormControl>
              );
            }}
          />
          <FormHelperText>
            {(errors.paymentFlowType as any)?.message}
          </FormHelperText>
        </Grid>
        <Grid xs={12} lg={6}>
          <Controller
            name="currency"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, ref } }) => (
              <FormControl sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
                <FormLabel>
                  {t("catalog.inventory.editForm.currency")}
                  <span className="text-danger-400">*</span>
                </FormLabel>

                <Select
                  ref={ref}
                  color={errors.currency ? "danger" : "neutral"}
                  indicator={<KeyboardArrowDown />}
                  placeholder={t("catalog.inventory.editForm.currency")}
                  slotProps={{
                    button: {
                      sx: { whiteSpace: "nowrap" },
                    },
                  }}
                  onChange={(_event, newValue: string | null) => {
                    if (
                      !newValue ||
                      // !oldCurrency ||
                      oldCurrency === newValue
                    ) {
                      return;
                    }

                    setCurrencyModal({
                      show: true,
                      currency: newValue,
                    });
                  }}
                  value={value}
                  className="mt-auto h-[41.6px]"
                >
                  {currencyList.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>

                <FormHelperText>
                  {t("catalog.createCatalog.currencyNote")}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <Controller
            name={`taxId`}
            control={control}
            render={({
              field: { onChange, onBlur, name, ref },
              fieldState: { error },
            }) => (
              <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                <FormLabel>
                  {t("order.createOrder.productForm.taxAdditional")}
                </FormLabel>
                <Autocomplete
                  name={name}
                  placeholder={t("order.createOrder.productForm.taxAdditional")}
                  popupIcon={<KeyboardArrowDown />}
                  options={nationalTaxes || []}
                  getOptionLabel={(option) => option?.taxLabel || ""}
                  onChange={(_event, newValue: any) => {
                    onChange(newValue);
                    console.log("National Tax Item changed? ::", newValue);
                    if (newValue) {
                      setValue("taxId", newValue.id);
                    }
                  }}
                  onBlur={onBlur}
                  value={
                    catalog.taxId ||
                    selectedTax ||
                    nationalTaxes.find((tax) => tax.taxLabel === "NONE") ||
                    null
                  }
                />

                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="periodStartDate"
              control={control}
              render={({
                field: { name, value, ref, onChange },
                fieldState: { error },
              }) => (
                <FormControl sx={{ flexGrow: 1 }}>
                  <FormLabel>
                    {t("catalog.createCatalog.catalogStartPeriod")}
                  </FormLabel>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    format="YYYY/MM/DD"
                    minDate={dayjs(new Date()).utc(true)}
                    slotProps={{
                      textField: {
                        sx: {
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "8px",
                            },
                          },
                        },
                      },
                      popper: {
                        sx: {
                          "& .MuiDateCalendar-root": {
                            height: "auto !important",
                          },
                        },
                      },
                      yearButton: {
                        sx: {
                          width: "100%",
                          height: "100%",
                          borderRadius: 0,
                          border: "1px solid var(--joy-palette-neutral-200)",
                          "&:hover, &:focus, &.Mui-selected": {
                            backgroundColor:
                              "var(--joy-palette-primary-500) !important",
                            color: "var(--joy-palette-common-white)",
                          },
                          "&.Mui-disabled": {
                            background: "var(--joy-palette-neutral-100)",
                            color: "var(--joy-palette-neutral-400)",
                          },
                        },
                      },
                      monthButton: {
                        sx: {
                          width: "100%",
                          height: "100%",
                          borderRadius: 0,
                          border: "1px solid var(--joy-palette-neutral-200)",
                          "&:hover, &:focus, &.Mui-selected": {
                            backgroundColor:
                              "var(--joy-palette-primary-500) !important",
                            color: "var(--joy-palette-common-white)",
                          },
                          "&.Mui-disabled": {
                            background: "var(--joy-palette-neutral-100)",
                            color: "var(--joy-palette-neutral-400)",
                          },
                        },
                      },
                    }}
                    name={name}
                    value={value ? dayjs(value, "YYYY-MM-DD") : null}
                    onChange={(date) => {
                      const d = dayjs(date).format("YYYY-MM-DD");
                      onChange(dayjs(d).isValid() ? d : null);
                    }}
                    inputRef={ref}
                  />
                  {error?.message}
                </FormControl>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12} lg={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="periodEndDate"
              control={control}
              render={({
                field: { name, value, ref, onChange },
                fieldState: { error },
              }) => (
                <FormControl sx={{ flexGrow: 1 }}>
                  <FormLabel>
                    {t("catalog.createCatalog.catalogEndPeriod")}
                  </FormLabel>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    format="YYYY/MM/DD"
                    minDate={dayjs(new Date()).utc(true)}
                    slotProps={{
                      textField: {
                        sx: {
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "8px",
                            },
                          },
                        },
                      },
                      popper: {
                        sx: {
                          "& .MuiDateCalendar-root": {
                            height: "auto !important",
                          },
                        },
                      },
                      yearButton: {
                        sx: {
                          width: "100%",
                          height: "100%",
                          borderRadius: 0,
                          border: "1px solid var(--joy-palette-neutral-200)",
                          "&:hover, &:focus, &.Mui-selected": {
                            backgroundColor:
                              "var(--joy-palette-primary-500) !important",
                            color: "var(--joy-palette-common-white)",
                          },
                          "&.Mui-disabled": {
                            background: "var(--joy-palette-neutral-100)",
                            color: "var(--joy-palette-neutral-400)",
                          },
                        },
                      },
                      monthButton: {
                        sx: {
                          width: "100%",
                          height: "100%",
                          borderRadius: 0,
                          border: "1px solid var(--joy-palette-neutral-200)",
                          "&:hover, &:focus, &.Mui-selected": {
                            backgroundColor:
                              "var(--joy-palette-primary-500) !important",
                            color: "var(--joy-palette-common-white)",
                          },
                          "&.Mui-disabled": {
                            background: "var(--joy-palette-neutral-100)",
                            color: "var(--joy-palette-neutral-400)",
                          },
                        },
                      },
                    }}
                    name={name}
                    value={value ? dayjs(value, "YYYY-MM-DD") : null}
                    onChange={(date) => {
                      const d = dayjs(date).format("YYYY-MM-DD");
                      onChange(dayjs(d).isValid() ? d : null);
                    }}
                    inputRef={ref}
                  />
                  {error?.message}
                </FormControl>
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {/* modal */}
      <ChangeCurrencyModal
        show={currencyModal.show}
        onClick={() => {
          setValue("currency", currencyModal.currency);
          setCurrencyModal({
            show: false,
            currency: currencyModal.currency,
          });
        }}
        onClose={() => {
          setCurrencyModal({
            show: false,
            currency: currencyModal.currency,
          });
        }}
      />
    </Box>
  );
};

export default CatalogInfoSection;
