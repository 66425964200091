import dayjs from "dayjs";
import { ItemType } from "../types/item";

/**
 * Process an array of items to detect new items created in the last 3 minutes
 * and invoke a callback with the new items.
 *
 * @param {string} key - The key to use for storing the last 3 minutes of items in localStorage.
 * @param {ItemType[]} items - The array of items to process.
 * @param {(notAlreadySeenItems: string[]) => void} callback - The callback function to invoke with the new items created in the last 3 minutes.
 */
export const processNewItems = (
  key: string,
  items: ItemType[],
  callback: (notAlreadySeenItems: string[]) => void
): void => {
  // get already seen items from localStorage
  const alreadySeenItems: string[] = JSON.parse(
    localStorage.getItem(key) || "[]"
  );

  const currentTime = dayjs();

  // filter items created in the last 3 minutes and map to SKUs
  const newCreatedItems: string[] = items
    .filter((item) => {
      const itemCreatedAtLocal = dayjs.utc(item.createdAt).local();
      const itemUpdatedAtLocal = dayjs.utc(item.updatedAt).local();

      return (
        currentTime.diff(itemCreatedAtLocal, "second") < 180 ||
        currentTime.diff(itemUpdatedAtLocal, "second") < 180
      );
    })
    .map((item) => item.sku);

  // filter items that have not been seen in the last 3 minutes
  const notAlreadySeenItems: string[] = newCreatedItems.filter(
    (sku) => !alreadySeenItems.includes(sku)
  );

  console.log("NEWLABELITEM", {
    notAlreadySeenItems,
    newCreatedItems,
    alreadySeenItems,
  });

  // save new 3-minute items to localStorage
  localStorage.setItem(key, JSON.stringify(newCreatedItems));

  // invoke the callback with the notAlreadySeenItems
  callback(notAlreadySeenItems);
};
