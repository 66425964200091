import { Box, Button, Input, Modal, Option, Select, Stack } from "@mui/joy";
import { Checkbox, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import CopyLinkButton from "../../../components/CopyLinkButton";
import showErrorToast, { ErrorToastConfig } from "../../../components/Error";
import DeleteIcon from "../../../components/Icons/DeleteIcon";
import EmailIcon from "../../../components/Icons/EmailIcon";
import InfoIcon from "../../../components/Icons/InfoIcon";
import { TRACKER_CONSTANTS } from "../../../constants/tracker.events";
import { AccountService } from "../../../services/account.service";
import { CatalogService } from "../../../services/catalog.service";
import { InventoryService } from "../../../services/inventory.service";
import { TrackerService } from "../../../services/tracker.service";
import { IBusinessResponse } from "../../../types/business";
import { ICatalogInviteCreatePayload } from "../../../types/catalog";
import { getInitials } from "../../../utils/misc";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { countryMap } from "../../../utils/constants";

export type IAccountBusiness = IAccount & {
  businesses: IBusinessResponse[];
  newlySelectedBusinessId: string;
  recipientBusiness: string | null;
  identifier?: string | null;
};

interface Props {
  show?: boolean;
  showToast: () => void;
  onClose: () => void;
  catalogId: string;
  selectedTab: string;
  businessId: string;
  accounts: IAccountBusiness[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  // px: 3,
  // py: 3,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
  height: "90vh",
  width: { xs: "90dvw", md: "848px" },
};

const InviteModal = ({
  selectedTab,
  show = false,
  onClose,
  catalogId,
  businessId,
  accounts,
  showToast,
}: Props) => {
  const [accountBusinessAcesses, setAccountBusinessAcesses] =
    useState<IAccountBusiness[]>(accounts);
  const { t } = useTranslation();

  const [businesses, setBusinesses] = useState<IBusinessResponse[]>([]);
  const [account, setAccount] = useState<IAccount | undefined>(undefined);
  const [business, setBusiness] = useState<string | undefined>(undefined);
  const [newlyAddedAccountBusinesses, setNewlyAddedAccountBusinesses] =
    useState<(IAccountBusiness & { invitationSent: boolean })[]>([]);

  const [businessAcessesToRemove, setBusinessAcessesToRemove] = useState<
    {
      businessId: string;
      catalogId: string;
      accountId: string;
      email: string;
    }[]
  >([]);

  const [error, setError] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedAccountBusinesses, setSelectedAccountBusinesses] = useState<
    { businessId: string; accountId: string; email: string }[]
  >([]);

  const removeMutation = useMutation({
    mutationFn: CatalogService.removeCatalogAccess,
    onSuccess: () => {
      setBusinessAcessesToRemove([]);
      setisSubmitting(false);
      handleClose();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const invitationMutation = useMutation({
    mutationFn: CatalogService.sendEmailInvitation,
    onSuccess: () => {
      TrackerService.track(
        TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events
          .SellerSendInvitationToBuyer
      );
      setisSubmitting(false);
      setBusinessAcessesToRemove([]);
      showToast();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const catalogInvitationMutation = useMutation({
    mutationFn: InventoryService.createCatalogInvite,
    onSuccess: () => {
      TrackerService.track(
        TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events
          .SellerSendInvitationToBuyer
      );
      console.log("BUSINESS TO REMOVE", businessAcessesToRemove);
      if (businessAcessesToRemove.length > 0) {
        removeMutation.mutate(businessAcessesToRemove);
      } else {
        handleClose();
        setisSubmitting(false);
        setSelectedAccountBusinesses([]);
      }

      showToast();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const newInvitationMutation = useMutation({
    mutationFn: CatalogService.sendEmailInvitationToUnregisterUser,
    onSuccess: ({ data }) => {
      console.log(data);
      showToast();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const isPending =
    isSubmitting ||
    removeMutation.isPending ||
    catalogInvitationMutation.isPending;

  const handleClose = () => {
    setSelectedAccountBusinesses([]);
    setBusinessAcessesToRemove([]);
    setShowModal(false);
    onClose();
  };

  const handleRemove = () => {
    selectedAccountBusinesses.forEach((sab) => {
      console.log("SAB", sab);
      // if (sab.accountId) {
      setBusinessAcessesToRemove((prev) => [
        ...prev,
        {
          businessId: sab.businessId,
          catalogId,
          accountId: sab.accountId,
          email: sab.email,
        },
      ]);
      // }
    });
    console.log("SABTR", businessAcessesToRemove);
    setAccountBusinessAcesses((prev) =>
      prev.filter(
        (p) =>
          !selectedAccountBusinesses.some(
            (sab) =>
              sab.accountId === p.id &&
              sab.businessId === p.newlySelectedBusinessId &&
              sab.email === p.identifier
          )
      )
    );
    setNewlyAddedAccountBusinesses((prev) =>
      prev.filter(
        (p) =>
          !selectedAccountBusinesses.some(
            (sab) =>
              (p.id ? sab.accountId === p.id : p.email === sab.email) &&
              sab.businessId === p.newlySelectedBusinessId &&
              sab.email === p.identifier
          )
      )
    );
    setSelectedAccountBusinesses([]);
  };

  const handleSendEmailUnregisterUser = (emails: string[]) => {
    const emailList = newlyAddedAccountBusinesses.filter(
      (naab) =>
        (emails.includes(naab.email) ||
          emails.includes(naab.phoneNumber ?? "")) &&
        !naab.invitationSent
    );
    if (emailList.length > 0) {
      const payload: ICatalogInviteCreatePayload[] = [];
      emailList.forEach((email) =>
        payload.push({
          catalog: catalogId,
          senderBusiness: businessId,
          catalogInvitationStatus: "PENDING",
          recipientEmail: email.email || email.phoneNumber || "", // use phoneNumber as "email" if email is not available
          recipientBusiness: null,
        })
      );
      newInvitationMutation.mutate({ catalogId, payload });
      setNewlyAddedAccountBusinesses((prev) =>
        prev.map((naab) =>
          emails.includes(naab.email) || emails.includes(naab.phoneNumber ?? "")
            ? { ...naab, invitationSent: true }
            : naab
        )
      );
    }
  };

  const handleSendEmail = () => {
    invitationMutation.mutate({
      catalogId,
      accountIds: [
        ...selectedAccountBusinesses.map((sab) => sab?.accountId ?? sab.email),
      ],
    });
  };

  const sendIndividualEmail = (id: string) => {
    invitationMutation.mutate({
      catalogId,
      accountIds: [id],
    });
  };

  const handleApply = () => {
    const invitesPayload: ICatalogInviteCreatePayload[] = [];
    if (newlyAddedAccountBusinesses.length > 0) {
      newlyAddedAccountBusinesses
        .filter((naab) => !naab.invitationSent)
        .forEach((naab) => {
          const recipientPhoneNumber = validatePhoneNumber(
            naab.phoneNumber || ""
          )
            ? naab.phoneNumber
            : `+${naab.phoneNumberCountryCode}${naab.phoneNumber}`;
          invitesPayload.push({
            catalog: catalogId,
            senderBusiness: businessId,
            catalogInvitationStatus: "PENDING",
            recipientEmail: naab.email || recipientPhoneNumber || "",
            recipientBusiness: naab.newlySelectedBusinessId
              ? naab.newlySelectedBusinessId
              : null,
          });
        });
      // invite new account to catalog.
    }
    accountBusinessAcesses
      .filter((aba) => aba.newlySelectedBusinessId !== aba?.recipientBusiness)
      .forEach((aba) => {
        const recipientPhoneNumber = validatePhoneNumber(aba.phoneNumber || "")
          ? aba.phoneNumber
          : `+${aba.phoneNumberCountryCode}${aba.phoneNumber}`;
        invitesPayload.push({
          catalog: catalogId,
          senderBusiness: businessId,
          catalogInvitationStatus: "PENDING",
          recipientEmail: aba.email || recipientPhoneNumber || "",
          recipientBusiness: aba.newlySelectedBusinessId
            ? aba.newlySelectedBusinessId
            : null,
        });
      });

    console.log("BUSINESS ACCESS TO REMOVE", businessAcessesToRemove);
    if (invitesPayload.length > 0) {
      catalogInvitationMutation.mutate({
        catalogInvites: invitesPayload,
        catalogId: catalogId,
      });
    } else if (businessAcessesToRemove.length > 0) {
      removeMutation.mutate(businessAcessesToRemove);
    } else {
      handleClose();
      setisSubmitting(false);
    }
  };

  const debouncedSetSearchTerm = useCallback(
    debounce(async (value: string) => {
      let payload = {};

      if (validatePhoneNumber(value)) {
        for (const [_, country] of Object.entries(countryMap)) {
          if (value.startsWith("+" + country.code)) {
            payload = {
              phoneNumberCountryCode: `${country.code}`,
              phoneNumber: value.slice(country.code.length + 1),
            };
          }
        }
      } else {
        payload = {
          email: value,
        };
      }

      const { account, businesses } =
        await AccountService.getBusinessByAccountEmailOrPhoneNumber(payload);
      if (account) {
        setAccount(account);
        if (businesses.length > 0) {
          setBusinesses(businesses);
        }
      }
    }, 300),
    []
  );

  const onSubmit = async () => {
    const isAlrdyExisted = [
      ...newlyAddedAccountBusinesses,
      ...accountBusinessAcesses,
    ].some(
      (aba) =>
        (aba.email === emailInput || aba.phoneNumber === emailInput) &&
        aba.newlySelectedBusinessId === business
    );
    if (isAlrdyExisted) {
      setError("This user already added to catalog");
      return;
    } else {
      if (businesses.length > 0 && account && businesses) {
        setNewlyAddedAccountBusinesses((prev) => [
          {
            ...account,
            businesses,
            newlySelectedBusinessId: business,
            invitationSent: false,
          } as any,
          ...prev,
        ]);
      } else {
        setNewlyAddedAccountBusinesses((prev) => [
          {
            email: validateEmail(emailInput) ? emailInput : "",
            phoneNumber: validatePhoneNumber(emailInput) ? emailInput : "",
            businesses: [],
            ...(account ? { ...account } : {}),
            invitationSent: false,
          } as any,
          ...prev,
        ]);
      }
      setEmailInput("");
      setBusiness("");
      setBusinesses([]);
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Stack
          direction={"column"}
          sx={{
            height: "100%",
            gap: "1rem",
            px: { xs: "1rem", lg: "1.5rem" },
            py: "1.5rem",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: 16,
              py: "1rem",
            }}
          >
            {t("catalog.shareCatalog.title")}
          </Typography>
          <Stack
            direction={"column"}
            sx={{
              borderWidth: { lg: "1px" },
              borderColor: "#EAECF0",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
              py: { xs: "0.5rem", lg: "1.5rem" },
              px: { xs: 0, lg: "1.5rem" },
              gap: "1rem",
              flex: "1 1 0%",
              overflow: "hidden",
            }}
          >
            {selectedTab === "public" && (
              <Stack
                direction={"row"}
                sx={{
                  backgroundColor: "#CFE5DA",
                  py: "0.5rem",
                  px: "1rem",
                  alignItems: "center",
                  gap: "1rem",
                  borderRadius: "0.5rem",
                  display: { xs: "none", md: "flex" },
                }}
              >
                <InfoIcon />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#101828",
                  }}
                >
                  {t("catalog.shareCatalog.prompt")}
                </Typography>
              </Stack>
            )}
            {/* Email Input */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <Stack
                direction={{ xs: "column", lg: "row" }}
                sx={{
                  flex: "1 1 0%",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Stack
                  direction={"column"}
                  sx={{
                    flex: "1 1 0%",
                    gap: "0.25rem",
                  }}
                >
                  <Typography
                    sx={{
                      display: { xs: "none", lg: "block" },
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    {t("catalog.shareCatalog.typeEmail")}
                  </Typography>
                  <Input
                    size="sm"
                    value={emailInput}
                    onChange={(e) => {
                      if (businesses.length > 0) {
                        setBusinesses([]);
                      }
                      setEmailInput(e.target.value);
                      if (validateEmail(emailInput)) {
                        debouncedSetSearchTerm(e.target.value);
                      }
                      if (validatePhoneNumber(emailInput)) {
                        debouncedSetSearchTerm(e.target.value);
                      }
                      setError("");
                    }}
                    type="text"
                    placeholder={t("catalog.typeEmailOrPhonePlaceholder")}
                    className="border border-[#475467] px-4 py-1 rounded-lg flex-1"
                  />

                  {emailInput &&
                    (() => {
                      const isEmail = emailInput.includes("@");
                      const isNumeric = /^\+?\d+$/.test(emailInput);

                      if (isEmail) {
                        if (!validateEmail(emailInput)) {
                          return (
                            <Typography className="text-sm text-danger-500">
                              {t("commons.error.form.invalid", {
                                label: "Email",
                              })}
                            </Typography>
                          );
                        }
                      } else if (isNumeric) {
                        if (!validatePhoneNumber(emailInput)) {
                          return (
                            <Typography className="text-sm text-danger-500">
                              {t("commons.error.form.invalid", {
                                label: "Phone Number",
                              })}
                            </Typography>
                          );
                        }
                      } else {
                        // default to invalid email when user starts typing but doesn't match email or phone
                        return (
                          <Typography className="text-sm text-danger-500">
                            {t("commons.error.form.invalid", {
                              label: "Email",
                            })}
                          </Typography>
                        );
                      }

                      return null;
                    })()}

                  {/* {emailInput &&
                    !validateEmail(emailInput) &&
                    !validatePhoneNumber(emailInput) && (
                      <Typography className="text-sm text-danger-500">
                        {t("commons.error.form.invalid", { label: "Email" })}
                      </Typography>
                    )} */}
                  {error && error.length > 0 && (
                    <Typography className="text-sm text-danger-500">
                      {error}
                    </Typography>
                  )}
                </Stack>

                <Stack
                  direction={"column"}
                  sx={{
                    flex: "1 1 0%",
                    gap: "0.25rem",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Typography
                    sx={{
                      display: { xs: "none", lg: "block" },
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    {t("landing.signup.companyName")}
                  </Typography>
                  <Select
                    size="sm"
                    placeholder="Choose a Company.."
                    disabled={businesses.length === 0}
                    className="w-full p-2 border border-[#475467] disabled:bg-[#EAECF0] rounded-lg relative"
                    value={business}
                    onChange={(
                      _e: React.SyntheticEvent | null,
                      value: string | null
                    ) => {
                      if (!value) {
                        return;
                      }

                      setBusiness(value);
                      setError("");
                    }}
                  >
                    {businesses.map((business) => (
                      <Option key={business.id} value={business.id}>
                        {business.companyName ?? "-"}
                      </Option>
                    ))}
                  </Select>
                </Stack>
              </Stack>
              <Button
                disabled={
                  isSubmitting ||
                  (!validateEmail(emailInput) &&
                    !validatePhoneNumber(emailInput)) ||
                  (businesses.length > 0 && !business)
                }
                onClick={onSubmit}
                variant="outlined"
                sx={{
                  mt: { xs: 0, lg: "1.5rem" },
                }}
                className="px-4 py-1 border border-[#499873] text-[#499873] text-sm disabled:border-[#98A2B3] disabled:text-[#98A2B3]"
              >
                {t("commons.addBtn")}
              </Button>
            </Stack>

            {/* Email list */}
            <Stack
              direction={"column"}
              sx={{
                flex: "1 1 0%",
                overflow: "hidden",
              }}
            >
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "center",
                  py: "0.5rem",
                  px: 0,
                  mt: "1.5rem",
                }}
              >
                {/* checkbox */}
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    flex: "1 1 0%",
                    gap: "0.5rem",
                  }}
                >
                  <Checkbox
                    onChange={() => {
                      if (
                        selectedAccountBusinesses.length > 0 &&
                        selectedAccountBusinesses.length ===
                          accountBusinessAcesses.length +
                            newlyAddedAccountBusinesses.length
                      ) {
                        setSelectedAccountBusinesses([]);
                      } else {
                        setSelectedAccountBusinesses([
                          ...accountBusinessAcesses.map((aba) => ({
                            businessId: aba.newlySelectedBusinessId,
                            accountId: aba.id,
                            email: aba.email,
                          })),
                          ...newlyAddedAccountBusinesses.map((aba) => ({
                            businessId: aba.newlySelectedBusinessId,
                            accountId: aba.id,
                            email: aba.email,
                          })),
                        ]);
                      }
                    }}
                    checked={
                      selectedAccountBusinesses.length > 0 &&
                      selectedAccountBusinesses.length ===
                        accountBusinessAcesses.length +
                          newlyAddedAccountBusinesses.length
                    }
                  />
                  <Typography className="text-sm font-medium">
                    Select all
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Button
                    variant="outlined-gray"
                    onClick={handleRemove}
                    disabled={selectedAccountBusinesses.length === 0}
                    sx={{
                      minWidth: "fit-content",
                      px: { xs: "0.6rem", lg: "1rem" },
                      py: { xs: "0.5rem", lg: "10px" },
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", lg: "none" },
                      }}
                    >
                      <DeleteIcon
                        disabled={selectedAccountBusinesses.length === 0}
                      />
                    </Box>
                    <Typography
                      sx={{
                        display: { xs: "none", lg: "block" },
                      }}
                    >
                      {t("commons.removeBtn")}
                    </Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleSendEmail}
                    disabled={selectedAccountBusinesses.length === 0}
                    sx={{
                      minWidth: "fit-content",
                      px: { xs: "0.5rem", lg: "1rem" },
                      py: { xs: "0.5rem", lg: "10px" },
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", lg: "none" },
                      }}
                    >
                      <EmailIcon
                        disabled={selectedAccountBusinesses.length === 0}
                      />
                    </Box>
                    <Typography
                      sx={{
                        display: { xs: "none", lg: "block" },
                      }}
                    >
                      Send to Email & WhatsApp
                    </Typography>
                  </Button>
                  {/* <button
                    disabled={selectedAccountBusinesses.length === 0}
                    onClick={handleRemove}
                    className="rounded-full border border-[#475467] lg:px-4 lg:py-[10px] text-sm p-2 disabled:text-[#98A2B3] disabled:border-[#98A2B3]"
                  >
                    <div className="block lg:hidden"></div>
                    <p className="hidden lg:block">Remove</p>
                  </button> */}
                  {/* <button
                    disabled={selectedAccountBusinesses.length === 0}
                    onClick={handleSendEmail}
                    className="text-[#499873] border disabled:text-[#98A2B3] disabled:border-[#98A2B3] border-[#499873] text-sm lg:px-4 lg:py-[10px] p-2 flex items-center rounded-full gap-2"
                  >
                    <EmailIcon
                      disabled={selectedAccountBusinesses.length === 0}
                    />
                    <p className="hidden lg:block">Send to Email & WhatsApp</p>
                  </button> */}
                </Stack>
              </Stack>

              <Box
                sx={{
                  flex: "1 1 0%",
                  overflow: "auto",
                  borderTopWidth: "1px",
                  borderColor: "#F2F4F7",
                  scrollbarWidth: "none",
                }}
              >
                {newlyAddedAccountBusinesses.map((account, index) => (
                  <AccountList
                    key={`${index}-${account.id}`}
                    account={account}
                    selectedAccountBusinesses={selectedAccountBusinesses}
                    sendIndividualEmail={sendIndividualEmail}
                    handleSendEmailUnregisterUser={
                      handleSendEmailUnregisterUser
                    }
                    // businessChangeHandler={(businessId: string) => {
                    //   const _newlyAddedAccountBusinesses = [
                    //     ...newlyAddedAccountBusinesses,
                    //   ];
                    //   _newlyAddedAccountBusinesses[
                    //     index
                    //   ].newlySelectedBusinessId = businessId;
                    //   setNewlyAddedAccountBusinesses(
                    //     _newlyAddedAccountBusinesses
                    //   );
                    // }}
                    onChange={() => {
                      if (
                        selectedAccountBusinesses.findIndex(
                          (sab) =>
                            (sab.accountId
                              ? sab.accountId === account.id
                              : sab.email === account.email) &&
                            sab.businessId === account.newlySelectedBusinessId
                        ) > -1
                      ) {
                        setSelectedAccountBusinesses((prev) =>
                          prev.filter(
                            (p) =>
                              !(
                                p.accountId === account.id &&
                                p.businessId === account.newlySelectedBusinessId
                              )
                          )
                        );
                      } else {
                        setSelectedAccountBusinesses((prev) => [
                          ...prev,
                          {
                            email: account.email,
                            accountId: account.id,
                            businessId: account.newlySelectedBusinessId,
                          },
                        ]);
                      }
                    }}
                  />
                ))}
                {accountBusinessAcesses.map((account, index) => (
                  <AccountList
                    key={`${index}-${account.id}`}
                    account={account}
                    selectedAccountBusinesses={selectedAccountBusinesses}
                    sendIndividualEmail={sendIndividualEmail}
                    handleSendEmailUnregisterUser={
                      handleSendEmailUnregisterUser
                    }
                    onChange={() => {
                      if (
                        selectedAccountBusinesses.findIndex(
                          (sab) =>
                            sab.businessId ===
                              account.newlySelectedBusinessId &&
                            sab.accountId === account.id &&
                            sab.email === account.identifier
                        ) > -1
                      ) {
                        setSelectedAccountBusinesses((prev) =>
                          prev.filter(
                            (p) =>
                              !(
                                p.accountId === account.id &&
                                p.businessId === account.newlySelectedBusinessId
                              )
                          )
                        );
                      } else {
                        setSelectedAccountBusinesses((prev) => [
                          ...prev,
                          {
                            email: account.identifier ?? "",
                            accountId: account.id,
                            businessId: account.newlySelectedBusinessId,
                          },
                        ]);
                      }
                    }}
                  />
                ))}
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              pb: "1rem",
            }}
          >
            <CopyLinkButton
              url={`${
                window.location.href.split("/client")[0]
              }/catalog/${catalogId}/items`}
            />
            <Button
              disabled={removeMutation.isPending || isSubmitting}
              onClick={handleApply}
            >
              <Typography
                sx={{
                  display: { xs: "none", lg: "block" },
                  color: "white",
                  fontSize: 14,
                }}
              >
                {isPending ? "Loading...." : "Save Changes"}
              </Typography>
              <Typography
                sx={{
                  display: { xs: "block", lg: "none" },
                  color: "white",
                  fontSize: 14,
                }}
              >
                {isPending ? "Loading...." : "Apply"}
              </Typography>
            </Button>
            {/* <button
              disabled={removeMutation.isPending || isSubmitting}
              onClick={handleApply}
              className="border bg-[#499873] px-8 py-[10px] flex items-center gap-2 rounded-full"
            >
              <Typography className="hidden text-sm text-white lg:block">
                {isPending ? "Loading...." : "Save Changes"}
              </Typography>
              <Typography className="block text-sm text-white lg:hidden">
                {isPending ? "Loading...." : "Apply"}
              </Typography>
            </button> */}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default InviteModal;

function AccountList({
  account,
  selectedAccountBusinesses,
  sendIndividualEmail,
  onChange,
  handleSendEmailUnregisterUser,
}: {
  account: IAccountBusiness;
  selectedAccountBusinesses: {
    businessId: string;
    accountId: string;
    email: string;
  }[];
  sendIndividualEmail: (id: string) => void;
  handleSendEmailUnregisterUser: (emails: string[]) => void;
  onChange: () => void;
}) {
  console.log("ACCOUNT", account);
  const { t } = useTranslation();
  const business = account.id
    ? account.businesses.find((b) => b.id === account.newlySelectedBusinessId)
    : null;

  return (
    <Stack
      direction={"row"}
      key={account.id ?? new Date()}
      sx={{
        alignItems: "center",
        gap: { xs: "0.5rem", lg: "1rem" },
        px: 0,
        py: "0.5rem",
        borderBottomWidth: "1px",
        borderColor: "#F2F4F7",
      }}
    >
      <Checkbox
        checked={selectedAccountBusinesses.some(
          (sba) =>
            sba.accountId === account.id &&
            sba.businessId === account.newlySelectedBusinessId &&
            sba.email === account.identifier
        )}
        onChange={onChange}
      />
      <Stack direction={"column"} flex={"1 1 0%"}>
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              borderWidth: "1px",
              fontWeight: 700,
              borderColor: "#636B744D",
              alignItems: "center",
              justifyContent: "center",
              width: {
                xs: "2rem",
                md: "50px",
              },
              height: { xs: "2rem", md: "50px" },
              ml: "-12px",
              fontSize: 14,
              color: "#499873",
              textTransform: "uppercase",
              backgroundColor: "#F1F8F4",
              borderRadius: "50%",
            }}
          >
            {account.id ? (
              account.profile?.fullName ? (
                getInitials(account.profile?.fullName)
              ) : account.email ? (
                <EnvelopeIcon width={23.25} height={23.2} />
              ) : (
                <PhoneIcon width={23.25} height={23.2} />
              )
            ) : account.authStrategy === "PHONE_AUTH" ? (
              <PhoneIcon width={23.25} height={23.2} />
            ) : (
              <EnvelopeIcon width={23.25} height={23.2} />
            )}
          </Stack>
          <Stack direction={"column"} gap={"0.25rem"}>
            {account.id && account.profile?.fullName && (
              <Typography
                sx={{
                  fontSize: { xs: 14, md: 18 },
                  fontWeight: 600,
                  color: "#101828",
                }}
              >
                {account.profile?.fullName ?? "-"}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: { xs: 12, lg: 16 },
                color: "#475467",
              }}
            >
              {account.email
                ? account.email
                : account.phoneNumber?.startsWith("+")
                ? account.phoneNumber
                : `+${account.phoneNumberCountryCode}${account.phoneNumber}`}
            </Typography>
            {!account.profile?.fullName && (
              <Typography color={"#B41F1C"} className="text-sm">
                {t("commons.error.notRegisteredOnLinkzYet")}
              </Typography>
            )}
            <Typography color={"#101828"} className="text-sm font-semibold">
              {business?.companyName}
            </Typography>
            {/* <div className="block md:hidden">
              {account.id && account.businesses.length > 0 && (
                <select
                  className="w-[150px]"
                  value={account.newlySelectedBusinessId}
                  onChange={(e) => {
                    businessChangeHandler(e.target.value);
                  }}
                >
                  {account.businesses.map((business) => (
                    <option
                      value={business.id}
                      className="text-sm text-[#101828]"
                    >
                      {business.companyName ?? "-"}
                    </option>
                  ))}
                </select>
              )}
            </div> */}
          </Stack>
        </Stack>
      </Stack>
      {/* <div className="flex-1 hidden md:block">
        {account.id && account.businesses.length > 0 && (
          <select
            value={account.newlySelectedBusinessId}
            onChange={(e) => {
              businessChangeHandler(e.target.value);
            }}
          >
            {account.businesses.map((business) => (
              <option value={business.id} className="text-sm text-[#101828]">
                {business.companyName ?? "-"}
              </option>
            ))}
          </select>
        )}
      </div> */}
      <button
        onClick={() =>
          account.id
            ? sendIndividualEmail(account.id)
            : handleSendEmailUnregisterUser([
                account.email || account.phoneNumber || "",
              ])
        }
      >
        <EmailIcon />
      </button>
    </Stack>
  );
}

const validateEmail = (email: string) => {
  const valid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return valid.test(email.toLowerCase());
};

function validatePhoneNumber(input: string) {
  input = input.replace(/[^\d+]/g, "");

  if (!input.startsWith("+")) {
    return false;
  }

  let numbersOnly = input.slice(1).replace(/\D/g, "");

  if (numbersOnly.length < 9 || numbersOnly.length > 14) {
    return false;
  }

  return true;
}
