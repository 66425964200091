import {
  Button,
  Input,
  List,
  ListItem,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { theme } from "../../components/Theme";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InventoryService } from "../../services/inventory.service";
import { CatalogType } from "../../types/catalog";
import { ItemService } from "../../services/item.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { CartDataType } from "../../types/cart";
import { CatalogInventoryItemType } from "../../types/item";
import { OrderService } from "../../services/order.service";
import { OrderStatusEnum, PaymentFlowTypeEnum } from "../../types/order";
import { BusinessService } from "../../services/business.service";
import toast from "react-hot-toast";
import { ErrorToastConfig } from "../../components/Error";
import { IExternalBusiness } from "../../types/business";
import {
  calculatePrice,
  getConversionRate,
  getDeliveryFee,
  getSubtotal,
  getTaxAmount,
  getUOMFromCart,
  updateCartQty,
} from "../../utils/cart";
import { formatPrice } from "../../utils/formatPrice";
import PaymentModal from "../../components/payments/PaymentModal";
import { FormProvider, useForm } from "react-hook-form";
import { getCountryInfo } from "../../utils/country";
import { PaymentService, PaymentType } from "../../services/payment.service";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import PaymentInfoModal from "../../components/payments/PaymentInfoModal";
import AddressSelectionModal from "./components/AddressSelectionModal";
import { If, IfElse } from "../../components/Condition";
import AddressModal from "../Profile/Address/AddressModal";
import { AddressService } from "../../services/address.service";
import PaymentSuccessModal from "./components/PaymentSuccessModal";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";

const CartCheckout = () => {
  const { catalogId } = useParams();
  const { t } = useTranslation();
  const { session } = useAuthStore.getState();

  const [isLoading, setIsLoading] = useState(false);
  const [catalog, setCatalog] = useState<CatalogType>();
  const [subtotal, setSubtotal] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const [paymentTransactionDetails, setPaymentTransactionDetails] =
    useState<any>(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<IAddressResponse>();
  // const [cartItems, setCartItems] = useState<CartDataType>();
  const [items, setItems] = useState<CatalogInventoryItemType[]>([]);
  // const [selected, setSelected] = useState<string[]>([]);
  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [canOrder, setCanOrder] = useState(false);
  // const [hasTracked, setHasTracked] = useState(false);
  const [paymentFee, setPaymentFee] = useState<any>(0);
  const [currency, setCurrency] = useState("");
  const [checkOutItems, setCheckOutItems] = useState<CartDataType>();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState<{
    show: boolean;
    isCompleted: boolean;
  }>({ show: false, isCompleted: false });
  const [_orderId, setOrderId] = useState<string>("");
  const [addresses, setAddresses] = useState<IAddressResponse[]>([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [taxFee, setTaxFee] = useState(0);
  // TODO: simplify
  // const [removedItems, setRemovedItems] = useState<CatalogInventoryItemType[]>(
  //   []
  // );
  // const [updatedItems, setUpdatedItems] = useState<
  //   {
  //     cartItem: CartItemDataType;
  //     item: CatalogInventoryItemType;
  //   }[]
  // >([]);
  // const [alertMessage, setAlertMessage] = useState("");

  const methods = useForm();
  const { control, register, setValue, getValues } = methods;

  const trackCart = (
    cartItems: CartDataType,
    catalog: CatalogType,
    timing: "pre" | "post"
  ) => {
    const itemsObject = cartItems.items
      .map((i, index) => {
        const item = items.find((item) => item.id === i.cid);
        if (item) {
          if (timing === "pre") {
            return {
              [`Product ${index + 1} ID`]: item.inventoryItem.id,
              [`Product ${index + 1} Name`]: item.inventoryItem.productName,
              [`Quantity ${index + 1}`]: i.quantity,
            };
          } else {
            return {
              [`Product ${index + 1} ID`]: item.inventoryItem.id,
              [`Product ${index + 1} Name`]: item.inventoryItem.productName,
              [`Quantity ${index + 1}`]: i.quantity,
              [`Total Product Value Amount ${index + 1}`]:
                calculatePrice(item, i.uom) * i.quantity,
            };
          }
        }
      })
      ?.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

    TrackerService.track(
      timing === "pre"
        ? TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.CartPageVisited
        : TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.CreateOrderBtnClick,
      {
        "Catalog ID": catalog.id,
        "Catalog Name": catalog.catalogName,
        "Catalog Owner Company Name": catalog.business.companyName,
        "Product Count": cartItems.items.length,
        ...itemsObject,
      }
    );
  };

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          setCatalog(res);

          const orderExist = sessionStorage.getItem("checkoutOrderId");

          if (orderExist) {
            sessionStorage.removeItem("checkOutItems");
            // window.location.href = `/client/orders/${orderExist}`;

            let paymentType = PaymentType.FINAL_PAYMENT;
            PaymentService.enquiryPaymentStatus(
              orderExist,
              catalog?.business.id || "",
              `${paymentType}` as any
            ).then((paymentRes) => {
              // sessionStorage.removeItem("checkoutOrderId");
              sessionStorage.removeItem("checkOutItems");

              setShowPaymentSuccessModal({
                show: true,
                isCompleted: paymentRes.data.isCompleted ?? false,
              });
              // if (paymentRes.data.isCompleted) {
              //   OrderService.updateOrderStatus(
              //     orderExist,
              //     OrderStatusEnum.PROCESSING
              //   ).then((_res) => {
              //     setShowPaymentSuccessModal({
              //       show: true,
              //       isCompleted: paymentRes.data.isCompleted,
              //     });
              //     // window.location.href = `/client/orders/${orderExist}`;
              //   });
              // } else {
              //   setShowPaymentSuccessModal({
              //     show: true,
              //     isCompleted: paymentRes.data.isCompleted,
              //   });
              // }

              // window.location.href = `/client/orders/${orderExist}`;
            });
          } else {
            setCheckOutItems(
              JSON.parse(sessionStorage.getItem("checkOutItems") || "")
            );
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [catalogId]);

  useEffect(() => {
    if (checkOutItems && catalog) {
      const ids = checkOutItems?.items.map((i) => i.cid) || [];
      if (checkOutItems.items.length > 0) {
        ItemService.getCatalogInventoryItemsByIds({ ids })
          .then((res) => {
            // validateCartItems(res, checkOutItems, (result) => {
            //   setRemovedItems(result.removedItems);
            //   setUpdatedItems(result.updatedItems);
            // });
            setItems(res);
            let tempSubtotal = 0;
            getSubtotal({
              selected: res.map((i) => i.inventoryItem.id),
              items: res,
              cartItems: checkOutItems,
              callback: (subtotal) => {
                tempSubtotal = subtotal;
                setSubtotal(subtotal);
              },
            });
            setCurrency(catalog.currency);
            setDeliveryFee(
              getDeliveryFee(catalog, checkOutItems, tempSubtotal)
            );
            setTaxFee(getTaxAmount(checkOutItems.items, res, catalog) || 0);
          })
          .catch((err) => console.error(err))
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setItems([]);
        setIsLoading(false);
      }
    }
  }, [checkOutItems, catalog]);

  useEffect(() => {
    AddressService.getAddressByAccount()
      .then((res) => {
        setAddresses(res);

        const defaultAddress = res.find((add) => add.defaultAddress);

        if (defaultAddress) {
          setValue("address", defaultAddress.id);
          setSelectedAddress(defaultAddress);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  // useEffect(() => {
  //   if (cartItems) {
  //     getSubtotal({
  //       selected,
  //       items,
  //       cartItems,
  //       callback: (subtotal) => setSubtotal(subtotal),
  //     });

  //     if (verifyCartItems(cartItems, items)) {
  //       setCanOrder(true);
  //     } else {
  //       setCanOrder(false);
  //     }
  //   }
  // }, [selected, checkOutItems]);

  // useEffect(() => {
  //   if (!hasTracked && catalog && checkOutItems && items.length > 0) {
  //     trackCart(checkOutItems, catalog, "pre");
  //     setHasTracked(true);
  //   }
  // }, [catalog, items, checkOutItems, hasTracked]);

  // TODO: confirm with Felix to make this a utils function
  const getPaymentStatus = ({
    orderId,
    onSuccess,
  }: {
    orderId: string;
    onSuccess?: () => void;
  }) => {
    let paymentType = PaymentType.FINAL_PAYMENT;
    PaymentService.enquiryPaymentStatus(
      orderId,
      catalog?.business.id || "",
      `${paymentType}` as any
    ).then((response) => {
      setPaymentInfo({
        ...response.data.transactionInfo,
        updatedAt: response.data.updatedAt,
      });
      setPaymentTransactionDetails(response.data);
      onSuccess?.();
    });
  };

  const handlePaymentContinue = async (orderId: string, amount: number) => {
    const payload = getValues();
    console.log("To Continue", payload);
    setOpenPaymentModal(false);
    const method = payload.paymentMethod?.split(":")[0];
    const option = payload.paymentMethod?.split(":")[1];
    let paymentType = PaymentType.FINAL_PAYMENT;
    // let amount = subtotal;

    if (method) {
      console.log("Payable Amount", amount, getValues("status"));
      // setLoading(true);
      await OrderService.updateOrderStatus(orderId, OrderStatusEnum.CONFIRMED);
      PaymentService.requestPayment(
        PaymentService.createPaymentRequestPayload({
          method,
          option: option ?? "",
          transactionType: "order",
          transactionId: orderId,
          amount: amount + paymentFee,
          currency: currency,
          redirectUrl: window.location.origin + `/client/orders/${orderId}`,
          paymentType: `${paymentType}` as any,
        }),
        getValues("sellerIds.businessId") ?? ""
      )
        .then((response: any) => {
          if (response) {
            if (getValues("paymentMethod") !== "offline-payment")
              getPaymentStatus({
                orderId,
                onSuccess: () => {
                  setShowPaymentInfoModal(true);
                },
              });
            else window.location.href = `/client/orders/${orderId}`;
          }
        })
        .catch((err: any) => {
          console.log(err);
          // toast(showErrorToast(err), ErrorToastConfig);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCreateOrder = (
    selectedIds: string[],
    items: CatalogInventoryItemType[]
  ) => {
    setIsLoading(true);
    const orderExist = sessionStorage.getItem("checkoutOrderId");

    if (orderExist) {
      handlePaymentContinue(
        orderExist,
        parseFloat(subtotal.toString()) +
          parseFloat(deliveryFee.toString()) +
          parseFloat(taxFee.toString())
      );
      return;
    }

    if (catalog && !orderExist) {
      BusinessService.getMyBusinessRoles(getActiveBusiness()?.id || "").then(
        (_res) => {
          // had to cast as any
          // TODO: update this flow (wether or not need business relation)
          // const sellerBusiness: any = res.find(
          //   (b) => b.id === catalog?.business.id
          // );
          const sellerBusiness: IExternalBusiness = catalog.business;

          if (sellerBusiness) {
            // const sellerAccount = sellerBusiness?.role?.[0]?.account?.id ?? "";
            const roles = sellerBusiness.role as any[];
            const sellerAccount: any =
              sellerBusiness.role.find((r: any) => r.isOwner) ?? roles[0];

            if (sellerAccount) {
              OrderService.initiateDraft({
                grandTotalAmount: 0,
                paymentTerm: "",
                sellerId: catalog?.business.id || "",
                buyerId: getActiveBusiness()?.id || "",
                invoiceNumber: "",
                createdById: session?.account.id || null,
                status: OrderStatusEnum.DRAFT,
                isFromCatalog: true,
                isUnPaidCatalogueOrder: true,
              })
                .then((res) => {
                  if (res.data.id) {
                    const selectedItems = items.filter((i) =>
                      selectedIds.includes(i.inventoryItem.id)
                    );
                    const itemsToBeOrdered = selectedItems.map((i) => {
                      const cartItem = checkOutItems?.items.find(
                        (item) => item.cid === i.id
                      );

                      return {
                        productId: i.inventoryItem.id,
                        sku: i.inventoryItem.sku,
                        productName: i.inventoryItem.productName,
                        quantity: cartItem?.quantity ?? 1,
                        uom: i.inventoryItem[cartItem?.uom ?? "uom1"],
                        conversion: getConversionRate(
                          cartItem?.uom ?? "uom1",
                          i.inventoryItem
                        ),
                        price: calculatePrice(i, cartItem?.uom ?? "uom1"),
                        discount: 0,
                        totalPrice:
                          calculatePrice(i, cartItem?.uom ?? "uom1") *
                          (cartItem?.quantity ?? 1),
                        businessId: i.inventoryItem.business,
                        inventoryType: i.inventoryItem.inventoryType,
                        currency: i.currency,
                        productDescription: i.inventoryItem.productDescription,
                        createdById: i.createdBy || "",
                      };
                    });

                    /**
                     * REMIND - Abiyyu: sellerId/buyerId and sellerUserId/buyerId are not using anymore because there are already sellerIds/buyerIds objects
                     * So, I commented out for now.
                     * */
                    OrderService.update(res.data.id, {
                      currency:
                        items[0].currency ?? items[0].inventoryItem.currency,
                      orderItems: itemsToBeOrdered,
                      grandTotalAmount: subtotal,
                      paymentFlowType: PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY,
                      status: OrderStatusEnum.AWAITING_RESPONSE,
                      paymentMethod: getValues("paymentMethod"),
                      createdById: session?.account.id || "",
                      // sellerId: catalog?.business.id,
                      // sellerUserId: "",
                      sellerIds: {
                        businessId: catalog?.business.id,
                        accountId: sellerAccount.account,
                      },
                      // buyerId: getActiveBusiness()?.id || "",
                      // buyerUserId: session?.account.id || "",
                      buyerIds: {
                        businessId: getActiveBusiness()?.id || "",
                        accountId: session?.account.id || "",
                      },
                      deliveryFee: deliveryFee,
                      totalTax: taxFee,
                      additionalDiscountType: "PERCENTAGE",
                      orderShippingAddress: selectedAddress,
                    })
                      .then((res) => {
                        if (checkOutItems) {
                          trackCart(checkOutItems, catalog, "post");
                        }

                        if (res.id) {
                          setOrderId(res.id);
                          sessionStorage.setItem("checkoutOrderId", res.id);
                          items.forEach((i) => {
                            const checkOutItem = checkOutItems?.items.find(
                              (ci) => ci.cid === i.id
                            );
                            updateCartQty({
                              catalogId: catalog.id,
                              accountId: session?.account.id || "wildcart",
                              override: false,
                              quantityChange: -(checkOutItem?.quantity || 0),
                              inventoryItem: i,
                              direct: session?.account.id ? false : true,
                              activeUOM: checkOutItem?.uom || "uom1",
                              storageType: session?.account.id
                                ? "local"
                                : "session",
                            });
                          });

                          // const newCartItems = getCartItems(
                          //   catalog.id,
                          //   session?.account.id || "wildcart",
                          //   "local"
                          // );
                          // setCartItems(newCartItems ?? undefined);

                          handlePaymentContinue(
                            res.id,
                            parseFloat(subtotal.toString()) +
                              parseFloat(deliveryFee.toString()) +
                              parseFloat(taxFee.toString())
                          );
                          // window.location.href = `/client/orders/${res.id}`;
                        }
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  }
                })
                .catch((error) => console.error(error));
            }
          } else {
            setIsLoading(false);
            toast(t("Error: Seller Not Found", ErrorToastConfig));
          }
        }
      );
    }
  };

  const handlePaymentMethodName = (paymentMethod: string) => {
    switch (paymentMethod) {
      case "va":
        return "Virtual Account";
      case "e-wallet":
        return "E-Wallet";
      case "retail":
        return "Retail";
      case "qris":
        return "QRIS";

      default:
        return "Credit / Debit Card";
    }
  };

  const AddressTypography = () => {
    const selectedAddress = addresses.find(
      (address) => address.id === getValues("address")
    );

    if (!selectedAddress) return <></>;
    else
      return (
        <Stack direction={"column"} gap={"0.5rem"}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            gap={{ xs: "0.5rem", lg: "2rem" }}
          >
            <Stack direction={"column"} gap={"4px"}>
              <Typography fontSize={14} fontWeight={700} textColor={"#171A1C"}>
                {selectedAddress.fullName}
              </Typography>
              <Typography fontSize={14} fontWeight={600} textColor={"#171A1C"}>
                {`+${selectedAddress.phoneNumberCountryCode} ${selectedAddress.phoneNumber}`}
              </Typography>
            </Stack>
            <Typography fontSize={14} fontWeight={400} textColor={"#000000"}>
              {`${selectedAddress.address}, ${selectedAddress.province} ${
                selectedAddress.postalCode
              }, ${
                getCountryInfo(selectedAddress.countryCode || "ID", "LOCALE")
                  ?.name
              }`}
            </Typography>
          </Stack>

          <Typography fontWeight={500} textColor={"#101828"}>
            {selectedAddress.title}{" "}
            {selectedAddress.defaultAddress && (
              <span className="border rounded-full text-primary-500 border-primary-500 text-xs px-2 ml-4">
                {"Default"}
              </span>
            )}
          </Typography>
        </Stack>
      );
  };

  return (
    <FormProvider {...methods}>
      <Sheet
        sx={{
          width: { xs: "90vw", lg: "100%" },
          px: { lg: "24px" },
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <hr
          className={`w-full hidden lg:block border-b border-solid border-[${theme.colorSchemes.light.palette.others.disabled}]`}
        />
        <Stack
          direction={{ xs: "column", lg: "row" }}
          justifyContent={"space-between"}
          alignItems={{ xs: "flex-start", lg: "center" }}
          gap={{ xs: "12px", lg: 0 }}
          className="border border-gray-200 rounded-md p-3"
        >
          <Stack direction={"column"} gap={"8px"}>
            <span className="flex flex-col gap-5">
              <Typography fontSize={16} fontWeight={600} textColor={"#408565"}>
                {t("catalog.checkout.shippingAddress")}
              </Typography>
              {getValues("address") ? (
                <AddressTypography />
              ) : (
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  textColor={"#B41F1C"}
                >
                  {t("catalog.checkout.noAddressPrompt")}
                </Typography>
              )}
            </span>
          </Stack>
          <IfElse
            condition={addresses.length > 0}
            ifBlock={
              <button
                onClick={() => {
                  setShowAddressModal(true);
                }}
                className="border border-primary-500 text-primary-500 rounded-md px-5 py-3"
              >
                {t(
                  `catalog.checkout.${
                    getValues("address") ? "changeAddressBtn" : "setAdressBtn"
                  }`
                )}
              </button>
            }
            elseBlock={
              <button
                onClick={() => {
                  setShowNewAddressModal(true);
                }}
                className="border border-primary-500 text-primary-500 rounded-md px-5 py-3"
              >
                {t(`catalog.checkout.setAdressBtn`)}
              </button>
            }
          />
        </Stack>
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          gap={{ xs: "12px", lg: 0 }}
          className="border border-gray-200 rounded-md p-3"
        >
          <Typography fontSize={16} fontWeight={600} textColor={"#408565"}>
            {t("catalog.checkout.productOrdered")}
          </Typography>

          <List>
            {catalog && <CartItemHeader catalog={catalog} />}
            {items.map((i) => {
              if (!catalog || !checkOutItems) {
                return;
              }

              // const currency = i.currency ?? i.inventoryItem.currency;
              return (
                <CheckoutItem
                  catalog={catalog}
                  i={i}
                  checkOutItems={checkOutItems}
                />
              );
            })}
          </List>
          <Stack
            direction={{ lg: "row", xs: "column-reverse" }}
            sx={{ padding: "10px", width: "100%" }}
            spacing={"10px"}
          >
            <div className=" w-full lg:w-[50%] py-6 lg:py-0">
              <Typography fontSize={16} fontWeight={400} textColor={"#101828"}>
                {t("catalog.checkout.remarksToSeller")}
              </Typography>
              <Input
                // onChange={(e) => {
                //   setRemarks(e.target.value);
                // }}
                {...register("remarks")}
                placeholder={t("catalog.checkout.remarksToSellerPlaceholder")}
                className="text-neutral-600"
              />
            </div>
            <Stack
              direction={"column"}
              gap={"0.5rem"}
              width={{ xs: "100%", lg: "50%" }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={{ xs: "100%", lg: "100%" }}
              >
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textColor={"#101828"}
                >
                  {t("catalog.checkout.deliveryFee")}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textColor={"#101828"}
                >
                  {`${currency} ${formatPrice(
                    deliveryFee,
                    items[0]?.currency || items[0]?.inventoryItem.currency
                  )}`}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={{ xs: "100%", lg: "100%" }}
              >
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textColor={"#101828"}
                >
                  {t("catalog.checkout.productSubtotal")}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textColor={"#101828"}
                >
                  {`${currency} ${formatPrice(
                    subtotal,
                    items[0]?.currency || items[0]?.inventoryItem.currency
                  )}`}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={{ xs: "100%", lg: "100%" }}
              >
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textColor={"#101828"}
                >
                  {t("catalog.checkout.taxes")}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textColor={"#101828"}
                >
                  {`${currency} ${formatPrice(
                    taxFee,
                    items[0]?.currency || items[0]?.inventoryItem.currency
                  )}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: "column-reverse", lg: "row" }}
          justifyContent={"space-between"}
          gap={{ xs: 0, lg: "12px" }}
          className="border border-gray-200 rounded-md p-3"
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={{ xs: "100%", lg: "50%" }}
          >
            <Stack direction={"column"} gap={"8px"}>
              <span className="flex flex-col gap-5">
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  textColor={"#408565"}
                >
                  {t("catalog.checkout.paymentMethod")}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={getValues("paymentMethod") ? 700 : 500}
                  textColor={getValues("paymentMethod") ? "#171A1C" : "#B41F1C"}
                >
                  {getValues("paymentMethod")
                    ? handlePaymentMethodName(getValues("paymentMethod"))
                    : t("catalog.checkout.choosePayment")}
                </Typography>
              </span>
            </Stack>
            <button
              onClick={() => {
                setOpenPaymentModal(true);
              }}
              className="border border-primary-500 text-primary-500 rounded-md px-5 py-3"
            >
              {t("commons.changeBtn")}
            </button>
          </Stack>
          <Stack
            width={{ xs: "100%", lg: "50%" }}
            justifyContent={"center"}
            sx={{ padding: { xs: "20px 0px", lg: "0px" } }}
            spacing={"10px"}
          >
            {/* <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={16} fontWeight={400} textColor={"#101828"}>
                {t("catalog.checkout.productSubtotal")}{" "}
              </Typography>
              <Typography fontSize={16} fontWeight={500} textColor={"#408565"}>
                {`${currency} ${formatPrice(
                  subtotal,
                  items[0]?.currency || items[0]?.inventoryItem.currency
                )}`}
              </Typography>
            </Stack> */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={16} fontWeight={500} textColor={"#000"}>
                {t("commons.order.grandTotal")}
              </Typography>
              <Typography fontSize={16} fontWeight={500} textColor={"#408565"}>
                {`${currency} ${formatPrice(
                  parseFloat(subtotal.toString()) +
                    parseFloat(deliveryFee.toString()) +
                    parseFloat(taxFee.toString()),
                  items[0]?.currency || items[0]?.inventoryItem.currency
                )}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Sheet>
      <div className="border-t border-gray-200 rounded-md p-3 mt-5 text-right">
        <Button
          onClick={() => {
            const selectedIds = items.map((i) => i.inventoryItem.id);

            handleCreateOrder(selectedIds, items);
          }}
          fullWidth={isMobile}
          className="my-auto rounded-md"
          disabled={
            !getValues("address") ||
            !getValues("paymentMethod") ||
            !checkOutItems
          }
        >
          {t("catalog.createOrderBtn")}
        </Button>
      </div>

      {currency && catalog?.business?.id && (
        <PaymentModal
          open={openPaymentModal}
          setOpenPaymentModal={setOpenPaymentModal}
          handleContinue={() => {
            setOpenPaymentModal(false);
            // if (isConfimrAndPay) {
            //   handleConfirm(() => {
            //     handlePaymentContinue();
            //   });
            // } else {
            //   handlePaymentContinue();
            // }
          }}
          handleSkip={() => {
            setOpenPaymentModal(false);
          }}
          control={control}
          setValue={setValue}
          onlinePaymentOnly={true}
          paymentAmount={
            parseFloat(subtotal.toString()) +
            parseFloat(deliveryFee.toString()) +
            parseFloat(taxFee.toString())
          }
          paymentCurrency={currency}
          handleChangePaymentFee={setPaymentFee}
          applicableAdminFee={
            (getValues("selectedExternalBusiness") as any)?.applicableAdminFee
          }
          sellerId={catalog?.business.id}
          sellerCurrency={
            getCountryInfo(catalog?.business.companyCountry ?? "ID", "LOCALE")
              ?.currency
          }
        />
      )}

      {paymentTransactionDetails && paymentInfo && (
        <PaymentInfoModal
          open={showPaymentInfoModal}
          setOpenModal={setShowPaymentInfoModal}
          paymentInfo={paymentInfo}
          status={paymentTransactionDetails?.status}
          onFinish={() => {
            setShowPaymentSuccessModal({
              show: true,
              isCompleted: false,
            });
            // window.location.href = `/client/orders/${orderId}`;
          }}
          onClose={() => {
            setShowPaymentSuccessModal({
              show: true,
              isCompleted: false,
            });
          }}
          currency={currency??""}
        />
      )}

      {addresses.length > 0 && selectedAddress && (
        <AddressSelectionModal
          show={showAddressModal}
          onClose={() => {
            setShowAddressModal(false);
          }}
          onConfirm={(address: string) => {
            setValue("address", address);
            setSelectedAddress(addresses.find((add) => add.id === address));
            setShowAddressModal(false);
          }}
          addresses={addresses}
          defaultAddress={selectedAddress}
        />
      )}

      {addresses.length === 0 && (
        <AddressModal
          show={showNewAddressModal}
          onClose={() => {
            setShowNewAddressModal(false);
          }}
          onSuccess={() => {
            window.location.reload();
          }}
        />
      )}

      <PaymentSuccessModal
        show={showPaymentSuccessModal.show}
        isCompleted={showPaymentSuccessModal.isCompleted}
        onClose={() => {
          sessionStorage.removeItem("checkoutOrderId");
          window.location.href = `/client/suppliers/${catalogId}/items`;
        }}
        onConfirm={() => {
          const orderExist = sessionStorage.getItem("checkoutOrderId");

          sessionStorage.removeItem("checkoutOrderId");
          window.location.href = `/client/orders/${orderExist}`;
        }}
      />

      <LoadingModal isLoading={isLoading} />
    </FormProvider>
  );
};

const CartItemHeader = ({ catalog }: { catalog: CatalogType }) => {
  const { t } = useTranslation();

  return (
    <ListItem
      key={0}
      sx={{
        display: "block",
        borderBottom: "1px solid #F2F4F7",
        pb: "16px",
      }}
    >
      <div className={`pt-4 flex align-middle w-full gap-4`}>
        <div
          className={`flex flex-col lg:flex-row lg:align-middle w-full gap-4`}
        >
          <Stack
            className="gap-4 w-full lg:w-[37%]"
            direction={"row"}
            alignItems={"center"}
          >
            <img
              src={"/inventory-img-placeholder.png"}
              alt="item_image"
              className="lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-[32px] h-[32px] object-cover flex-shrink-0"
            />
            <Stack>
              <Typography fontSize={15} fontWeight={500} textColor={"#000"}>
                {catalog.catalogName}
              </Typography>
              <If
                condition={
                  (catalog.business as any).kycPaymentGatewayStatus ===
                  "VALIDATED"
                }
              >
                <Typography fontSize={13} fontWeight={400} textColor={"#000"}>
                  {t("catalog.supplier.items.verifiedSupplier")}
                </Typography>
              </If>
            </Stack>
          </Stack>

          <div className="lg:flex w-[63%] justify-between items-center gap-4 hidden">
            <div className="w-[30%] text-center">
              <Typography fontSize={16} fontWeight={500} textColor={"#000"}>
                {t("catalog.checkout.unitPrice")}
              </Typography>
            </div>
            <div className="hidden lg:block">
              <Typography fontSize={16} fontWeight={500} textColor={"#000"}>
                {t("catalog.checkout.quantity")}
              </Typography>
            </div>
            <div className="w-[30%] text-center">
              <Typography fontSize={16} fontWeight={500} textColor={"#000"}>
                {t("catalog.checkout.subtotal")}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

const CheckoutItem = ({
  i,
  checkOutItems,
}: {
  catalog: CatalogType;
  i: CatalogInventoryItemType;
  checkOutItems: CartDataType;
}) => {
  const currency = i.currency ?? i.inventoryItem.currency;
  const activeUOM: "uom1" | "uom2" | "uom3" = getUOMFromCart(
    i.id,
    checkOutItems
  );
  const checkOutItem = checkOutItems.items.find((ci) => ci.cid === i.id);

  return (
    <ListItem
      key={i.id}
      sx={{
        display: "block",
        borderBottom: "1px solid #F2F4F7",
        pb: "16px",
      }}
    >
      <div className={`pt-4 flex align-middle w-full gap-4`}>
        <div className={`flex lg:align-middle w-full gap-4 justify-between`}>
          <Stack
            className="gap-4"
            direction={"row"}
            alignItems={"center"}
            justifyContent={{ xs: "space-between", lg: "center" }}
            width={"100%"}
          >
            <img
              src={`${
                i.inventoryItem.thumbnail ?? "/inventory-img-placeholder.png"
              }`}
              alt="item_image"
              // width={100}
              // height={100}
              className="lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-[64px] h-[64px] lg:w-[100px] lg:h-[100px] object-contain flex-shrink-0"
            />
            <Stack gap={{ xs: "0px", lg: "8px" }} width={"100%"}>
              <Typography
                fontSize={{ xs: 14, lg: 16 }}
                fontWeight={500}
                textColor={"#475467"}
              >
                {i.inventoryItem.sku ?? "-"}
              </Typography>
              <div className="line-clamp-1 lg:line-clamp-none">
                <Typography
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={700}
                  textColor={"#101828"}
                >
                  {i.inventoryItem.productName}
                </Typography>
              </div>
              <div className="visible lg:hidden">
                <Typography
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={500}
                  textColor={"#475467"}
                >
                  {`${checkOutItem?.quantity} ${
                    i.inventoryItem[activeUOM] || "unit"
                  }`}
                </Typography>
              </div>
            </Stack>
          </Stack>

          <div className="flex w-[40%] lg:w-[63%] justify-between items-center gap-4">
            <div className="w-full lg:w-[30%] lg:text-center text-left">
              <Typography fontSize={16} fontWeight={500} textColor={"#000"}>
                {`${currency} ${formatPrice(
                  calculatePrice(i, activeUOM),
                  currency
                )}`}
              </Typography>
              {/* <span className="line-through text-xs text-neutral-400">
                {"IDR 12.000.000,00"}
              </span> */}
            </div>
            <div className="hidden lg:block text-center">
              <Typography fontSize={16} fontWeight={500} textColor={"#000"}>
                {`${checkOutItem?.quantity} ${
                  i.inventoryItem[activeUOM] || "unit"
                }`}
              </Typography>
            </div>
            <div className="w-[30%] text-center hidden lg:block">
              <Typography fontSize={16} fontWeight={500} textColor={"#000"}>
                {`${currency} ${formatPrice(
                  (checkOutItem?.quantity || 1) * calculatePrice(i, activeUOM),
                  currency
                )}`}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default CartCheckout;
