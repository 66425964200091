import {
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
} from "@mui/joy";
import { useRef } from "react";

interface Props {
  label: string;
  options: { label: string; id?: string; action: () => void }[];
  isReadOnly?: boolean;
}

const DropdownButton = ({
  label,
  options,
  isReadOnly = false,
  ...props
}: Props & MenuButtonProps) => {
  const menuBtnRef = useRef<any>(null);
  // const [open, setOpen] = useState(false);

  return (
    <Dropdown>
      <MenuButton
        {...props}
        ref={menuBtnRef}
        disabled={isReadOnly}
        fullWidth
        slots={{ root: Button }}
        slotProps={{
          root: {
            variant: "linkz-filled-primary",
            size: "sm",
          },
        }}
      >
        {label}
      </MenuButton>
      <Menu sx={{ width: menuBtnRef.current?.clientWidth }}>
        {options.map((opt, index) => (
          <MenuItem
            key={index}
            id={opt.id}
            onClick={opt.action}
            sx={{ padding: 1 }}
          >
            {opt.label}
            {/* {i18next.t("order.table.rowMenu.duplicate")} */}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default DropdownButton;
