import { ArrowsPointingOutIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/joy";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CatalogType } from "../../../types/catalog";
import { CatalogInventoryItemType } from "../../../types/item";
import { CartItemDataType } from "../../../types/cart";
import { calculatePrice } from "../../../utils/cart";
import { theme } from "../../../components/Theme";
import ProductCarousel from "../../../components/carousel/ProductCarousel";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  catalog?: CatalogType;
  item: CatalogInventoryItemType;
  type?: "supplier" | "inventory";
  onClick?: () => void;
  handleRemoveItem?: (id: string) => void;
  setToCart?: Dispatch<SetStateAction<CartItemDataType[]>>;
  resetFlag?: boolean;
  selectedItems?: string[];
  handleChecked?: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    catalogItemId: string
  ) => void;
};

export function ProductCard({
  setToCart,
  item,
  type = "inventory",
  handleRemoveItem,
  selectedItems,
  handleChecked,
}: Props) {
  const navigate = useNavigate();
  const { catalogId } = useParams();

  const [activeUOM, _setActiveUOM] = useState<"uom1" | "uom2" | "uom3">("uom1");
  const [_isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const variantTypeArr = [
    item.inventoryItem.uom1,
    item.inventoryItem.uom2,
    item.inventoryItem.uom3,
  ].filter(Boolean);

  const variantPriceArr = [
    item.inventoryItem.price,
    item.inventoryItem.price2,
    item.inventoryItem.price3,
  ];

  const [variantType, setVariantType] = useState(0);

  const onChangeVariant = (val: number) => {
    if (val >= 0 && val < variantTypeArr.length) {
      setVariantType(val);
    }
  };

  useEffect(() => {
    if (setToCart) {
      setToCart((prev) => {
        const currentIndex = prev.findIndex((p) => p.cid === item.id);
        if (currentIndex !== -1) {
          const updatedItem = {
            ...prev[currentIndex],
            uom: activeUOM,
            initPrice: calculatePrice(item, activeUOM),
          };

          return [
            ...prev.slice(0, currentIndex),
            updatedItem,
            ...prev.slice(currentIndex + 1),
          ];
        }

        return prev;
      });
    }
  }, [activeUOM]);

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  const goToDetail = (id: string) => {
    navigate(
      `/client/catalogues/${catalogId}/items/${id}?inventoryId=${item.id}`
    );
  };

  return (
    <Stack
      key={item.id}
      direction={"column"}
      sx={{
        borderWidth: "2px",
        boxShadow: { xs: 1, md: "none" },
        borderColor: "#EAECF0",
        borderRadius: "1.5rem",
        overflow: "hidden",
        height: "100%",
        // width: { xs: "50%", lg: "208px" },
        p: 0,
        gap: "1rem",
        "&:hover": {
          borderColor: "#6BAB8D",
          boxShadow: 3,
        },
      }}
      // className={
      //   "border-2 shadow-sm md:shadow-none border-[#EAECF0] rounded-3xl overflow-hidden h-full p-0 flex flex-col gap-4 hover:border-[#6BAB8D] transition-all hover:shadow-lg"
      // }
    >
      <Stack
        direction={"column"}
        sx={{
          position: "relative",
        }}
      >
        <div className="relative">
          <IconButton
            size="sm"
            className="absolute bottom-[20px] right-[20px] bg-white z-50 p-[2px]"
            onClick={() => goToDetail(item.inventoryItem.id)}
          >
            <ArrowsPointingOutIcon className="w-5 h-5" />
          </IconButton>
          <ProductCarousel
            alt={item.id}
            slides={[
              {
                src:
                  item.inventoryItem.thumbnail ??
                  "/inventory-img-placeholder.png",
              },
            ]}
          />
        </div>
        {type === "inventory" && handleChecked && (
          <Checkbox
            size="lg"
            checked={selectedItems?.includes(item.inventoryItem.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              handleChecked(e, item.inventoryItem.id, item.id);
            }}
            sx={{
              position: "absolute",
              top: "1.25rem",
              left: "1.25rem",
            }}
          />
        )}
        {type === "inventory" && (
          <TrashIcon
            onClick={(event) => {
              event.stopPropagation();
              if (handleRemoveItem) {
                handleRemoveItem(item.id);
              }
            }}
            className={
              "h-6 w-6 text-danger-500 bg-white p-[2px] rounded cursor-pointer absolute top-[1.25rem] right-[1.25rem]"
            }
          />
        )}
        <Box padding="15px">
          <Stack
            direction={"column"}
            sx={{
              borderBottomWidth: "1px",
              borderColor: "#d1d5db",
              gap: "0.25rem",
            }}
          >
            <span className="text-neutral-500 text-xs">
              {item.inventoryItem.sku}
            </span>
            <Typography level="h4" sx={{ fontSize: "md", mb: "1rem" }}>
              {item.inventoryItem.productName}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            className="border border-gray-400 rounded-lg overflow-hidden mt-4 mb-2"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <button
              onClick={() => onChangeVariant(variantType - 1)}
              disabled={variantType === 0}
              className={`w-[20%] h-[34px] flex justify-center items-center border-r border-gray-700 ${
                variantType === 0 ? "bg-gray-200" : ""
              }`}
            >
              <ChevronLeftIcon className="w-6" />
            </button>
            <Typography level="title-md">
              {variantTypeArr[variantType]}
            </Typography>
            <button
              onClick={() => onChangeVariant(variantType + 1)}
              disabled={variantType === variantTypeArr.length - 1}
              className={`w-[20%] h-[34px] flex justify-center items-center border-l border-gray-700 ${
                variantType === variantTypeArr.length - 1 ? "bg-gray-200" : ""
              }`}
            >
              <ChevronRightIcon className="w-6" />
            </button>
          </Stack>
          <Typography fontSize={12} textColor={"#737373"}>
            {" "}
            {item.inventoryItem.quantity} {variantTypeArr[variantType]}
          </Typography>
          <Box
            sx={{
              pt: "0.5rem",
            }}
          >
            {/* <span className="line-through text-sm text-neutral-400">
              IDR 120.000,00
            </span> */}
            <h5 className="text-md font-bold text-[#408565]">
              {item.currency} {variantPriceArr[variantType]}
            </h5>
          </Box>
          {/* <button
            className={`my-4 lg:my-0 py-3 px-4 rounded-lg  border capitalize items-center flex cursor-default 
           text-white bg-primary-500
            `}
          >
            <ShoppingCartIcon
              style={{
                width: 18,
                height: 18,
                marginRight: "0.5rem",
              }}
            />
            {t(`catalog.addToCartBtn`)}
          </button> */}
        </Box>
      </Stack>
      {/* <Stack
        direction={{ xs: "column", md: "row" }}
        gap={{ xs: 0, md: "16px" }}
        sx={{
          position: "relative",
        }}
        onClick={onClick}
      >

        <img
          src={`${item.inventoryItem.thumbnail ?? "/inventory-img-placeholder.png"
            }`}
          alt="item_image"
          // width={100}
          // height={100}
          className={`lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-full h-[176.5px]  ${type === "supplier"
            ? "md:w-[114px] md:h-[114px]"
            : "md:w-[100px] md:h-[100px]"
            } object-contain flex-shrink-0`}
        />

        {type === "inventory" && handleChecked && (
          <Checkbox
            size="sm"
            checked={selectedItems?.includes(item.inventoryItem.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              handleChecked(e, item.inventoryItem.id, item.id);
            }}
            sx={{
              position: "absolute",
              top: "0.25rem",
              left: "0.25rem",
            }}
          />
        )}
        <Stack direction={"column"} gap={"8px"} p={"8px"} width={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography textColor={"#475467"} className={"text-sm"}>
              {item.inventoryItem.sku}
            </Typography>
            {type === "inventory" && (
              <TrashIcon
                onClick={(event) => {
                  event.stopPropagation();
                  if (handleRemoveItem) {
                    handleRemoveItem(item.id);
                  }
                }}
                className={"h-5 w-5 text-[#499873] cursor-pointer"}
              />
            )}
          </Stack>

          <Typography
            title={item.inventoryItem.productName}
            className={"font-bold text-base text-ellipsis line-clamp-1"}
          >
            {item.inventoryItem.productName}
          </Typography>

          <TagsCollection tags={item.inventoryItem.tags} />
          <hr className="hidden lg:block mt-auto" />
        </Stack>
      </Stack>

      {catalog && type === "supplier" && (
        <div className="hidden lg:block">
          <UOMSelector
            item={item}
            hidePrice={catalog.hidePrice}
            catalogType={catalog.catalogType}
            activeUOM={activeUOM}
            setActiveUOM={(uom) => {
              setActiveUOM(uom);
            }}
            size="sm"
          />
        </div>
      )}

      <If condition={isMobile || type === "inventory"}>
        <Stack
          direction={"column"}
          p={{ xs: "8px", md: 0 }}
          gap={"4px"}
          onClick={onClick}
        >
          <Typography className="text-[#101828] text-base font-semibold">
            {`${currency}`} {isMobile && <br />}
            {formatPrice(calculatePrice(item, activeUOM), currency)}
          </Typography>

          <If condition={isMobile && item.inventoryItem.uom1 ? true : false}>
            <div className="border-[#499873] p-2 rounded border w-fit">
              <Typography className="text-xs text-[#499873]">
                {item.inventoryItem.uom1}
              </Typography>
            </div>
          </If>
        </Stack>
      </If>

      <If condition={!isMobile && type === "supplier"}>
        <div className="mt-auto hidden lg:block">
          <CartQuantityManager
            hidePerItemPrice={true}
            caller="product-card"
            item={item}
            hidePrice={catalog?.hidePrice}
            defaultUOM={activeUOM}
            withAddToCart
            catalog={catalog!}
            setToCart={setToCart}
            resetFlag={resetFlag}
          />
        </div>
      </If> */}
    </Stack>
  );
}
