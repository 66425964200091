import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { AddressService } from "../../../services/address.service";
import toast from "react-hot-toast";
import showErrorToast, { ErrorToastConfig } from "../../../components/Error";

export default function useContainer({
  selectedAddress,
  onClose,
  onSuccess,
}: {
  selectedAddress?: IAddressResponse;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const addressSchema = z.object({
    title: z.string().min(3),
    fullName: z.string().min(5),
    address: z.string().min(10),
    countryCode: z.string().min(2),
    postalCode: z.string().optional(),
    province: z.string().min(5),
    phoneNumber: z.string().optional(),
    phoneNumberCountryCode: z.coerce
      .number()
      .transform((num) => num.toString())
      .refine((str) => str.length >= 1, {
        message: "Phone number country code invalid",
      })
      .optional(),
    defaultAddress: z.boolean().default(true),
  });

  const {
    register,
    watch,
    setError,
    formState,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
  } = useForm<z.infer<typeof addressSchema>>({
    resolver: zodResolver(addressSchema),
  });

  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const handleEdit = (selectedAddress: IAddressResponse) => {
    setValue("address", selectedAddress.address);
    setValue("countryCode", selectedAddress.countryCode);
    setValue("defaultAddress", selectedAddress.defaultAddress);
    setValue("fullName", selectedAddress.fullName);
    setValue("phoneNumber", selectedAddress.phoneNumber);
    setValue("phoneNumberCountryCode", selectedAddress.phoneNumberCountryCode);
    setValue("postalCode", selectedAddress.postalCode);
    setValue("province", selectedAddress.province);
    setValue("title", selectedAddress.title);
  };

  const onSubmit: SubmitHandler<z.infer<typeof addressSchema>> = (data) => {
    setLoading(true);
    if (selectedAddress) {
      upadateMutation.mutate({ ...data, id: selectedAddress.id });
    } else {
      createMutation.mutate(data);
    }
  };

  const createMutation = useMutation({
    mutationFn: AddressService.createAddress,
    onSuccess: () => {
      handleClose();
      onSuccess();
      setLoading(false);
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const upadateMutation = useMutation({
    mutationFn: AddressService.updateAddress,
    onSuccess: (_) => {
      handleClose();
      onSuccess();
      setLoading(false);
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  return {
    register,
    watch,
    setError,
    handleSubmit,
    setValue,
    errors,
    loading,
    showModal,
    setShowModal,
    handleEdit,
    onSubmit,
    handleClose,
    trigger,
    clearErrors,
  };
}
