import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { Controller, useFormContext } from "react-hook-form";
import { ItemType } from "../../../types/item";
import { KeyboardArrowDown } from "@mui/icons-material";
import { currencyList } from "../../../pages/Order/EditOrders";
import { Trans, useTranslation } from "react-i18next";
import React, { useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { AVAILABLE_UOMS } from "../../../utils/constants";
import { validateOnChange, validateOnKeyDown } from "../../../utils/cart";
import InfoSnackbar from "../../../pages/Inventory/components/InfoSnackbar";
import { getCountryAndCurrency } from "../../../utils/country";
import { getActiveBusiness } from "../../../store/session";
import { useSearchParams } from "react-router-dom";
import { If } from "../../Condition";

interface Props {
  item: ItemType;
  allUOMs: string[];
  isReadOnly?: boolean;
  isDetail?: boolean;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const ItemQuantityManager = ({
  item,
  allUOMs,
  isReadOnly = false,
  isDetail = false,
}: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isCreate = searchParams.get("mode") === "create";
  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [quantityInput, setQuantityInput] = useState(item.quantity?.toString());
  const [minimumQuantityInput, setMinimumQuantityInput] = useState(
    item.minimumQuantity?.toString()
  );
  const [lowStockQuantityInput, setLowStockQuantityInput] = useState(
    item.lowStockQuantity?.toString()
  );

  const NumericFormatAdapter = React.forwardRef<
    NumericFormatProps,
    CustomProps
  >(function NumericFormatAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        {...register(`price`, {
          required: t("commons.error.form.required", {
            label: t("catalog.inventory.editForm.price"),
          }),
          valueAsNumber: true,
        })}
        onValueChange={(values) => {
          setValue("price", values.value);
        }}
        thousandSeparator=","
        valueIsNumericString
      />
    );
  });

  return (
    <Grid container spacing={2}>
      <If condition={isCreate}>
        <Grid xs={12}>
          <InfoSnackbar
            message={
              <Typography textColor={"#101828"} fontSize={16} fontWeight={500}>
                <Trans
                  i18nKey={"catalog.inventory.empty.inventoryCurrencyPrompt"}
                  values={{
                    currency: getCountryAndCurrency(getActiveBusiness()),
                  }}
                >
                  The currency for inventory is automatically set based on the
                  seller’s region: <b>Indonesia - IDR</b>
                </Trans>
              </Typography>
            }
            withExit
          />
        </Grid>
      </If>

      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <FormControl
          sx={{ flex: "1", height: "100%", flexGrow: 1 }}
          error={!!errors.quantity}
        >
          <FormLabel>{t("catalog.inventory.editForm.quantity")}</FormLabel>
          <Input
            disabled={isReadOnly}
            placeholder={t("catalog.inventory.editForm.quantity")}
            value={quantityInput}
            // type="number"
            {...register(`quantity`, {
              required: t("commons.error.form.required", {
                label: t("catalog.inventory.editForm.quantity"),
              }),
              valueAsNumber: true,
            })}
            onChange={(e) => {
              validateOnChange(e.target.value, (sanitizedValue) => {
                setQuantityInput(sanitizedValue);
              });
            }}
            onKeyDown={(e) => {
              if (validateOnKeyDown(e.key)) {
                e.preventDefault();
              }
            }}
            className={`mt-auto ${isReadOnly && "bg-[#EAECF0]"}`}
          />
          <FormHelperText>{(errors.quantity as any)?.message}</FormHelperText>
        </FormControl>
      </Grid>

      {/* TODO: this is minimumSellingQty */}
      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <FormControl
          sx={{ flex: "1", height: "100%", flexGrow: 1 }}
          error={!!errors.minimumQuantity}
        >
          <FormLabel
            sx={{
              whiteSpace: { xs: "break-spaces", lg: "nowrap" },
            }}
          >
            {t("catalog.inventory.editForm.minimumSellingQty")}
          </FormLabel>
          <Input
            disabled={isReadOnly}
            placeholder={t("catalog.inventory.editForm.minimumSellingQty")}
            value={minimumQuantityInput}
            // type="number"
            slotProps={{ input: { min: 0, step: "0.01" } }}
            {...register(`minimumQuantity`, {
              required: t("commons.error.form.required", {
                label: t("catalog.inventory.editForm.minimumSellingQty"),
              }),
              valueAsNumber: true,
            })}
            onChange={(e) => {
              let newValue = e.target.value;
              newValue = newValue.replace(",", ".");
              if (
                newValue.startsWith("0") &&
                !newValue.startsWith("0.") &&
                newValue.length > 1
              ) {
                newValue = newValue.replace(/^0+/, "");
              }

              if (!isNaN(Number(newValue)) || newValue === "") {
                if (newValue.includes(".")) {
                  const [integerPart, decimalPart] = newValue.split(".");
                  if (decimalPart.length > 2) {
                    newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
                  }
                }

                setMinimumQuantityInput(newValue);
              }
            }}
            onKeyDown={(e) => {
              if (validateOnKeyDown(e.key)) {
                e.preventDefault();
              }
            }}
            className={`mt-auto ${isReadOnly && "bg-[#EAECF0]"}`}
          />
          <FormHelperText>
            {(errors.minimumQuantity as any)?.message}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <FormControl
          sx={{ flex: "1", height: "100%", flexGrow: 1 }}
          error={!!errors.lowStockQuantity}
        >
          <FormLabel
            sx={{
              whiteSpace: { xs: "break-spaces", lg: "nowrap" },
            }}
          >
            {t("catalog.inventory.editForm.lowStockQuantity")}
          </FormLabel>
          <Input
            disabled={isReadOnly}
            placeholder={t("catalog.inventory.editForm.lowStockQuantity")}
            value={lowStockQuantityInput}
            // type="number"
            slotProps={{ input: { min: 0, step: "0.01" } }}
            {...register(`lowStockQuantity`, {
              required: t("commons.error.form.required", {
                label: t("catalog.inventory.editForm.lowStockQuantity"),
              }),
              valueAsNumber: true,
            })}
            onChange={(e) => {
              let newValue = e.target.value;
              newValue = newValue.replace(",", ".");
              if (
                newValue.startsWith("0") &&
                !newValue.startsWith("0.") &&
                newValue.length > 1
              ) {
                newValue = newValue.replace(/^0+/, "");
              }

              if (!isNaN(Number(newValue)) || newValue === "") {
                if (newValue.includes(".")) {
                  const [integerPart, decimalPart] = newValue.split(".");
                  if (decimalPart.length > 2) {
                    newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
                  }
                }

                setLowStockQuantityInput(newValue);
              }
            }}
            onKeyDown={(e) => {
              if (validateOnKeyDown(e.key)) {
                e.preventDefault();
              }
            }}
            className={`mt-auto ${isReadOnly && "bg-[#EAECF0]"}`}
          />
          <FormHelperText>
            {(errors.minimumQuantity as any)?.message}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <Controller
          name={"uom1"}
          control={control}
          defaultValue={item.uom1}
          render={({ field: { value } }) => (
            <FormControl sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
              <FormLabel
                sx={{
                  whiteSpace: { xs: "break-spaces", lg: "nowrap" },
                }}
              >
                {t("catalog.inventory.editForm.uom1")}
              </FormLabel>
              <Autocomplete
                disabled={isReadOnly}
                options={[...new Set([...allUOMs, ...AVAILABLE_UOMS])]}
                getOptionLabel={(option) => option}
                onInputChange={(_e, newValue) => {
                  setValue("uom1", newValue);
                }}
                value={value}
                className={`mt-auto ${isReadOnly && "bg-[#EAECF0]"}`}
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <Controller
          name="currency"
          control={control}
          defaultValue={item.currency}
          render={({ field: { value, ref } }) => (
            <FormControl sx={{ flex: "1", height: "100%", flexGrow: 1 }}>
              <FormLabel>{t("catalog.inventory.editForm.currency")}</FormLabel>
              <Select
                disabled={isReadOnly}
                // disabled
                ref={ref}
                indicator={<KeyboardArrowDown />}
                placeholder={t("catalog.inventory.editForm.currency")}
                slotProps={{
                  button: { sx: { whiteSpace: "nowrap" } },
                }}
                onChange={(_event, newValue: string | null) => {
                  setValue("currency", newValue ?? item.currency);
                }}
                value={value}
                className={`mt-auto ${
                  isReadOnly && "bg-[#EAECF0] border-[#98A2B3]"
                }`}
              >
                {currencyList.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>

      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <FormControl
          sx={{ flex: "1", height: "100%", flexGrow: 1 }}
          error={!!errors.price}
        >
          <FormLabel>{t("catalog.inventory.editForm.price")}</FormLabel>
          <Input
            placeholder={t("catalog.inventory.editForm.price")}
            defaultValue={getValues("price") || 0}
            className="mt-auto"
            // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            //   console.log("WTF", event.target.value);
            //   setValue("price", event.target.value);
            // }}
            slotProps={{
              input: {
                component: NumericFormatAdapter,
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ItemQuantityManager;
