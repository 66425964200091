import { XCircleIcon } from "@heroicons/react/24/solid";
import { Box, Button, Modal, Stack, Typography } from "@mui/joy";

interface IProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  onClose?: () => void;
}

const FailModal = ({ open, title, content, onClose }: IProps) => {
  return (
    <Modal
      id="fail-modal"
      open={open}
      style={{ backgroundColor: "#32383E99", backdropFilter: "unset" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90dvw", lg: "550px" },
          height: "auto",
          bgcolor: "#fff",
          boxShadow: 24,
          px: "24px",
          py: "32px",
          borderRadius: "32px",
          borderColor: "transparent",
          outline: "none",
        }}
      >
        <Stack alignItems={"center"}>
          <XCircleIcon
            width={72}
            height={72}
            color="var(--joy-palette-danger-500)"
          />
          <Typography mt={"8px"} level="text-xl2-bold" id="modal-modal-title">
            {title}
          </Typography>
          <Typography
            textAlign={"center"}
            level="text-md-regular"
            id="modal-modal-description"
            mt={"8px"}
            width={"100%"}
          >
            {content}
          </Typography>
          {onClose && (
            <Button sx={{ marginTop: "1rem" }} onClick={onClose}>
              OK
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default FailModal;
