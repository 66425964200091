import { XMarkIcon } from "@heroicons/react/24/outline";
import { InfoOutlined } from "@mui/icons-material";
import { Button, Card, Typography } from "@mui/joy";
import React, { useState } from "react";
import { If } from "../../../components/Condition";
import { theme } from "../../../components/Theme";

interface Props {
  icon?: React.ReactElement;
  message: string | React.ReactElement;
  withExit?: boolean;
  onExitCallback?: () => void;
}

const InfoSnackbar = ({
  icon,
  message,
  withExit = false,
  onExitCallback,
}: Props) => {
  const [show, setShow] = useState(true);

  const onExit = () => {
    setShow(false);
    if (onExitCallback) {
      onExitCallback();
    }
  };
  return show ? (
    <Card
      sx={{
        padding: 1,
        width: "100%",
        flex: 1,
        borderRadius: 10,
      }}
      variant="soft"
      color="primary"
    >
      {icon ?? (
        <InfoOutlined
          style={{
            width: 24,
            height: 24,
            color: theme.palette.others.icon_outline,
          }}
        />
      )}

      {typeof message === "string" ? (
        <Typography
          level="text-xs-regular"
          sx={{ color: theme.palette.common.black }}
        >
          {message}
        </Typography>
      ) : (
        message
      )}

      <If condition={withExit}>
        <Button
          onClick={onExit}
          variant="plain"
          className="ml-auto text-[#101828] p-0 min-w-6"
        >
          <XMarkIcon width={24} height={24} />
        </Button>
      </If>
    </Card>
  ) : (
    <></>
  );
};

export default InfoSnackbar;
