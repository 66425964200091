import { Box, Button, FormControl, Modal, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { IBusinessResponse } from "../../types/business";
import { useTranslation } from "react-i18next";
import { BrowserRouterConstants } from "../../utils/constants";
import { ItemType } from "../../types/item";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface Props {
  show?: boolean;
  onClose: () => void;
  businessId: string;
  items: string[];
  payload: ItemType[];
}

export interface TempAccountType {
  id?: string;
  authStrategy: string;
  email: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  isActive?: boolean;
  role?: {
    roleType: string;
    isOwner: boolean | null;
    business: IBusinessResponse;
  }[];
}

type TempCatalogType = {
  // catalogName: string;
  catalogType: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "10px",
  left: "10px",
  bottom: "10px",
  right: "10px",
  overflowY: "auto",
  margin: "auto",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "94%",
  maxWidth: 782,
  height: 600,
  px: { xs: 2, md: 4 },
  py: { xs: 2, md: 4 },
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const CreateCatalogModal = ({
  show = false,
  onClose,
  businessId,
  items,
}: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    // setCatalogType("PRIVATE");
    onClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);
  const methods = useForm<TempCatalogType>({
    defaultValues: {
      catalogType: "PRIVATE",
    },
    mode: "onSubmit",
  });
  const { handleSubmit, register, watch, setValue } = methods;

  const navigate = useNavigate();

  const selectedCatalogType = watch("catalogType");

  const onSubmit = async (data: TempCatalogType) => {
    if (items.length === 0) {
      window.alert("no items selected");
    }

    const createCatalogPayload = {
      business: {
        id: businessId,
      },
      catalogType: data.catalogType,
      items,
      importMethod: "inventory",
      step: 1,
    };

    localStorage.setItem("new-catalog", JSON.stringify(createCatalogPayload));

    handleClose();
    navigate(
      `/client/${BrowserRouterConstants.CataloguesCreate}?type=${selectedCatalogType}&source=inventory`
    );
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
        height: "auto",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"} className="hide-scrollbar">
        <Typography
          textAlign={"center"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
            marginTop: { xs: 0, md: 4 },
            marginBottom: { xs: 2, md: 0 },
          }}
        >
          {t("catalog.inventory.createCatalog.title")}
        </Typography>

        <form
          className="w-full flex flex-col px-2"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (!(event.target instanceof HTMLTextAreaElement)) {
                event.preventDefault();
              }
            }
          }}
        >
          <FormControl>
            <div className="w-full  py-1 md:py-3 px-2 flex flex-col  lg:flex-row lg:justify-between lg:items-center gap-3 md:gap-2">
              <Stack
                sx={{
                  width: { xs: "100%", md: 355 },
                  height: { xs: "100%", md: 350 },
                  border: `2px solid ${
                    selectedCatalogType === "PUBLIC" ? "#6BAB8D" : "#ccc"
                  }`,
                  padding: { xs: 2, md: 4 },
                  gap: { xs: 3, md: 2 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "32px",
                }}
                onClick={() => setValue("catalogType", "PUBLIC")}
              >
                <div>
                  <Typography
                    level="title-lg"
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    {t("catalog.catalogType.publicLabel")}
                  </Typography>
                </div>

                <img
                  src="/public-svg.svg"
                  width={100}
                  height={100}
                  className="flex-shrink"
                />

                <div className="self-stretch">
                  <Typography
                    sx={{
                      color: "var(--Text-Primary, #101828)",
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: 1.4,
                    }}
                  >
                    {t("catalog.catalogType.publicDescription")}
                  </Typography>
                </div>
              </Stack>
              <Stack
                sx={{
                  width: { xs: "100%", md: 355 },
                  height: { xs: "100%", md: 350 },
                  border: `2px solid ${
                    selectedCatalogType === "PRIVATE" ? "#6BAB8D" : "#ccc"
                  }`,
                  padding: { xs: 2, md: 4 },
                  gap: { xs: 3, md: 2 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "32px",
                }}
                onClick={() => setValue("catalogType", "PRIVATE")}
              >
                <div>
                  <Typography
                    level="title-lg"
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    {t("catalog.catalogType.privateLabel")}
                  </Typography>
                </div>

                <img
                  src="/private-svg.svg"
                  width={100}
                  height={100}
                  className="flex-shrink"
                />

                <div className="self-stretch">
                  <Typography
                    sx={{
                      color: "var(--Text-Primary, #101828)",
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: 1.4,
                    }}
                  >
                    {t("catalog.catalogType.privateDescription")}
                  </Typography>
                </div>
              </Stack>
            </div>
            <input type="hidden" {...register("catalogType")} />
          </FormControl>
          <Stack direction={"row"} justifyContent={"right"} mt={4}>
            <Button
              type="button"
              variant="plain-gray"
              className="ml-auto rounded-md"
              onClick={handleClose}
            >
              {t("commons.cancelBtn")}
            </Button>
            <Button
              type="submit"
              sx={{
                marginLeft: "2rem",
                padding: 1.5,
              }}
              className="rounded-md"
            >
              {t("catalog.inventory.createCatalogBtn")}
            </Button>
          </Stack>
        </form>

        {/* modals */}
        {/* <SuccessModal
          title={t("commons.successModalTitle")}
          content={t("catalog.inventory.createCatalog.catalogCreated")}
          open={showAlert}
        />
        <LoadingModal isLoading={isLoading} /> */}
      </Box>
    </Modal>
  );
};

export default CreateCatalogModal;
