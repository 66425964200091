import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Button, Modal, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

interface IProps {
  open: boolean;
  title: string;
  content: string;
  onClose?: () => void;
}

export function SuccessModal({ open, title, content, onClose }: IProps) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 329,
    height: "auto",
    bgcolor: "#fff",
    boxShadow: 24,
    px: "24px",
    py: "32px",
    borderRadius: "32px",
    borderColor: "transparent",
    outline: "none",
  };

  return (
    <Modal
      id="success-modal"
      open={open}
      style={{ backgroundColor: "#32383E99", backdropFilter: "unset" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack alignItems={"center"}>
          <CheckCircleIcon
            width={72}
            height={72}
            color="var(--joy-palette-primary-500)"
          />
          <Typography mt={"8px"} level="text-xl2-bold" id="modal-modal-title">
            {title}
          </Typography>
          <Typography
            textAlign={"center"}
            level="text-md-regular"
            id="modal-modal-description"
            mt={"8px"}
          >
            {content}
          </Typography>
          {onClose && (
            <Button sx={{ marginTop: "1rem" }} onClick={onClose}>
              OK
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
