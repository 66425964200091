import { Box, Button, Grid, Input, Stack, Typography } from "@mui/joy";
import { Popover } from "@mui/material";
import * as React from "react";

export function openSidebar() {
  if (typeof window !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
  }
}

export function closeSidebar() {
  if (typeof window !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
  }
}

export function toggleSidebar() {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
  buttonPosition = "top",
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sxClasses: object;
  }) => React.ReactNode;
  buttonPosition?: "top" | "bottom";
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  const sxClasses = { cursor: "pointer" };

  return (
    <React.Fragment>
      {buttonPosition === "top" && renderToggle({ open, setOpen, sxClasses })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          marginTop: open ? "" : "0 !important",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
      {buttonPosition === "bottom" &&
        renderToggle({ open, setOpen, sxClasses })}
    </React.Fragment>
  );
}

export function PartialToggler({
  defaultExpanded = false,
  renderToggle,
  children,
  buttonPosition = "top",
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sxClasses: object;
  }) => React.ReactNode;
  buttonPosition?: "top" | "bottom";
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  const sxClasses = { cursor: "pointer" };

  const alwaysShowChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === AlwaysShow
  );

  const togglyShowUpperChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === TogglyShowUpper
  );

  const togglyShowLowerChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === TogglyShowLower
  );

  return (
    <React.Fragment>
      {buttonPosition === "top" && renderToggle({ open, setOpen, sxClasses })}
      <Stack mt={"0 !important"}>
        {/* Conditionally show this row */}
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: open ? "1fr" : "0fr",
            marginTop: !!togglyShowUpperChildren?.length && open ? 4 : 0,
            transition: "0.2s ease",
            "& > *": {
              overflow: "hidden",
            },
            rowGap: 2,
          }}
        >
          {togglyShowUpperChildren}
        </Box>
        {/* Always show this row */}
        {!!alwaysShowChildren?.length && (
          <Box mt={1} position={"relative"}>
            {alwaysShowChildren}
          </Box>
        )}

        {/* Conditionally show this row */}
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: open ? "1fr" : "0fr",
            transition: "0.2s ease",
            "& > *": {
              overflow: "hidden",
            },
            rowGap: 2,
          }}
        >
          {togglyShowLowerChildren}
        </Box>
      </Stack>
      {buttonPosition === "bottom" &&
        renderToggle({ open, setOpen, sxClasses })}
    </React.Fragment>
  );
}

const AlwaysShow = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
PartialToggler.AlwaysShow = AlwaysShow;

const TogglyShowUpper = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
PartialToggler.TogglyShowUpper = TogglyShowUpper;

const TogglyShowLower = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
PartialToggler.TogglyShowLower = TogglyShowLower;

export function TwentyEightDaysPicker({
  name,
  value,
  onChange,
  disabled,
  placholder,
}: any) {
  const daysInMonth = Array.from({ length: 28 }, (_, index) => index + 1);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDayClick = (day: number) => {
    // const newDate = new Date(value.getFullYear(), value.getMonth(), day);
    onChange(day);
    handleClose();
  };

  return (
    <>
      <Input
        placeholder={placholder}
        disabled={disabled}
        name={name}
        value={value}
        onClick={handleOpen}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "400px",
            },
          },
        }}
      >
        <Stack p={2}>
          <Typography>Select Day (1-28)</Typography>
          <Grid container columns={7} width={"100%"} mt={1}>
            {daysInMonth.map((day) => (
              <Grid key={day} xs={1}>
                <Button
                  onClick={() => handleDayClick(day)}
                  // variant={value.getDate() === day ? "contained" : "outlined"}
                  sx={{
                    minWidth: "auto",
                    width: "100%",
                    background:
                      value === day
                        ? "var(--joy-palette-primary-500)"
                        : "var(--joy-palette-common-white)",
                    color:
                      value === day
                        ? "var(--joy-palette-common-white) !important"
                        : "var(--joy-palette-common-black)",
                    padding: 2,
                    borderRadius: 0,
                    border: "1px solid var(--joy-palette-neutral-200)",
                    "&:hover, &:focus": {
                      backgroundColor:
                        "var(--joy-palette-primary-500) !important",
                      color: "var(--joy-palette-common-white)",
                    },
                  }}
                >
                  {day}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Popover>
    </>
  );
}

export function OrderTypeTag({
  type,
  size = 40,
}: {
  type: string;
  size?: number;
}) {
  return (
    <Typography
      sx={{
        backgroundColor: `var(--joy-palette-others-order_${type})`,
        width: `${size}px`,
        height: `${size}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        textTransform: "capitalize",
      }}
    >
      {type.slice(0, 1)}
    </Typography>
  );
}

export function formatInventoryItemResponse(items: any[]) {
  console.log("BEFORE FORMATTING...", items);
  const formattedItems = items.map((item: any) => {
    if (item.inventoryItem) {
      return {
        ...item,
        ...item.inventoryItem,
        price: item.price || item.inventoryItem.price,
        currency: item.currency || item.inventoryItem.currency,
      };
    }
    return item;
  });

  console.log("FORMATTING...", formattedItems);
  return formattedItems;
}

export function transformUomList(productData: any) {
  const list = [];
  console.log("Transform UOM List ::: ", productData);
  for (const key in productData) {
    if (key.startsWith("uom") && productData[key] !== null) {
      // cut the prefix "uom" and take the number
      const num = key.length > 3 ? key.slice(3) : "";

      // if num is 1, then key is just "conversion" and "price"
      const conversionKey = "conversion" + (num === "1" ? "" : num);
      const priceKey = "price" + (num === "1" ? "" : num);

      list.push({
        uom: productData[key],
        conversion: productData[conversionKey] || 1,
        price: productData[priceKey] || null,
      });
    }
  }
  console.log("Product UOM List ::: ", list);
  return list;
}
