import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  iconButtonClasses,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import {
  IOrderList,
  orderStatuses,
  PaymentFlowTypeEnum,
} from "../../types/order";
import { OrderTypeTag } from "../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { BrowserRouterConstants } from "../../utils/constants";
import dayjs from "dayjs";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { EventRepeatOutlined } from "@mui/icons-material";
import { getInvoiceNumber } from "../../utils/order";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

export default function OrderListTable({
  isPublicView,
  orderList,
  handleOnClick,
  selected,
  setSelected,
  rows,
  ThreeDotsRowMenu,
}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {/* Order Table */}
      <Sheet
        className="OrderTableContainer"
        // variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader={true}
          hoverRow
          sx={{
            "--TableCell-headBackground": "var(--joy-palette-neutral-100)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground": "var(--joy-palette-neutral-50)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              {!isPublicView && (
                <th
                  style={{
                    width: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                >
                  <Checkbox
                    size="sm"
                    indeterminate={
                      selected.length > 0 && selected.length !== rows.length
                    }
                    checked={selected.length === rows.length}
                    onChange={(event) => {
                      setSelected(
                        event.target.checked
                          ? rows.map((row: any) => row.id)
                          : []
                      );
                    }}
                    color={
                      selected.length > 0 || selected.length === rows.length
                        ? "primary"
                        : undefined
                    }
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </th>
              )}
              {/* <th style={{ width: 120, padding: "12px 6px" }}>
                      <Link
                        underline="none"
                        color="primary"
                        component="button"
                        onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                        fontWeight="lg"
                        endDecorator={<ArrowDropDownIcon />}
                        sx={{
                          "& svg": {
                            transition: "0.2s",
                            transform:
                              order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                          },
                        }}
                      >
                        Invoice
                      </Link>
                    </th> */}
              <th style={{ width: 80, padding: "12px 12px" }}>
                {t("order.table.type")}
              </th>
              <th style={{ width: "auto", padding: "12px 6px" }}>
                {t("order.table.orderNo")}
              </th>
              <th style={{ width: "auto", padding: "12px 6px" }}>
                {t("order.table.company")}
              </th>
              <th style={{ width: "auto", padding: "12px 6px" }}>
                {t("order.table.person")}
              </th>
              <th style={{ width: "auto", padding: "12px 6px" }}>
                {t("order.table.status")}
              </th>
              <th style={{ width: "auto", padding: "12px 6px" }}>
                {t("order.table.updated")}
              </th>
              {!isPublicView && (
                <th style={{ width: 60, padding: "12px 6px" }}> </th>
              )}
            </tr>
          </thead>
          <tbody>
            {/* {stableSort(orderList, getComparator("desc", "id")).map( */}
            {orderList?.map((row: IOrderList) => (
              <tr
                key={row.id}
                onClick={() =>
                  navigate(
                    isPublicView
                      ? BrowserRouterConstants.PublicPrefix +
                          BrowserRouterConstants.Orders +
                          `/${row.orderType}/${row.id}`
                      : BrowserRouterConstants.ClientPrefix +
                          BrowserRouterConstants.Orders +
                          `/${row.id}`
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {!isPublicView && (
                  <td style={{ textAlign: "center", width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      color={selected.includes(row.id) ? "primary" : undefined}
                      onChange={(event) => {
                        event.stopPropagation();
                        setSelected((ids: any) =>
                          event.target.checked
                            ? ids.concat(row.id)
                            : ids.filter((itemId: any) => itemId !== row.id)
                        );
                      }}
                      onClick={(event) => event.stopPropagation()}
                      slotProps={{
                        checkbox: { sx: { textAlign: "left" } },
                      }}
                      sx={{ verticalAlign: "text-bottom" }}
                    />
                  </td>
                )}
                <td>
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <OrderTypeTag type={row.orderType} />
                  </Box>
                </td>
                <td>
                  <NavLink
                    to={
                      isPublicView
                        ? BrowserRouterConstants.PublicPrefix +
                          BrowserRouterConstants.Orders +
                          `/${row.orderType}/${row.id}`
                        : BrowserRouterConstants.ClientPrefix +
                          BrowserRouterConstants.Orders +
                          `/${row.id}`
                    }
                  >
                    <Typography
                      level="body-xs"
                      sx={{ "&:hover": { textDecoration: "underline" } }}
                      endDecorator={
                        row.invoiceNumber &&
                        row.paymentFlowType ===
                          PaymentFlowTypeEnum.RECURRING_PAYMENT ? (
                          <EventRepeatOutlined
                            color="primary"
                            sx={{ marginLeft: "4px" }}
                          />
                        ) : row.orderRevisions?.length > 0 ? (
                          <div className="rounded-lg bg-[#F7D5AC] w-[24px] h-[24px] flex justify-center items-center ml-3">
                            <DocumentDuplicateIcon
                              width={16}
                              height={18}
                              color="#D48434"
                            />
                          </div>
                        ) : (
                          ""
                        )
                      }
                    >
                      {row.invoiceNumber
                        ? getInvoiceNumber(row, row.orderRevisions)
                        : "-"}
                    </Typography>
                  </NavLink>
                </td>
                <td>
                  <div>
                    {/* If Sales 'S', this person will be Buyer Company & Person Info
            If Purchase 'P', this person will be Seller Company & Person Info */}
                    <Typography level="body-xs">
                      {row.orderType === "sales"
                        ? row.buyer?.companyName || "-"
                        : row.orderType === "purchase"
                        ? row.seller?.companyName || "-"
                        : ""}
                    </Typography>
                  </div>
                </td>
                <td>
                  <div>
                    <Typography level="body-xs">
                      {row.orderType === "sales"
                        ? row.buyerUser?.profile?.fullName || "-"
                        : row.orderType === "purchase"
                        ? row.sellerUser?.profile?.fullName || "-"
                        : ""}
                    </Typography>
                  </div>
                </td>
                <td>
                  <Chip
                    variant="soft"
                    size="sm"
                    sx={{
                      textWrap: "wrap",
                      padding: 1,
                      textTransform: "capitalize",
                    }}
                    // color={
                    //   {
                    //     Paid: "success",
                    //     Refunded: "neutral",
                    //     Cancelled: "danger",
                    //   }[row.status] as ColorPaletteProp
                    // }
                  >
                    {/* {OrderStatusEnum[row.status]
                          .toLowerCase()
                          .replace("_", " ") || ""} */}
                    {/* {t(`order.filters.status.${OrderStatusEnum[row.status]}`)} */}
                    {
                      orderStatuses?.find((os) =>
                        os.backendStatuses.includes(row.status)
                      )?.label
                    }
                  </Chip>
                </td>
                <td>
                  <Typography level="body-xs">
                    {dayjs(row.updatedAt).format("DD MMM YYYY hh:mm A")}
                  </Typography>
                </td>
                {!isPublicView && (
                  <td>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      {ThreeDotsRowMenu && (
                        <ThreeDotsRowMenu
                          orderId={row.id}
                          orderStatus={row.status}
                          handleOnClick={handleOnClick}
                        />
                      )}
                    </Box>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "none", // Pagination made "none" for now
          },
          alignItems: "center",
          mt: 4,
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {["1", "2", "3", "…", "8", "9", "10"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
            sx={{ width: "3rem", height: "3rem" }}
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </>
  );
}
