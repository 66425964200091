import { Typography } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "../../services/auth.service";

import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import i18next from "../../i18n/config";
import { TrackerService } from "../../services/tracker.service";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../lib/firebase";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
const RESEND_CODE_COUNTDOWN = 60 * 3;

// const RESEND_CODE_COUNTDOWN = 10;
const useContainer = () => {
  //#region local states
  const navigate = useNavigate();
  const [mobilePhone, setMobilePhone] = useState({
    countryCode: "",
    mobile: "",
    token: "",
  });
  const [openBusinessSelectionModal, setOpenBusinessSelectionModal] =
    useState(false);
  const { state } = useLocation();
  const isFromSSO = state?.origin === "sso";
  const phoneNoChangeAttempLocalStorage =
    localStorage.getItem("phoneNoChangeAttemp") || "3";
  const [otpInput, setOtpInput] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [phoneNoChangeAttempts, setPhoneNoChangeAttempts] = useState(
    parseInt(phoneNoChangeAttempLocalStorage) ?? 0
  );
  const [maxTries, setMaxTries] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [changePhoneSeconds, setChangePhoneSeconds] = useState(
    RESEND_CODE_COUNTDOWN
  );
  const verifyMethod = localStorage.getItem("method") || "signup-email";
  const timerRef = useRef<NodeJS.Timeout | undefined>();
  const changePhoneTimerRef = useRef<NodeJS.Timeout | undefined>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const callbackUrl = searchParams.get("callbackURL");
  const email =
    JSON.parse(localStorage.getItem("signUpCredentials") || "{}")?.email ?? "";
  const signUpData = JSON.parse(localStorage.getItem("signUpData") || "{}");

  const otpVerifyMutation = useMutation({
    mutationFn:
      verifyMethod === "signup-phone" ? signUpWithPhone : AuthService.otpVerify,
    // : AuthService.otpVerify,
    onSuccess: () => {
      TrackerService.identify(email, {
        countryCode: mobilePhone.countryCode,
        mobile: mobilePhone.mobile,
        email: email,
      });
      TrackerService.track(
        TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.OTPSuccessSubmitted
      );
      localStorage.removeItem("userSub");
      localStorage.removeItem("countryCode");
      localStorage.removeItem("mobile");
      localStorage.removeItem("phoneNoChangeAttemp");
      localStorage.removeItem("phoneVerificationToken");
      localStorage.removeItem("signUpCredentials");
      localStorage.removeItem("signUpData");

      if (callbackUrl) {
        navigate(
          `/phone-verified?callbackURL=${encodeURIComponent(callbackUrl)}`,
          {
            state: { phoneVerified: true },
          }
        );
      } else {
        navigate("/phone-verified", { state: { phoneVerified: true } });
      }
    },
    onError: (error: any) => {
      const errorMessage = i18next.t(
        `commons.error.${
          error.response.data.message || "Something went wrong."
        }`
      );
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
      setDisabled(false);
    },
  });

  const resendMutation = useMutation({
    mutationFn: AuthService.resendOTP,
    onError: (error: any) => {
      const errorMessage =
        error.response.data.message || "Something went wrong.";
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
      setDisabled(false);
    },
  });

  const signinSSOMutation = useMutation({
    mutationFn: AuthService.signinSSO,
    onSuccess: ({ data }) => {
      if (data.accessToken) {
        localStorage.setItem("businessChooseToken", data.accessToken);
      }
      setOpenBusinessSelectionModal(true);
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });
  //#endregion

  //#region ----- life cycle methods
  useEffect(() => {
    if (otpInput.trim().length === 6) {
      onSubmit({ otpInput });
    }
  }, [otpInput]);

  useEffect(() => {
    timerRef.current = setInterval(countDownInterval, 1000);
    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    changePhoneTimerRef.current = setInterval(
      countDownPhoneChangeInterval,
      1000
    );
    return () => clearInterval(changePhoneTimerRef.current);
  }, []);

  useEffect(() => {
    const countryCode = localStorage.getItem("countryCode") || "+65";
    const mobile = localStorage.getItem("mobile") || "9444054610";
    const phoneVerificationToken =
      localStorage.getItem("phoneVerificationToken") || "";
    if (!countryCode || !mobile) {
      return navigate("/signup");
    }
    setMobilePhone({
      countryCode,
      mobile,
      token: phoneVerificationToken,
    });
  }, []);

  // setup recaptcha
  useEffect(() => {
    if (!window.recaptchaVerifier) {
      // create element if not found
      let recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        recaptchaContainer = document.createElement("div");
        recaptchaContainer.id = "recaptcha-container";
        document.body.append(recaptchaContainer);
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );

      window.recaptchaVerifier.verify();
    }
  }, []);

  //#endregion

  //#region ----- handler methods
  const countDownInterval = () => {
    setSeconds((prevSeconds) => {
      if (prevSeconds - 1 <= 0) {
        clearInterval(timerRef.current);
        return 0;
      }
      return prevSeconds - 1;
    });
  };

  const countDownPhoneChangeInterval = () => {
    setChangePhoneSeconds((prevSeconds) => {
      if (prevSeconds - 1 <= 0) {
        clearInterval(timerRef.current);
        return 0;
      }
      return prevSeconds - 1;
    });
  };

  const resendOTPCode = async () => {
    if (verifyMethod === "signup-phone" || verifyMethod === "signin-phone") {
      const sendFirebaseOtp = await AuthService.sendFirebaseOtp({
        phoneNumber: mobilePhone.mobile,
        phoneNumberCountryCode: mobilePhone.countryCode,
      });

      if (sendFirebaseOtp?.status !== 200) {
        console.error("Error resending OTP from firebase", sendFirebaseOtp);
      }
    } else {
      resendMutation.mutate({
        phoneNumber: mobilePhone.mobile,
        countryCode: mobilePhone.countryCode,
        token: mobilePhone.token,
      });
    }

    setSeconds(RESEND_CODE_COUNTDOWN);
    setMaxTries((prev) => prev - 1);
    timerRef.current = setInterval(countDownInterval, 1000);
  };

  const changeNumberClick = () => {
    localStorage.setItem(
      "phoneNoChangeAttemp",
      (phoneNoChangeAttempts - 1).toString()
    );
    setPhoneNoChangeAttempts((prev) => prev - 1);

    if (verifyMethod === "signin-phone") {
      navigate(`/signin?${searchParams.toString()}`);
    } else {
      const _callbackURL = callbackUrl
        ? `/signup/phone-input?callbackURL=${encodeURIComponent(callbackUrl)}`
        : `/signup/phone-input`;
      navigate(_callbackURL, {
        state:
          verifyMethod === "signup-phone"
            ? {
                ...signUpData,
              }
            : {
                ...(email ? { email } : {}),
                ...(isFromSSO ? { origin: "sso" } : {}),
              },
      });
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);

    if (verifyMethod === "signin-phone") {
      const isOtpValid = await verifyFirebaseOtp(otpInput);

      if (isOtpValid) signinSSOMutation.mutate({ accessToken: isOtpValid });
      else {
        const errorMessage = i18next.t(`commons.error.form.invalidOtp`);
        toast(
          (t) => (
            <div
              style={{
                width: "100%",
                opacity: t.visible ? 1 : 0,
                transition: "opacity 100ms ease-in-out",
              }}
            >
              <Typography
                textColor={"danger.softColor"}
                fontSize={"14px"}
                fontWeight={500}
                textAlign={"center"}
                py={"12px"}
              >
                {errorMessage}
              </Typography>
            </div>
          ),
          {
            style: {
              padding: 0,
              margin: 0,
            },
            position: "bottom-center",
            duration: 4000,
          }
        );
      }

      return;
    }

    otpVerifyMutation.mutate({
      phoneNumber: mobilePhone.mobile,
      countryCode: mobilePhone.countryCode,
      code: data.otpInput,
      token: mobilePhone.token,
    });
  };

  const formatTime = (_seconds: number) => {
    const minutes = Math.floor((_seconds % 3600) / 60);
    const remainingSeconds = _seconds % 60;

    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  async function signUpWithPhone(payload: IOtpVerificationPayload) {
    const confirmationResult = window.confirmationResult;

    if (!confirmationResult) {
      console.error("Invalid or missing verificationId.");
      return;
    }

    try {
      const userCredential = await confirmationResult.confirm(payload.code);

      let signUpPayload: any = {};
      console.log("SIGNUP DATA", signUpData);

      if (email) {
        signUpPayload = {
          uid: userCredential.user.uid,
          fullName: signUpData.fullName,
          companyName: signUpData.companyName,
          companyCountryCode: signUpData.countryName,
          phoneNumber: mobilePhone.mobile,
          phoneNumberCountryCode: mobilePhone.countryCode.replace("+", ""),
          email: email,
          referralCode: signUpData.referralCode || null,
          referrerUserId: signUpData.referrerUserId || null,
        };
      } else {
        signUpPayload = {
          uid: userCredential.user.uid,
          fullName: signUpData.fullName,
          companyName: signUpData.companyName,
          companyCountryCode: signUpData.countryName,
          phoneNumber: mobilePhone.mobile,
          phoneNumberCountryCode: mobilePhone.countryCode.replace("+", ""),
          referralCode: signUpData.referralCode || null,
          referrerUserId: signUpData.referrerUserId || null,
        };
      }

      const phoneAccessToken = await userCredential.user.getIdToken();
      if (token) {
        return await AuthService.signUpWithPhoneWithRoleInvitation({
          fullName: signUpData.fullName,
          localPhoneNumber: mobilePhone.mobile,
          countryCode: mobilePhone.countryCode,
          accessToken: phoneAccessToken,
          invitationToken: token,
        });
      }
      return await AuthService.signUpWithPhone(signUpPayload);
    } catch (e: any) {
      console.error("Error verifying OTP:", e);
      const errorMessage = i18next.t(`commons.error.form.invalidOtp`);
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
      setDisabled(false);
      throw e;
    }
  }

  async function verifyFirebaseOtp(otp: string) {
    const confirmationResult = window.confirmationResult;

    if (!confirmationResult) {
      console.error("Invalid or missing verificationId.");

      navigate(`/signup?${searchParams.toString()}`);
      return;
    }
    try {
      // const credential = PhoneAuthProvider.credential(
      //   verificationId,
      //   payload.code
      // );
      // console.log("User signed in successfully:", credential);
      const userCredential = await confirmationResult.confirm(otp);

      return await userCredential.user.getIdToken();
    } catch (e: any) {
      console.error("Error verifying OTP:", e);
      const errorMessage = i18next.t(`commons.error.form.invalidOtp`);
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
      setDisabled(false);
      throw e;
    }
  }

  function closeBusinessModalHandler() {
    setOpenBusinessSelectionModal(false);
  }
  //#endregion

  return {
    // states
    phoneNoChangeAttempts,
    phone: `+${mobilePhone.countryCode.replace("+", "")} ${mobilePhone.mobile}`,
    isError: otpVerifyMutation.isError,
    otpInput,
    maxTries,
    seconds,
    disabled,
    isLoading: otpVerifyMutation.isPending,
    phoneNoChangeAttempLocalStorage,
    changePhoneSeconds,
    openBusinessSelectionModal,
    // functions
    setOtpInput,
    onSubmit,
    formatTime,
    resendOTPCode,
    changeNumberClick,
    closeBusinessModalHandler,
  };
};

export default useContainer;
