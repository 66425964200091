import { Box, Chip, Sheet, Table, Typography } from "@mui/joy";
import {
  IOrderList,
  orderReportTypes,
  orderStatuses,
  PaymentFlowTypeEnum,
  paymentFlowTypes,
} from "../../types/order";
import { NavLink, useNavigate } from "react-router-dom";
import { BrowserRouterConstants } from "../../utils/constants";
import { EventRepeatOutlined } from "@mui/icons-material";
import { getInvoiceNumber } from "../../utils/order";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { formatPrice } from "../../utils/formatPrice";
import { OrderTypeTag } from "../utils";
import dayjs from "dayjs";

const ReportTableBodyRows = ({
  orderList,
  reportType,
  handleDownloadPdf,
  DownloadPdfButton,
}: any) => {
  const navigate = useNavigate();
  if (
    [
      orderReportTypes.TOTAL_RECEIVABLE,
      orderReportTypes.TOTAL_PAYABLE,
    ].includes(reportType)
  ) {
    return orderList?.map((row: IOrderList) => (
      <tr
        key={row.id}
        onClick={() => {
          navigate(
            BrowserRouterConstants.ClientPrefix +
              BrowserRouterConstants.Orders +
              `/${row.id}`
          );
        }}
        style={{ cursor: "pointer" }}
      >
        <td
          style={{
            textAlign: "left",
            width: 120,
            position: "sticky",
            left: 0,
            zIndex: 1,
          }}
        >
          <DownloadPdfButton
            onClickDownloadPdf={() => handleDownloadPdf(row.id)}
          />
        </td>
        {/* <td>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <OrderTypeTag type={row.orderType} />
          </Box>
        </td> */}
        <td>
          <NavLink
            to={
              BrowserRouterConstants.ClientPrefix +
              BrowserRouterConstants.Orders +
              `/${row.id}`
            }
          >
            <Typography
              level="body-xs"
              sx={{ "&:hover": { textDecoration: "underline" } }}
              endDecorator={
                row.invoiceNumber &&
                row.paymentFlowType ===
                  PaymentFlowTypeEnum.RECURRING_PAYMENT ? (
                  <EventRepeatOutlined
                    color="primary"
                    sx={{ marginLeft: "4px" }}
                  />
                ) : row.orderRevisions?.length > 0 ? (
                  <div className="rounded-lg bg-[#F7D5AC] w-[24px] h-[24px] flex justify-center items-center ml-3">
                    <DocumentDuplicateIcon
                      width={16}
                      height={18}
                      color="#D48434"
                    />
                  </div>
                ) : (
                  ""
                )
              }
            >
              {row.invoiceNumber
                ? getInvoiceNumber(row, row.orderRevisions)
                : "-"}
            </Typography>
          </NavLink>
        </td>

        <td>
          <div>
            {/* If Sales 'S', this person will be Buyer Company & Person Info
    If Purchase 'P', this person will be Seller Company & Person Info */}
            <Typography level="body-xs">
              {row.orderType === "sales"
                ? row.buyer?.companyName || "-"
                : row.orderType === "purchase"
                ? row.seller?.companyName || "-"
                : ""}
            </Typography>
          </div>
        </td>

        <td>
          <Typography level="body-xs">
            {row.currency || row.seller?.companyCountry}{" "}
            {formatPrice(
              row.remainingAmount || 0,
              row.currency as string | undefined
            )}
          </Typography>
        </td>
        <td>
          <Chip
            variant="soft"
            size="sm"
            sx={{
              textWrap: "wrap",
              padding: 1,
              textTransform: "capitalize",
            }}
          >
            {
              orderStatuses?.find((os) =>
                os.backendStatuses.includes(row.status)
              )?.label
            }
          </Chip>
        </td>
      </tr>
    ));
  } else if (reportType === orderReportTypes.PAYMENT_HISTORY) {
    return orderList?.map((row: IOrderList) => (
      <tr
        key={row.id}
        onClick={() => {
          navigate(
            BrowserRouterConstants.ClientPrefix +
              BrowserRouterConstants.Orders +
              `/${row.id}`
          );
        }}
        style={{ cursor: "pointer" }}
      >
        <td
          style={{
            textAlign: "center",
            width: 120,
            position: "sticky",
            left: 0,
            zIndex: 1,
            background: "#fff",
          }}
        >
          <DownloadPdfButton
            onClickDownloadPdf={() => handleDownloadPdf(row.id)}
          />
        </td>
        <td>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <OrderTypeTag type={row.orderType} />
          </Box>
        </td>
        <td>
          <NavLink
            to={
              BrowserRouterConstants.ClientPrefix +
              BrowserRouterConstants.Orders +
              `/${row.id}`
            }
          >
            <Typography
              level="body-xs"
              sx={{ "&:hover": { textDecoration: "underline" } }}
              endDecorator={
                row.invoiceNumber &&
                row.paymentFlowType ===
                  PaymentFlowTypeEnum.RECURRING_PAYMENT ? (
                  <EventRepeatOutlined
                    color="primary"
                    sx={{ marginLeft: "4px" }}
                  />
                ) : row.orderRevisions?.length > 0 ? (
                  <div className="rounded-lg bg-[#F7D5AC] w-[24px] h-[24px] flex justify-center items-center ml-3">
                    <DocumentDuplicateIcon
                      width={16}
                      height={18}
                      color="#D48434"
                    />
                  </div>
                ) : (
                  ""
                )
              }
            >
              {row.invoiceNumber
                ? getInvoiceNumber(row, row?.orderRevisions)
                : "-"}
            </Typography>
          </NavLink>
        </td>

        <td>
          <div>
            {/* If Sales 'S', this person will be Buyer Company & Person Info
    If Purchase 'P', this person will be Seller Company & Person Info */}
            <Typography level="body-xs">
              {row.orderType === "sales"
                ? row.buyer?.companyName || (row as any).companyName || "-"
                : row.orderType === "purchase"
                ? row.seller?.companyName || "-"
                : ""}
            </Typography>
          </div>
        </td>
        <td>
          <Typography level="body-xs">
            {
              paymentFlowTypes.find((pft) => pft.value === row.paymentFlowType)
                ?.label
            }
          </Typography>
        </td>
        <td>
          <Typography level="body-xs">
            {row.currency + " "}
            {formatPrice(
              row.grandTotalAmount || 0,
              row.currency as string | undefined
            )}
          </Typography>
        </td>
        <td>
          <Typography level="body-xs">
            {row.currency + " "}
            {formatPrice(
              row.paidAmount || 0,
              row.currency as string | undefined
            )}
          </Typography>
        </td>
        <td>
          <Typography level="body-xs">
            {dayjs(row.settlementDate).format("DD MMM YYYY")}
          </Typography>
        </td>
        <td>
          <Typography level="body-xs">
            {`${row.paymentMode}`.replace(/-/g, " ").toUpperCase()}
          </Typography>
        </td>
      </tr>
    ));
  }

  return <></>;
};

export default function OrderReportTable({
  orderList,
  tableHeader,
  reportType,
  handleDownloadPdf,
  DownloadPdfButton,
}: any) {
  // const { t } = useTranslation();
  console.log("Table Header", tableHeader);
  return (
    <>
      {/* Order Table */}
      <Sheet
        className="OrderTableContainer"
        // variant="outlined"
        sx={{
          display: { xs: "none", sm: "block" },
          width: "100%",
          borderRadius: "sm",
          // flexShrink: 1,
          minHeight: 0,
          overflowX: "scroll",
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader={true}
          hoverRow
          sx={{
            "--TableCell-headBackground": "var(--joy-palette-neutral-100)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground": "var(--joy-palette-neutral-50)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 80,
                  textAlign: "center",
                  padding: "12px 6px",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  zIndex: 12,
                  background: "var(--joy-palette-neutral-100)",
                }}
              ></th>
              {/* <th style={{ width: 120, padding: "12px 6px" }}>
                      <Link
                        underline="none"
                        color="primary"
                        component="button"
                        onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                        fontWeight="lg"
                        endDecorator={<ArrowDropDownIcon />}
                        sx={{
                          "& svg": {
                            transition: "0.2s",
                            transform:
                              order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                          },
                        }}
                      >
                        Invoice
                      </Link>
                    </th> */}
              {tableHeader &&
                tableHeader.map(
                  (
                    item: Record<string | number, any> | string,
                    index: number
                  ) => (
                    <th
                      key={index}
                      style={{
                        width:
                          (typeof item === "object" &&
                            (item?.width as string)) ||
                          "210px",
                        padding: "12px 6px",
                      }}
                    >
                      {typeof item === "string" ? item : item.title}
                    </th>
                  )
                )}
              {/* <th style={{ width: 80, padding: "12px 12px" }}>
                {t("order.table.type")}
              </th>
              <th style={{ width: "auto", padding: "12px 6px" }}>
                {t("order.table.orderNo")}
              </th> */}
            </tr>
          </thead>
          <tbody>
            <ReportTableBodyRows
              orderList={orderList}
              reportType={reportType}
              handleDownloadPdf={handleDownloadPdf}
              DownloadPdfButton={DownloadPdfButton}
            />
          </tbody>
        </Table>
      </Sheet>
    </>
  );
}
