import { Box, Button, List, ListItem, Stack, Typography } from "@mui/joy";
import { theme } from "../../../../components/Theme";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DownloadTemplateButton from "../../../../components/Inventory/Excel/DownloadTemplateButton";
import { FileDownload, FileUpload } from "@mui/icons-material";
import FailModal from "../../../../components/FailModal";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { SuccessModal } from "../../../../components/SuccessModal";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";
import useContainer from "../../useContainer";

type Props = {
  onClick: (method: "excel" | "inventory") => void;
};

const EmptyPreviewState = ({ onClick }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    onDrop,
    isLoading,
    setIsLoading,
    showFailModal,
    setShowFailModal,
    alertModal,
    excelErrors,
    setExcelErrors,
  } = useContainer();

  /**
   * upload excel file to backend API, filter out errors
   * @param acceptedFiles
   */
  // const onDrop = async (acceptedFiles: any) => {
  //   if (acceptedFiles.length > 0) {
  //     setIsLoading(true);

  //     try {
  //       const importResponse = await CatalogService.importCatalogItems(
  //         acceptedFiles[0]
  //       );
  //       console.log("IMPORT RESPONSE", importResponse);

  //       if (importResponse.data.invalidItems) {
  //         // find items with error that's not Duplicate SKU error code
  //         const invalidItemErrors = importResponse.data.invalidItems.filter(
  //           (invalidItem) => {
  //             const nonDuplicateError = invalidItem.errors?.find(
  //               (error) => error.error !== "Duplicate SKU"
  //             );

  //             return !!nonDuplicateError;
  //           }
  //         );

  //         // put the errors in excelErrors state and show fail modal
  //         const itemErrors = invalidItemErrors.map((invalidItemError) => {
  //           const formattedErrors = invalidItemError.errors?.map((error) => {
  //             const fieldNames = error.fields.map((field) => {
  //               return error.error === "Duplicate SKU"
  //                 ? field
  //                 : t(`catalog.items.${field}`);
  //             });

  //             return {
  //               code: error.error,
  //               message: t("catalog.inventory.import.error.errorOnField", {
  //                 code: error.error,
  //                 field: fieldNames.join(", "),
  //               }),
  //             };
  //           });
  //           return {
  //             name: `row ${invalidItemError.row}`,
  //             errors: formattedErrors,
  //           };
  //         });
  //         setExcelErrors(itemErrors);
  //         setShowFailModal(true);

  //         // exit if there are other errors aside from Duplicate SKU
  //         if (itemErrors.length > 0) {
  //           setIsLoading(false);
  //           return;
  //         }

  //         // store invalidItems inside localStorage for use in duplicate sku page
  //         localStorage.setItem(
  //           "catalog-import-invalid-items",
  //           JSON.stringify({
  //             invalidItems: importResponse.data.invalidItems,
  //             totalItems: importResponse.data.totalItems,
  //           })
  //         );
  //         navigate(
  //           `/client/catalogues/export-import/duplicate/${importResponse.data.draftId}?method=create-catalog`,
  //           {}
  //         );
  //       } else {
  //         const message = t(
  //           importResponse.data?.updated ?? 0 > 0
  //             ? "catalog.inventory.import.success.updatedInventory"
  //             : "catalog.inventory.import.success.addInventory",
  //           {
  //             totalItems: importResponse.data?.totalItems ?? 0,
  //           }
  //         );
  //         setAlertModal({
  //           show: true,
  //           message,
  //         });

  //         setTimeout(() => {
  //           // window.location.href = `/client/${BrowserRouterConstants.Inventory}`;
  //         }, 3000);
  //       }
  //     } catch (error: any) {
  //       console.error("Error uploading file:", error);
  //       alert(error.message);
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
  });

  /**
   * format errors from fileRejections and put into excelErrors state
   */
  useEffect(() => {
    const formattedErrors = fileRejections.map((reject) => {
      return {
        name: reject.file.name,
        errors: reject.errors,
      };
    });

    setExcelErrors(formattedErrors);
    setShowFailModal(fileRejections.length > 0);
    console.log("file rejections", fileRejections);
  }, [fileRejections]);

  /**
   * turn off isLoading on render
   */
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      sx={{
        gap: "32px",
        alignItems: "stretch",
        justifyContent: "center",
        pb: "32px",
      }}
    >
      {/* export/import excel file */}
      <Stack
        direction={"column"}
        sx={{
          borderWidth: "1px",
          borderColor: theme.palette.neutral[300],
          width: { lg: "479px" },
          minHeight: "320px",
          borderRadius: "8px",
          gap: "20px",
          alignItems: "center",
          cursor: "pointer",
          py: { xs: "30px", lg: "72px" },
          px: "80px",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "300ms",
          "&:hover": {
            backgroundColor: theme.palette.primary[50],
          },
        }}
        {...getRootProps()}
      >
        <Typography
          level="text-lg-bold"
          sx={{ color: theme.palette.common.black }}
        >
          {t("catalog.createCatalog.import.options.option1")}
        </Typography>
        <img
          src="/bulk.png"
          width={100}
          height={100}
          className="object-contain"
        />
        <Stack
          direction={"column"}
          sx={{
            gap: "4px",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography level="text-sm-regular">
            {t("catalog.createCatalog.import.options.option1Subtitle")}
          </Typography>
        </Stack>
        <List
          sx={{
            width: "15.62rem",
            gap: "8px",
          }}
        >
          <ListItem sx={{ display: "flex", gap: "8px", padding: 0 }}>
            <Typography fontSize={14}>1.</Typography>
            <DownloadTemplateButton
              icon={<FileDownload />}
              title={t("catalog.createCatalog.exportExcelBtn")}
              id="catalog-create-export-button"
            />
          </ListItem>
          <ListItem sx={{ display: "flex", gap: "8px", padding: 0 }}>
            <Typography fontSize={14}>2.</Typography>
            <Button
              id="catalog-create-import-button"
              {...getRootProps({ className: "dropzone" })}
              startDecorator={<FileUpload />}
              fullWidth
              className="whitespace-nowrap"
              onClick={() => {
                onClick("excel");
              }}
            >
              {t("catalog.createCatalog.importExcelBtn")}
              <input {...getInputProps()} />
            </Button>
          </ListItem>
        </List>
      </Stack>

      <Typography
        level="text-lg-bold"
        sx={{
          textAlign: "center",
          my: { md: "auto" },
          color: theme.palette.common.black,
        }}
      >
        {t("landing.or")}
      </Typography>

      {/* add from inventory */}
      <Stack
        direction={"column"}
        sx={{
          borderWidth: "1px",
          borderColor: theme.palette.neutral[300],
          width: { lg: "479px" },
          minHeight: "320px",
          borderRadius: "8px",
          gap: "20px",
          alignItems: "center",
          cursor: "pointer",
          py: { xs: "30px", lg: "72px" },
          px: "80px",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "300ms",
          "&:hover": {
            backgroundColor: theme.palette.primary[50],
          },
        }}
      >
        <Typography
          level="text-lg-bold"
          sx={{ color: theme.palette.common.black }}
        >
          {t("catalog.createCatalog.import.options.option2")}
        </Typography>
        <img
          src="/single.png"
          width={100}
          height={100}
          className="object-contain"
        />
        <Stack
          direction={"column"}
          sx={{
            gap: "4px",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography level="text-sm-regular">
            {t("catalog.createCatalog.import.options.option2Subtitle")}
          </Typography>
        </Stack>

        <Button
          id="catalog-create-addItems-button"
          onClick={() => {
            onClick("inventory");
            navigate("/client/catalogues/add-items?isCreate=true");
          }}
          startDecorator={<MagnifyingGlassIcon width={20} height={20} />}
        >
          {t("catalog.createCatalog.import.options.chooseItemsBtn")}
        </Button>
      </Stack>

      {/* modal */}
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertModal.message}
        open={alertModal.show}
      />
      <FailModal
        open={showFailModal}
        title="Failed Uploading File"
        content={
          <Box
            sx={{
              width: "100%",
              p: "16px",
              textAlign: "left",
              height: "250px",
              overflow: "auto",
            }}
            className="scrollbar-container"
          >
            <List marker="disc">
              {excelErrors.map(({ name, errors }) => (
                <ListItem key={name}>
                  {t("catalog.inventory.import.error.errorOnRow", {
                    row: name,
                  })}
                  :
                  <List marker="circle">
                    {errors.map((e: any) => (
                      <ListItem key={e.code}>{e.message}</ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Box>
        }
        onClose={() => {
          setShowFailModal(false);
        }}
      />
      <LoadingModal isLoading={isLoading} />
    </Stack>
  );
};

export default EmptyPreviewState;
