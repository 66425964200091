import { useDropzone } from "react-dropzone";
import { ItemType } from "../../../types/item";
import { ItemService } from "../../../services/item.service";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/joy";
import { UploadService } from "../../../services/upload.service";

interface Props {
  item: ItemType;
  size?: number;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setShowToast: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  variant?: "button" | "image" | "inventory";
  callback?: () => void;
  label?: string;
}

const UploadImageBtn = ({
  item,
  size = 30,
  setIsLoading,
  setShowToast,
  setAlertMessage,
  variant = "image",
  callback,
  label,
}: Props) => {
  const { t } = useTranslation();
  const [inventoryItem, setInventoryItem] = useState<ItemType>(item);

  const generateUniqueName = () => {
    const timestamp = Date.now();
    const random = Math.random().toString(36).substring(2, 7);

    return `${timestamp}-${random}`;
  };

  const handleDrop = async (acceptedFiles: any) => {
    const doSpaceHost =
      import.meta.env.VITE_SPACES_HOST ||
      "https://linkz-staging-bucket.sgp1.digitaloceanspaces.com";
    setIsLoading(true);

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const key = generateUniqueName();

      if (item.thumbnail) {
        // TODO : implement delete on backend
        // const objectKey = item.thumbnail.split("/")[3];

        // const params = {
        //   Bucket: import.meta.env.VITE_SPACES_BUCKET_NAME,
        //   Key: objectKey,
        // };

        // try {
        //   await s3Client.send(new DeleteObjectCommand(params));
        // } catch (error) {
        //   console.error("error deleting thumbnail", error);
        // }
      }

      const oldData = item.images?.data || [];

      try {
        // TODO: update CORS settings AllowHeader, this is untested
        // const data = await s3Client.send(new PutObjectCommand(params));
        
        const data = await UploadService.upload(
          file,
          `${item.id}_${key}`,
          "",
          true
        )

        if (data) {
          ItemService.patchInventoryItemThumbnail({
            id: item.id,
            thumbnail: `${doSpaceHost}/${item.id}_${key}`,
            images: {
              data: [...oldData, { url: key }],
            },
          })
            .then((data) => {
              setInventoryItem(data);
              setAlertMessage(t("catalog.inventory.imageUploadSuccess"));
              setShowToast(true);
              setTimeout(() => {
                setShowToast(false);
              }, 3000);
            })
            .catch((error) => {
              window.alert("Error updating database");
              throw new Error(error);
            })
            .finally(() => {
              if (callback) {
                callback();
              }
              setIsLoading(false);
            });
        }

        // s3Client.putObject(params).send((err) => {
        //   if (!err) {
        //     ItemService.patchInventoryItemThumbnail({
        //       id: item.id,
        //       thumbnail: `${doSpaceHost}/${item.id}_${key}`,
        //       images: {
        //         data: [...oldData, { url: key }],
        //       },
        //     })
        //       .then((data) => {
        //         setInventoryItem(data);
        //         setAlertMessage(t("catalog.inventory.imageUploadSuccess"));
        //         setShowToast(true);

        //         setTimeout(() => {
        //           setShowToast(false);
        //         }, 3000);
        //       })
        //       .catch((error) => {
        //         window.alert("Error updating database");
        //         throw new Error(error);
        //       })
        //       .finally(() => {
        //         setIsLoading(false);
        //       });
        //   } else {
        //     console.log(err);
        //   }
        // });
      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  // dropzone
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    },
  });

  if (variant === "button") {
    return (
      <Button
        {...getRootProps({ className: "dropzone" })}
        type="button"
        // onClick={handleClick}
        size="sm"
      >
        {t("catalog.inventory.editForm.changePhotoBtn")}
        <input {...getInputProps()} />
      </Button>
    );
  }

  if (variant === "inventory") {
    return (
      <div
        {...getRootProps({ className: "dropzone" })}
        className={`rounded-lg flex flex-col justify-center items-center relative`}
        // style={{
        //   borderStyle: "dashed",
        //   borderWidth: "2.7px",
        //   borderColor: "#499873",
        // }}
      >
        {inventoryItem.thumbnail ? (
          <img
            src={inventoryItem.thumbnail}
            crossOrigin="anonymous"
            width={size}
            height={size}
            className="object-contain rounded-lg"
          />
        ) : (
          <>
            {label && (
              <Typography
                fontSize={{ xs: 12, lg: 14 }}
                fontWeight={{ xs: 500, lg: 700 }}
                textColor={"#499873"}
                className="top-[75px] lg:top-[133px] absolute"
              >
                {label}
              </Typography>
            )}
            <img
              src={"/inventory-form-no-img.png"}
              crossOrigin="anonymous"
              width={size}
              height={size}
              className="object-contain rounded-lg"
            />
            {/* <img
              src="/productimg-bg.png"
              width={"115.2px"}
              height={"102.4px"}
              className="object-contain absolute z-0"
            />
            <img
              src="/productimg-fg.png"
              width={size}
              height={size}
              className="object-contain z-30"
            /> */}
          </>
        )}
        <input {...getInputProps()} />
      </div>
    );
  }

  return (
    <div className="relative w-full flex justify-center py-3 cursor-pointer">
      {inventoryItem.thumbnail ? (
        <img
          {...getRootProps({ className: "dropzone" })}
          src={inventoryItem.thumbnail}
          crossOrigin="anonymous"
          width={size}
          height={size}
          className="object-contain"
        />
      ) : (
        <img
          {...getRootProps({ className: "dropzone" })}
          src="/inventory-img-placeholder.png"
          width={size}
          height={size}
          className="object-contain"
        />
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export default UploadImageBtn;
