import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { ItemService } from "../../../services/item.service";
import { CatalogInventoryItemType } from "../../../types/item";
import { getActiveBusiness } from "../../../store/session";
import { formatPrice } from "../../../utils/formatPrice";
import useContainer from "../useContainer";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryService } from "../../../services/inventory.service";
import TablePagination from "../../Inventory/components/TablePagination";

const BatchUpdateAddCatalogItems = () => {
  const { t } = useTranslation();
  const { catalogId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [inventoryItems, setInventoryItems] = useState<
    CatalogInventoryItemType[]
  >([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currency, setCurrency] = useState<string | undefined>();
  const { pagination, setPagination } = useContainer();
  const existingItems = JSON.parse(
    localStorage.getItem("batchEditCatalogItems") || "[]"
  );
  const navigate = useNavigate();

  const initiateInventoryItems = useCallback((businessId: string) => {
    setIsLoading(true);

    if (catalogId) {
      InventoryService.getCatalogItems({
        catalogId: catalogId,
        paging: true,
        limit: 10,
        offset: 0,
        sortBy: "updatedAt",
      })
        .then((res) => {
          setInventoryItems(res.data);

          if (res.pagination) {
            setPagination(res.pagination);
          }

          // set to catalog currency
          setCurrency(res.data[0].currency);

          return ItemService.getInventoryItemField({
            fields: ["inventoryType"],
            businessId,
          });
        })
        .then((filterRes) => {
          const cats: string[] = [];
          for (const element of filterRes) {
            cats.push(element.inventoryType);
          }

          const uniqueValues = cats.filter(
            (item, index) => cats.indexOf(item) === index
          );
          setCategories(uniqueValues);

          return ItemService.getInventoryItemField({
            fields: ["id", "sku", "productName"],
            businessId,
            isDistinct: false,
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const fetchAllItems = useCallback(
    ({
      catalogId,
      paging = true,
      limit = 10,
      offset = 0,
      searchTerm,
      inventoryType,
    }: {
      catalogId: string;
      paging?: boolean;
      limit?: number;
      offset?: number;
      searchTerm?: string | null;
      inventoryType?: string | null;
    }) => {
      InventoryService.getCatalogItems({
        catalogId,
        paging: paging,
        limit: limit,
        offset: offset,
        sortBy: "updatedAt",
        searchTerm,
        inventoryType,
      })
        .then((res) => {
          setInventoryItems(res.data);

          if (res.pagination) {
            setPagination(res.pagination);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    },
    []
  );

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      initiateInventoryItems(businessId);
    }
  }, [getActiveBusiness()?.id]);

  const handleSelectAll = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId && catalogId) {
      InventoryService.getCatalogItems({
        catalogId: catalogId,
        paging: true,
        limit: 10,
        offset: 0,
        sortBy: "updatedAt",
      })
        .then((res) => {
          const ids: string[] = res.data.map((i: { id: string }) => i.id);
          setSelectedItems(ids.filter((i) => !existingItems.includes(i)));
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    }
  };

  const handleConfirm = () => {
    setIsLoading(true);
    ItemService.getCatalogInventoryItemsByIds({ ids: selectedItems })
      .then((res) => {
        // onConfirm(res);
        const ids = res.map((r) => r.id);
        localStorage.setItem(
          "batchEditCatalogItems",
          JSON.stringify([...existingItems, ...ids])
        );
        localStorage.setItem("addedCatalogItems", JSON.stringify(ids));
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        setSelectedItems([]);
        setSelectedFilter("");
        setSearchTerm("");
        navigate(-1);
      });
  };

  const handleCancel = () => {
    setSelectedItems([]);
    setSelectedFilter("");
    setSearchTerm("");
    navigate(-1);
  };

  const handlePaging = (action: string | number) => {
    const pagingConfig = {
      limit: pagination.limit,
      offset: 0,
    };

    if (typeof action === "string") {
      switch (action) {
        case "first":
          pagingConfig.offset = 0;
          break;

        case "previous":
          pagingConfig.offset = pagination.limit * (pagination.current - 2);
          break;

        case "next":
          pagingConfig.offset = pagination.limit * pagination.current;
          break;

        case "last":
          pagingConfig.offset =
            pagination.max * pagination.limit - pagination.limit;
          break;
      }
    } else {
      pagingConfig.offset = (action - 1) * pagination.limit;
    }

    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      try {
        if (catalogId) {
          fetchAllItems({
            catalogId: catalogId,
            paging: true,
            ...pagingConfig,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Stack>
      <Typography
        mt={"1rem"}
        mb={"1.5rem"}
        fontWeight={600}
        fontSize={24}
        id="modal-modal-title"
        sx={{
          color: "#265641",
        }}
      >
        {t("catalog.inventory.batchEdit.addCatalogItemsModal.title")}
      </Typography>

      <Box
        sx={{
          border: `1px solid #EAECF0`,
          borderRadius: "0.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "1.5rem",
        }}
      >
        <Stack
          direction={{ xs: "column", lg: "row" }}
          sx={{
            alignItems: { xs: "start", lg: "end" },
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <FormControl sx={{ flex: { lg: "1" }, width: "100%" }}>
            <FormLabel>
              {t("catalog.inventory.editForm.inventoryType")}
            </FormLabel>
            <Select
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  setSelectedFilter(newValue);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={""}
              sx={{
                paddingY: "10px",
                paddingX: "16px",
                gap: "8px",
              }}
              indicator={<ChevronDownIcon width={18} height={18} />}
            >
              <Option value={""}>{t("catalog.inventory.allFilterBtn")}</Option>
              {categories.map((cat) => (
                <Option key={cat} value={cat}>
                  {cat}
                </Option>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ flex: { lg: "1" }, width: "100%" }}>
            <FormLabel>{t("catalog.inventory.editForm.productName")}</FormLabel>
            <Input
              size="sm"
              type="text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value) {
                  setSearchTerm(event.target.value);
                }
              }}
              placeholder={t("catalog.inventory.editForm.productName")}
              aria-label="productName"
            />
          </FormControl>

          <Button
            variant="linkz-outlined-primary"
            onClick={() => {
              const businessId = getActiveBusiness()?.id;

              if (businessId) {
                if (catalogId) {
                  fetchAllItems({
                    catalogId: catalogId,
                    paging: true,
                    limit: 10,
                    offset: 0,
                    searchTerm,
                    inventoryType: selectedFilter,
                  });
                }
              }
            }}
            startDecorator={<MagnifyingGlassIcon width={18} height={18} />}
          >
            {t("commons.searchBtn")}
          </Button>
        </Stack>

        {/* items table */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            maxHeight: { lg: "20rem" },
            overflow: "auto",
            borderWidth: "2px",
            position: "relative",
          }}
        >
          <Table
            borderAxis="x"
            sx={{
              "& th": { textAlign: "center" },
              minWidth: "100%",
            }}
          >
            <thead>
              <tr key={"header"}>
                <th key={"checkbox"} className="w-12">
                  <Checkbox
                    size="sm"
                    onChange={(event) => {
                      if (event.target.checked) {
                        handleSelectAll();
                      } else {
                        setSelectedItems([]);
                      }
                    }}
                  />
                </th>
                <th key={"picture"} className="w-24">
                  {t("catalog.items.picture")}
                </th>
                <th key={"sku"} className="w-24">
                  {t("catalog.items.sku")}
                </th>
                <th key={"productName"} className="w-24">
                  {t("catalog.items.productName")}
                </th>
                <th key={"currency"} className="w-24">
                  {t("catalog.items.currency")}
                </th>
                <th key={"price"} className="w-24">
                  {t("catalog.items.price")}
                </th>
                <th key={"unit_of_measure"} className="w-24">
                  {t("catalog.items.baseUoM")}
                </th>
                <th key={"inventoryType"} className="w-24">
                  {t("catalog.items.category")}
                </th>
              </tr>
            </thead>
            <tbody>
              {inventoryItems.map((i) => {
                const isSelected = selectedItems.includes(i.id);
                const isExisting = existingItems.includes(i.id);

                return (
                  <tr
                    className={`${isSelected ? "bg-[#F1F8F4] " : ""} ${
                      isExisting ? "opacity-50" : ""
                    }`}
                  >
                    <td className="text-center">
                      <Checkbox
                        size="sm"
                        checked={isSelected || isExisting}
                        onChange={(e) => handleChecked(e, i.id)}
                      />
                    </td>
                    <td className="text-center">
                      <div className="relative w-full flex justify-center py-3 cursor-pointer">
                        {i.inventoryItem.thumbnail ? (
                          <img
                            src={i.inventoryItem.thumbnail}
                            crossOrigin="anonymous"
                            width={30}
                            height={30}
                            className="object-contain"
                          />
                        ) : (
                          <img
                            src="/inventory-img-placeholder.png"
                            width={30}
                            height={30}
                            className="object-contain"
                          />
                        )}
                      </div>
                    </td>
                    <td className="text-center break-all">
                      {i.inventoryItem.sku}
                    </td>
                    <td className="text-center break-all">
                      {i.inventoryItem.productName}
                    </td>
                    <td className="text-center break-all">
                      {currency ?? i.currency}
                    </td>
                    <td className="text-center break-all">
                      {formatPrice(i.price, i.currency)}
                    </td>
                    {/* <td className="text-center break-all">{i.quantity}</td> */}
                    <td className="text-center break-all">
                      {i.inventoryItem.uom1}
                    </td>
                    <td className="text-center break-all">
                      {i.inventoryItem.inventoryType}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>

        <TablePagination
          handlePaging={handlePaging}
          pagination={pagination}
          setPagination={setPagination}
          withLimit
        />
      </Box>

      <Stack
        direction={{ xs: "column", lg: "row" }}
        spacing={2}
        sx={{
          marginTop: "1.5rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight={400} fontSize={14}>
          {selectedItems.length}{" "}
          {t("catalog.inventory.batchEdit.itemsSelected")}
        </Typography>

        <Stack
          direction={{ xs: "column-reverse", lg: "row" }}
          spacing={1}
          justifyContent={{ xs: "center", lg: "flex-end" }}
          sx={{
            width: { xs: "100%" },
          }}
        >
          <Button
            type="button"
            variant="plain-gray"
            className="w-full lg:w-[8rem] text-nowrap"
            onClick={handleCancel}
          >
            {t("commons.cancelBtn")}
          </Button>
          <Button
            className="w-full lg:w-[8rem] text-nowrap"
            onClick={handleConfirm}
          >
            {t("catalog.inventory.batchEdit.addItemsModal.addItemSelectedBtn")}
          </Button>
        </Stack>
      </Stack>

      <LoadingModal isLoading={isLoading} />
    </Stack>
  );
};

export default BatchUpdateAddCatalogItems;
