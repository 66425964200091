import PageTitleBox from "../../components/PageTitleBox";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CreateCatalogueSteps from "./components/Form/CreateCatalogueSteps";
import {
  Sheet,
  Button,
  Divider,
  Stack,
  Typography,
  Box,
  List,
  ListItem,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import {
  DELIVERY_FEES_OPTIONS_ENUM,
  PaymentFlowTypeEnum,
} from "../../types/order";
import { useNavigate, useSearchParams } from "react-router-dom";
import { theme } from "../../components/Theme";
import {
  CreateCatalogType,
  CustomCreateCatalogType,
  TaxIDType,
} from "../../types/catalog";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { If, IfElse } from "../../components/Condition";
import { PlusIcon } from "@heroicons/react/24/outline";
import PreviewTable from "./components/Form/PreviewTable";
import EmptyPreviewState from "./components/Form/EmptyPreviewState";
import { CatalogService } from "../../services/catalog.service";
import { ItemService } from "../../services/item.service";
import CatalogInfoSection from "./components/Form/CatalogInfoSection";
import DeliveryInfoSection from "./components/Form/DeliveryInfoSection";
import { Download, Upload } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import useContainer from "./useContainer";
import FailModal from "../../components/FailModal";
import LottieCatalogLoader from "../../components/LoadingModal/LottieCatalogLoader";
import { SuccessModal } from "../../components/SuccessModal";
import useFormContainer from "./useFormContainer";
import { useAuthStore } from "../../store/session";
import { TaxService } from "../../services/tax.service";
import { ItemType } from "../../types/item";

const CreateCatalogue = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const catalogType = searchParams.get("type");

  const navigate = useNavigate();

  const { session } = useAuthStore();

  const [catalog, setCatalog] = useState<CustomCreateCatalogType>(
    JSON.parse(localStorage.getItem("new-catalog") || "{}")
  );
  console.log("CATALOG FROM LOCALSTORAGE", catalog);
  const [isStepValid, setIsStepValid] = useState(false);
  const [cachedInvalidItems, _setCachedInvalidItems] = useState(
    JSON.parse(localStorage.getItem("catalog-import-invalid-items") ?? "{}")
  );
  const [inventoryItems, setInventoryItems] = useState<ItemType[]>([]);

  const methods = useForm<CreateCatalogType>({
    defaultValues: {
      deliveryFeeConfig:
        catalog && catalog.deliveryFeeConfig
          ? catalog.deliveryFeeConfig
          : {
              feeValues: [{ upToCount: 0, value: 0 }],
              feeType: DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT,
            },
      catalogName: catalog?.catalogName ?? "",
      taxId: "MANSOOR",
      periodStartDate: catalog?.periodStartDate || null,
      periodEndDate: catalog?.periodEndDate || null,
      paymentFlowType:
        catalogType == "PUBLIC"
          ? PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
          : catalog?.paymentFlowType,
      currency: catalog.currency,
      items: catalog.items.map((item) => {
        return { itemId: item };
      }),
    },
  });
  const { watch, handleSubmit, formState, setValue } = methods;

  // custom hooks
  const {
    onDrop,
    showFailModal,
    setShowFailModal,
    excelErrors,
    setExcelErrors,
    isLoading,
    setIsLoading,
    alertModal,
    setAlertModal,
  } = useContainer();
  const {
    handleValidateStep,
    handleSaveData,
    handleGoToNext,
    handleGotoPrev,
    currentStep,
    setCurrentStep,
    nationalTaxes,
    setNationalTaxes,
  } = useFormContainer(methods, catalog, setCatalog);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
  });

  // watced fields depending on currentStep
  const watchValidation =
    currentStep === 1
      ? watch([
          "catalogName",
          "paymentFlowType",
          "currency",
          "periodStartDate",
          "periodEndDate",
        ])
      : currentStep === 2
      ? watch("items")
      : watch("deliveryFeeConfig.feeType");

  /**
   * get national taxes based on business's country
   */
  async function getAndSetNationalTaxes() {
    try {
      const res = await TaxService.getTaxesOption(
        session?.activeBusiness.companyCountry as string
      );

      setNationalTaxes(res);
      setValue(
        "taxId",
        res.find((t: TaxIDType) =>
          catalog.taxId ? t.id === catalog.taxId.id : t.taxLabel === "NONE"
        )?.id || null
      );
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAndSetNationalTaxes();
  }, [session?.activeBusiness.id]);

  /**
   * will get the first error field and setCurrentStep
   * to the corresponding @const stepFields
   */
  useEffect(() => {
    // fields need to be validated in each step
    const stepFields = [
      [
        "catalogName",
        "paymentFlowType",
        "currency",
        "periodStartDate",
        "periodEndDate",
      ],
      ["items"],
      ["feeValues"],
    ];

    console.log("formState.errors", formState.errors);
    const firstError = Object.keys(formState.errors)[0];
    if (!firstError) return;

    for (let i = 0; i < stepFields.length; i++) {
      if (stepFields[i].includes(firstError)) {
        setCurrentStep(i + 1);
        break;
      }
    }
  }, [formState]);

  /**
   * validate step every time watchValidation changes
   */
  useEffect(() => {
    setIsStepValid(handleValidateStep(currentStep));
  }, [watchValidation]);

  /**
   * will show fail modal based on excel upload error
   * @param fileRejections
   */
  useEffect(() => {
    if (fileRejections.length > 0) {
      const formattedErrors = fileRejections.map((reject) => {
        return {
          name: reject.file.name,
          errors: reject.errors,
        };
      });

      setIsLoading(false);
      setExcelErrors(formattedErrors);
      setShowFailModal(true);
    }
  }, [fileRejections]);

  /**
   * will show fail modal based on backend validation results
   * @param excelErrors
   */
  useEffect(() => {
    if (excelErrors.length > 0) {
      setIsLoading(false);
      setShowFailModal(true);
    }
  }, [excelErrors]);

  /**
   * stop initial loading
   */
  useEffect(() => {
    setIsLoading(false);
  }, []);

  /**
   * validate form data manually before creating catalog
   * @param data formValues
   */
  const onSubmit = async (data: CreateCatalogType) => {
    console.log("SUBMIT DATA", data);

    // validate each step
    for (let i = 0; i < 3; i++) {
      if (!handleValidateStep(i + 1)) {
        setCurrentStep(i + 1);
        return;
      }
    }

    setIsLoading(true);

    const { items, ...catalogData } = data;
    const catalogPayload: CreateCatalogType = {
      ...catalogData,
      catalogType: catalogType as string,
      hidePrice: catalogType === "PUBLIC" ? true : false,
      tags: { tags: [] },
      business: catalog?.business.id,
    };
    const itemsPayload = items?.map((item) => item.itemId);

    try {
      const catalogResponse = await CatalogService.createCatalogue({
        data: catalogPayload,
      });
      console.log("CATALOG CREAETE RESPONSE", catalogResponse);

      if (catalogResponse?.id) {
        let submitResponse: any;
        // if there is draftId, then use draft submit endpoint
        if (catalog.draftId) {
          // check if there are cached updated items
          const cachedPriceData = JSON.parse(
            localStorage.getItem("updated-items-price") ?? "[]"
          );

          // update draft items data before submitting
          if (cachedPriceData.length > 0) {
            await CatalogService.putCatalogDraft(
              catalog.draftId,
              cachedPriceData
            );
          }

          submitResponse = await CatalogService.submitCatalogDraft(
            catalog.draftId,
            {
              catalogId: catalogResponse.id,
            }
          );
        } else
          submitResponse = await ItemService.updateCatalogItems({
            itemIds: itemsPayload ?? [],
            catalogId: catalogResponse.id,
          });

        if (submitResponse) {
          setAlertModal({
            show: true,
            message: "Catalogue created",
          });
        }

        setTimeout(() => {
          localStorage.removeItem("new-catalog");
          localStorage.removeItem("updated-items-price");
          window.location.href = `/client/catalogues/${catalogResponse.id}/items`;
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * will take method, update localStorage data
   * and render the corresponding method components
   * @param method excel | inventory
   */
  const handleSelectImportMethod = (method: "excel" | "inventory") => {
    handleSaveData({ importMethod: method });
  };

  /**
   * will take catalog.draftId to export draft items in .xls
   * @returns excel download
   */
  const handleExcelDownload = async () => {
    if (!catalog.draftId) {
      window.alert("Draft ID not found");
      return;
    }

    await CatalogService.exportDraftItems({
      draftId: catalog.draftId,
    });
  };

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        py: "32px",
      }}
    >
      <PageTitleBox
        title={t("catalog.createCatalog.pageTitle")}
        showOnMobile={false}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={"column"} gap={"32px"}>
            {/* steps */}
            <CreateCatalogueSteps
              currentStep={currentStep}
              validation={handleValidateStep}
              onClick={(step: number) => {
                if (step > catalog.step) {
                  return;
                }

                handleSaveData();
                setCurrentStep(step);
              }}
            />

            <Divider
              sx={{
                width: "100dvw",
                display: { xs: "block", lg: "none" },
                ml: -2,
                backgroundColor: theme.palette.neutral[200],
              }}
            />

            {/* catalog info */}
            <If condition={currentStep === 1}>
              {catalog && (
                <CatalogInfoSection
                  catalog={catalog}
                  nationalTaxes={nationalTaxes}
                />
              )}
            </If>

            {/* items preview */}
            <If condition={currentStep === 2}>
              <Stack
                direction={"column"}
                sx={{
                  borderWidth: { xs: 0, md: "1px" },
                  borderColor: theme.palette.neutral[300],
                  borderRadius: "8px",
                  py: "32px",
                  px: { xs: "8px", md: "24px" },
                  gap: "24px",
                }}
              >
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  justifyContent={"space-between"}
                >
                  {/* title and add item button */}
                  <Stack direction={"column"} gap={"4px"}>
                    <Typography
                      level="text-xl-bold"
                      sx={{ color: theme.palette.common.black }}
                    >
                      {t("catalog.createCatalog.steps.2")}
                    </Typography>

                    <If
                      condition={
                        catalog.items.length > 0 ||
                        catalog.importMethod === "excel"
                      }
                    >
                      <Typography
                        level="text-xs-medium"
                        sx={{ color: theme.palette.neutral[600] }}
                      >
                        {t(
                          `catalog.createCatalog.steps.${
                            catalog.importMethod === "excel"
                              ? "subtitle2-2"
                              : "subtitle2"
                          }`
                        )}
                      </Typography>
                    </If>
                  </Stack>

                  {/* add items button */}
                  <If
                    condition={
                      catalog.importMethod === "inventory" &&
                      inventoryItems.length > 0
                    }
                  >
                    <Button
                      id="catalog-create-addItems-button"
                      variant="linkz-outlined-primary"
                      startDecorator={<PlusIcon width={20} height={20} />}
                      onClick={() => {
                        navigate("/client/catalogues/add-items?isCreate=true");
                      }}
                      sx={{
                        mt: { xs: "24px", lg: 0 },
                      }}
                    >
                      {t("catalog.createCatalog.addItemToCatalogBtn")}
                    </Button>
                  </If>

                  {/* excel draft export import */}
                  <If
                    condition={
                      catalog.importMethod === "excel" &&
                      inventoryItems.length > 0
                    }
                  >
                    <Stack
                      direction={{ xs: "column", lg: "row" }}
                      sx={{
                        justifyContent: "flex-end",
                        gap: "8px",
                        mt: { xs: "24px", lg: 0 },
                      }}
                    >
                      <Button
                        id="catalog-create-exportDraft-button"
                        variant="linkz-outlined-primary"
                        startDecorator={<Download width={20} height={20} />}
                        onClick={handleExcelDownload}
                      >
                        {t("catalog.createCatalog.exportDraftBtn")}
                      </Button>

                      <Button
                        id="catalog-create-importDraft-button"
                        {...getRootProps({ className: "dropzone" })}
                        variant="linkz-outlined-primary"
                        startDecorator={<Upload width={20} height={20} />}
                      >
                        {t("catalog.createCatalog.importDraftBtn")}
                        <input {...getInputProps()} />
                      </Button>
                    </Stack>
                  </If>
                </Stack>

                <IfElse
                  condition={catalog.items.length > 0 || !!catalog.draftId}
                  ifBlock={
                    <PreviewTable
                      catalog={catalog}
                      inventoryItems={inventoryItems}
                      setInventoryItems={setInventoryItems}
                    />
                  }
                  elseBlock={
                    <EmptyPreviewState onClick={handleSelectImportMethod} />
                  }
                />
              </Stack>
            </If>

            {/* delivery fee */}
            <If condition={currentStep === 3}>
              {catalog && <DeliveryInfoSection catalog={catalog} />}
            </If>

            {/* buttons */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                position: "relative",
                justifyContent: { md: "right" },
                alignItems: "center",
                width: "100%",
                py: { xs: "16px", md: "24px" },
                px: 0,
                gap: { xs: "8px", md: "16px" },
              }}
            >
              <Divider
                sx={{
                  width: "100dvw",
                  position: "absolute",
                  display: { xs: "none", lg: "block" },
                  top: 0,
                  left: "-272px",
                  backgroundColor: theme.palette.others.disabled,
                }}
              />

              {cachedInvalidItems && currentStep === 2 && (
                <Typography
                  level="text-sm-regular"
                  sx={{
                    ml: { lg: "16px" },
                    display: { xs: "flex", lg: "none" },
                  }}
                >
                  {t("catalog.createCatalog.import.importedItems", {
                    totalItems: cachedInvalidItems.totalItems,
                  })}
                </Typography>
              )}

              {/* prev button, next button sm, submit button */}
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  justifyContent: { xs: "space-between", md: "left" },
                  alignItems: "center",
                }}
              >
                <If condition={currentStep > 1}>
                  <Button
                    id="catalog-create-prev-button"
                    variant="linkz-outlined-primary"
                    sx={{
                      my: "auto",
                      minWidth: {
                        xs: "48%",
                        lg: "100px",
                      },
                    }}
                    startDecorator={<ArrowLeftIcon width={18} height={18} />}
                    onClick={handleGotoPrev}
                  >
                    {t("commons.prevBtn")}
                  </Button>
                </If>

                {cachedInvalidItems && currentStep === 2 && (
                  <Typography
                    level="text-sm-regular"
                    sx={{
                      ml: { lg: "16px" },
                      display: { xs: "none", lg: "flex" },
                    }}
                  >
                    {t("catalog.createCatalog.import.importedItems", {
                      totalItems: cachedInvalidItems.totalItems,
                    })}
                  </Typography>
                )}

                <If condition={currentStep < 3}>
                  <Button
                    id="catalog-create-next-button-mobile"
                    sx={{
                      display: { xs: "flex", md: "none" },
                      my: "auto",
                      minWidth: {
                        xs: currentStep > 1 ? "48%" : "100%",
                        lg: "100px",
                      },
                    }}
                    disabled={!isStepValid}
                    endDecorator={<ArrowRightIcon width={20} height={20} />}
                    onClick={handleGoToNext}
                  >
                    {t("commons.nextBtn")}
                  </Button>
                </If>

                <If condition={currentStep === 3}>
                  <Button
                    id="catalog-create-submit-mobile"
                    sx={{
                      display: { xs: "flex", md: "none" },
                      my: "auto",
                      minWidth: "48%",
                    }}
                    disabled={!isStepValid}
                    type="submit"
                  >
                    {t("catalog.inventory.createCatalogBtn")}
                  </Button>
                </If>
              </Stack>

              {/* next button lg, cancel button, submit button */}
              <Stack
                direction={"row"}
                sx={{
                  gap: "16px",
                  ml: { md: "auto" },
                  width: "100%",
                  justifyContent: { xs: "center", md: "right" },
                }}
              >
                <Button
                  id="catalog-create-cancel-button"
                  variant="linkz-outlined-primary"
                  onClick={() => {
                    navigate("/client/catalogues/list");
                  }}
                  sx={{
                    my: "auto",
                    minWidth: { xs: "100%", lg: "68px" },
                  }}
                >
                  {t("commons.cancelBtn")}
                </Button>

                <If condition={currentStep < 3}>
                  <Button
                    id="catalog-create-next-button"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      my: "auto",
                      minWidth: "100px",
                    }}
                    disabled={!isStepValid}
                    endDecorator={<ArrowRightIcon width={20} height={20} />}
                    onClick={handleGoToNext}
                  >
                    {t("commons.nextBtn")}
                  </Button>
                </If>

                <If condition={currentStep === 3}>
                  <Button
                    id="catalog-create-submit-desktop"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      my: "auto",
                      minWidth: "100px",
                    }}
                    disabled={!isStepValid}
                    type="submit"
                  >
                    {t("catalog.inventory.createCatalogBtn")}
                  </Button>
                </If>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>

      {/* modal */}
      <FailModal
        open={showFailModal}
        title="Failed Uploading File"
        content={
          <Box
            sx={{
              width: "100%",
              p: "16px",
              textAlign: "left",
              height: "250px",
              overflow: "auto",
            }}
            className="scrollbar-container"
          >
            <List marker="disc">
              {excelErrors.map(({ name, errors }) => (
                <ListItem key={name}>
                  {t("catalog.import.error.errorOnRow", {
                    row: name,
                  })}
                  :
                  <List marker="circle">
                    {errors.map((e: any) => (
                      <ListItem key={e.code}>{e.message}</ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Box>
        }
        onClose={() => {
          setShowFailModal(false);
        }}
      />
      <LottieCatalogLoader isLoading={isLoading} />
      <SuccessModal
        open={alertModal.show}
        title={t("commons.successModalTitle")}
        content={alertModal.message}
      />
    </Sheet>
  );
};

export default CreateCatalogue;
