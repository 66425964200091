import {
  ArrowsPointingOutIcon,
  ShoppingCartIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Box, Button, Checkbox, IconButton, Stack, Typography } from "@mui/joy";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProductCarousel from "../../../components/carousel/ProductCarousel";
import { CatalogType } from "../../../types/catalog";
import { CatalogInventoryItemType } from "../../../types/item";
import { calculatePrice, getConversionRate } from "../../../utils/cart";
// import { InventoryService } from "../../../services/inventory.service";
// import { useAuthStore } from "../../../store/session";
import { formatPrice } from "../../../utils/formatPrice";
import useContainer from "../useContainer";

type Props = {
  catalog: CatalogType;
  item: CatalogInventoryItemType;
  type?: "supplier" | "inventory";
  displayType: "list" | "grid";
  onClick?: () => void;
  handleRemoveItem?: (id: string) => void;
  setCartUpdated?: Dispatch<SetStateAction<boolean>>;
  orderDataCallback?: () => void;
  resetFlag?: boolean;
  selectedItems?: string[];
  handleChecked?: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    catalogItemId: string
  ) => void;
};

export function SupplierProductCard({
  catalog,
  setCartUpdated,
  item,
  type = "inventory",
  displayType = "grid",
  handleRemoveItem,
  selectedItems,
  handleChecked,
}: Props) {
  const currency = item.currency ?? item.inventoryItem.currency;
  const isGuestMode = window.location.pathname.startsWith("/catalog");
  const navigate = useNavigate();
  const { catalogId } = useParams();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order");

  const [activeUOM, setActiveUOM] = useState<"uom1" | "uom2" | "uom3">("uom1");
  const [uomIndex, setUOMIndex] = useState(0);
  // const variantTypeArr = ["Pieces", "Pack", "Box"];
  // const [variantType, setVariantType] = useState(0);
  // const [cart, setCart] = useState<CartItemDataType[]>();

  const { handleUpdateCart, handleUpdateOrder } = useContainer();

  // useEffect(() => {
  //   if (setToCart) {
  //     setToCart((prev) => {
  //       const currentIndex = prev.findIndex((p) => p.cid === item.id);
  //       if (currentIndex !== -1) {
  //         const updatedItem = {
  //           ...prev[currentIndex],
  //           uom: activeUOM,
  //           initPrice: calculatePrice(item, activeUOM),
  //         };

  //         return [
  //           ...prev.slice(0, currentIndex),
  //           updatedItem,
  //           ...prev.slice(currentIndex + 1),
  //         ];
  //       }
  //       // setCart(prev);
  //       return prev;
  //     });
  //   }
  // }, [activeUOM]);

  const goToDetail = (id: string) => {
    if (isGuestMode) {
      navigate(
        `/catalog/suppliers/${catalogId}/items/detail/${id}?inventoryId=${
          item.inventoryItem.id
        }${orderId ? `&order=${orderId}` : ""}`
      );
      return;
    }
    navigate(
      `/client/suppliers/${catalogId}/items/detail/${id}?inventoryId=${
        item.inventoryItem.id
      }${orderId ? `&order=${orderId}` : ""}`
    );
  };

  // async function handleUpdateCart() {
  //   // const itemIds = cartItems && cartItems.map((c) => c.cid);
  //   // const verifiedItem = await verifyItem(itemIds);
  //   // if (!verifiedItem) {
  //   //   setErrorMessage(t("catalog.supplier.items.verifyItemError"));
  //   //   setShowError(true);

  //   //   setTimeout(() => {
  //   //     setShowError;
  //   //     window.location.reload();
  //   //   }, 1500);

  //   //   return;
  //   // }

  //   const accountId =
  //     searchParams.get("cart") === "wildcart"
  //       ? "wildcart"
  //       : session?.account.id || "wildcart";
  //   const cartItems = getCartItems(
  //     catalogId as string,
  //     accountId,
  //     session?.account.id ? "local" : "session"
  //   );

  //   if (cartItems) {
  //     cartItems.items.forEach((c) => {
  //       const isItemExist = cartItems?.items.find((c) => c.cid === item.id);

  //       updateCartQty({
  //         catalogId: catalogId as string,
  //         accountId: session?.account.id || "wildcart",
  //         override: isItemExist ? false : true,
  //         quantityChange: 1,
  //         inventoryItem: item,
  //         direct: session?.account.id ? false : true,
  //         activeUOM: activeUOM === c.uom ? c.uom : activeUOM,
  //         storageType: session?.account.id ? "local" : "session",
  //       });

  //       updateCartUOM({
  //         catalogId: catalogId as string,
  //         accountId: session?.account.id || "wildcart",
  //         inventoryItem: item,
  //         uom: activeUOM === c.uom ? c.uom : activeUOM,
  //         storageType: session?.account.id ? "local" : "session",
  //       });

  //       // TrackerService.track(
  //       //   TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.AddItemToCart,
  //       //   {
  //       //     "Catalog ID": catalog.id,
  //       //     "Catalog Name": catalog.catalogName,
  //       //     "Catalog Owner Company Name": catalog.business.companyName,
  //       //     "Product ID": inventoryItem.inventoryItem.id,
  //       //     "Product Name": inventoryItem.inventoryItem.productName,
  //       //     Quantity: c.quantity,
  //       //   }
  //       // );
  //     });
  //   } else {
  //     createCart({
  //       payload: {
  //         catalogId: catalogId as string,
  //         accountId: accountId,
  //         direct: session?.account.id ? false : true,
  //         items: [
  //           {
  //             cid: item.id,
  //             quantity: 1,
  //             uom: activeUOM,
  //             initPrice: calculatePrice(item, activeUOM),
  //           },
  //         ],
  //       },
  //       storageType: "local",
  //     });
  //   }

  //   // window.location.reload();

  //   // setShowAddToCartToast(true);
  //   // if (cartDataCallback) {
  //   //   cartDataCallback();
  //   // }
  //   // setTimeout(() => {
  //   //   setShowAddToCartToast(false);
  //   //   if (setCartUpdated) {
  //   //     setCartUpdated(true);
  //   //   }
  //   // }, 1500);
  // }

  const handleChangeUOM = (val: number) => {
    setUOMIndex(val);

    switch (val) {
      case 1:
        setActiveUOM("uom2");
        break;

      case 2:
        setActiveUOM("uom3");
        break;

      default:
        setActiveUOM("uom1");
        break;
    }
  };

  const ProductUOMSelector = ({ type }: { type: string }) => {
    const uoms = [
      item.inventoryItem.uom1,
      item.inventoryItem.uom2 && item.inventoryItem.uom2,
      item.inventoryItem.uom3 && item.inventoryItem.uom3,
    ].filter((uom) => uom !== null);

    if (type === "slide") {
      return (
        <Stack
          direction={"column"}
          sx={{
            gap: "0.5rem",
          }}
        >
          <Stack
            direction={"row"}
            className="overflow-hidden rounded-lg border border-gray-400"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleChangeUOM(uomIndex - 1);
                // setUOMIndex(uomIndex - 1);
              }}
              disabled={uomIndex == 0 ? true : false}
              className={`"rounded-none w-[20%] h-[34px] flex justify-center items-center border-r border-gray-700" ${
                uomIndex == 0 ? "bg-gray-200" : ""
              }`}
            >
              <ChevronLeftIcon className="w-6" />
            </button>
            <Typography level="title-md">{uoms[uomIndex]}</Typography>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleChangeUOM(uomIndex + 1);
                // setUOMIndex(uomIndex + 1);
              }}
              disabled={
                (uomIndex == uoms.length - 1 ? true : false) ||
                uoms.length === 0
              }
              className={`"px-2 w-[20%] h-[34px] flex justify-center items-center rounded-none  border-l border-gray-700" ${
                uomIndex == uoms.length - 1 || uoms.length === 0
                  ? "bg-gray-200"
                  : ""
              }`}
            >
              <ChevronRightIcon className="w-6" />
            </button>
          </Stack>
          <span className="text-xs text-neutral-500">
            {activeUOM === "uom1"
              ? `1 ${item.inventoryItem.uom1 || "unit"}`
              : `${getConversionRate(activeUOM, item.inventoryItem)} ${
                  item.inventoryItem.uom1
                }/${item.inventoryItem[activeUOM]}`}
          </span>
        </Stack>
      );
    }

    if (type === "buttons") {
      return (
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            alignItems: "baseline",
            gap: "0.5rem",
            my: "1.5rem",
          }}
        >
          <Stack direction={"row"} justifyContent={"flex-start"} spacing="10px">
            {uoms.map((uom, index) => (
              <Button
                key={index}
                variant="linkz-outlined-primary"
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  handleChangeUOM(index);
                }}
                className={`${
                  item.inventoryItem[activeUOM] === uom
                    ? "bg-[#CFE5DA] border-[#CFE5DA] text-black"
                    : ""
                }`}
              >
                {uom || "unit"}
              </Button>
            ))}
          </Stack>

          <Typography fontSize={16} fontWeight={500} textColor={"#101828"}>
            {`${currency} ${formatPrice(calculatePrice(item, activeUOM))}`}
          </Typography>

          <Typography fontSize={12} fontWeight={500}>
            {activeUOM === "uom1"
              ? `1 ${item.inventoryItem.uom1 || "unit"}`
              : `${getConversionRate(activeUOM, item.inventoryItem)} ${
                  item.inventoryItem.uom1
                }/${item.inventoryItem[activeUOM]}`}
          </Typography>
        </Stack>
      );
    }
  };

  if (displayType === "list") {
    return (
      <>
        <Stack
          direction={"row"}
          onClick={() => goToDetail(item.id)}
          key={item.id}
          sx={{
            borderWidth: "2px",
            boxShadow: { xs: 1, md: "none" },
            borderColor: "#EAECF0",
            borderRadius: "1rem",
            overflow: "hidden",
            height: "100%",
            alignItems: "center",
            // justifyContent: "space-between",
            // width: { xs: "50%", lg: "208px" },
            p: "1rem",
            gap: "1rem",
            "&:hover": {
              borderColor: "#6BAB8D",
              boxShadow: 3,
              backgroundColor: "#F1F8F4",
            },
          }}
          // className={
          //   "border-2 shadow-sm md:shadow-none border-[#EAECF0] rounded-3xl overflow-hidden h-full p-0 flex flex-col gap-4 hover:border-[#6BAB8D] transition-all hover:shadow-lg"
          // }
          className={`transition-all`}
        >
          <Stack
            direction={"column"}
            sx={{
              width: { xs: "50%", lg: "30%" },
              justifyContent: { xs: "space-between", lg: "center" },
              alignItems: "left",
              gap: "0.5rem",
            }}
          >
            <Typography sx={{ fontSize: "xs", color: "#737373" }}>
              {item.inventoryItem.sku}
            </Typography>
            <Typography level="h4" sx={{ fontSize: { xs: "sm", lg: "md" } }}>
              {item.inventoryItem.productName}
            </Typography>

            <Typography fontSize={12} fontWeight={700} textColor={"#408565"}>
              {`${currency} ${formatPrice(calculatePrice(item, activeUOM))}`}
            </Typography>
          </Stack>

          <Box
            sx={{
              display: { xs: "none", lg: "block" },
              maxWidth: "30%",
            }}
          >
            <ProductUOMSelector type="buttons" />
          </Box>

          <Stack
            direction={"column"}
            sx={{
              justifyContent: "center",
              ml: "auto",
              gap: { xs: "0.5rem", lg: 0 },
            }}
          >
            <Box
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              <ProductUOMSelector type="slide" />
            </Box>

            <Button
              sx={{
                borderRadius: "0.5rem",
                width: "11rem",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (orderId) {
                  handleUpdateOrder(orderId, catalog, item, 1, activeUOM);
                } else {
                  handleUpdateCart(catalogId ?? "", activeUOM, item);
                }

                setCartUpdated?.(true);
              }}
            >
              <ShoppingCartIcon
                style={{
                  width: 18,
                  height: 18,
                  marginRight: "0.5rem",
                }}
              />
              {t(`catalog.${orderId ? "addToOrderBtn" : "addToCartBtn"}`)}
            </Button>
          </Stack>
        </Stack>

        <hr className="hidden my-4 border border-dashed w-ful lg:block" />
      </>
    );
  } else {
    return (
      <Stack
        direction={"column"}
        onClick={() => goToDetail(item.id)}
        key={item.id}
        sx={{
          borderWidth: "2px",
          boxShadow: { xs: 1, md: "none" },
          borderColor: "#EAECF0",
          borderRadius: "1.5rem",
          overflow: "hidden",
          height: "100%",
          // width: { xs: "50%", lg: "208px" },
          p: 0,
          gap: "1rem",
          "&:hover": {
            borderColor: "#6BAB8D",
            boxShadow: 3,
          },
        }}
        // className={
        //   "border-2 shadow-sm md:shadow-none border-[#EAECF0] rounded-3xl overflow-hidden h-full p-0 flex flex-col gap-4 hover:border-[#6BAB8D] transition-all hover:shadow-lg"
        // }
        className={`transition-all`}
      >
        <Stack
          direction={"column"}
          sx={{
            position: "relative",
          }}
          // onClick={onClick}
        >
          <div className="relative">
            <IconButton
              size="sm"
              className="absolute bottom-[20px] right-[20px] bg-white z-50 p-[2px]"
            >
              <ArrowsPointingOutIcon className="w-5 h-5" />
            </IconButton>
            <ProductCarousel
              alt={item.id}
              slides={[{ src: item.inventoryItem.thumbnail }]}
            />
          </div>
          {type === "inventory" && handleChecked && (
            <Checkbox
              size="lg"
              checked={selectedItems?.includes(item.inventoryItem.id)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleChecked(e, item.inventoryItem.id, item.id);
              }}
              sx={{
                position: "absolute",
                top: "1.25rem",
                left: "1.25rem",
              }}
            />
          )}
          {type === "inventory" && (
            <TrashIcon
              onClick={(event) => {
                event.stopPropagation();
                if (handleRemoveItem) {
                  handleRemoveItem(item.id);
                }
              }}
              className={
                "h-6 w-6 text-danger-500 bg-white p-[2px] rounded cursor-pointer absolute top-[1.25rem] right-[1.25rem]"
              }
            />
          )}
          <Stack
            direction={"column"}
            sx={{
              padding: "1rem",
              gap: "1rem",
            }}
          >
            <Stack
              direction={"column"}
              sx={{
                borderBottomWidth: "1px",
                borderColor: "#d1d5db",
                gap: "0.25rem",
              }}
            >
              <span className="text-xs text-neutral-500">
                {item.inventoryItem.sku}
              </span>
              <Typography level="h4" sx={{ fontSize: "md", mb: "1rem" }}>
                {item.inventoryItem.productName}
              </Typography>
            </Stack>

            {/* // TODO: this is supposed to be a separate component */}
            <ProductUOMSelector type="slide" />

            <Stack direction={"column"} sx={{}}>
              {/* <span className="text-sm line-through text-neutral-400">
                IDR 120.000,00
              </span> */}
              <Typography fontWeight={700} fontSize={16} textColor={"#408565"}>
                {currency}{" "}
                {`${formatPrice(
                  calculatePrice(item, activeUOM),
                  item.currency ?? item.inventoryItem.currency
                )}`}
              </Typography>
            </Stack>

            <Button
              fullWidth
              sx={{
                borderRadius: "0.5rem",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (orderId) {
                  handleUpdateOrder(orderId, catalog, item, 1, activeUOM);
                } else {
                  handleUpdateCart(catalogId ?? "", activeUOM, item);
                }

                setCartUpdated?.(true);
              }}
            >
              <ShoppingCartIcon
                style={{
                  width: 18,
                  height: 18,
                  marginRight: "0.5rem",
                }}
              />
              {t(`catalog.${orderId ? "addToOrderBtn" : "addToCartBtn"}`)}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      // <div
      //   key={item.id}
      //   className={
      //     "border-2 shadow-sm md:shadow-none border-[#EAECF0] rounded-2xl overflow-hidden h-full p-0 flex flex-col gap-4 hover:border-[#6BAB8D] transition-all hover:shadow-lg"
      //   }
      // >

      /* <Stack
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: 0, md: "16px" }}
          sx={{
            position: "relative",
          }}
          onClick={onClick}
        >
          <img
            src={`${item.inventoryItem.thumbnail ?? "/inventory-img-placeholder.png"
              }`}
            alt="item_image"
            // width={100}
            // height={100}
            className={`lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-full h-[176.5px]  ${type === "supplier"
              ? "md:w-[114px] md:h-[114px]"
              : "md:w-[100px] md:h-[100px]"
              } object-contain flex-shrink-0`}
          />

          {type === "inventory" && handleChecked && (
            <Checkbox
              size="sm"
              checked={selectedItems?.includes(item.inventoryItem.id)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleChecked(e, item.inventoryItem.id, item.id);
              }}
              sx={{
                position: "absolute",
                top: "0.25rem",
                left: "0.25rem",
              }}
            />
          )}
          <Stack direction={"column"} gap={"8px"} p={"8px"} width={"100%"}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography textColor={"#475467"} className={"text-sm"}>
                {item.inventoryItem.sku}
              </Typography>
              {type === "inventory" && (
                <TrashIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    if (handleRemoveItem) {
                      handleRemoveItem(item.id);
                    }
                  }}
                  className={"h-5 w-5 text-[#499873] cursor-pointer"}
                />
              )}
            </Stack>

            <Typography
              title={item.inventoryItem.productName}
              className={"text-base font-bold text-ellipsis line-clamp-1"}
            >
              {item.inventoryItem.productName}
            </Typography>

            <TagsCollection tags={item.inventoryItem.tags} />
            <hr className="hidden mt-auto lg:block" />
          </Stack>
        </Stack>

        {catalog && type === "supplier" && (
          <div className="hidden lg:block">
            <ProductUOMSelector
              item={item}
              hidePrice={catalog.hidePrice}
              catalogType={catalog.catalogType}
              activeUOM={activeUOM}
              setActiveUOM={(uom) => {
                setActiveUOM(uom);
              }}
              size="sm"
            />
          </div>
        )}

        <If condition={isMobile || type === "inventory"}>
          <Stack
            direction={"column"}
            p={{ xs: "8px", md: 0 }}
            gap={"4px"}
            onClick={onClick}
          >
            <Typography className="text-[#101828] text-base font-semibold">
              {`${currency}`} {isMobile && <br />}
              {formatPrice(calculatePrice(item, activeUOM), currency)}
            </Typography>

            <If condition={isMobile && item.inventoryItem.uom1 ? true : false}>
              <div className="border-[#499873] p-2 rounded border w-fit">
                <Typography className="text-xs text-[#499873]">
                  {item.inventoryItem.uom1}
                </Typography>
              </div>
            </If>
          </Stack>
        </If>

        <If condition={!isMobile && type === "supplier"}>
          <div className="hidden mt-auto lg:block">
            <CartQuantityManager
              hidePerItemPrice={true}
              caller="product-card"
              item={item}
              hidePrice={catalog?.hidePrice}
              defaultUOM={activeUOM}
              withAddToCart
              catalog={catalog!}
              setToCart={setToCart}
              resetFlag={resetFlag}
            />
          </div>
        </If> */
      // </div>
    );
  }
}
