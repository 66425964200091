import { Squares2X2Icon } from "@heroicons/react/24/outline";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  List,
  TableViewOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useAuthStore } from "../../store/session";
import { BrowserRouterConstants } from "../../utils/constants";
import { Toggler, transformUomList } from "../utils";
import OrderItem from "./OrderItem";
import { IOrderRevision, TaxAmountTypeEnum } from "../../types/order";
import {
  Collapse,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { calcAmountByTax, calcDiscountAmountByType } from "../../utils/order";
import { formatPrice } from "../../utils/formatPrice";
import { isSmallToMidScreen } from "../../utils/screen";
import { ConfirmationModal } from "../ConfirmationModal";
import SectionToggleBar from "./SectionToggleBar";
import { BtnOutlinedPrimaryRoundedSm } from "../stateless/buttons";
import { HeroIcon } from "../stateless/icons";

export default ({
  defaultTax,
  nationalTaxes,
  productSearchList,
  isBusinessAuthorized,
  isReadOnly,
  orderType,
  sellerCountry,
  isAdjustmentOrder,
  lowStockItems,
  updateItemCallback,
  revisions,
  orderStatus,
}: any) => {
  const mainOrder = revisions
    ? revisions.find((i: IOrderRevision) => i.isMain)
    : null;

  const { t } = useTranslation();
  const { control, getValues } = useFormContext();
  const { session } = useAuthStore();
  const { orderId } = useParams();
  const [isAppending, setIsAppending] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [listViewMode, setListViewMode] = useState(false);
  // console.log ("Product Search List:::", productSearchList);
  const { clearErrors } = useFormContext();
  const {
    fields: orderItemFields,
    append: appendOrderItem,
    remove: removeOrderItem,
    update: updateOrderItem,
  } = useFieldArray({
    control,
    name: "orderItems",
  });

  const handleSetListViewMode = () => {
    setListViewMode(!listViewMode);
  };

  const _isSmallToMidScreen = isSmallToMidScreen();

  console.log("Order Item Default Tax ::", getValues("defaultTaxItem"));

  const newProductItem = {
    orderItemId: "",
    productId: "",
    // businessId: getValues("sellerIds.businessId") || "",
    businessId: "",
    currency: getValues("currency") || "",
    catalogId: "",
    sku: "",
    productName: "",
    productDescription: "",
    inventoryType: "unknown",
    uomList: "",
    quantity: 1,
    price: 0,
    discount: 0,
    totalPrice: 0,
    createdById: session?.account.id.toString() || "",
    additionalTax: defaultTax,
    taxItem: {
      taxLabel: getValues("defaultTaxItem.taxLabel") || "NONE",
      taxAmount: getValues("defaultTaxItem.taxAmount") || 0,
      taxType: getValues("defaultTaxItem.taxType") || null,
      taxAmountType:
        getValues("defaultTaxItem.taxAmountType") ||
        TaxAmountTypeEnum.PERCENTAGE,
    },
  };

  const [selectedRemoveItem, setSelectedRemoveItem] = useState<number | null>(
    null
  );

  const handleConfirmRemoveOrderItem = () => {
    // if (!selectedRemoveItem) return;
    if (isRemoving) return;
    setIsRemoving(true);
    try {
      removeOrderItem(selectedRemoveItem ?? 0);
    } catch (error) {
      // Handle error
    } finally {
      /** for quantity rework, validate the qty whenever removing item if lowStockItems.length > 0 */
      if (lowStockItems.length > 0 && updateItemCallback) {
        updateItemCallback(getValues("orderItems"));
      }
      setIsRemoving(false);
      setSelectedRemoveItem(null);
    }
  };
  const handleRemoveOrderItem = (index: number) => {
    setSelectedRemoveItem(index);
  };

  const handleUpdateOrderItem = (index: number, product: any) => {
    if (isAppending) return;
    setIsAppending(true);
    console.log("handled Update Product ::: ", product);

    try {
      updateOrderItem(index, {
        selectedItem: product, // selected item object from AutoComplete search
        productId: product.id,
        businessId: product.business,
        catalogId: product.catalog || "",
        createdById: product.createdBy,
        price: product.price,
        uomItem: getValues(`orderItems.${index}.uomItem`),
        uomList: transformUomList(product),
        // quantity: product.quantity || 1,
        discount: product.discount || 0,
        discountType: getValues(`orderItems.${index}.discountType`),
        // tax: product.tax || 0,
        sku: product.sku,
        productName: product.productName,
        productDescription: product.productDescription,
        inventoryType: product.inventoryType,
        orderItemId: product.orderItemId,
      });
    } catch (error) {
    } finally {
      /** for quantity rework, validate the qty whenever removing item if lowStockItems.length > 0 */
      clearErrors(`orderItems.${index}.quantity`);
      if (lowStockItems.length > 0 && updateItemCallback) {
        updateItemCallback(getValues("orderItems"));
      }

      setIsAppending(false);
    }
  };

  const [rowToggle, setRowToggle] = useState<number | null>(null);
  const handleRowToggle = (index: number) => {
    setRowToggle(rowToggle === index ? null : index);
  };

  const getTotalItemAmount = (index: number) => {
    const wprod = getValues(`orderItems.${index}`);
    console.log("Tax Item in handleCaculatePrice :::", wprod.taxItem);
    let itemPrice = (wprod.price || 0) * (wprod.quantity || 0);
    const discount = calcDiscountAmountByType(
      wprod.discount,
      wprod.discountType,
      itemPrice
    );

    let itemPriceWithDiscount = itemPrice - discount;

    const itemTotalAmount = calcAmountByTax(
      wprod.taxItem?.taxAmount,
      wprod.taxItem?.taxType,
      itemPriceWithDiscount,
      wprod.taxItem?.taxAmountType
    );

    console.log("Item Total Amount >>>", typeof itemTotalAmount);
    return Number(itemTotalAmount.toFixed(2));
  };

  const [onRemoveSelectedItems, setOnRemoveSelectedItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const handleCheckdItem = (index: number) => {
    setSelectedItems((prev) => [...prev, index]);
  };

  const handleUncheckedItem = (index: number) => {
    setSelectedItems(selectedItems.filter((i) => i !== index));
  };

  const handleCheckAll = () => {
    setSelectedItems(
      getValues("orderItems").map((_: any, index: number) => index)
    );
  };

  const handleUncheckAll = () => {
    setSelectedItems([]);
  };

  const onConfirmRemoveSelectedItems = () => {
    setIsRemoving(true);
    try {
      removeOrderItem(selectedItems);
    } catch (error) {
      // Handle error
    } finally {
      /** for quantity rework, validate the qty whenever removing item if lowStockItems.length > 0 */
      if (lowStockItems.length > 0 && updateItemCallback) {
        updateItemCallback(getValues("orderItems"));
      }
      setIsRemoving(false);
    }
    setOnRemoveSelectedItems(false);
    setSelectedItems([]);
  };

  return (
    <>
      <Toggler
        defaultExpanded={true}
        renderToggle={({ open, setOpen, sxClasses }) => (
          <SectionToggleBar
            {...{ open, setOpen, sxClasses }}
            icon={<Squares2X2Icon width={24} height={24} />}
            title={t("order.createOrder.productForm.addProduct")}
            isRequired={true}
          />
        )}
      >
        <Stack spacing={4} width={{ xs: "90vw", md: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {!isReadOnly && isBusinessAuthorized && !isAdjustmentOrder && (
              <Link
                to={`/client/${BrowserRouterConstants.Supplier}?order=${orderId}`}
              >
                <BtnOutlinedPrimaryRoundedSm
                  startDecorator={
                    <HeroIcon name={"ClipboardDocumentListIcon"} />
                  }
                  sx={{ alignSelf: "start" }}
                  onClick={() => appendOrderItem(newProductItem)}
                  label={t("order.createOrder.productForm.addFromCatalogBtn")}
                />
              </Link>
            )}
            {!_isSmallToMidScreen && (
              <ButtonGroup>
                <IconButton
                  color={listViewMode ? "primary" : "neutral"}
                  onClick={handleSetListViewMode}
                >
                  <List />
                </IconButton>
                <IconButton
                  color={listViewMode ? "neutral" : "primary"}
                  onClick={handleSetListViewMode}
                >
                  <TableViewOutlined />
                </IconButton>
              </ButtonGroup>
            )}
          </Box>

          {listViewMode &&
            !_isSmallToMidScreen &&
            orderItemFields?.length > 0 && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "5%" }}>
                        <Checkbox
                          checked={
                            selectedItems.length === orderItemFields?.length
                          }
                          disabled={isReadOnly}
                          onChange={(e) =>
                            e.target.checked
                              ? handleCheckAll()
                              : handleUncheckAll()
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ width: "5%" }}>
                        <Typography level="text-md-bold">No.</Typography>
                      </TableCell>
                      <TableCell sx={{ width: "10%" }}>
                        <Typography level="text-md-bold">SKU</Typography>
                      </TableCell>
                      <TableCell sx={{ width: "30%" }}>
                        <Typography level="text-md-bold">Product Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography level="text-md-bold">Quantity</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography level="text-md-bold">Price Per Unit</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography level="text-md-bold">Total Amount</Typography>
                      </TableCell>
                      <TableCell sx={{ width: "5%" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderItemFields?.map((field: any, index: number) => (
                      <>
                        <TableRow key={field.id + index}>
                          <TableCell sx={{ width: "5%" }}>
                            <Checkbox
                              checked={selectedItems.includes(index)}
                              disabled={isReadOnly}
                              onChange={(e) =>
                                e.target.checked
                                  ? handleCheckdItem(index)
                                  : handleUncheckedItem(index)
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ width: "5%" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "10%",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {getValues(`orderItems.${index}.sku`)}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "30%",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {getValues(`orderItems.${index}.productName`)}
                          </TableCell>
                          <TableCell>
                            {getValues(`orderItems.${index}.quantity`)}
                          </TableCell>
                          <TableCell
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {getValues("currency") +
                              " " +
                              formatPrice(
                                getValues(`orderItems.${index}.price`),
                                getValues("currency")
                              )}
                          </TableCell>
                          <TableCell
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {getValues("currency") +
                              " " +
                              formatPrice(
                                getTotalItemAmount(index),
                                getValues("currency")
                              )}
                          </TableCell>
                          <TableCell onClick={() => handleRowToggle(index)}>
                            {rowToggle === index ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            display: rowToggle === index ? "table-row" : "none",
                          }}
                        >
                          <TableCell colSpan={8}>
                            <Collapse
                              in={rowToggle === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              <OrderItem
                                key={field.id + index}
                                index={index}
                                orderItemField={field}
                                handleRemoveOrderItem={handleRemoveOrderItem}
                                handleUpdateOrderItem={handleUpdateOrderItem}
                                isReadOnly={isReadOnly}
                                nationalTaxes={nationalTaxes}
                                productSearchList={productSearchList.filter(
                                  (p: any) => {
                                    return (
                                      orderItemFields.length <= 1 ||
                                      p?.currency === getValues("currency")
                                    );
                                  }
                                )}
                                sellerCountry={sellerCountry}
                                orderType={orderType}
                                isAdjustmentOrder={isAdjustmentOrder}
                                mainOrderContent={mainOrder?.content.orderItems}
                                listViewMode={listViewMode}
                                orderStatus={orderStatus}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}

          {((!listViewMode || (listViewMode && _isSmallToMidScreen)) &&
            orderItemFields?.map((field, index: number) => (
              <OrderItem
                key={field.id + index}
                index={index}
                orderItemField={field}
                handleRemoveOrderItem={handleRemoveOrderItem}
                handleUpdateOrderItem={handleUpdateOrderItem}
                isReadOnly={isReadOnly}
                nationalTaxes={nationalTaxes}
                productSearchList={productSearchList.filter((p: any) => {
                  // console.log("BANANA currency", p.productName, p.currency);
                  return (
                    orderItemFields.length <= 1 ||
                    p?.currency === getValues("currency")
                  );
                })}
                sellerCountry={sellerCountry}
                orderType={orderType}
                isAdjustmentOrder={isAdjustmentOrder}
                mainOrderContent={mainOrder?.content.orderItems}
                listViewMode={listViewMode && !_isSmallToMidScreen}
                orderStatus={orderStatus}
              />
            ))) ||
            ""}

          {!isReadOnly && !isAdjustmentOrder && (
            <Stack direction={"row"} gap={2}>
              <BtnOutlinedPrimaryRoundedSm
                startDecorator={<HeroIcon name="PlusIcon" />}
                sx={{ alignSelf: "start" }}
                onClick={() => {
                  appendOrderItem(newProductItem);
                  if (listViewMode) setRowToggle(orderItemFields.length);
                }}
                id="btn-add-order-item"
                label={t("order.createOrder.productForm.addNewBtn")}
              />
              {listViewMode && !_isSmallToMidScreen && (
                <Button
                  disabled={selectedItems.length === 0}
                  color="danger"
                  variant="plain"
                  sx={{ alignSelf: "start" }}
                  onClick={() => {
                    setOnRemoveSelectedItems(true);
                  }}
                >
                  Remove Selected Items
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        <ConfirmationModal
          open={selectedRemoveItem !== null}
          title="Remove This Item?"
          content="Are you sure about deleting this product/service? Don't worry, you can always add it back later if needed."
          onCancel={() => {
            setSelectedRemoveItem(null);
          }}
          onConfirm={() => {
            handleConfirmRemoveOrderItem();
          }}
        />
        <ConfirmationModal
          open={onRemoveSelectedItems}
          title="Remove Selected Item?"
          content="Are you sure about deleting this product/service? Don't worry, you can always add it back later if needed."
          onCancel={() => {
            setOnRemoveSelectedItems(false);
          }}
          onConfirm={() => {
            onConfirmRemoveSelectedItems();
          }}
        />
      </Toggler>
    </>
  );
};
