import { Box, Chip, Stack, Typography } from "@mui/joy";
import { OrderTypeTag } from "../utils";
import {
  IOrderList,
  OrderStatusEnum,
  PaymentFlowTypeEnum,
} from "../../types/order";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { getInvoiceNumber } from "../../utils/order";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";
import { BrowserRouterConstants } from "../../utils/constants";
import { EventRepeatOutlined } from "@mui/icons-material";

export default function OrderListCards({
  // isPublicView,
  orderList,
  ThreeDotsRowMenu,
  handleOnClick,
}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      {(orderList as IOrderList[])?.map((order: IOrderList) => (
        <Stack
          className="cardItem"
          key={order.id}
          sx={{
            width: "100%",
            borderRadius: "sm",
            my: 2,
            p: 2,
            boxShadow: "sm",
            cursor: "pointer",
          }}
          spacing={2}
          onClick={() => {
            navigate(
              BrowserRouterConstants.ClientPrefix +
                BrowserRouterConstants.Orders +
                `/${order.id}`
            );
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"end"}
            alignItems={"center"}
            gap={2}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <OrderTypeTag type={order.orderType} />
            </Box>
            <Typography
              fontWeight={"600"}
              marginRight={"auto"}
              endDecorator={
                order.invoiceNumber &&
                order.paymentFlowType ===
                  PaymentFlowTypeEnum.RECURRING_PAYMENT ? (
                  <EventRepeatOutlined
                    color="primary"
                    sx={{ marginLeft: "4px" }}
                  />
                ) : order.orderRevisions?.length > 0 ? (
                  <div className="rounded-lg bg-[#F7D5AC] w-[24px] h-[24px] flex justify-center items-center ml-3">
                    <DocumentDuplicateIcon
                      width={16}
                      height={18}
                      color="#D48434"
                    />
                  </div>
                ) : (
                  ""
                )
              }
            >
              <NavLink
                to={
                  BrowserRouterConstants.ClientPrefix +
                  BrowserRouterConstants.Orders +
                  `/${order.id}`
                }
              >
                {order.invoiceNumber
                  ? getInvoiceNumber(order, order.orderRevisions)
                  : "-"}
              </NavLink>
            </Typography>
            {ThreeDotsRowMenu && (
              <ThreeDotsRowMenu
                orderId={order.id}
                orderStatus={order.status}
                handleOnClick={handleOnClick}
              />
            )}
          </Stack>
          <Chip>
            {t(`order.filters.status.${OrderStatusEnum[order.status]}`)}
          </Chip>
          <Typography level="body-sm" px={1}>
            {order.orderType === "sales"
              ? order.buyer?.companyName || "-"
              : order.orderType === "purchase"
              ? order.seller?.companyName || "-"
              : ""}
            <br />
            Person:{" "}
            {order.orderType === "sales"
              ? order.buyerUser?.profile.fullName || "-"
              : order.orderType === "purchase"
              ? order.sellerUser?.profile.fullName || "-"
              : ""}
            <br />
            Updated: {dayjs(order.updatedAt).format("DD MMM YYYY hh:mm A")}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
