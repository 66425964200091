import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@mui/joy";
import startsWith from "lodash.startswith";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { theme } from "../../components/Theme";
import { ProfileService } from "../../services/profile.service";
import { IUpdatePhoneNumberPayload } from "../../types/update_phone_number";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
} from "../../utils/constants";
import { matchesFormat } from "../../utils/misc";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";

const mobileNumberScheme = z.object({
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  mobile: z.string().min(1).max(100),
});

export default function ProfileMobileNumber() {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    trigger,
    clearErrors,
  } = useForm<z.infer<typeof mobileNumberScheme>>({
    resolver: zodResolver(mobileNumberScheme),
  });

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  const onSubmit: SubmitHandler<z.infer<typeof mobileNumberScheme>> = async (
    data
  ) => {
    setIsLoading(true);
    const payload: IUpdatePhoneNumberPayload = {
      countryCode: data!.country!.code,
      phoneNumber: data.mobile,
    };
    ProfileService.updatePhoneNumber(payload)
      .then((_) => {
        setTimeout(() => {
          setIsLoading(false);
          nav("verification", { state: payload });
        }, 3000);
      })
      .catch((_) => {
        alert("Change phone error");
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Divider />
      <form
        id="update-full-name-form"
        aria-label="profiles-full-name"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{ display: "flex", marginTop: 5, flexDirection: "column" }}
          component="section"
        >
          <FormControl error={!!errors.mobile}>
            <FormLabel>{t("profile.updatePhoneNumberPrompt")}</FormLabel>
            <PhoneInput
              onlyCountries={PhNoWhiteListedCountries}
              enableTerritories={false}
              masks={{
                my: "..-....-....",
                sg: "....-....",
                th: "... ... ...",
                id: "... ... ... ...",
                mm: ". .... .... .....",
                au: "... ... ...",
              }}
              isValid={(inputNumber, meta: any, countries) => {
                if (inputNumber === "" || inputNumber === meta.dialCode) {
                  return true;
                }

                if (inputNumber.toString().startsWith(`${meta.dialCode}0`)) {
                  if (!errors.mobile?.message) {
                    setError("mobile", {
                      message: t("commons.error.form.mobileInvalidStartZero"),
                    });
                  }
                  return false;
                }

                const result = (countries || []).some((country: any) => {
                  if (
                    startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber)
                  ) {
                    const iso2: CountryCode = meta.iso2 as CountryCode;
                    const format = PhNoFormat[iso2];
                    if (!format) return false;
                    return matchesFormat(
                      inputNumber,
                      format,
                      country.countryCode
                    );
                  }
                  return false;
                });

                if (!result && !errors.mobile?.message) {
                  setError("mobile", {
                    message: t("commons.error.form.mobileInvalid"),
                  });
                } else if (result && !!errors.mobile?.message) {
                  if (errors.mobile.type === "manual") {
                    return false;
                  }
                  clearErrors("mobile");
                  trigger("mobile");
                }
                return result;
              }}
              inputStyle={{
                height: "42px",
                width: isMobile ? "90vw" : "100%",
                fontSize: "16px",
              }}
              country={BusinessSessionHelper.GetBusinessCountryID().toLocaleLowerCase()}
              onChange={(phone, meta: any) => {
                setValue("country.code", meta.dialCode);
                setValue("country.label", meta.name);
                setValue(
                  "country.dial",
                  phone.replace(new RegExp(meta.dialCode), "")
                );
                setValue(
                  "mobile",
                  phone.replace(new RegExp(meta.dialCode), "")
                );
              }}
            />
            {errors.mobile?.message ? (
              <FormHelperText>{errors.mobile?.message}</FormHelperText>
            ) : null}
          </FormControl>
          <Box
            sx={{
              alignSelf: "center",
              display: "flex",
              position: "absolute",
              maxWidth: 480,
              width: { xs: "90vw", lg: 480 },
              bottom: 0,
              marginBottom: 5,
            }}
          >
            <Button fullWidth size="lg" type="submit">
              {t("commons.continueBtn")}
            </Button>
          </Box>
        </Box>
      </form>
      <LoadingModal isLoading={isLoading} />
    </React.Fragment>
  );
}
