import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";

import {
  ArrowsRightLeftIcon,
  BanknotesIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  DocumentChartBarIcon,
  HomeIcon,
  LifebuoyIcon,
  NewspaperIcon,
  RectangleStackIcon,
  SwatchIcon,
  TableCellsIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LogoImg from "../../assets/linkz-logo-small.png";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { AuthService } from "../../services/auth.service";
import { TrackerService } from "../../services/tracker.service";
import {
  getLoggedInUserEmail,
  getLoggedInUserName,
  useAuthStore,
} from "../../store/session";
import { BrowserRouterConstants } from "../../utils/constants";
import { If } from "../Condition";
import CloseIcon from "../Icons/Close";
import { theme } from "../Theme";
import { Toggler, closeSidebar } from "../utils";

export default function NavSidebar() {
  const currentLocation = useLocation();
  const { t } = useTranslation();
  const { pathname } = currentLocation;
  const navigate = useNavigate();
  const { logout } = useAuthStore();
  const manageRef = useRef(null);

  const handleManageClick = (ref: any) => {
    if (ref && ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }, 200);
    }
  };

  const logoutHandler = async () => {
    try {
      await AuthService.logout();
    } catch (error: any) {
      if (error.response.data.statusCode !== 401) {
        throw error;
      }
    }
    TrackerService.stop();
    logout();
    navigate("/signin");
  };

  useEffect(() => {
    closeSidebar();
  }, [pathname]);
  return (
    <Sheet
      variant={"linkz-sidebar"}
      sx={{
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          lg: "none",
        },
        position: {
          xs: "fixed",
          lg: "sticky",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 999,
        height: "100dvh",
        width: "100%",
        top: 0,
        p: "8px",
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={() => ({
          ":root": {
            "--Sidebar-width": "240px",
            // [theme.breakpoints.up("lg")]: {
            //   "--Sidebar-width": "240px",
            // },
          },
        })}
      />

      <Box
        className="px-4 pt-4 pb-4"
        sx={{ display: "flex", gap: 1, alignItems: "center" }}
      >
        <img
          src={LogoImg}
          alt="linkz-logo"
          width={84}
          height={40}
          className={"mr-auto"}
        />
        <Box
          display={{ xs: "block", lg: "none", cursor: "pointer" }}
          onClick={() => closeSidebar()}
        >
          <CloseIcon />
        </Box>
        {/* <ColorSchemeToggle sx={{ ml: 'auto' }} /> */}
      </Box>
      <Box
        className="scrollbar-container"
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
          "&::-webkit-scrollbar": {
            width: "0.2em",
          },
          "&::-webkit-scrollbar-track": {
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
          },
        }}
      >
        <List
          className="gap-4 px-2"
          size="sm"
          sx={{
            // gap: 0.5,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
            // mb: "6.125rem",
          }}
        >
          <NavLink
            id={"nav-link-home"}
            to={BrowserRouterConstants.ClientPrefix}
          >
            {({ isActive }) => (
              <ListItem>
                <ListItemButton
                  {...(isActive &&
                  pathname === BrowserRouterConstants.ClientPrefix
                    ? { selected: true }
                    : "")}
                  sx={{
                    py: "8px",
                    px: "12px",
                  }}
                  className={isActive ? "!bg-transparent" : ""}
                >
                  <HomeIcon width={24} height={24} />
                  {/* <HomeRoundedIcon /> */}
                  <ListItemContent>
                    <Typography level="title-sm">
                      {t("sidebar.home")}
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            )}
          </NavLink>

          <If
            condition={BusinessSessionHelper.RoleResourcePermissions(
              "inventory",
              "READ"
            )}
          >
            <NavLink
              id={"nav-link-supplier"}
              to={BrowserRouterConstants.Supplier}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemButton
                    {...(isActive || pathname.includes("cart")
                      ? { selected: true }
                      : "")}
                    sx={{
                      py: "8px",
                      px: "12px",
                    }}
                    className={isActive ? "!bg-transparent" : ""}
                  >
                    <RectangleStackIcon width={24} height={24} />
                    <ListItemContent>
                      <Typography level="title-sm">
                        {t("sidebar.catalog.supplier")}
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              )}
            </NavLink>
          </If>
          <If
            condition={
              BusinessSessionHelper.RoleResourcePermissions(
                "purchase",
                "READ"
              ) ||
              BusinessSessionHelper.RoleResourcePermissions("sales", "READ")
            }
          >
            <ListItem id={"nav-link-order"} nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton
                    onClick={() => setOpen(!open)}
                    sx={{
                      py: "8px",
                      px: "12px",
                    }}
                  >
                    <ArrowsRightLeftIcon
                      width={24}
                      height={24}
                      strokeWidth={1}
                    />
                    <ListItemContent>
                      <Typography level="title-sm">
                        {t("sidebar.order")}
                      </Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <NavLink
                    id={"nav-link-order-list"}
                    to={
                      BrowserRouterConstants.ClientPrefix +
                      BrowserRouterConstants.Orders
                    }
                  >
                    {({ isActive }) => (
                      <ListItem sx={{ mt: 0.5 }}>
                        <ListItemButton
                          {...(isActive && !pathname.includes("orders/reports")
                            ? { selected: true }
                            : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <TableCellsIcon width={24} height={24} />
                          {t("sidebar.orderList")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>

                  <NavLink
                    id={"nav-link-order-reports"}
                    to={
                      BrowserRouterConstants.ClientPrefix +
                      BrowserRouterConstants.ReportOrders +
                      "?reportType=TOTAL_RECEIVABLE"
                    }
                  >
                    {({ isActive }) => (
                      <ListItem sx={{ mt: 0.5 }}>
                        <ListItemButton
                          {...(isActive ? { selected: true } : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <DocumentChartBarIcon width={24} height={24} />
                          {t("sidebar.orderReports")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                </List>
              </Toggler>
            </ListItem>
          </If>

          <If
            condition={BusinessSessionHelper.RoleResourcePermissions(
              "inventory",
              "READ"
            )}
          >
            <ListItem id={"nav-link-warehouse"} nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton
                    onClick={() => setOpen(!open)}
                    sx={{
                      py: "8px",
                      px: "12px",
                    }}
                  >
                    <BookOpenIcon width={24} height={24} />
                    <ListItemContent>
                      <Typography level="title-sm">
                        {t("sidebar.catalog.title")}
                      </Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <NavLink
                    id={"nav-link-inventory"}
                    to={BrowserRouterConstants.Inventory}
                  >
                    {({ isActive }) => (
                      <ListItem sx={{ mt: 0.5 }}>
                        <ListItemButton
                          {...(isActive ? { selected: true } : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <TableCellsIcon width={24} height={24} />
                          {t("sidebar.catalog.inventory")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>

                  <NavLink
                    id={"nav-link-catalog"}
                    to={BrowserRouterConstants.CataloguesList}
                  >
                    {({ isActive }) => (
                      <ListItem sx={{ mt: 0.5 }}>
                        <ListItemButton
                          {...(isActive || pathname.includes("catalogues")
                            ? { selected: true }
                            : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <SwatchIcon width={24} height={24} />
                          {t("sidebar.catalog.list")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                </List>
              </Toggler>
            </ListItem>
          </If>

          <If
            condition={BusinessSessionHelper.RoleResourcePermissions(
              "finance",
              "READ"
            )}
          >
            <NavLink
              id={"nav-link-finance"}
              to={BrowserRouterConstants.Finance}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemButton
                    {...(isActive ? { selected: true } : "")}
                    sx={{
                      py: "8px",
                      px: "12px",
                    }}
                  >
                    <BanknotesIcon width={24} height={24} />
                    <ListItemContent>
                      <Typography level="title-sm">
                        {t("sidebar.finance")}
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              )}
            </NavLink>
          </If>

          {/* <ListItem>
            <ListItemButton
              role="menuitem"
              component="a"
              href="/joy-ui/getting-started/templates/messages/"
            >
              <QuestionAnswerRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Messages</Typography>
              </ListItemContent>
              <Chip size="sm" color="primary" variant="solid">
                4
              </Chip>
            </ListItemButton>
          </ListItem> */}

          <ListItem id={"nav-link-manage"} nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  onClick={() => {
                    if (!open) {
                      handleManageClick(manageRef);
                    }

                    setOpen(!open);
                  }}
                  {...([BrowserRouterConstants.Manage].indexOf(
                    currentLocation.pathname
                  ) !== -1
                    ? { selected: true }
                    : "")}
                  sx={{
                    py: "8px",
                    px: "12px",
                  }}
                >
                  <BuildingOfficeIcon width={24} height={24} />
                  <ListItemContent>
                    <Typography level="title-sm">
                      {t("sidebar.manage.title")}
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <If
                  condition={BusinessSessionHelper.RoleResourcePermissions(
                    "business-profile",
                    "READ"
                  )}
                >
                  <NavLink
                    id={"nav-link-profile"}
                    to={BrowserRouterConstants.Profile}
                  >
                    {({ isActive }) => (
                      <ListItem>
                        <ListItemButton
                          {...(isActive ? { selected: true } : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <UserIcon width={24} height={24} />
                          {t("sidebar.manage.profile")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                </If>
                {/* <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    role="menuitem"
                    component="a"
                    href="/client/profile"
                    {...(isActive ? { selected: true } : "")}
                    sx={{
                      py: "8px",
                      px: "12px",
                    }}
                  >
                    <UserIcon width={24} height={24} />
                    {t("sidebar.manage.profile")}
                  </ListItemButton>
                </ListItem> */}
                <If
                  condition={BusinessSessionHelper.RoleResourcePermissions(
                    "employee",
                    "READ"
                  )}
                >
                  <NavLink
                    id={"nav-link-employee"}
                    to={BrowserRouterConstants.Employees}
                  >
                    {({ isActive }) => (
                      <ListItem>
                        <ListItemButton
                          {...(isActive ? { selected: true } : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <UserGroupIcon width={24} height={24} />
                          {t("sidebar.manage.employee")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                </If>
                <If
                  condition={BusinessSessionHelper.RoleResourcePermissions(
                    "credit",
                    "READ"
                  )}
                >
                  <NavLink
                    id={"nav-link-credit"}
                    to={BrowserRouterConstants.Credit}
                  >
                    {({ isActive }) => (
                      <ListItem>
                        <ListItemButton
                          {...(isActive ? { selected: true } : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <LifebuoyIcon width={24} height={24} />
                          {t("sidebar.manage.credit")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                </If>
                <If
                  condition={BusinessSessionHelper.RoleResourcePermissions(
                    "credit",
                    "READ"
                  )}
                >
                  <NavLink
                    id={"nav-link-referral"}
                    to={BrowserRouterConstants.Referrals}
                  >
                    {({ isActive }) => (
                      <ListItem>
                        <ListItemButton
                          {...(isActive ? { selected: true } : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <UserGroupIcon width={24} height={24} />
                          {t("sidebar.manage.referral")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                </If>
                <If
                  condition={BusinessSessionHelper.RoleResourcePermissions(
                    "business-contact",
                    "READ"
                  )}
                >
                  <NavLink
                    id={"nav-link-business_contact"}
                    to={BrowserRouterConstants.BusinessContact}
                  >
                    {({ isActive }) => (
                      <ListItem sx={{ mt: 0.5 }}>
                        <ListItemButton
                          {...(isActive ? { selected: true } : "")}
                          sx={{
                            py: "8px",
                            pr: "12px",
                            pl: "30px",
                          }}
                        >
                          <NewspaperIcon width={24} height={24} />
                          {t("sidebar.manage.businessContact")}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                </If>
                {/* <NavLink to={"/roles"}>
                  {({ isActive }) => (
                    <ListItem>
                      <ListItemButton
                        {...(isActive ? { selected: true } : "")}
                        sx={{
                          py: "8px",
                          pr: "12px",
                          pl: "30px",
                        }}
                      >
                        <BriefcaseIcon width={24} height={24} />
                        {t("sidebar.manage.rolesAndPermission")}
                      </ListItemButton>
                    </ListItem>
                  )}
                </NavLink> */}
                {/* <NavLink to={BrowserRouterConstants.Manage}>
                  {({ isActive }) => (
                    <ListItem>
                      <ListItemButton
                        {...(isActive ? { selected: true } : "")}
                        sx={{
                          py: "8px",
                          pr: "12px",
                          pl: "30px",
                        }}
                      >
                        <Cog8ToothIcon width={24} height={24} />
                        {t("sidebar.manage.setting")}
                      </ListItemButton>
                    </ListItem>
                  )}
                </NavLink> */}
              </List>
            </Toggler>
          </ListItem>

          <div ref={manageRef} />
        </List>

        {/* <Card
          invertedColors
          variant="soft"
          color="warning"
          size="sm"
          sx={{ boxShadow: "none" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="title-sm">Used space</Typography>
            <IconButton size="sm">
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
          <Typography level="body-xs">
            Your team has used 80% of your available space. Need more?
          </Typography>
          <LinearProgress
            variant="outlined"
            value={80}
            determinate
            sx={{ my: 1 }}
          />
          <Button size="sm" variant="solid">
            Upgrade plan
          </Button>
        </Card> */}
      </Box>
      <List
        size="sm"
        sx={{
          mt: "auto",
          flexGrow: 0,
          "--ListItem-radius": (theme) => theme.vars.radius.sm,
          "--List-gap": "8px",
        }}
      >
        <ListItem
          sx={{
            backgroundColor: "#DCF8E4",
            px: "16px",
            pt: "11px",
            pb: "20px",
            color: theme.colorSchemes.light.palette.primary[700],
          }}
        >
          <div className="flex flex-col">
            <ListItemButton
              sx={{
                color: theme.colorSchemes.light.palette.primary[700],
                fontWeight: 700,
                mt: 0,
              }}
            >
              {/* <SupportRoundedIcon /> */}
              {t("sidebar.support")}
            </ListItemButton>
            <div className="flex flex-col gap-2 mt-2">
              <p className="text-xs">{import.meta.env.VITE_SUPPORT_EMAIL}</p>
              <p className="text-xs">{import.meta.env.VITE_SUPPORT_PHONE}</p>
            </div>
          </div>
        </ListItem>
      </List>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
        <UserCircleIcon width={40} height={40} />
        {/* <Avatar
          variant="outlined"
          size="sm"
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
        /> */}
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            level="title-sm"
            title={getLoggedInUserName() ?? ""}
          >
            {getLoggedInUserName() ?? ""}
          </Typography>
          <Typography
            level="body-xs"
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            title={getLoggedInUserEmail() ?? "-"}
          >
            {getLoggedInUserEmail() ?? "-"}
          </Typography>
          <Typography
            level="body-xs"
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            title={`${BusinessSessionHelper.GetBusinessName() ?? ""} ${
              BusinessSessionHelper.GetRoleName() ?? ""
            }`}
          >
            {BusinessSessionHelper.GetBusinessName() ?? ""} |{" "}
            {BusinessSessionHelper.GetRoleName() ?? ""}
          </Typography>
        </Box>
        <IconButton
          id="btn-logout"
          onClick={logoutHandler}
          size="sm"
          variant="plain"
          color="neutral"
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", justifyItems: "center" }}>
        <Typography fontSize={12} mx={"auto"}>
          Version {import.meta.env.VITE_APP_VERSION ?? "-"}
        </Typography>
      </Box>
    </Sheet>
  );
}
