import {
  BarsArrowDownIcon,
  DocumentTextIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowsPointingOutIcon,
  BarsArrowUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Input,
  List,
  ListItem,
  Option,
  Select,
  Sheet,
  Snackbar,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { If, IfElse } from "../../components/Condition";
import CreateCatalogModal from "../../components/Inventory/CreateCatalogModal";
import CreateInventoryDropzone from "../../components/Inventory/CreateInventoryDropzone";
import DuplicateSKUModal from "../../components/Inventory/Excel/DuplicateSKUModal";
import InventoryUploadDropzone from "../../components/Inventory/Excel/InventoryUploadDropzone";
import UploadImageBtn from "../../components/Inventory/Items/UploadImageBtn";
import UpdateQuantityModal from "../../components/Inventory/UpdateQuantityModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";
import { theme } from "../../components/Theme";
import { ItemService } from "../../services/item.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { CreateItemType, ItemType } from "../../types/item";
import { BrowserRouterConstants } from "../../utils/constants";
import { getCountryInfo } from "../../utils/country";
import { validateDuplicateSku } from "../../utils/excel";
import NotFound from "../NotFound/NotFound";
import ConfirmDeleteItemsModal from "./components/ConfirmDeleteItemsModal";
import PageTitleBox from "../../components/PageTitleBox";
import TablePagination from "./components/TablePagination";
import useContainer from "./useContainer";
import { Tooltip } from "@mui/joy";
import { WbIncandescentOutlined } from "@mui/icons-material";
import { SnackBarInfo } from "../../components/snackbar";
import FailModal from "../../components/FailModal";
import { processNewItems } from "../../utils/items";

function ListInventory() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const isFullView = currentPath.includes("full-view");
  const [showTooltip, setShowTooltip] = useState<string>(""); // createcatalog-sm, createcatalog-lg, exportimport-sm, exportimport-lg

  const [searchParams, setSearchParams] = useSearchParams();

  const [flexiColumns, setFlexiColumns] = useState<string[]>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [showFailModal, setShowFailModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState<{
    show: boolean;
    useDatabaseSku: boolean;
    onClick: (data: ItemType[]) => void;
  }>({ show: false, useDatabaseSku: true, onClick: () => {} });
  const [payload, setPayload] = useState<ItemType[]>([]);
  const [selectedQuantityItem, setSelectedQuantityItem] = useState<
    { show: boolean; item: ItemType } | undefined
  >();
  const [databaseSku, setDatabaseSku] = useState<string[]>([]);
  const [duplicateSKU, setDuplicateSKU] = useState<{
    processedData: CreateItemType[];
    duplicates: string[];
  }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newItems, setNewItems] = useState<string[]>([]);
  const {
    handlePaging,
    handleUpdateSku,
    handleItemFlexiCols,
    fetchInventoryItems,
    deleteInventoryItemBatch,
    isLoading,
    setIsLoading,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    showCreateModal,
    setShowCreateModal,
    pagination,
    setPagination,
    inventoryItems,
    setInventoryItems,
    searchTerm,
    setSearchTerm,
    showToast,
    setShowToast,
    excelErrors,
    onDrop,
  } = useContainer();

  const { session } = useAuthStore.getState();

  const initiateInventoryItems = useCallback((businessId: string) => {
    ItemService.getInventoryItems({
      businessId: businessId,
      paging: true,
      limit: 20,
      offset: 0,
      sortBy: "updatedAt",
    })
      .then((itemRes) => {
        setShowAlert(false);
        setInventoryItems(itemRes.data);
        if (itemRes.pagination) {
          setPagination(itemRes.pagination);
        }

        processNewItems(
          "already_seen_items",
          itemRes.data,
          (noteAlreadySeenItems) => {
            setNewItems(noteAlreadySeenItems);
          }
        );

        return ItemService.getInventoryItemField({
          fields: ["flexiColumns", "inventoryType"],
          businessId,
        });
      })
      .then((filterRes) => {
        const { allFlexiColumns } = handleItemFlexiCols(filterRes);
        setFlexiColumns(allFlexiColumns);

        const cats: string[] = [];
        for (const element of filterRes) {
          cats.push(element.inventoryType);
        }

        const uniqueValues = cats.filter(
          (item, index) => cats.indexOf(item) === index
        );
        setCategories(uniqueValues);

        return ItemService.getInventoryItemField({
          fields: ["id", "sku", "productName"],
          businessId,
          isDistinct: false,
        });
      })
      .then((skuRes) => {
        const allSku = skuRes.map((item: ItemType) => item.sku);
        setDatabaseSku(allSku);
        const validatedDuplicates = validateDuplicateSku(
          skuRes as CreateItemType[]
        );

        if (validatedDuplicates.length > 0 && verifySKUReminder()) {
          setDuplicateSKU({
            processedData: skuRes as CreateItemType[],
            duplicates: validatedDuplicates,
          });
          setShowDuplicateModal({
            show: true,
            useDatabaseSku: false,
            onClick: (data: ItemType[]) => {
              setIsLoading(true);
              handleUpdateSku(businessId, data);
            },
          });
          localStorage.setItem(
            "duplicateSKUReminder",
            new Date().getTime().toString()
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  const query = searchParams.get("q");

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      initiateInventoryItems(businessId);
      if (query && !searchTerm) {
        fetchInventoryItems({
          businessId,
          paging: true,
          limit: pagination.limit,
          offset: 0,
          sortBy: "updatedAt",
          sortDirection: sortDirection,
          searchTerm: query,
          inventoryType: selectedFilter,
        });
      }
    }
  }, [getActiveBusiness()?.id]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      if (query && !searchTerm) {
        return;
      }
      fetchInventoryItems({
        businessId,
        paging: true,
        limit: pagination.limit,
        offset: 0,
        sortBy: "updatedAt",
        sortDirection: sortDirection,
        searchTerm: searchTerm,
        inventoryType: selectedFilter,
      });
    }
  }, [searchTerm, selectedFilter, sortDirection]);

  /**
   * will show fail modal based on backend validation results
   * @param excelErrors
   */
  useEffect(() => {
    if (excelErrors.length > 0) {
      setShowFailModal(true);
    }
  }, [excelErrors]);

  useEffect(() => {
    const category = searchParams.get("category");
    const _sortDirection = searchParams.get("sortDirection");
    if (category) {
      setSelectedFilter(category);
    }
    if (_sortDirection) {
      setSortDirection(_sortDirection as any);
    }
    if (query) {
      setSearchTerm(query);
    }
    return () => {};
  }, []);

  if (!session) {
    return <NotFound />;
  }

  const verifySKUReminder = () => {
    const reminder = localStorage.getItem("duplicateSKUReminder");

    if (reminder) {
      const currentTime = new Date().getTime();
      const hoursDifference = (currentTime - +reminder) / (1000 * 60 * 60);

      return hoursDifference > 24;
    }

    return true;
  };

  const handleSelectAll = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      ItemService.getInventoryItemField({
        fields: ["id"],
        businessId,
        inventoryType: selectedFilter,
        searchTerm,
      })
        .then((res) => {
          const ids: string[] = res.map((i: { id: string }) => i.id);
          setSelectedItems(ids);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, inventoryItems[index].id]);
    } else {
      setSelectedItems(
        selectedItems.filter((i) => i !== inventoryItems[index].id)
      );
    }
  };

  const handleCreateItem = () => {
    // had to cast to any to get bankInfo
    const business = getActiveBusiness() as any;
    if (!business) {
      return;
    }

    ItemService.createBatchInventoryItems({
      items: [
        {
          sku: "-",
          currency:
            business.bankInfo[0]?.currency ||
            getCountryInfo(business.countryCode, "LOCALE"),
          price: 0,
          quantity: 0,
          minimumQuantity: 1,
          productName: "-",
          productDescription: "-",
          inventoryType: "-",
          business: business.id ?? null,
          flexiColumns: { data: [] },
          isActive: true,
          isDeleted: false,
          createdBy: session?.account.id,
        },
      ],
    })
      .then((res) => {
        if (res) {
          window.location.href = `/client/inventory/${res.data[0].id}/edit?mode=create`;
        }
      })
      .catch((error) => console.error(error));
  };

  const handleBatchEdit = () => {
    if (selectedItems.length > 0) {
      localStorage.setItem("batchEditItems", JSON.stringify(selectedItems));

      window.location.href = `${BrowserRouterConstants.InventoryBatchEdit}`;
    }
  };

  /**
   * filter inventoryItems that exist in selectedIds
   * setPayload and show create catalog modal
   */
  const handleCreateCatalog = () => {
    const filteredItems: ItemType[] = inventoryItems.filter((item) =>
      selectedItems.includes(item.id)
    );

    setPayload(filteredItems);
    setShowCreateModal(true);
  };

  const { tableData } = handleItemFlexiCols(inventoryItems, flexiColumns);

  return (
    <React.Fragment>
      {!isLoading && (
        <Sheet
          sx={{
            backgroundColor: "transparent",
            overflow: "hidden",
            padding: isFullView ? { xs: "1rem", lg: "4rem" } : 0,
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            py: "32px",
          }}
        >
          <If condition={inventoryItems.length === 0 && searchTerm === ""}>
            <PageTitleBox
              title={t("breadcrumb.inventory")}
              subtitle={t("catalog.inventory.empty.emptySubtitle")}
            />
          </If>

          {inventoryItems.length > 0 || searchTerm !== "" ? (
            <Stack
              direction={"column"}
              sx={{
                gap: "32px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SnackBarInfo message={t("catalog.inventory.uploadRemarks")} />

              {/* searchbar */}
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  marginRight: { lg: "auto" },
                }}
              >
                <Input
                  defaultValue={searchTerm}
                  // variant="linkz-input-search"
                  size="sm"
                  name="searchFormData.search"
                  placeholder={t("catalog.inventory.searchPlaceholder")}
                  startDecorator={
                    <MagnifyingGlassIcon width={24} height={24} />
                  }
                  fullWidth
                  sx={{
                    flexGrow: 1,
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newParams = new URLSearchParams(searchParams);
                    const query = event.target.value;
                    if (query) {
                      newParams.set("q", query);
                    } else {
                      newParams.delete("q");
                    }
                    setSearchParams(newParams);
                    setSearchTerm(event.target.value);
                  }}
                />
              </Box>

              {/* toolbar */}
              <Stack
                direction={{ xs: "column", md: "row" }}
                gap={{ xs: "16px", md: 0 }}
                justifyContent={"space-between"}
                width={{ xs: "90vw", md: "100%" }}
                p={0.2}
              >
                <Stack
                  direction={"row"}
                  overflow={"auto"}
                  alignItems={"center"}
                  justifyContent={{ xs: "space-between", md: "flex-start" }}
                  gap={"16px"}
                  className={"hide-scrollbar"}
                >
                  {/* filter by category `inventoryType` */}
                  <FormControl size="sm">
                    <Select
                      onChange={(
                        _event: React.SyntheticEvent | null,
                        newValue: string | null
                      ) => {
                        if (newValue || newValue === "") {
                          const newParams = new URLSearchParams(searchParams);

                          if (newValue === "") {
                            newParams.delete(
                              "category",
                              encodeURIComponent(newValue)
                            );
                          } else {
                            newParams.set(
                              "category",
                              encodeURIComponent(newValue)
                            );
                          }

                          setSearchParams(newParams);
                          setSelectedFilter(newValue);
                        }
                      }}
                      slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                      size="sm"
                      defaultValue={""}
                      sx={{
                        paddingY: "10px",
                        paddingX: "16px",
                        fontWeight: 600,
                        gap: "8px",
                        borderRadius: "8px",
                        color:
                          theme.colorSchemes.light.palette.others.neutral_dark,
                        borderColor:
                          theme.colorSchemes.light.palette.others.neutral_dark,
                      }}
                      indicator={<ChevronDownIcon width={18} height={18} />}
                    >
                      <Option value={""}>
                        {t("catalog.inventory.allFilterBtn")}
                      </Option>
                      {categories.map((cat) => (
                        <Option key={cat} value={cat}>
                          {cat}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>

                  <Stack direction={"row"} gap={"8px"}>
                    {/* sort button */}
                    <Button
                      variant="filter-outlined"
                      // endDecorator={
                      //   sortDirection === "asc" ? (
                      //     <BarsArrowUpIcon width={18} height={18} />
                      //   ) : (
                      //     <BarsArrowDownIcon width={18} height={18} />
                      //   )
                      // }
                      sx={{
                        whiteSpace: "nowrap",
                        p: "10px",
                        minWidth: { xs: 0, md: "173px" },
                      }}
                      onClick={() => {
                        const newParams = new URLSearchParams(searchParams);
                        newParams.set(
                          "sortDirection",
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                        setSearchParams(newParams);
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                    >
                      <Typography
                        level="text-sm-semibold"
                        display={{ xs: "none", md: "block" }}
                      >
                        {t(
                          `catalog.inventory.sort${
                            sortDirection === "asc" ? "Ascending" : "Descending"
                          }Btn`
                        )}
                      </Typography>
                      {sortDirection === "asc" ? (
                        <BarsArrowUpIcon width={20} height={20} />
                      ) : (
                        <BarsArrowDownIcon width={20} height={20} />
                      )}
                    </Button>

                    {/* export/import button mobile */}
                    <Box
                      sx={{ display: { xs: "block", md: "none" } }}
                      onMouseOver={() => setShowTooltip("exportimport-sm")}
                      onMouseLeave={() => setShowTooltip("")}
                    >
                      <Tooltip
                        placement="bottom"
                        variant="outlined"
                        arrow
                        open={showTooltip === "exportimport-sm"}
                        sx={{ maxWidth: 350 }}
                        title={
                          <div className="p-4 space-y-2">
                            <Typography level="title-sm">
                              {t("catalog.inventory.exportImportTooltipTitle")}
                            </Typography>
                            <div className="flex gap-2 items-center">
                              <WbIncandescentOutlined
                                color="primary"
                                className="rotate-180"
                              />
                              <Typography level="body-sm">
                                {t("catalog.inventory.exportImportTooltipBody")}
                              </Typography>
                            </div>
                          </div>
                        }
                      >
                        <Link
                          to={`/client/${BrowserRouterConstants.InventoryExportImport}`}
                        >
                          <Button
                            id="inventory-exportImport-button-mobile"
                            variant="linkz-outlined-primary"
                            sx={{
                              p: "10px",
                              minWidth: 0,
                            }}
                          >
                            <DocumentTextIcon width={20} height={20} />{" "}
                          </Button>
                        </Link>
                      </Tooltip>
                    </Box>
                  </Stack>

                  <If condition={isFullView}>
                    <Link to={`/full-view/inventory`} target="_blank">
                      <Button
                        variant="filter-outlined"
                        // startDecorator={<FileUploadOutlinedIcon />}
                        className="min-w-[9rem] whitespace-nowrap"
                        endDecorator={
                          <ArrowsPointingOutIcon width={18} height={18} />
                        }
                      >
                        {t("catalog.inventory.expandViewBtn")}
                      </Button>
                    </Link>
                  </If>

                  {/* create catalog button desktop */}
                  <Box
                    sx={{ display: { xs: "none", md: "block" } }}
                    onMouseOver={() => setShowTooltip("createcatalog-lg")}
                    onMouseLeave={() => setShowTooltip("")}
                  >
                    <Tooltip
                      placement="right"
                      variant="outlined"
                      arrow
                      open={showTooltip === "createcatalog-lg"}
                      sx={{ maxWidth: 350 }}
                      title={
                        <div className="p-4 space-y-2">
                          <Typography level="title-sm">
                            {t("catalog.inventory.tooltipTitle")}
                          </Typography>
                          <div className="flex gap-2 items-center">
                            <WbIncandescentOutlined
                              color="primary"
                              className="rotate-180"
                            />
                            <Typography level="body-sm">
                              {t("catalog.inventory.tooltipBody")}
                            </Typography>
                          </div>
                        </div>
                      }
                    >
                      <Button
                        // startDecorator={<FileDownloadOutlinedIcon />}
                        disabled={!(selectedItems.length > 0)}
                        className="min-w-[9.2rem] whitespace-nowrap rounded-lg"
                        onClick={handleCreateCatalog}
                      >
                        {t("catalog.inventory.createCatalogBtn")}
                      </Button>
                    </Tooltip>
                  </Box>
                </Stack>

                <Stack direction={"row"} sx={{ gap: "10px" }} overflow={"auto"}>
                  {/* create catalog button mobile */}
                  <Box
                    sx={{ display: { xs: "block", md: "none" } }}
                    className="xs:block md:hidden"
                    onMouseOver={() => setShowTooltip("createcatalog-sm")}
                    onMouseLeave={() => setShowTooltip("")}
                  >
                    <Tooltip
                      placement="right"
                      variant="outlined"
                      arrow
                      open={showTooltip === "createcatalog-sm"}
                      sx={{ maxWidth: 350 }}
                      title={
                        <div className="p-4 space-y-2">
                          <Typography level="title-sm">
                            {t("catalog.inventory.tooltipTitle")}
                          </Typography>
                          <div className="flex gap-2 items-center">
                            <WbIncandescentOutlined
                              color="primary"
                              className="rotate-180"
                            />
                            <Typography level="body-sm">
                              {t("catalog.inventory.tooltipBody")}
                            </Typography>
                          </div>
                        </div>
                      }
                    >
                      <Button
                        disabled={!(selectedItems.length > 0)}
                        className="min-w-[9.2rem] whitespace-nowrap rounded-lg"
                        onClick={handleCreateCatalog}
                      >
                        {t("catalog.inventory.createCatalogBtn")}
                      </Button>
                    </Tooltip>
                  </Box>

                  {/* export/import button desktop */}
                  <Box
                    sx={{ display: { xs: "none", md: "block" } }}
                    onMouseOver={() => setShowTooltip("exportimport-lg")}
                    onMouseLeave={() => setShowTooltip("")}
                  >
                    <Tooltip
                      placement="bottom"
                      variant="outlined"
                      arrow
                      open={showTooltip === "exportimport-lg"}
                      sx={{ maxWidth: 350 }}
                      title={
                        <div className="p-4 space-y-2">
                          <Typography level="title-sm">
                            {t("catalog.inventory.exportImportTooltipTitle")}
                          </Typography>
                          <div className="flex gap-2 items-center">
                            <WbIncandescentOutlined
                              color="primary"
                              className="rotate-180"
                            />
                            <Typography level="body-sm">
                              {t("catalog.inventory.exportImportTooltipBody")}
                            </Typography>
                          </div>
                        </div>
                      }
                    >
                      <Link
                        to={`/client/${BrowserRouterConstants.InventoryExportImport}`}
                      >
                        <Button
                          id="inventory-exporti=Import-button-desktop"
                          startDecorator={
                            <DocumentTextIcon width={20} height={20} />
                          }
                          variant="linkz-outlined-primary"
                          className="whitespace-nowrap"
                        >
                          {t("catalog.inventory.exportImportBtn")}
                        </Button>
                      </Link>
                    </Tooltip>
                  </Box>

                  <Button
                    startDecorator={<PlusIcon width={18} height={18} />}
                    className="ml-auto whitespace-nowrap rounded-lg"
                    onClick={handleCreateItem}
                  >
                    {t("catalog.inventory.newItem")}
                  </Button>
                </Stack>
              </Stack>

              {/* items table */}
              <Box
                sx={{
                  width: { xs: "90vw", lg: isFullView ? "90vw" : "100%" },
                  height: "100%",
                  maxHeight: { lg: "27.5rem" },
                  overflow: "auto",
                  borderWidth: "2px",
                  position: "relative",
                }}
              >
                <Table
                  sx={{
                    "& th": { textAlign: "center", py: "20px", px: "10px" },
                    "& td": { textAlign: "center" },
                    "& thead th:nth-child(1)": { width: "48px" },
                    "& thead th:nth-child(2)": { width: "68px" },
                    "& thead th:nth-child(3)": { width: "45px" },
                    "& thead th:nth-child(4)": { width: "110px" },
                    "& thead th:nth-child(5)": { width: "208px" },
                    "& thead th:nth-child(6)": { width: "208px" },
                    "& thead th:nth-child(7)": { width: "110px" },
                    "& thead th:nth-child(8)": { width: "110px" },
                    "& thead th:nth-child(9)": { width: "110px" },
                    "& thead th:nth-child(10)": { minWidth: "110px" },
                    minWidth: "100%",
                    tableLayout: "auto",
                  }}
                >
                  <thead>
                    <tr key={"header"}>
                      <th key={"edit"} className="border-r">
                        {t("catalog.items.edit")}
                      </th>
                      <th key={"checkbox"}>
                        <Checkbox
                          size="sm"
                          onChange={(event) => {
                            if (event.target.checked) {
                              handleSelectAll();
                            } else {
                              setSelectedItems([]);
                            }
                          }}
                        />
                      </th>
                      <th key={"index"}>{t("catalog.items.no")}</th>
                      <th key={"picture"}>{t("catalog.items.picture")}</th>
                      <th key={"sku"}>{t("catalog.items.sku")}</th>
                      <th key={"productName"}>
                        {t("catalog.items.productName")}
                      </th>
                      <th key={"quantity"}>{t("catalog.items.quantity")}</th>
                      <th key={"currency"}>{t("catalog.items.currency")}</th>
                      <th key={"price"}>{t("catalog.items.price")}</th>
                      <th key={"unit_of_measure"}>{t("catalog.items.uom")}</th>
                      <th key={"inventoryType"}>
                        {t("catalog.items.category")}
                      </th>
                      {flexiColumns?.map((col) => (
                        <th key={col} className="min-w-[110px]">
                          {col}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((d, index) => {
                      const isSelected = selectedItems.includes(
                        inventoryItems[index].id
                      );
                      return (
                        <tr
                          key={inventoryItems[index].id}
                          className={`${
                            isSelected
                              ? `bg-[${theme.palette.primary[50]}]`
                              : ""
                          } ${
                            newItems.includes(inventoryItems[index].sku)
                              ? `bg-[${theme.palette.primary[50]}]`
                              : ""
                          }`}
                        >
                          <td className="text-center border-r">
                            <div className="flex justify-center w-full">
                              <Link
                                to={`${inventoryItems[index].id}/edit`}
                                className="hover:text-primary-500"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.4142 2.58579C16.6332 1.80474 15.3668 1.80474 14.5858 2.58579L7 10.1716V13H9.82842L17.4142 5.41421C18.1953 4.63316 18.1953 3.36683 17.4142 2.58579Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2 6C2 4.89543 2.89543 4 4 4H8C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6H4V16H14V12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12V16C16 17.1046 15.1046 18 14 18H4C2.89543 18 2 17.1046 2 16V6Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </td>
                          <td className="text-center">
                            <Checkbox
                              size="sm"
                              checked={isSelected}
                              onChange={(e) => handleChecked(e, index)}
                            />
                          </td>
                          <td className="text-center">
                            {(pagination.current - 1) * pagination.limit +
                              (index + 1)}
                          </td>
                          <td className="text-center">
                            <UploadImageBtn
                              item={inventoryItems[index]}
                              setIsLoading={setIsLoading}
                              setShowToast={setShowToast}
                              setAlertMessage={setAlertMessage}
                            />
                          </td>
                          {d.map((i, idx) => (
                            <td
                              key={
                                inventoryItems[index].id +
                                i.toString() +
                                "_col" +
                                index +
                                Math.random() * 695622
                              }
                              className="text-center break-all"
                            >
                              <IfElse
                                condition={idx === 2}
                                ifBlock={
                                  <IfElse
                                    condition={
                                      Number(i) <=
                                      (inventoryItems[index].lowStockQuantity ||
                                        0)
                                    }
                                    ifBlock={
                                      <button
                                        onClick={() => {
                                          setSelectedQuantityItem({
                                            show: true,
                                            item: inventoryItems[index],
                                          });
                                        }}
                                        className="bg-[#F7D5AC] w-fit h-fit mx-auto rounded-[4px] p-1"
                                      >
                                        {i}
                                      </button>
                                    }
                                    elseBlock={<>{i}</>}
                                  />
                                }
                                elseBlock={
                                  <IfElse
                                    condition={idx === 0}
                                    ifBlock={
                                      <Stack direction={"row"} gap={"10px"}>
                                        <Typography level="text-sm-medium">
                                          {i}
                                        </Typography>
                                        {newItems.includes(i.toString()) && (
                                          <Chip
                                            sx={{
                                              bgcolor:
                                                theme.palette.primary[500],
                                              p: "4px",
                                              minHeight: "16px",
                                              maxHeight: "16px",
                                              minWidth: "35px",
                                              color: theme.palette.common.white,
                                            }}
                                          >
                                            <Typography
                                              level="text-xs-medium"
                                              fontSize={12}
                                            >
                                              New
                                            </Typography>
                                          </Chip>
                                        )}
                                      </Stack>
                                    }
                                    elseBlock={
                                      <Typography level="text-sm-medium">
                                        {i}
                                      </Typography>
                                    }
                                  ></IfElse>
                                }
                              />
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <Box
                  sx={{
                    backgroundColor:
                      theme.colorSchemes.light.palette.others.neutral_light,
                    position: "sticky",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    borderTop: "1px solid #e5e7eb",
                    paddingY: selectedItems.length > 0 ? "1.25rem" : 0,
                    paddingX: "1.25rem",
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxHeight: selectedItems.length > 0 ? "500px" : 0,
                    transition: "all 0.1s ease-in-out",
                    overflow: "hidden",
                  }}
                >
                  <Typography fontWeight={700} fontSize={14}>
                    {selectedItems.length}{" "}
                    <span className="font-medium">
                      {t("catalog.inventory.batchEdit.itemsSelected")}
                    </span>
                  </Typography>

                  <Stack direction={"row"} gap={{ lg: "10px", xs: "3px" }}>
                    <Button
                      variant="plain"
                      onClick={() => setShowDeleteModal(true)}
                      disabled={selectedItems.length === 0}
                      sx={{
                        color: "var(--joy-palette-danger-500)",
                        "&:hover": {
                          backgroundColor: "var(--joy-palette-danger-100)",
                        },
                      }}
                    >
                      {t("catalog.inventory.batchEdit.deleteSelected")}
                    </Button>
                    <Button
                      variant="plain"
                      onClick={handleBatchEdit}
                      disabled={selectedItems.length === 0}
                      sx={{
                        // display: { xs: "none", lg: "block" },
                        color: "var(--joy-palette-primary-500)",
                        "&:hover": {
                          backgroundColor: "var(--joy-palette-primary-100)",
                        },
                      }}
                    >
                      {t("catalog.inventory.batchEdit.editSelected")}
                    </Button>
                  </Stack>
                </Box>
              </Box>

              <TablePagination
                handlePaging={handlePaging}
                pagination={pagination}
                setPagination={setPagination}
                withLimit
              />
            </Stack>
          ) : (
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                gap: "32px",
                alignItems: "stretch",
                justifyContent: "center",
              }}
            >
              <InventoryUploadDropzone onDrop={onDrop} />
              <Typography
                fontSize={18}
                fontWeight={600}
                className="text-center md:my-auto"
                sx={{
                  color: "#101828",
                }}
              >
                {t("landing.or")}
              </Typography>
              <CreateInventoryDropzone onClick={handleCreateItem} />
            </Stack>
          )}
        </Sheet>
      )}

      {/* modals */}
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
      <ConfirmDeleteItemsModal
        show={showDeleteModal}
        onClick={() => {
          deleteInventoryItemBatch(selectedItems);
        }}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        selectedItems={selectedItems}
      />
      <LoadingModal isLoading={isLoading} />
      <CreateCatalogModal
        show={showCreateModal}
        businessId={getActiveBusiness()?.id ?? ""}
        onClose={() => {
          setShowCreateModal(false);
        }}
        items={selectedItems}
        payload={payload}
      />
      {duplicateSKU && (
        <DuplicateSKUModal
          show={showDuplicateModal.show}
          useDatabaseSku={showDuplicateModal.useDatabaseSku}
          items={duplicateSKU}
          databaseSku={databaseSku}
          onClose={() => {
            setIsLoading(false);
            setShowDuplicateModal({
              show: false,
              useDatabaseSku: true,
              onClick: () => {},
            });
          }}
          onClick={showDuplicateModal.onClick}
        />
      )}
      <Snackbar
        variant="solid"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showToast}
        key={"asjfkangdlknakjrsh"}
        sx={{
          width: "40%",
          maxWidth: 800,
        }}
      >
        <Box width={"100%"}>
          <Typography
            textAlign={"center"}
            textColor={"common.white"}
            fontSize={14}
          >
            {alertMessage}
          </Typography>
        </Box>
      </Snackbar>
      {selectedQuantityItem?.item && (
        <UpdateQuantityModal
          show={selectedQuantityItem?.show ?? false}
          onClose={() => {
            setSelectedQuantityItem(undefined);
          }}
          item={[selectedQuantityItem?.item]}
        />
      )}
      <FailModal
        open={showFailModal}
        title="Failed Uploading File"
        content={
          <Box
            sx={{
              width: "100%",
              p: "16px",
              textAlign: "left",
              height: "250px",
              overflow: "auto",
            }}
            className="scrollbar-container"
          >
            <List marker="disc">
              {excelErrors.map(({ name, errors }) => (
                <ListItem key={name}>
                  {t("catalog.inventory.import.error.errorOnRow", {
                    row: name,
                  })}
                  :
                  <List marker="circle">
                    {errors.map((e: any) => (
                      <ListItem key={e.code}>{e.message}</ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Box>
        }
        onClose={() => {
          setShowFailModal(false);
        }}
      />
    </React.Fragment>
  );
}

export default ListInventory;
