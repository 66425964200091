import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dropdown,
  FormControl,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuButton,
  Option,
  Select,
  Sheet,
  Snackbar,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { SuccessModal } from "../../components/SuccessModal";
import { InventoryService } from "../../services/inventory.service";
import useContainer from "./useContainer";
// import LoadingModal from "../../components/LoadingModal/LoadingModal";
import {
  ChevronDownIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Edit, SearchOutlined } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { If } from "../../components/Condition";
import CopyLinkButton from "../../components/CopyLinkButton";
import ConfirmDeleteModal from "../../components/Inventory/ConfirmDeleteModal";
import ConfirmDuplicateModal from "../../components/Inventory/ConfirmDuplicateModal";
import EditCatalogueItemModal from "../../components/Inventory/Items/EditCatalogueItemModal";
import ShareModal from "../../components/Inventory/ShareModal";
// import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { ListBulletIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { theme } from "../../components/Theme";
import { CatalogService } from "../../services/catalog.service";
import { ItemService } from "../../services/item.service";
import { getActiveBusiness } from "../../store/session";
import { CatalogInventoryItemType } from "../../types/item";
import { paymentFlowTypes } from "../../types/order";
import { calculatePrice } from "../../utils/cart";
import { formatPrice } from "../../utils/formatPrice";
import ConfirmDeleteItemsModal from "../Inventory/components/ConfirmDeleteItemsModal";
import TablePagination from "../Inventory/components/TablePagination";
import InfoSnackbar from "./components/InfoSnackbar";
import { ProductCard } from "./components/ProductCard";
import { processNewItems } from "../../utils/items";
// import { getCountryInfo } from "../../utils/country";

const sortOptions = [
  { value: "", label: "Sort By" },
  { value: "id_asc", label: "Newest first" },
  { value: "id_desc", label: "Latest first" },
  { value: "productName_asc", label: "A to Z (ascending)" },
  { value: "productName_desc", label: "Z to A (descending)" },
  // { value: "price_desc", label: "Highest Price" },
  // { value: "price_asc", label: "Lowest Price" },
];

const ListCatalogueItems = () => {
  const { t } = useTranslation();
  const { catalogId } = useParams();
  // const currentBusiness = getActiveBusiness() as any;
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const sortBy = searchParams.get("sort_by");
  const sortDirection = searchParams.get("sort_direction") ?? "";
  const showCurrency = searchParams.get("show_currency");
  const layoutType = searchParams.get("layout") ?? "list";
  const inventoryType = searchParams.get("inventory_type");

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CatalogInventoryItemType>();
  // const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [isHidePrice, setIsHidePrice] = useState(false);
  // const [isMobile, setIsMobile] = useState(
  //   window.innerWidth < theme.breakpoints.values.sm
  // );
  const [isEditCatalogName, setIsEditCatalogName] = useState(false);

  // const currentPage = searchParams.get("current_page") ?? 0;

  // const loaderRef = useRef(null);
  // const [openProductDetailsModal, setOpenProductDetailsModal] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [errorUpdateName, setErrorUpdateName] = useState<string | undefined>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedCatalogItems, setSelectedCatalogItems] = useState<string[]>(
    []
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUOMs, setSelectedUOMs] = useState<{ [key: string]: number }>(
    {}
  );
  const [newItems, setNewItems] = useState<string[]>([]);

  // TODO: refactor or remove this entirely
  const [hasSeenMenu, setHasSeenMenu] = useState<string | null>(
    localStorage.getItem("hasSeenMenu")
  );

  const handleUOMChange = (id: string, uom: number) => {
    setSelectedUOMs((prev) => ({ ...prev, [id]: uom }));
  };

  const {
    // isPending,
    fetchAllItems,
    deleteInventoryItemBatch,
    items,
    setItems,
    catalog,
    setCatalog,
    isLoading,
    setIsLoading,
    showAlert,
    showError,
    setShowAlert,
    showToast,
    unauthorized,
    setUnauthorized,
    alertMessage,
    setAlertMessage,
    confirmDelete,
    setConfirmDelete,
    showShareModal,
    setShowShareModal,
    confirmDuplicate,
    setConfirmDuplicate,
    // fillerItems,
    pagination,
    hasMore,
    imgExistanceCheckQuery,
    handlePaging,
    setPagination,
  } = useContainer();
  console.log({ items });
  // useEffect(() => {
  //   if (window.innerWidth < theme.breakpoints.values.sm) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          if (res.business.id !== getActiveBusiness()?.id) {
            setUnauthorized(true);

            return;
          }

          setCatalog(res);

          return fetchAllItems({
            catalogId,
            paging: true,
            limit: 10,
            offset: 0,
            isReset: true,
            inventoryType: inventoryType !== "all" ? inventoryType : "",
            searchTerm: query,
            sortDirection: (["asc", "desc"].includes(sortDirection)
              ? sortDirection
              : "desc") as "asc" | "desc",
            sortBy: sortBy ? sortBy : "updatedAt",
          });
        })
        .then(() => {
          ItemService.getCatalogInventoryTypes({
            catalogId,
          }).then((res) => {
            // const cats = res.map(
            //   (type: { inventoryType: string }) => type.inventoryType
            // );

            setCategories(res);
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [catalogId, query, inventoryType, sortDirection, sortBy, layoutType]);

  /**
   * check for new items
   */
  useEffect(() => {
    if (items.length > 0) {
      // map catalogInventoryItems to inventoryItems with catalogInventoryItems createdAt
      const inventoryItems = items.map((item) => {
        return {
          ...item.inventoryItem,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
        };
      });

      processNewItems(
        "already_seen_items_catalog",
        inventoryItems,
        (noteAlreadySeenItems) => {
          setNewItems(noteAlreadySeenItems);
        }
      );
    }
  }, [items]);

  const handleRemove = () => {
    setIsLoading(true);
    InventoryService.removeCatalog({
      catalogId: catalogId || "",
    })
      .then((res) => {
        if (res) {
          setAlertMessage(t("catalog.catalogRemoved"));
          setShowAlert(true);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
        window.location.href = "/client/catalogues/list";
      });
  };

  const handleRemoveItem = (id: string) => {
    InventoryService.removeCatalogItem({
      catalogInventoryItemId: id,
    })
      .then((res) => {
        if (res && catalogId) {
          setAlertMessage(t("catalog.inventory.catalogItemRemoved"));
          setShowAlert(true);

          fetchAllItems({
            catalogId,
            paging: true,
            limit: 10,
            offset: 0,
            isReset: true,
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDuplicate = () => {
    setIsLoading(true);

    const businessId = getActiveBusiness()?.id;

    if (catalogId && businessId) {
      InventoryService.duplicateCatalog({
        catalogId,
      })
        .then((res) => {
          if (res.id) {
            setShowAlert(true);
            setAlertMessage(
              t("catalog.inventory.duplicateCatalog.successMessage")
            );

            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          window.location.href = `/client/catalogues/list?type=${
            catalog?.catalogType.toLowerCase() ?? "public"
          }`;
        });
    }
  };

  const handleUpdateName = async (value: string) => {
    setIsLoading(true);

    const isDuplicate = await CatalogService.validateCatalogName(value);
    if (isDuplicate) {
      setErrorUpdateName(t("catalog.duplicateCatalogError"));
      setIsLoading(false);
      return;
    }

    if (catalog) {
      InventoryService.patchCatalog({
        catalog: {
          id: catalog.id,
          catalogType: catalog.catalogType,
          catalogName: value,
          business: catalog.business.id,
          hidePrice: catalog.hidePrice,
        },
      })
        .then((res) => {
          setCatalog(res);
          setErrorUpdateName(undefined);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
          setIsEditCatalogName(false);
        });
    }
  };

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") {
        searchParams.delete("query");
      } else {
        searchParams.set("query", value);
      }
      setSearchParams(searchParams);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    catalogItemId: string
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, catalogItemId]);
      setSelectedCatalogItems((prev) => [...prev, catalogItemId]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i !== id));
      setSelectedCatalogItems(
        selectedCatalogItems.filter((i) => i !== catalogItemId)
      );
    }
  };

  const handleSelectAll = () => {
    // const inventoryItemIds = items.map((item) => item.inventoryItem.id);
    // const catalogItemIds = items.map((item) => item.id);
    if (catalogId) {
      // setSelectedItems(inventoryItemIds);
      // setSelectedCatalogItems(catalogItemIds);
      ItemService.getCatalogInventoryItemField({
        fields: ["id", "inventoryItem"],
        catalogId,
        inventoryType: searchParams.get("inventory_type") ?? "",
        searchTerm: searchParams.get("query") ?? "",
      })
        .then((res) => {
          const ids: string[] = res.map((i: { id: string }) => i.id);
          setSelectedItems(ids);
          const catalogItemIds: string[] = res.map((i: { id: string }) => i.id);
          setSelectedCatalogItems(catalogItemIds);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleBatchEdit = () => {
    if (selectedItems.length > 0) {
      localStorage.setItem(
        "batchEditCatalogItems",
        JSON.stringify(selectedItems)
      );

      window.location.href = `/client/catalogues/inventory/${catalogId}/mass-update-pricing`;
    }
  };

  const CatalogTypeButton = () => {
    return (
      <button
        className={`py-2 px-3 text-sm rounded-md ${
          catalog?.catalogType === "PUBLIC"
            ? "bg-primary-200"
            : "bg-gray-700 text-white"
        } capitalize font-semibold items-center flex cursor-default`}
      >
        {catalog?.catalogType === "PUBLIC" ? (
          <LockOpenOutlinedIcon
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        ) : (
          <LockOutlinedIcon
            style={{
              color: "white",
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        )}

        {catalog?.catalogType.toLowerCase()}
      </button>
    );
  };

  const CatalogInfo = ({
    title,
    value,
    textClass,
  }: {
    title: string;
    value: string;
    textClass?: string;
  }) => {
    return (
      <Grid lg={4}>
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1.25rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.colorSchemes.light.palette.others.secondary_text,
                  width: "100px",
                }}
              >
                {title}
              </Typography>

              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.colorSchemes.light.palette.others.secondary_text,
                }}
              >
                {":"}
              </Typography>

              <Typography
                className={`${textClass}`}
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                {value}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {/* <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between gap-5">
              <span
                className={`text-[12px] text-[${theme.colorSchemes.light.palette.others.secondary_text}] w-[100px]`}
              ></span>

              <span
                className={`text-[12px] [${theme.colorSchemes.light.palette.others.secondary_text}]`}
              >
                {":"}
              </span>
            </div>
            <span className={`text-[12px] font-semibold ${textClass}`}>
              {value}
            </span>
          </div>
        </div> */}
      </Grid>
    );
  };

  if (unauthorized) {
    return <> {t("catalog.noAccessCatalog")}</>;
  }

  const isExist = () => {
    searchParams.delete("show_currency");
    setSearchParams(searchParams);
  };

  console.log("WHAT?");

  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      <Box
        id={"scroll-container"}
        sx={{
          width: { xs: "90vw", lg: "100%" },
        }}
      >
        {showCurrency == "true" && (
          <InfoSnackbar
            message={
              <Typography textColor={"#101828"} fontSize={14} fontWeight={400}>
                {t("catalog.inventory.changeCurrencyAlert")}
              </Typography>
            }
            isExist={isExist}
          />
        )}

        {/* page header */}
        <Grid container spacing={"2px"}>
          <Grid xs={12} lg={6} display={"flex"} position={"relative"}>
            <Stack alignItems={"center"} direction={"row"} gap={2}>
              {imgExistanceCheckQuery.data && (
                <img
                  style={{ width: "60px", height: "60px", objectFit: "cover" }}
                  src={imgExistanceCheckQuery.data}
                  alt=""
                />
              )}
              {!isEditCatalogName ? (
                <Typography>
                  {catalog?.periodStartDate &&
                    catalog?.periodEndDate &&
                    (dayjs().isAfter(dayjs(catalog.periodEndDate)) ? (
                      <Chip
                        color="neutral"
                        sx={{
                          borderRadius: 10,
                          paddingY: 0.3,
                          paddingX: 0.7,
                          border: 1,
                          backgroundColor: "#EAECF0",
                          borderColor: "#98A2B3",
                        }}
                      >
                        Expired
                      </Chip>
                    ) : (
                      // Active Chip
                      <Chip
                        color="success"
                        sx={{
                          borderRadius: 10,
                          paddingY: 0.3,
                          paddingX: 0.7,
                          border: 1,
                          backgroundColor: "#CFE5DA",
                          borderColor: "#408565",
                        }}
                      >
                        Active
                      </Chip>
                    ))}

                  <h3
                    className="text-2xl font-bold"
                    onClick={() => setIsEditCatalogName(true)}
                  >
                    {catalog?.catalogName}
                  </h3>
                </Typography>
              ) : (
                <Stack direction={"column"}>
                  <Input
                    placeholder={t(
                      "catalog.inventory.createCatalog.placeholder"
                    )}
                    defaultValue={catalog?.catalogName}
                    type="text"
                    className="text-2xl font-bold"
                    fullWidth
                    autoFocus
                    onBlur={(event) => {
                      if (event.target.value !== catalog?.catalogName) {
                        handleUpdateName(event.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        if (
                          event.currentTarget.value !== catalog?.catalogName
                        ) {
                          handleUpdateName(event.currentTarget.value);
                        }
                      }
                    }}
                  />
                  <If condition={errorUpdateName ? true : false}>
                    <p className="text-[#C31D1D] text-sm">{errorUpdateName}</p>
                  </If>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid
            lg={6}
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <CatalogTypeButton />
          </Grid>
        </Grid>
        <Stack
          direction={"row"}
          justifyContent={""}
          spacing={1}
          mt={4}
          sx={{
            background: "var(--Neutral-050, #F9FAFB)",
            paddingX: 2,
            paddingY: 3,
            // borderRadius: 8,
          }}
        >
          <Grid container lg={12} columnSpacing={{ md: 1 }} width={"100%"}>
            {catalog?.paymentFlowType && (
              <CatalogInfo
                title={t("catalog.catalogTypeOfPayment")}
                value={
                  paymentFlowTypes.find(
                    (flow) => flow.value === catalog.paymentFlowType
                  )?.label || ""
                }
              />
            )}

            {catalog?.taxId && (
              <CatalogInfo
                title={t("catalog.catalogTax")}
                value={catalog?.taxId.taxLabel as string}
              />
            )}

            <CatalogInfo
              title={t("catalog.catalogPeriod")}
              textClass={
                dayjs().isAfter(catalog?.periodEndDate) ? "text-danger-400" : ""
              }
              value={
                catalog?.periodStartDate
                  ? `${dayjs(catalog.periodStartDate).format(
                      "YYYY-MM-DD"
                    )} ~ ${dayjs(catalog.periodEndDate).format("YYYY-MM-DD")}`
                  : "-"
              }
            />

            {catalog?.currency && (
              <CatalogInfo
                title={t("catalog.catalogCurrency")}
                value={catalog.currency}
              />
            )}

            {catalog?.itemsCount && (
              <CatalogInfo
                title={t("catalog.catalogTotalItems")}
                value={`${catalog.itemsCount} Items`}
              />
            )}

            <CatalogInfo
              title={t("catalog.catalogLastUpdated")}
              value={dayjs(catalog?.updatedAt).format("YYYY-MM-DD")}
            />
          </Grid>

          <Box width={"4rem"}>
            <Link to={`/client/catalogues/${catalogId}/edit`}>
              <Button
                id="catalog-edit-button"
                variant="filter-outlined"
                size="sm"
                className="rounded-md min-w-2"
              >
                <Edit className="w-6 h-6" />
              </Button>
            </Link>
          </Box>
        </Stack>

        {/* toolbar */}
        <Stack
          direction={"row"}
          sx={{
            display: { md: "none" },
            alignItems: "center",
            justifyContent: "space-between",
            my: "1.5rem",
          }}
        >
          <CatalogTypeButton />

          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Stack
              direction={"row"}
              className="border border-gray-400 rounded-lg overflow-hidden max-w-[78px] flex md:hidden"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <IconButton
                onClick={() => {
                  searchParams.set("layout", "list");
                  setSearchParams(searchParams);
                  // setLayoutType("list");
                }}
                variant="plain"
                className={`h-[100%] rounded-none px-2  ${
                  layoutType == "list" ? "bg-primary-200" : ""
                }`}
              >
                <ListBulletIcon className="w-6" />
              </IconButton>
              <span className="border-l border-gray-400 h-[100%]"></span>
              <IconButton
                onClick={() => {
                  searchParams.set("layout", "grid");
                  setSearchParams(searchParams);
                  // setLayoutType("grid");
                }}
                variant="plain"
                className={`h-[100%]  rounded-none ${
                  layoutType == "grid" ? "bg-primary-200" : ""
                }`}
              >
                <Squares2X2Icon className="w-6" />
              </IconButton>
            </Stack>
            <Stack
              width={40}
              sx={{
                display: { lg: "none" },
              }}
            >
              <Dropdown
                id="catalog-toolbar-dropdown-mobile"
                open={mobileMenuOpen}
                onOpenChange={() => {
                  setMobileMenuOpen(!mobileMenuOpen);

                  if (!hasSeenMenu && mobileMenuOpen) {
                    localStorage.setItem("hasSeenMenu", "export");
                    setHasSeenMenu("export");
                  }
                }}
              >
                <MenuButton
                  fullWidth
                  slots={{ root: Button }}
                  slotProps={{
                    root: {
                      variant: "plain",
                      color: "neutral",
                      sx: {
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                        "&:focus": {
                          bgcolor: "transparent",
                        },
                        padding: 0,
                        minWidth: 0,
                      },
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    className="min-w-[40px] text-nowrap p-0 rounded-lg"
                  >
                    <EllipsisVerticalIcon width={18} height={18} />
                  </Button>
                </MenuButton>
                <Menu>
                  <Link to={`/client/catalogues/${catalogId}/export-import`}>
                    <MenuItem
                      id="catalog-exportImport-button-mobile"
                      sx={{
                        gap: "1rem",
                      }}
                    >
                      <DocumentTextIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.inventory.exportImportBtn")}
                      <If condition={hasSeenMenu !== "export"}>
                        <Chip
                          sx={{
                            bgcolor: theme.palette.primary[500],
                            p: "4px",
                            minHeight: "16px",
                            maxHeight: "16px",
                            minWidth: "35px",
                            color: theme.palette.common.white,
                          }}
                        >
                          <Typography level="text-xs-medium" fontSize={12}>
                            New
                          </Typography>
                        </Chip>
                      </If>
                    </MenuItem>
                  </Link>
                  <Link to={`/client/catalogues/${catalogId}/manage`}>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                    >
                      <PlusIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("commons.addItemBtn")}
                    </MenuItem>
                  </Link>
                  <MenuItem
                    sx={{
                      gap: "1rem",
                    }}
                    onClick={() => {
                      setShowShareModal(true);
                    }}
                  >
                    <SendOutlinedIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                    {t("catalog.sendBtn")}
                  </MenuItem>
                  <CopyLinkButton
                    url={t("catalog.copyLinkPrompt", {
                      catalogueLink: `${
                        window.location.href.split("/client")[0]
                      }/catalog/${catalogId}/items`,
                    })}
                    variant={"dropdown"}
                  />
                  <MenuItem
                    sx={{
                      gap: "1rem",
                    }}
                    onClick={() => {
                      setConfirmDuplicate(true);
                    }}
                  >
                    <ContentCopyOutlinedIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                    {t("catalog.inventory.items.duplicateBtn")}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      gap: "1rem",
                    }}
                    onClick={() => {
                      setConfirmDelete(true);
                    }}
                  >
                    <TrashIcon width={18} height={18} />
                    {t("catalog.inventory.removeCatalogBtn")}
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={"column"} gap={"1rem"} mt={6}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Input
              defaultValue={query ?? ""}
              variant="linkz-input-search"
              size="sm"
              name="searchFormData.search"
              placeholder={t("catalog.searchItemPlaceholder")}
              startDecorator={<SearchOutlined />}
              sx={{ flexGrow: 0, width: { xs: "100%", lg: "37%" } }}
              onChange={handleChange}
            />

            <Stack
              direction={"row"}
              sx={{
                gap: "0.75rem",
                display: { xs: "none", lg: "flex" },
              }}
            >
              <Link to={`/client/catalogues/${catalogId}/manage`}>
                <Button
                  id="catalog-create-button"
                  startDecorator={
                    <PlusIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  }
                  className="rounded-md text-nowrap"
                >
                  {t("commons.addItemBtn")}
                </Button>
              </Link>

              <Button
                variant="outlined"
                startDecorator={
                  <SendOutlinedIcon
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                }
                className="w-full mt-4 text-sm rounded-md lg:mt-0 text-nowrap"
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                {t("catalog.sendBtn")}
              </Button>

              <CopyLinkButton
                url={t("catalog.copyLinkPrompt", {
                  catalogueLink: `${
                    window.location.href.split("/client")[0]
                  }/catalog/${catalogId}/items`,
                })}
                variant={"outlined"}
                isRounded={true}
              />

              <Stack width={40}>
                <Dropdown
                  id="catalog-toolbar-dropdown-desktop"
                  open={menuOpen}
                  onOpenChange={() => {
                    setMenuOpen(!menuOpen);

                    if (!hasSeenMenu && menuOpen) {
                      localStorage.setItem("hasSeenMenu", "export");
                      setHasSeenMenu("export");
                    }
                  }}
                >
                  <MenuButton
                    fullWidth
                    slots={{ root: Button }}
                    slotProps={{
                      root: {
                        variant: "plain",
                        color: "neutral",
                        sx: {
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                          "&:focus": {
                            bgcolor: "transparent",
                          },
                          padding: 0,
                          minWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button
                      variant="filter-outlined"
                      className="min-w-[40px] rounded-md text-nowrap p-0"
                    >
                      <EllipsisVerticalIcon width={18} height={18} />
                    </Button>
                  </MenuButton>
                  <Menu>
                    <Link to={`/client/catalogues/${catalogId}/export-import`}>
                      <MenuItem
                        id="catalog-exportImport-button-desktop"
                        sx={{
                          gap: "1rem",
                        }}
                      >
                        <DocumentTextIcon
                          style={{
                            width: 18,
                            height: 18,
                          }}
                        />
                        {t("catalog.inventory.exportImportBtn")}
                        <If condition={hasSeenMenu !== "export"}>
                          <Chip
                            sx={{
                              bgcolor: theme.palette.primary[500],
                              p: "4px",
                              minHeight: "16px",
                              maxHeight: "16px",
                              minWidth: "35px",
                              color: theme.palette.common.white,
                            }}
                          >
                            <Typography level="text-xs-medium" fontSize={12}>
                              New
                            </Typography>
                          </Chip>
                        </If>
                      </MenuItem>
                    </Link>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDuplicate(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.inventory.items.duplicateBtn")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      <TrashIcon width={18} height={18} />
                      {t("catalog.inventory.removeCatalogBtn")}
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
            spacing={"10px"}
          >
            <Stack
              direction={"row"}
              sx={{
                gap: "1rem",
                width: { xs: "90vw", lg: "100%" },
                alignItems: "center",
                justifyContent: { xs: "space-between", lg: "flex-start" },
                overflow: "auto",
                // paddingX: "1rem",
              }}
              className={`hide-scrollbar`}
            >
              {/* checkbox select all */}
              {/* // TODO: checked when all items are checked one by one */}
              {layoutType == "grid" && (
                <Checkbox
                  onChange={(event) => {
                    if (event.target.checked) {
                      handleSelectAll();
                    } else {
                      setSelectedItems([]);
                      setSelectedCatalogItems([]);
                    }
                  }}
                />
              )}

              {/* category filter */}
              <FormControl size="sm">
                <Select
                  onChange={(
                    _event: React.SyntheticEvent | null,
                    value: string | null
                  ) => {
                    if (!value && value !== "") {
                      return;
                    }

                    if (value.length > 1) {
                      searchParams.set("inventory_type", value);
                    } else {
                      searchParams.delete("inventory_type");
                    }
                    setSearchParams(searchParams);
                  }}
                  slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                  size="sm"
                  defaultValue={""}
                  sx={{
                    paddingY: "10px",
                    paddingX: "16px",
                    fontWeight: 600,
                    gap: "8px",
                    color: theme.colorSchemes.light.palette.others.neutral_dark,
                    borderColor:
                      theme.colorSchemes.light.palette.others.neutral_dark,
                  }}
                  indicator={<ChevronDownIcon width={18} height={18} />}
                >
                  <Option value={""}>{t("catalog.items.allFilterBtn")}</Option>
                  {categories.map((cat) => {
                    return (
                      <Option
                        key={cat}
                        color="primary"
                        value={cat}
                        label={cat.toUpperCase()}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {cat}
                      </Option>
                    );
                  })}
                </Select>
              </FormControl>
              {/* sort by */}
              <FormControl size="sm">
                <Select
                  defaultValue={
                    sortBy && sortDirection ? sortBy + "_" + sortDirection : ""
                  }
                  onChange={(
                    _event: React.SyntheticEvent | null,
                    value: string | null
                  ) => {
                    if (!value) {
                      return;
                    }

                    if (value.length > 1) {
                      const [field, order] = value.split("_");
                      searchParams.set("sort_by", field);
                      searchParams.set("sort_direction", order);
                    } else {
                      searchParams.delete("sort_by");
                      searchParams.delete("sort_direction");
                    }
                    setSearchParams(searchParams);
                  }}
                  slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                  size="sm"
                  sx={{
                    paddingY: "10px",
                    paddingX: "16px",
                    fontWeight: 600,
                    gap: "8px",
                    color: theme.colorSchemes.light.palette.others.neutral_dark,
                    borderColor:
                      theme.colorSchemes.light.palette.others.neutral_dark,
                  }}
                  indicator={<ChevronDownIcon width={18} height={18} />}
                >
                  {sortOptions.map((opt) => {
                    if (catalog?.hidePrice && opt.value.includes("price")) {
                      return;
                    }

                    return (
                      <Option
                        key={opt.value}
                        value={opt.value}
                        color="primary"
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t(`catalog.sortOptions.${opt.label}`)}
                      </Option>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction={"row"}
              className="border border-gray-400 rounded-lg overflow-hidden max-w-[78px] hidden md:flex"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <IconButton
                onClick={() => {
                  searchParams.set("layout", "list");
                  setSearchParams(searchParams);
                  // setLayoutType("list");
                }}
                variant="plain"
                className={`h-[100%] rounded-none px-2  ${
                  layoutType == "list" ? "bg-primary-200" : ""
                }`}
              >
                <ListBulletIcon className="w-6" />
              </IconButton>
              <span className="border-l border-gray-400 h-[100%]"></span>
              <IconButton
                onClick={() => {
                  searchParams.set("layout", "grid");
                  setSearchParams(searchParams);
                  window.location.reload();
                  // setLayoutType("grid");
                }}
                variant="plain"
                className={`h-[100%]  rounded-none ${
                  layoutType == "grid" ? "bg-primary-200" : ""
                }`}
              >
                <Squares2X2Icon className="w-6" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>

        {/* items table */}
        {layoutType == "list" && (
          <>
            <Box
              sx={{
                width: { xs: "90vw", lg: "78vw" },
                height: "100%",
                maxHeight: { lg: "27.5rem" },
                overflow: "auto",
                borderWidth: { lg: "2px" },
                my: "0.75rem",
              }}
            >
              <Table
                borderAxis="x"
                sx={{
                  "& th": { textAlign: "center" },
                  minWidth: { xs: "1000px", md: "100%" },
                }}
              >
                <thead>
                  <tr key={"header"}>
                    <th key={"checkbox"} className="w-[5%]">
                      <Checkbox
                        size="sm"
                        onChange={(event) => {
                          if (event.target.checked) {
                            handleSelectAll();
                          } else {
                            setSelectedItems([]);
                          }
                        }}
                      />
                    </th>
                    <th key={"index"} className="w-[5%]">
                      {t("catalog.items.no")}
                    </th>
                    <th key={"productName"} className="w-[30%]">
                      {t("catalog.items.productName")}
                    </th>
                    <th key={"variant"} className="w-[30%]">
                      {t("catalog.items.variant")}
                    </th>
                    <th key={"price"} className="w-[30%]">
                      {t("catalog.items.price")}
                    </th>
                    <th key={"delete"} className="w-[7%]">
                      {t("catalog.items.delete")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((d, index) => {
                    const isSelected = selectedItems?.includes(d.id);
                    const itemId = d.inventoryItem.id;
                    const selectedUOM = selectedUOMs[itemId] || 1;
                    console.log({ selectedUOM, selectedUOMs, d });
                    return (
                      <tr
                        key={items[index].id}
                        className={`${isSelected ? "bg-primary-50 " : ""}`}
                      >
                        <td className="text-center">
                          <Checkbox
                            checked={isSelected}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            size="sm"
                            onChange={(e) =>
                              handleChecked(e, d.inventoryItem.id, d.id)
                            }
                          />
                        </td>
                        <td className="text-center">
                          {(pagination.current - 1) * pagination.limit +
                            (index + 1)}
                        </td>
                        <td>
                          <Link
                            to={`/client/catalogues/${catalogId}/items/${d.inventoryItem.id}?inventoryId=${d.id}`}
                            className="flex justify-start items-center gap-2"
                          >
                            <img
                              src={`${
                                d.inventoryItem.thumbnail ??
                                "/inventory-img-placeholder.png"
                              }`}
                              alt="item_image"
                              // width={100}
                              // height={100}
                              className={`w-[60px] h-[60px] rounded`}
                            />
                            <div className="flex flex-col">
                              {newItems.includes(d.inventoryItem.sku) && (
                                <Chip
                                  sx={{
                                    bgcolor: theme.palette.primary[500],
                                    p: "4px",
                                    minHeight: "16px",
                                    maxHeight: "16px",
                                    minWidth: "35px",
                                    color: theme.palette.common.white,
                                  }}
                                >
                                  <Typography
                                    level="text-xs-medium"
                                    fontSize={12}
                                  >
                                    New
                                  </Typography>
                                </Chip>
                              )}
                              <span className="text-xs text-neutral-500">
                                {d.inventoryItem.sku}
                              </span>
                              <Typography
                                level="title-lg"
                                sx={{ fontSize: "md", mb: 0.5 }}
                              >
                                {d.inventoryItem.productName}
                              </Typography>
                            </div>
                          </Link>
                        </td>
                        <td>
                          <div className="max-w-[172px] ">
                            <Stack
                              direction={"row"}
                              justifyContent={"start"}
                              spacing="10px"
                              mb={1}
                            >
                              {d.inventoryItem.uom1 ? (
                                <button
                                  onClick={() => handleUOMChange(itemId, 1)}
                                >
                                  <span
                                    className={`p-2 rounded-xl text-sm font-bold ${
                                      selectedUOM === 1
                                        ? "bg-primary-100 text-primary-900"
                                        : "text-primary-400 border border-primary-400"
                                    }`}
                                  >
                                    {d.inventoryItem.uom1}
                                  </span>
                                </button>
                              ) : null}
                              {d.inventoryItem.uom2 ? (
                                <button
                                  onClick={() => handleUOMChange(itemId, 2)}
                                >
                                  <span
                                    className={`p-2 rounded-xl text-sm font-bold ${
                                      selectedUOM === 2
                                        ? "bg-primary-100 text-primary-900"
                                        : "text-primary-400 border border-primary-400"
                                    }`}
                                  >
                                    {d.inventoryItem.uom2}
                                  </span>
                                </button>
                              ) : null}
                              {d.inventoryItem.uom3 ? (
                                <button
                                  onClick={() => handleUOMChange(itemId, 3)}
                                >
                                  <span
                                    className={`p-2 rounded-xl text-sm font-bold ${
                                      selectedUOM === 3
                                        ? "bg-primary-100 text-primary-900"
                                        : "text-primary-400 border border-primary-400"
                                    }`}
                                  >
                                    {d.inventoryItem.uom3}
                                  </span>
                                </button>
                              ) : null}
                            </Stack>

                            <span>
                              {selectedUOM === 1
                                ? d.inventoryItem.quantity
                                : selectedUOM === 2
                                ? d.inventoryItem.conversion2
                                : selectedUOM === 3
                                ? d.inventoryItem.conversion3
                                : ""}{" "}
                              {selectedUOM === 1
                                ? d.inventoryItem.uom1
                                : selectedUOM === 2
                                ? d.inventoryItem.uom2
                                : selectedUOM === 3
                                ? d.inventoryItem.uom3
                                : ""}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">
                          <span className="font-bold text-black">
                            {`${
                              d.currency ?? d.inventoryItem.currency
                            } ${formatPrice(
                              calculatePrice(d, `uom${selectedUOM}` as any)
                            )}`}
                            {/* {d.currency}{" "}
                            {selectedUOM === 1
                              ? d.price
                              : selectedUOM === 2
                              ? d.price2
                              : selectedUOM === 3
                              ? d.price3
                              : ""} */}
                          </span>
                        </td>
                        <td className="text-center">
                          <IconButton>
                            <TrashIcon
                              onClick={(event) => {
                                event.stopPropagation();
                                if (handleRemoveItem) {
                                  handleRemoveItem(d.id);
                                }
                              }}
                              className={
                                "h-6 w-6 text-danger-500 bg-white rounded cursor-pointer"
                              }
                            />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <TablePagination
              handlePaging={handlePaging}
              pagination={pagination}
              setPagination={setPagination}
              withLimit
            />
          </>
        )}
        {layoutType == "grid" && (
          <Box
            className={"hide-scrollbar"}
            sx={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <InfiniteScroll
              pageStart={0}
              // initialLoad
              // getScrollParent={() => document.getElementById("scroll-container")}
              useWindow={false}
              loadMore={(_page: number) => {
                if (isLoading) {
                  return;
                }

                fetchAllItems({
                  catalogId: catalogId ?? "",
                  paging: true,
                  limit: 10,
                  searchTerm: query,
                  offset: pagination.limit * pagination.current,
                  isReset: false,
                  inventoryType: searchParams.get("inventory_type") ?? "",
                  sortDirection: (["asc", "desc"].includes(sortDirection)
                    ? sortDirection
                    : "desc") as "asc" | "desc",
                  sortBy: sortBy ? sortBy : "updatedAt",
                });
              }}
              hasMore={hasMore}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
            >
              <Box width={{ xs: "90vw", lg: "100%" }}>
                {items.length > 0 ? (
                  <Grid
                    container
                    // columns={{ xs: 2, xl: 5 }}
                    sx={{
                      mt: 4,
                      mb: 2,
                      spacing: { xs: 1, lg: 0 },
                      alignItems: "stretch",
                      gap: { xs: "0.5rem", md: "1.3rem" },
                      width: "100%",
                    }}
                    // mt={4}
                    // mb={2}
                    // spacing={{ xs: 1, lg: 0 }}
                    // alignItems={"stretch"}
                    className={"grid grid-cols-2 xl:grid-cols-5"}
                  >
                    {items.map((i, index) => {
                      return (
                        <div key={index}>
                          <ProductCard
                            onClick={() => {
                              setSelectedItem(i);
                              setShowEditModal(true);
                            }}
                            key={i.id}
                            handleRemoveItem={handleRemoveItem}
                            item={i}
                            selectedItems={selectedItems}
                            handleChecked={handleChecked}
                          />
                        </div>
                      );
                    })}

                    {/* {!isMobile &&
                      Array.from({ length: fillerItems }, () => (
                        <Grid
                          key={Math.random() * 6502}
                          xs={12}
                          lg={4}
                          flexGrow={1}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            justifyContent: "space-between",
                          }}
                        >
                          <hr
                            className={`hidden lg:block mt-auto w-full border-b border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                          />
                        </Grid>
                      ))} */}
                  </Grid>
                ) : (
                  <div className="flex w-full mt-10">
                    <p className="mx-auto my-auto text-base">
                      {t("catalog.noItemInCatalog")}
                    </p>
                  </div>
                )}
              </Box>
            </InfiniteScroll>
          </Box>
        )}

        {/* modal */}
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={alertMessage}
          open={showAlert}
        />
        {/* <LoadingModal isLoading={isLoading} /> */}
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.white"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showToast}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
            backgroundColor: theme.colorSchemes.light.palette.others.success,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.black"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <ShareModal
          show={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
          catalogId={catalogId ?? ""}
          businessId={getActiveBusiness()?.id ?? ""}
          catalogType={catalog?.catalogType.toLowerCase() ?? "private"}
        />
        <ConfirmDeleteModal
          show={confirmDelete}
          onClick={handleRemove}
          onClose={() => {
            setConfirmDelete(false);
          }}
        />
        <ConfirmDuplicateModal
          show={confirmDuplicate}
          catalogType={
            t(`catalog.catalogType.${catalog?.catalogType.toLowerCase()}`) ??
            "private"
          }
          onClick={handleDuplicate}
          onClose={() => {
            setConfirmDuplicate(false);
          }}
        />

        {selectedItem && (
          <EditCatalogueItemModal
            catalogId={catalogId ?? ""}
            showModal={showEditModal}
            catalogInventoryItem={selectedItem}
            onClose={() => {
              setShowEditModal(false);
              setSelectedItem(undefined);
            }}
            onUpdate={(item: CatalogInventoryItemType) => {
              const updatedItems = items.map((i) => {
                if (i.id === item.id) {
                  return { ...item };
                } else {
                  return i;
                }
              });

              setItems(updatedItems);
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          backgroundColor:
            theme.colorSchemes.light.palette.others.neutral_light,
          position: "sticky",
          bottom: 0,
          left: 0,
          marginBottom: "-1rem",
          marginLeft: { xs: "-1rem", lg: "-2rem" },
          width: { xs: "calc(100% + 2rem)", lg: "calc(100% + 4rem)" },
          borderTop: "1px solid #e5e7eb",
          paddingY: selectedItems.length > 0 ? "1.25rem" : 0,
          paddingX: "1.25rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxHeight: selectedItems.length > 0 ? "500px" : 0,
          transition: "all 0.1s ease-in-out",
          overflow: "hidden",
        }}
      >
        <Typography fontWeight={700} fontSize={14}>
          {selectedItems.length}{" "}
          <span className="font-medium">
            {t("catalog.inventory.batchEdit.itemsSelected")}
          </span>
        </Typography>

        <Stack direction={"row"} gap={"10px"}>
          <Button
            variant="plain"
            onClick={() => setShowDeleteModal(true)}
            disabled={selectedItems.length === 0}
            sx={{
              color: "var(--joy-palette-danger-500)",
              "&:hover": {
                backgroundColor: "var(--joy-palette-danger-100)",
              },
            }}
          >
            {t("catalog.inventory.batchEdit.deleteSelected")}
          </Button>
          <Button
            variant="plain"
            onClick={handleBatchEdit}
            disabled={selectedItems.length === 0}
            sx={{
              display: { xs: "none", lg: "block" },
              color: "var(--joy-palette-primary-500)",
              "&:hover": {
                backgroundColor: "var(--joy-palette-primary-100)",
              },
            }}
          >
            {t("catalog.inventory.batchEdit.editSelected")}
          </Button>
        </Stack>
      </Box>

      {/* <LoadingModal isLoading={isLoading || isPending} /> */}
      <ConfirmDeleteItemsModal
        show={showDeleteModal}
        onClick={() => {
          deleteInventoryItemBatch(selectedCatalogItems, catalogId);
        }}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        selectedItems={selectedCatalogItems}
      />
      {/* {selectedItem && (
        <Modal
          onClose={() => {
            setOpenProductDetailsModal(false);
            setSelectedItem(undefined);
          }}
          open={openProductDetailsModal}
          style={{
            backgroundColor: "rgba(50, 56, 62,.6)",
            backdropFilter: "blur(0px)",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex items-center w-full h-full">
            <ProductModal
              onUpdate={(item: CatalogInventoryItemType) => {
                const updatedItems = items.map((i) => {
                  if (i.id === item.id) {
                    return { ...item };
                  } else {
                    return i;
                  }
                });
                setItems(updatedItems);
              }}
              catalogId={catalogId ?? ""}
              catalogInventoryItem={selectedItem}
              onClose={() => {
                setOpenProductDetailsModal(false);
                setSelectedItem(undefined);
              }}
            />
          </div>
        </Modal>
      )} */}
    </Sheet>
  );
};

export default ListCatalogueItems;
