import {
  Box,
  Card,
  Divider,
  FormControl,
  Modal,
  ModalClose,
  ModalDialog,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  RadioProps,
} from "@mui/joy";
import * as React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PaymentService } from "../../services/payment.service";
import { Toggler } from "../utils";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { TagIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { KycPaymentGatewayService } from "../../services/kyc-payment-gateway.service";
import { KycPaymentGatewayStatus } from "../../types/kyc";
import { formatCurrencyByCode } from "../../utils/formatPrice";
import { ApplicableAdminFeeTypes } from "../../types/applicable-admin-fee.enum";
import { HeroIcon } from "../stateless/icons";
import { BtnFilledPrimarySm, BtnOutlinedPrimarySm } from "../stateless/buttons";
interface IProps {
  open: boolean;
  setOpenPaymentModal: any;
  handleContinue: any;
  handleSkip: any;
  control: any;
  setValue: any;
  onlinePaymentOnly?: boolean;
  paymentAmount?: number | null;
  paymentCurrency?: string;
  paymentCountry?: string;
  paymentMethods?: any;
  transactionType?: "credit" | "order";
  sellerId?: string | null;
  sellerCurrency?: string | "IDR";
  applicableAdminFee?: ApplicableAdminFeeTypes;
  handleChangePaymentFee?: (arg0: number) => void;
  loanPaymentActive?: boolean;
}

const MethodIcons = [
  {
    name: "va",
    icon: <HeroIcon name="CubeTransparentIcon" />,
  },
  {
    name: "e-wallet",
    icon: <HeroIcon name="WalletIcon" />,
  },
  {
    name: "retail",
    icon: <HeroIcon name="TagIcon" />,
  },
  {
    name: "qris",
    icon: <HeroIcon name="QrCodeIcon" />,
  },
  {
    name: "credit-card",
    icon: <HeroIcon name="CreditCardIcon" />,
  },
];

const MethodDescriptions = [
  {
    name: "va",
    description:
      "You can use virtual account with selected bank to proceed the transaction",
  },
  {
    name: "e-wallet",
    description:
      "You can choose payment with e-wallet to proceed the transaction",
  },
  {
    name: "retail",
    description:
      "You can go to the near retail store to proceed the transaction",
  },
  {
    name: "qris",
    description: "You can scan this QR code to proceed the transaction",
  },
  {
    name: "credit-card",
    description:
      "You can use a credit or debit card to proceed the transaction",
  },
];

const MethodIcon = ({ method }: { method: string }) => {
  return (
    MethodIcons.find((m) => m.name === method)?.icon ?? (
      <TagIcon style={{ width: "1.5rem", height: "1.5rem" }} />
    )
  );
};
export default function PaymentModal({
  open,
  setOpenPaymentModal,
  handleContinue,
  handleSkip,
  control,
  setValue,
  onlinePaymentOnly = false,
  paymentAmount = 0,
  paymentCurrency = "IDR",
  paymentMethods = null,
  transactionType = "order",
  applicableAdminFee = ApplicableAdminFeeTypes.CHARGE_TO_SELLER,
  handleChangePaymentFee = () => {},
  sellerId,
  sellerCurrency = "IDR",
  loanPaymentActive = false,
}: IProps) {
  console.log("Amount", paymentAmount);
  const { t } = useTranslation();
  const { data, refetch } = useQuery({
    queryKey: ["kyc-payment-gateway-status-payment"],
    queryFn: () => KycPaymentGatewayService.getVerificationStatus(sellerId),
  });
  const [adminfee, setAdminFee] = React.useState<number>(0);
  var [availableOnlinePayments, setAvailableOnlinePayments] =
    React.useState<any>([]);
  if (
    paymentMethods &&
    import.meta.env.VITE_ONLINE_PAYMENT_ENABLED === "true"
  ) {
    var [availableOnlinePayments, setAvailableOnlinePayments] =
      React.useState<any>(paymentMethods);
  }

  const [selectedPaymentOption, setSelectedPaymentOption] = React.useState("");

  const RadioCard = (props: RadioProps) => {
    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "var(--joy-palette-neutral-50)",
          borderColor: "transparent",
          borderRadius: "24px",
          padding: "24px",
        }}
        variant="outlined"
      >
        <Radio
          color="primary"
          sx={{
            flexDirection: "row-reverse",
            paddingBottom: "20px",
            borderBottom: "1px solid var(--joy-palette-neutral-200)",
          }}
          {...props}
        />
      </Card>
    );
  };

  React.useEffect(() => {
    console.log("PAYEMTN CURRENCY", {
      paymentCurrency,
      sellerCurrency,
    });
    if (
      import.meta.env.VITE_ONLINE_PAYMENT_ENABLED === "true" &&
      !paymentMethods
      // Allow multi currency PG transaction on one business 
      // && sellerCurrency === paymentCurrency
    ) {
      PaymentService.getAvailbleOnlinePaymentMethods(
        paymentCurrency,
        sellerId ?? ""
      ).then((res) => {
        console.log("RESPONSE", res);
        setAvailableOnlinePayments(res.data);
      });
    }
    refetch();
  }, []);

  const handleCalculatePaymentFee = (method: string) => {
    console.log("METHOD", method);
    if (applicableAdminFee === ApplicableAdminFeeTypes.CHARGE_TO_SELLER) {
      handleChangePaymentFee?.(0);
      return;
    }
    const methodAndOptions = method.split(":");
    const selectedMethod = availableOnlinePayments.find(
      (item: any) => item.method === methodAndOptions[0]
    );
    console.log("FEE", selectedMethod, method);
    if (selectedMethod) {
      // Use method fee first
      var fee =
        selectedMethod.feeType === "amount"
          ? selectedMethod.fee
          : (selectedMethod.fee / 100) * (paymentAmount ?? 0);
      if (methodAndOptions.length == 2) {
        const optionsFee = selectedMethod.options.find(
          (op: any) => op.code === methodAndOptions[1]
        );
        // If there is an option fee, ignore the method fee
        if (optionsFee && optionsFee.fee) {
          fee =
            optionsFee.feeType === "amount"
              ? optionsFee.fee
              : (optionsFee.fee / 100) * (paymentAmount ?? 0);
        }
      }
      if (sellerCurrency === "IDR") {
        fee = Math.ceil(fee);
      }
      setAdminFee(fee);
      handleChangePaymentFee?.(fee);
      return;
    }
    setAdminFee(0);
    handleChangePaymentFee?.(0);
  };

  console.log("Available Online Payments ::", availableOnlinePayments);
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpenPaymentModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalDialog
          layout="center"
          maxWidth={"lg"}
          sx={{
            width: "100%",
            maxWidth: "600px",
            borderRadius: "md",
            p: 2,
            overflow: "scroll",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Stack flex={1} spacing={4} mt={4} mb={2} px={4}>
            <Typography
              level="text-xl-bold"
              textColor={"primary.solidActiveBg"}
              textAlign={"center"}
            >
              {t("order.paymentModal.choosePayment")}
            </Typography>
            <form>
              <Stack maxWidth={"100%"} spacing={2}>
                {/* Your Purchase Info Part */}
                {/* payment detail */}
                <Stack
                  sx={{ width: "100%" }}
                  justifyContent={"center"}
                  alignItems={"start"}
                >
                  <Typography level="text-xl-bold">
                    {t("order.paymentModal.yourPurchase")}
                  </Typography>
                  <Box
                    sx={{ width: "100%" }}
                    padding={"1rem"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                  >
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Typography level="text-md-regular">
                        {t("commons.order.subtotal")}
                      </Typography>
                      <Typography level="text-md-regular">
                        {formatCurrencyByCode(
                          paymentAmount ?? 0,
                          paymentCurrency
                        )}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Typography level="text-md-regular">Admin Fee</Typography>
                      <Typography level="text-md-regular">
                        {formatCurrencyByCode(adminfee ?? 0, paymentCurrency)}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Typography level="text-md-bold">
                        {t("commons.order.grandTotal")}
                      </Typography>
                      <Typography level="text-md-bold">
                        {formatCurrencyByCode(
                          (paymentAmount ?? 0) + adminfee,
                          paymentCurrency
                        )}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>

                {/* Payment Method Part */}
                <Stack spacing={1}>
                  <Typography level="text-xl-bold">
                    {t("order.paymentModal.paymentMethod")}
                  </Typography>
                  <Typography level="text-sm-regular">
                    {t("order.paymentModal.paymentMethodPrompt")}
                  </Typography>
                </Stack>

                <Stack>
                  <Controller
                    name={"paymentMethod"}
                    defaultValue={""}
                    control={control}
                    render={({ field: { value } }) => (
                      <FormControl>
                        <RadioGroup
                          defaultValue="medium"
                          name="payment-radio-buttons-group"
                          onChange={(_event) => {
                            setValue("paymentMethod", _event.target.value);
                            handleCalculatePaymentFee(_event.target.value);
                            setSelectedPaymentOption(_event.target.value);
                          }}
                          value={value}
                        >
                          <Stack spacing={2}>
                            {(data?.status ===
                              KycPaymentGatewayStatus.VALIDATED ||
                              transactionType === "credit") &&
                              // true &&
                              availableOnlinePayments &&
                              availableOnlinePayments
                                .filter((p: any) => p.isActive)
                                .map((item: any) => (
                                  <>
                                    {/* Card Option - Credit Cards */}
                                    <Card
                                      sx={{
                                        width: "100%",
                                        backgroundColor: "#F9FAFB",
                                        borderColor: "transparent",
                                        borderRadius: "24px",
                                        padding: "24px",
                                      }}
                                      variant="outlined"
                                    >
                                      {item?.options &&
                                      item?.options?.length > 0 ? (
                                        <>
                                          <Toggler
                                            renderToggle={({
                                              open,
                                              setOpen,
                                              sxClasses,
                                            }) => (
                                              <Stack
                                                direction={"row"}
                                                justifyContent={"space-between"}
                                                onClick={() => setOpen(!open)}
                                                sx={sxClasses}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                    justifyContent: "center",
                                                    gap: 1,
                                                  }}
                                                >
                                                  {item.icon ? (
                                                    <img
                                                      src={item.icon}
                                                      style={{
                                                        width: "2rem",
                                                        height: "2rem",
                                                        backgroundColor:
                                                          "#F9FAFB",
                                                        objectFit: "contain",
                                                      }}
                                                    />
                                                  ) : (
                                                    <MethodIcon
                                                      method={item.method}
                                                    />
                                                  )}
                                                  <Stack>
                                                    <Typography
                                                      level="text-md-bold"
                                                      sx={sxClasses}
                                                      onClick={() =>
                                                        setOpen(!open)
                                                      }
                                                    >
                                                      {item.name}
                                                    </Typography>
                                                    <Typography level="text-sm-regular">
                                                      {MethodDescriptions.find(
                                                        (desc) =>
                                                          desc.name ===
                                                          item.method
                                                      )?.description ?? ""}
                                                    </Typography>
                                                  </Stack>
                                                </Box>
                                                <KeyboardArrowDownOutlined
                                                  sx={{
                                                    transform: open
                                                      ? "rotate(180deg)"
                                                      : "none",
                                                  }}
                                                />
                                              </Stack>
                                            )}
                                          >
                                            <Stack
                                              marginLeft={"2rem"}
                                              spacing={2}
                                            >
                                              {item.options
                                                .filter((o: any) => o.isActive)
                                                .map((option: any) => (
                                                  <>
                                                    <Radio
                                                      value={`${item.method}:${option.code}`}
                                                      label={
                                                        <Typography
                                                          level="text-md-regular"
                                                          startDecorator={
                                                            <img
                                                              src={
                                                                option.icon?.includes(
                                                                  "http"
                                                                )
                                                                  ? option.icon
                                                                  : `${
                                                                      import.meta
                                                                        .env
                                                                        .VITE_SPACES_HOST
                                                                    }${
                                                                      option.icon
                                                                    }`
                                                              }
                                                              style={{
                                                                width: "2rem",
                                                                height: "2rem",
                                                                backgroundColor:
                                                                  "#F9FAFB",
                                                                objectFit:
                                                                  "contain",
                                                              }}
                                                            />
                                                          }
                                                        >
                                                          {option.name}
                                                        </Typography>
                                                      }
                                                      color="primary"
                                                      sx={{
                                                        flexDirection:
                                                          "row-reverse",
                                                        paddingBottom: "20px",
                                                        borderBottom:
                                                          "1px solid var(--joy-palette-neutral-200)",
                                                      }}
                                                      slotProps={{
                                                        input: {
                                                          "aria-describedby":
                                                            "ep-helper-text",
                                                        },
                                                        radio: {
                                                          style: {
                                                            alignSelf: "center",
                                                          },
                                                        },
                                                      }}
                                                    />
                                                  </>
                                                ))}
                                            </Stack>
                                          </Toggler>
                                          <Divider />
                                        </>
                                      ) : (
                                        <>
                                          <Radio
                                            value={item.method}
                                            color="primary"
                                            slotProps={{
                                              input: {
                                                "aria-describedby":
                                                  "ep-helper-text",
                                              },
                                              radio: {
                                                style: {
                                                  alignSelf: "center",
                                                },
                                              },
                                            }}
                                            sx={{
                                              flexDirection: "row-reverse",
                                              paddingBottom: "20px",
                                              borderBottom:
                                                "1px solid var(--joy-palette-neutral-200)",
                                            }}
                                            label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "start",
                                                  gap: 1,
                                                }}
                                              >
                                                {item.icon ? (
                                                  <img
                                                    src={item.icon}
                                                    style={{
                                                      width: "2rem",
                                                      height: "2rem",
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      objectFit: "contain",
                                                    }}
                                                  />
                                                ) : (
                                                  <MethodIcon
                                                    method={item.method}
                                                  />
                                                )}
                                                <Stack>
                                                  <Typography level="text-md-bold">
                                                    {item.name}
                                                  </Typography>
                                                  <Typography
                                                    level="text-sm-regular"
                                                    color="neutral"
                                                  >
                                                    {MethodDescriptions.find(
                                                      (desc) =>
                                                        desc.name ===
                                                        item.method
                                                    )?.description ?? ""}
                                                  </Typography>
                                                </Stack>
                                              </Box>
                                            }
                                          />
                                        </>
                                      )}
                                    </Card>
                                  </>
                                ))}

                            {/* No Online Payment - Loan and Offline */}
                            {!onlinePaymentOnly && (
                              <>
                                {loanPaymentActive && (
                                  <RadioCard
                                    value="loan-payment"
                                    label={
                                      <>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            gap: 1,
                                          }}
                                        >
                                          <HeroIcon name="CreditCardIcon" />
                                          <Stack>
                                            <Typography level="text-md-bold">
                                              {" "}
                                              {t(
                                                "order.paymentModal.loanLabel"
                                              )}
                                            </Typography>

                                            <Typography
                                              level="text-sm-regular"
                                              color="neutral"
                                            >
                                              {t("order.paymentModal.loanDesc")}
                                            </Typography>
                                          </Stack>
                                        </Box>
                                      </>
                                    }
                                    slotProps={{
                                      input: {
                                        "aria-describedby": "rfl-helper-text",
                                      },
                                      radio: {
                                        style: {
                                          alignSelf: "center",
                                        },
                                      },
                                    }}
                                  />
                                )}

                                <RadioCard
                                  value="offline-payment"
                                  label={
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "start",
                                          gap: 1,
                                        }}
                                      >
                                        <HeroIcon name="BanknotesIcon" />
                                        <Stack>
                                          <Typography level="text-md-bold">
                                            {" "}
                                            {t(
                                              "order.paymentModal.offlineLabel"
                                            )}
                                          </Typography>
                                          <br />
                                          <Typography
                                            color="neutral"
                                            fontSize={"sm"}
                                            fontWeight={"sm"}
                                          >
                                            {t(
                                              "order.paymentModal.offlineDesc"
                                            )}
                                          </Typography>
                                        </Stack>
                                      </Box>
                                    </>
                                  }
                                  slotProps={{
                                    input: {
                                      "aria-describedby": "op-helper-text",
                                    },
                                    radio: {
                                      style: {
                                        alignSelf: "center",
                                      },
                                    },
                                  }}
                                />
                              </>
                            )}
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </Stack>
              </Stack>

              {/* Button Part */}
              <Stack
                spacing={1}
                mt={6}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <BtnOutlinedPrimarySm
                  fullWidth
                  id="cancel-payment-btn"
                  onClick={handleSkip}
                  label={t("commons.cancelBtn")}
                />
                <BtnFilledPrimarySm
                  fullWidth
                  id="continue-payment-btn"
                  disabled={!selectedPaymentOption}
                  onClick={handleContinue}
                  label={t("commons.continueBtn")}
                />
              </Stack>
            </form>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
