import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import { InventoryService } from "../../services/inventory.service";
import { ItemService } from "../../services/item.service";
import { OrderService } from "../../services/order.service";
import { TrackerService } from "../../services/tracker.service";
import { UploadService } from "../../services/upload.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { CartDataType } from "../../types/cart";
import { CatalogType } from "../../types/catalog";
import { CatalogInventoryItemType, PaginationType } from "../../types/item";
import {
  calculatePrice,
  createCart,
  getCartItems,
  getConversionRate,
  updateCartQty,
  updateCartUOM,
} from "../../utils/cart";
import { getCountryInfo } from "../../utils/country";

const useContainer = () => {
  //#region ----- local states
  const [items, setItems] = useState<CatalogInventoryItemType[]>([]);
  const [catalog, setCatalog] = useState<CatalogType>();
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [confirmDuplicate, setConfirmDuplicate] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [unauthorized, setUnauthorized] = useState(false);
  const [currentItemsCount, setCurrentItemsCount] = useState(0);
  // changed to CatalogInventoryItemType cause we will use price in CatalogInventoryItem
  const [currentItem, setCurrentItem] = useState<CatalogInventoryItemType>();
  const [cartUpdated, setCartUpdated] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>();
  const [fillerItems, setFillerItems] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 10,
    max: 1,
  });
  const [cartItems, setCartItems] = useState<CartDataType | undefined>();
  const [showToast, setShowToast] = useState(false);
  const [currency, setCurrency] = useState(
    getCountryInfo(getActiveBusiness()?.countryCode ?? "ID", "LOCALE")?.currency
  );
  // const [layoutType, setLayoutType] = useState(
  //   searchParams.get("layout") || "list"
  // );
  const [allUOMs, setAllUOMs] = useState<string[]>([]);
  const [isItemLoading, setIsItemLoading] = useState(true);
  // const [unsavedOrderData, setUnsavedOrderData] = useState<string | null>(null);

  const { session } = useAuthStore();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const inventoryType = searchParams.get("inventory_type");
  const query = searchParams.get("query");
  const sortBy = searchParams.get("sort_by");
  const sortDirection = searchParams.get("sort_direction") ?? "";

  const inventoryCategoryQuery = useQuery({
    queryKey: ["inventory", "category"],
    queryFn: () =>
      ItemService.getInventoryItemField({
        fields: ["inventory_type"],
        businessId: session?.activeBusiness.id!,
      }),
  });

  const imgExistanceCheckQuery = useQuery({
    queryKey: ["business-profile-image", catalog?.business?.id],
    queryFn: () => UploadService.checkIfImageExists(catalog?.business?.id!),
    enabled: !!catalog?.business?.id,
    retry: false,
    staleTime: 0,
  });

  //#region ----- misc
  const fetchAllItems = useCallback(
    ({
      catalogId,
      paging = true,
      limit = 10,
      offset = 0,
      searchTerm,
      inventoryType,
      isReset = true,
      sortDirection,
      sortBy,
    }: {
      catalogId: string;
      paging?: boolean;
      limit?: number;
      offset?: number;
      searchTerm?: string | null;
      inventoryType?: string | null;
      isReset?: boolean;
      sortBy?: string;
      sortDirection?: "asc" | "desc";
    }) => {
      console.log("FETCHING ITEM", {
        catalogId,
        paging,
        limit,
        offset,
        searchTerm,
        inventoryType,
        isReset,
        sortDirection,
        sortBy,
      });
      setIsLoading(true);
      InventoryService.getCatalogItems({
        catalogId,
        paging,
        limit,
        offset,
        searchTerm,
        inventoryType,
        sortDirection,
        sortBy,
      })
        .then((res) => {
          // if (isReset) {
          //   console.log("BANANA RESET", searchTerm, res.data);
          //   setItems((_) => [...res.data]);
          //   setHasMore(true);
          // } else {
          //   console.log("BANANA NOT RESET", searchTerm, res.data);
          //   setItems((prev) => [...prev, ...res.data]);
          // }

          if (isReset) {
            console.log("ISRESET RESPONSE", res);
            // Reset the items and ensure uniqueness
            setItems((_) => [
              ...new Map(res.data.map((item) => [item.id, item])).values(),
            ]);

            console.log("FIRST DATA", items);
            setHasMore(true);
          } else {
            console.log("NOT ISRESET RESPONSE COMBINED", res);
            // Append new items and ensure uniqueness
            setItems((prev) => {
              console.log("COMBINED DATA", {
                resData: res.data,
                current: prev,
              });
              const combinedItems = [...prev, ...res.data];
              const uniqueItems = [
                ...new Map(
                  combinedItems.map((item) => [item.id, item])
                ).values(),
              ];

              console.log("COMBINED ITEM RESPONSE", combinedItems);
              return uniqueItems;
            });
          }

          if (res.pagination) {
            setPagination(res.pagination);
          }

          if (res.data.length === 0) {
            setHasMore(false);
          }

          // if (res.data.length % 3 !== 0) {
          //   setFillerItems(res.data.length % 3 > 1 ? 1 : 2);
          // }

          // set currency to catalog currency
          // setCurrency(items[0].currency);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    },
    [items, hasMore, pagination]
  );

  // const fetchItems = useCallback(
  //   ({
  //     catalogId,
  //   }: {
  //     catalogId: string;
  //     paging?: boolean;
  //     limit?: number;
  //     offset?: number;
  //     searchTerm?: string | null;
  //     inventoryType?: string | null;
  //     isReset?: boolean;
  //     sortBy?: string;
  //     sortDirection?: "asc" | "desc";
  //   }) => {
  //     console.log("FETCHING ITEM", {
  //       catalogId,
  //       paging,
  //       limit,
  //       offset,
  //       searchTerm,
  //       inventoryType,
  //       isReset,
  //       sortDirection,
  //       sortBy,
  //     });
  //     setIsLoading(true);
  //     InventoryService.getCatalogItems({
  //       catalogId,
  //       paging,
  //       limit,
  //       offset,
  //       searchTerm,
  //       inventoryType,
  //       sortDirection,
  //       sortBy,
  //     })
  //       .then((res) => {
  //         // if (isReset) {
  //         //   console.log("BANANA RESET", searchTerm, res.data);
  //         //   setItems((_) => [...res.data]);
  //         //   setHasMore(true);
  //         // } else {
  //         //   console.log("BANANA NOT RESET", searchTerm, res.data);
  //         //   setItems((prev) => [...prev, ...res.data]);
  //         // }

  //         if (isReset) {
  //           console.log("ISRESET RESPONSE", res);
  //           // Reset the items and ensure uniqueness
  //           setItems((_) => [
  //             ...new Map(res.data.map((item) => [item.id, item])).values(),
  //           ]);

  //           console.log("FIRST DATA", items);
  //           setHasMore(true);
  //         } else {
  //           console.log("NOT ISRESET RESPONSE COMBINED", res);
  //           // Append new items and ensure uniqueness
  //           setItems((prev) => {
  //             console.log("COMBINED DATA", {
  //               resData: res.data,
  //               current: prev,
  //             });
  //             const combinedItems = [...prev, ...res.data];
  //             const uniqueItems = [
  //               ...new Map(
  //                 combinedItems.map((item) => [item.id, item])
  //               ).values(),
  //             ];

  //             console.log("COMBINED ITEM RESPONSE", combinedItems);
  //             return uniqueItems;
  //           });
  //         }

  //         if (res.pagination) {
  //           setPagination(res.pagination);
  //         }

  //         if (res.data.length === 0) {
  //           setHasMore(false);
  //         }

  //         // if (res.data.length % 3 !== 0) {
  //         //   setFillerItems(res.data.length % 3 > 1 ? 1 : 2);
  //         // }

  //         // set currency to catalog currency
  //         // setCurrency(items[0].currency);
  //       })
  //       .catch((err) => console.error(err))
  //       .finally(() => setIsLoading(false));
  //   },
  //   [items, hasMore, pagination]
  // );

  // TODO: handle this later
  // const useCatalogItems = ({
  //   catalogId,
  //   paging = true,
  //   limit = 10,
  //   offset = 0,
  //   searchTerm,
  //   inventoryType,
  //   isReset = true,
  //   sortDirection,
  //   sortBy,
  // }: {
  //   catalogId: string;
  //   paging?: boolean;
  //   limit?: number;
  //   offset?: number;
  //   searchTerm?: string | null;
  //   inventoryType?: string | null;
  //   isReset?: boolean;
  //   sortBy?: string;
  //   sortDirection?: "asc" | "desc";
  // }) => {
  //   console.log("FETCHING ITEM", {
  //     catalogId,
  //     paging,
  //     limit,
  //     offset,
  //     searchTerm,
  //     inventoryType,
  //     isReset,
  //     sortDirection,
  //     sortBy,
  //   });
  //   const { data, isLoading, error, refetch } = useQuery({
  //     queryKey: [
  //       "catalogItems",
  //       catalogId,
  //       paging,
  //       limit,
  //       offset,
  //       searchTerm,
  //       inventoryType,
  //       sortBy,
  //       sortDirection,
  //     ],
  //     queryFn: () =>
  //       InventoryService.getCatalogItems({
  //         catalogId,
  //         paging,
  //         limit,
  //         offset,
  //         searchTerm,
  //         inventoryType,
  //         sortDirection,
  //         sortBy,
  //       }),
  //     enabled: !!catalogId,
  //   });

  //   return {
  //     data: data?.data,
  //     pagination: data?.pagination,
  //     isLoading,
  //     error,
  //     refetch,
  //   };
  // };

  const deleteInventoryItemBatch = (ids: string[], catalogId?: string) => {
    setIsLoading(true);
    ItemService.deleteInventoryItemBatch({ ids, catalogId })
      .then((res) => {
        if (res) {
          setAlertMessage(
            t("catalog.inventory.removeItemsSuccessModalLabel", {
              selected: ids.length,
            })
          );
          setShowAlert(true);

          setTimeout(() => {
            const url = new URL(window.location.href);
            url.searchParams.delete("inventory_type");
            window.location.href = url.toString();
          }, 2000);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  const fetchCatalog = (catalogId: string, callback: (res: any) => void) => {
    InventoryService.getOneCatalog({
      catalogId: catalogId,
    })
      .then((res) => {
        setCatalog(res);
        // setIsCatalogOwner(res.business.id === session?.activeBusiness.id);
        const tempCartItems = getCartItems(
          catalogId,
          session?.account.id || "wildcart",
          session?.accessToken ? "local" : "session"
        );

        if (tempCartItems) {
          setCartItems(tempCartItems);
          setCartUpdated(false);
        }

        TrackerService.track(
          TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.AccessCatalogDetails,
          {
            "Catalog ID": catalogId,
            "Catalog Name": res?.catalogName,
            "Catalog Owner Company Name": res?.business.companyName,
            "Product Count": res?.itemsCount,
          }
        );

        callback(res);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchCurrentItem = useCallback(({ itemId }: { itemId: string }) => {
    setIsItemLoading(true);
    ItemService.getCatalogInventoryItemsByIds({
      ids: [itemId],
    })
      .then((res) => {
        setShowAlert(false);
        setCurrentItem(res[0]);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsItemLoading(false));
  }, []);

  const fetchInventoryFormData = (
    businessId: string,
    itemId?: string,
    catalogId?: string
  ) => {
    setIsLoading(true);
    itemId && fetchCurrentItem({ itemId });
    catalogId && fetchCatalog(catalogId, (_res) => {});
    ItemService.getInventoryItemField({
      fields: ["uom1", "uom2", "uom3", "tags", "sku"],
      businessId,
      isDistinct: false,
    })
      .then((res) => {
        const uoms: string[] = [];
        const tags: string[] = [];

        for (let i = 0; i < res.length; i++) {
          if (res[i].tags) {
            tags.push(...res[i].tags.tags);
          }

          for (const key in res[i]) {
            if (
              res[i][key] !== undefined &&
              res[i][key] !== null &&
              !uoms.includes(res[i][key]) &&
              // key !== "tags" &&
              // key !== "sku" &&
              res[i][key] !== ""
            ) {
              uoms.push(res[i][key]);
            }
          }
        }

        setAllUOMs(uoms);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  //#region ----- handler functions
  // TODO: function is outdated and unused
  const handlePaging = (action: string | number) => {
    const pagingConfig = {
      limit: pagination.limit || 50,
      offset: 0,
    };

    if (typeof action === "string") {
      switch (action) {
        case "first":
          pagingConfig.offset = 0;
          break;

        case "previous":
          pagingConfig.offset = pagination.limit * (pagination.current - 2);
          break;

        case "next":
          pagingConfig.offset = pagination.limit * pagination.current;
          break;

        case "last":
          pagingConfig.offset =
            pagination.max * pagination.limit - pagination.limit;
          break;
      }
    } else {
      pagingConfig.offset = (action - 1) * pagination.limit;
    }

    if (catalog) {
      try {
        fetchAllItems({
          catalogId: catalog?.id,
          paging: true,
          ...pagingConfig,
          inventoryType: inventoryType !== "all" ? inventoryType : "",
          searchTerm: query,
          sortDirection: (["asc", "desc"].includes(sortDirection)
            ? sortDirection
            : "desc") as "asc" | "desc",
          sortBy: sortBy ? sortBy : "updatedAt",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function handleUpdateCart(
    catalogId: string,
    activeUOM: "uom1" | "uom2" | "uom3",
    item: CatalogInventoryItemType,
    quantity?: number
  ) {
    // const itemIds = cartItems && cartItems.map((c) => c.cid);
    // const verifiedItem = await verifyItem(itemIds);
    // if (!verifiedItem) {
    //   setErrorMessage(t("catalog.supplier.items.verifyItemError"));
    //   setShowError(true);

    //   setTimeout(() => {
    //     setShowError;
    //     window.location.reload();
    //   }, 1500);

    //   return;
    // }

    TrackerService.track(
      TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.AddItemToCart,
      {
        "Catalog ID": catalogId as string,
        "Catalog Name": catalog?.catalogName,
        "Catalog Owner Company Name": catalog?.business.companyName,
        "Product ID": item.inventoryItem.id,
        "Product Name": item.inventoryItem.productName,
        Quantity: quantity,
      }
    );

    const accountId =
      searchParams.get("cart") === "wildcart"
        ? "wildcart"
        : session?.account.id || "wildcart";
    // console.log(accountId);
    const cartItems = getCartItems(
      catalogId as string,
      accountId,
      session?.account.id ? "local" : "session"
    );

    if (cartItems) {
      // cartItems.items.forEach((c) => {
      const isItemExist = cartItems?.items.find((c) => c.cid === item.id);

      updateCartQty({
        catalogId: catalogId as string,
        accountId: session?.account.id || "wildcart",
        override: isItemExist ? false : true,
        quantityChange: quantity || 1,
        inventoryItem: item,
        direct: session?.account.id ? false : true,
        activeUOM: activeUOM === isItemExist?.uom ? isItemExist.uom : activeUOM,
        storageType: session?.account.id ? "local" : "session",
      });

      updateCartUOM({
        catalogId: catalogId as string,
        accountId: session?.account.id || "wildcart",
        inventoryItem: item,
        uom: activeUOM === isItemExist?.uom ? isItemExist.uom : activeUOM,
        storageType: session?.account.id ? "local" : "session",
      });
    } else {
      createCart({
        payload: {
          catalogId: catalogId as string,
          accountId: accountId,
          direct: session?.account.id ? false : true,
          items: [
            {
              cid: item.id,
              quantity: 1,
              uom: activeUOM,
              initPrice: calculatePrice(item, activeUOM),
            },
          ],
        },
        storageType: session?.account.id ? "local" : "session",
      });
    }

    // window.dispatchEvent(new CustomEvent("cartUpdated"));

    // window.location.reload();

    // setShowAddToCartToast(true);
    // if (cartDataCallback) {
    //   cartDataCallback();
    // }
    // setTimeout(() => {
    //   setShowAddToCartToast(false);
    //   if (setCartUpdated) {
    //     setCartUpdated(true);
    //   }
    // }, 1500);
  }

  function handleUpdateOrder(
    orderId: string,
    catalog: CatalogType,
    inventoryItem: CatalogInventoryItemType,
    quantity: number,
    activeUOM: "uom1" | "uom2" | "uom3"
  ) {
    const newProduct = {
      inventoryItem: inventoryItem,
      orderItem: {
        productId: inventoryItem.inventoryItem.id,
        inventoryItem: inventoryItem.inventoryItem,
        sku: inventoryItem.inventoryItem.sku,
        productName: inventoryItem.inventoryItem.productName,
        quantity: quantity,
        uom: inventoryItem.inventoryItem[activeUOM || "uom1"],
        uomItem: {
          conversion: getConversionRate(
            activeUOM || "uom1",
            inventoryItem.inventoryItem
          ),
          price: calculatePrice(inventoryItem, activeUOM || "uom1"),
          uom: inventoryItem.inventoryItem[activeUOM || "uom1"],
        },
        uomList: [
          {
            conversion: 1,
            price: inventoryItem.price,
            uom: inventoryItem.inventoryItem.uom1,
          },
          inventoryItem.inventoryItem.uom2 && {
            conversion: inventoryItem.inventoryItem.conversion2,
            price: inventoryItem.price2 ?? inventoryItem.inventoryItem.price2,
            uom: inventoryItem.inventoryItem.uom2,
          },
          inventoryItem.inventoryItem.uom3 && {
            conversion: inventoryItem.inventoryItem.conversion3,
            price: inventoryItem.price3 ?? inventoryItem.inventoryItem.price3,
            uom: inventoryItem.inventoryItem.uom3,
          },
        ],
        conversion: getConversionRate(
          activeUOM || "uom1",
          inventoryItem.inventoryItem
        ),
        price: calculatePrice(inventoryItem, activeUOM || "uom1"),
        discount: 0,
        totalPrice:
          calculatePrice(inventoryItem, activeUOM || "uom1") * quantity,
        businessId: inventoryItem.inventoryItem.business,
        inventoryType: inventoryItem.inventoryItem.inventoryType,
        currency: inventoryItem.currency,
        productDescription: inventoryItem.inventoryItem.productDescription,
        createdById: session?.account.id || "",
      },
    };

    const businessId = getActiveBusiness()?.id;
    const unsavedOrderData = localStorage.getItem(
      `unsavedOrderData_${orderId}`
    );
    if (orderId && businessId) {
      OrderService.getOrder(orderId)
        .then((res) => {
          console.log("ORDER", res);
          console.log("LOCAL STORAGE", unsavedOrderData);
          const business: any = catalog?.business;
          if (!["DRAFT", "AWAITING_RESPONSE"].includes(res.status)) {
            window.alert(t("catalog.items.orderInvalid"));
            window.location.href = "/client/suppliers";
          }
          if (!unsavedOrderData) {
            const newUnsavedOrder: any = {
              additionalDiscount: 0,
              additionalDiscountType: "PERCENTAGE",
              additionalTaxItem: { taxLabel: "", taxAmount: 0, taxType: null },
              // additionalTax: 0,
              // additionalTaxLabel: "",
              // additionalTaxType: null,
              buyerIds: {
                businessId: res.buyer ? res.buyer.id : null,
                accountId: res.buyerUser ? res.buyerUser.id : null,
              },
              createdByBusiness: res.createdByBusiness,
              createdById: res.createdBy,
              currency: null,
              defaultTaxItem: {
                taxLabel: catalog.taxId?.taxLabel || "",
                taxAmount: catalog.taxId?.taxAmount || 0,
                taxType: catalog.taxId?.taxType || null,
              },
              deliveryDate: "",
              deliveryFee: 0,
              driverName: null,
              grandTotalAmount: 0,
              invoiceNumber: null,
              newBusiness: null,
              orderItems: [],
              downPayment: 0,
              paymentFlowType: null,
              paymentTerm: null,
              remarks: null,
              selectedExternalBusiness:
                session?.activeBusiness.id === catalog?.business.id
                  ? null
                  : catalog?.business,
              sellerIds: {
                businessId: res.seller ? res.seller.id : catalog?.business.id,
                accountId: res.seller
                  ? res.sellerUser.id
                  : business.role?.[0]?.account,
              },
              shippingMethod: null,
              trackingNumber: null,
              vehicleNumber: null,
            };
            newUnsavedOrder.orderItems.push(newProduct.orderItem);

            console.log("BANANA ORDER ITEM", newUnsavedOrder);
            localStorage.setItem(
              `unsavedOrderData_${orderId}`,
              JSON.stringify(newUnsavedOrder)
            );
          } else {
            const orderData = JSON.parse(unsavedOrderData);
            const currentOrderItems = orderData.orderItems.find(
              (i: any) => i.productId === newProduct.orderItem.productId
            );
            // console.log("CURRENT ORDER ITEM", {
            //   newProduct,
            //   currentOrderItems,
            // });

            // if there is orderItem with same id, add qty instead
            if (currentOrderItems) {
              const newQuantity =
                currentOrderItems.quantity + newProduct.orderItem.quantity;
              currentOrderItems.quantity = newQuantity;
              (currentOrderItems.totalPrice =
                calculatePrice(
                  newProduct.inventoryItem,
                  newProduct.orderItem.uom as "uom1" | "uom2" | "uom3"
                ) * newQuantity),
                (currentOrderItems.uom = newProduct.orderItem.uom);
              currentOrderItems.uom = newProduct.orderItem.uom;
              currentOrderItems.conversion = newProduct.orderItem.conversion;
              currentOrderItems.price = newProduct.orderItem.price;
              currentOrderItems.uomItem = {
                ...newProduct.orderItem.uomItem,
              };
            } else {
              orderData.orderItems.push(newProduct.orderItem);
            }
            orderData.selectedExternalBusiness =
              orderData.selectedExternalBusiness
                ? orderData.selectedExternalBusiness
                : session?.activeBusiness.id === catalog?.business.id
                ? null
                : catalog?.business;
            orderData.sellerIds = orderData.sellerIds
              ? orderData.sellerIds
              : {
                  businessId: res.seller ? res.seller.id : businessId,
                  accountId: res.seller
                    ? res.sellerUser.id
                    : business.role?.[0]?.account,
                };

            console.log("BANANA ORDER ITEM", orderData);

            localStorage.setItem(
              `unsavedOrderData_${orderId}`,
              JSON.stringify(orderData)
            );
          }

          // setUnsavedOrderData(
          //   localStorage.getItem(`unsavedOrderData_${orderId}`)
          // );
        })
        .catch((error: any) => {
          console.error(error);
          window.alert("Error adding item to order. Please try again.");
        });
    }
  }

  return {
    isPending: inventoryCategoryQuery.isPending,
    inventoryCategories: inventoryCategoryQuery.data,
    fetchAllItems,
    // fetchItems,
    fetchCatalog,
    handlePaging,
    handleUpdateCart,
    handleUpdateOrder,
    deleteInventoryItemBatch,
    items,
    setItems,
    catalog,
    setCatalog,
    pagination,
    setPagination,
    isLoading: isLoading || imgExistanceCheckQuery.isLoading,
    setIsLoading,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    hasMore,
    setHasMore,
    showError,
    setShowError,
    unauthorized,
    setUnauthorized,
    currentItemsCount,
    setCurrentItemsCount,
    cartItems,
    setCartItems,
    showToast,
    setShowToast,
    confirmDelete,
    setConfirmDelete,
    showShareModal,
    setShowShareModal,
    confirmDuplicate,
    setConfirmDuplicate,
    fillerItems,
    setFillerItems,
    currency,
    setCurrency,
    imgExistanceCheckQuery,
    currentItem,
    setCurrentItem,
    cartUpdated,
    setCartUpdated,
    // unsavedOrderData,
    // setUnsavedOrderData,
    error,
    setError,
    setAllUOMs,
    fetchCurrentItem,
    fetchInventoryFormData,
    allUOMs,
    searchTerm,
    setSearchTerm,
    isItemLoading,
    // useCatalogItems,
  };
};

export default useContainer;
