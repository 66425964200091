import { UseFormReturn } from "react-hook-form";
import { CustomCreateCatalogType } from "../../types/catalog";
import { Dispatch, SetStateAction, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

type FormMethods = Pick<
  UseFormReturn<any>,
  "control" | "watch" | "setValue" | "getValues"
>;

const useFormContainer = (
  formMethods: FormMethods,
  catalog: CustomCreateCatalogType,
  setCatalog: Dispatch<SetStateAction<CustomCreateCatalogType>>
) => {
  const { getValues } = formMethods;

  const { catalogId } = useParams();

  const [currentStep, setCurrentStep] = useState(
    catalogId ? 1 : catalog.step ?? 1
  );
  const [nationalTaxes, setNationalTaxes] = useState<any[]>([]);

  /**
   * validate each step items before proceed
   * @param step number
   * @returns boolean
   */
  const handleValidateStep = (step: number) => {
    let result = false;
    switch (step) {
      case 1:
        const fieldsToValidate = getValues([
          "catalogName",
          "paymentFlowType",
          "currency",
        ]);

        // make sure all fields are filled
        const areFieldsValid =
          !fieldsToValidate.some((field) => field === undefined) &&
          !fieldsToValidate.includes(null) &&
          !fieldsToValidate.includes("");

        // validate startDate is before endDate
        const startDate = getValues("periodStartDate");
        const endDate = getValues("periodEndDate");
        const areDatesValid =
          startDate && endDate
            ? (dayjs().isBefore(startDate, "days") ||
                dayjs().isSame(startDate, "days")) &&
              dayjs(startDate).isBefore(endDate)
            : true;

        result = areFieldsValid && areDatesValid;
        break;

      case 2:
        result = !(getValues("items")?.length === 0);
        break;

      case 3:
        result =
          !(getValues("deliveryFeeConfig.feeValues").length === 0) &&
          catalog.step === 3;
        break;

      default:
        result = false;
        break;
    }

    return result;
  };

  /**
   * save current changes to localStorages
   * @param changes
   */
  const handleSaveData = (changes?: any) => {
    const data = getValues();
    const savePayload = {
      ...catalog,
      ...data,
      ...changes,
      taxId: nationalTaxes.find((tax) => tax.id === data.taxId) ?? null,
    };

    console.log("SAVE PAYLOAD", savePayload);

    setCatalog(savePayload as CustomCreateCatalogType);

    localStorage.setItem(
      catalogId ? "edit-catalog" : "new-catalog",
      JSON.stringify(savePayload)
    );
  };

  /**
   * save changes to localStorage, proceed to next step
   */
  const handleGoToNext = () => {
    if (!handleValidateStep(currentStep)) {
      return;
    }

    handleSaveData(!catalogId && { step: currentStep + 1 });
    setCurrentStep((prev) => prev + 1);
  };

  /**
   * save changes to localStorage, go back to previous page
   */
  const handleGotoPrev = () => {
    handleSaveData();
    setCurrentStep((prev) => prev - 1);
  };

  return {
    handleValidateStep,
    handleSaveData,
    handleGoToNext,
    handleGotoPrev,
    currentStep,
    setCurrentStep,
    nationalTaxes,
    setNationalTaxes,
  };
};

export default useFormContainer;
