import { PlusIcon } from "@heroicons/react/24/solid";
import { Button, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { theme } from "../Theme";

type Props = {
  onClick: () => void;
};

const CreateInventoryDropzone = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={"column"}
      sx={{
        borderWidth: "1px",
        borderColor: theme.palette.neutral[300],
        width: { lg: "479px" },
        minHeight: "320px",
        height: { lg: "550px" },
        borderRadius: "8px",
        gap: "20px",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        py: { xs: "30px", lg: "72px" },
        px: "80px",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        transitionDuration: "300ms",
        "&:hover": {
          borderColor: theme.palette.primary[500],
        },
      }}
    >
      <Typography
        level="text-lg-bold"
        sx={{ color: theme.palette.common.black }}
      >
        {t("catalog.inventory.empty.option")} 2
      </Typography>
      <img
        src="/single.png"
        width={100}
        height={100}
        className="object-contain"
      />
      <Stack
        direction={"column"}
        sx={{
          gap: "4px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography
          level="text-lg-semibold"
          sx={{ color: theme.palette.common.black }}
        >
          {t("catalog.inventory.empty.option2")}
        </Typography>
        <Typography level="text-sm-regular">
          {t("catalog.inventory.empty.option2Prompt")}
        </Typography>
      </Stack>
      {/* <Link to={`/client/${BrowserRouterConstants.InventoryCreate}`}> */}
      <Button
        onClick={onClick}
        startDecorator={<PlusIcon width={18} height={18} />}
        className="whitespace-nowrap"
      >
        {t("catalog.inventory.empty.startNowBtn")}
      </Button>
      {/* </Link> */}
    </Stack>
  );
};

export default CreateInventoryDropzone;
